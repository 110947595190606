import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

import PersonalInfo from './PersonalInfo';
import ProfessionalInfo from './ProfessionalInfo';
import Portfolio from './Portfolio';
import SocialAccounts from './SocialAccounts';
import AccountSecurity from './AccountSecurity';
import BankDetails from './BankDetails';

import { connect } from 'react-redux';
import {User} from '../../actions/user';

import Api from '../../api/Api';

import Main from '../../components/Main';

import { UserOutlined } from '@ant-design/icons';

import LayoutHelper from '../../helpers/LayoutHelper';

import { Progress, Tabs, Modal, Button } from 'antd';

class ProfileUpdate extends Component { 
    constructor(props) {
        super();
        this.state = {

            logout: false,

            error_message: '',
            success_message: '',

            active_tab: '1',

            user: false,

            display_modal: false,

            user: false,

            tools: [],
            languages: [],
            industry: [],
            skills: [],
            job_options: [],
            job_status_options: [],
            work_hour_options: [],
            countries_list: [],
            industries_list: [],
            bpo_options: []
        }

        this.page = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(user && account_token){

            var _user = JSON.parse(user);
            this.setState({user: _user});

            if(_user.profile_progress < 70){

                this.setState({display_modal: true});
            }

            this.loadFields(account_token);
        }else{

            this.setState({logout: true});
        }
    }

    updateUser = (user) => {

        this.props.User(user);
        localStorage.setItem('user', JSON.stringify(user));
        this.setState({user: user});
    }

    profileProgress = () => {

        return (
            <div className="profile-progress">
                
                <div className="progress-info c-brown fw-600 fs-20">{this.state.user ? this.state.user.profile_progress : 0}%</div>
            </div>
        )
    }

    render () {
        if(this.state.logout){
            return <Redirect to='/logout'/>;
        }

        return (

            <>
                <Main page_title="Account Details" page="account_update_profile" error_message={this.state.error_message} success_message={this.state.success_message}>

                    <div className="section container update-profile-page d-flex" ref={this.page}>
                        
                        <div className="profile-wrapper">
                            
                            <Tabs onTabClick={(key, e) => {
                                this.setState({active_tab: key})
                            }} defaultActiveKey="1" activeKey={this.state.active_tab} tabPosition="top" className="profile-tabs" tabBarExtraContent={this.profileProgress()}>

                                <Tabs.TabPane tab={<span>Personal Information</span>} key="1">

                                    <PersonalInfo
                                        onSuccess={(message, _continue) => {
                                            LayoutHelper.addSuccessMessage(this, message);

                                            if(_continue){
                                                this.setState({active_tab: '2'});
                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        }}
                                        onError={(message) => {
                                            LayoutHelper.addErrorMessage(this, message);
                                        }}
                                        updateUser={(user) => {
                                            this.updateUser(user);
                                        }}
                                        tools={this.state.tools}
                                        industry={this.state.industry}
                                        skills={this.state.skills}
                                        languages={this.state.languages}
                                        job_options={this.state.job_options}
                                        job_status_options={this.state.job_status_options}
                                        work_hour_options={this.state.work_hour_options}
                                        countries_list={this.state.countries_list}
                                    />
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<span>Professional Information</span>} key="2">

                                    <ProfessionalInfo
                                        onSuccess={(message, _continue) => {
                                            LayoutHelper.addSuccessMessage(this, message);

                                            if(_continue){
                                                this.setState({active_tab: '3'});
                                                this.page.current.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        }}
                                        onError={(message) => {
                                            LayoutHelper.addErrorMessage(this, message);
                                        }}
                                        updateUser={(user) => {
                                            this.updateUser(user);
                                        }}
                                        tools={this.state.tools}
                                        industry={this.state.industry}
                                        skills={this.state.skills}
                                        languages={this.state.languages}
                                        industries_list={this.state.industries_list}
                                        bpo_options={this.state.bpo_options}
                                        jumpToNext={() => {

                                            this.setState({active_tab: '3'})
                                        }}
                                    />
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<span>Portfolio</span>} key="3">

                                    <Portfolio
                                        tools={this.state.tools}
                                        industry={this.state.industry}
                                        skills={this.state.skills}
                                        languages={this.state.languages}
                                        updateUser={(user) => {
                                            this.updateUser(user);
                                        }}
                                        onError={(message) => {
                                            LayoutHelper.addErrorMessage(this, message);
                                        }}
                                        jumpToNext={() => {

                                            this.setState({active_tab: '4'})
                                        }}
                                    />
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<span>Social Accounts</span>} key="4">

                                    <SocialAccounts
                                        user={this.state.user}
                                        account_token={this.state.account_token}

                                        jumpToNext={() => {

                                            this.setState({active_tab: '5'})
                                        }}

                                        onSuccess={(user, message) => {

                                            this.updateUser(user)
                                            LayoutHelper.addSuccessMessage(this, message);
                                        }}
                                        onError={(message) => {
                                            LayoutHelper.addErrorMessage(this, message);
                                        }}
                                    />
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<span>Account Security</span>} key="5">

                                    <AccountSecurity
                                        user={this.state.user}
                                        updateUser={(user) => {
                                            this.updateUser(user);
                                        }}
                                        onSuccess={(message) => {
                                            LayoutHelper.addSuccessMessage(this, message);
                                        }}
                                        onError={(message) => {
                                            LayoutHelper.addErrorMessage(this, message);
                                        }}
                                        jumpToNext={() => {

                                            this.setState({active_tab: '6'})
                                        }}
                                    />
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<span>Bank Details</span>} key="6">

                                    <BankDetails
                                        account_token={this.state.account_token}
                                        onError={(message) => {
                                            LayoutHelper.addErrorMessage(this, message);
                                        }}
                                        updateUser={(user) => {
                                            this.updateUser(user);
                                        }}
                                        onSuccess={(message) => {
                                            LayoutHelper.addSuccessMessage(this, message);
                                        }}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    </div>

                    <Modal
                        visible={this.state.display_modal}
                        footer={[]}
                        className="profile-modal"
                        width={800}
                        onCancel={() => {
                            this.setState({display_modal: false});
                        }}
                    >
                        <div className="profile-modal-content">
                            <div className="profile-icon">
                                
                                <UserOutlined className="fs-40 c-w op-6" />
                            </div>

                            <div className="profile-modal-body">
                                <strong>You’re almost Done!</strong>
                                <div className="d-flex profile-progress">
                                    <span>{this.state.user ? this.state.user.profile_progress : 0}%</span>
                                    <Progress percent={this.state.user ? this.state.user.profile_progress : 0} strokeColor="#FF4500" showInfo={false} />
                                </div>
                                <div className="profile-modal-text">
                                    <b>A minimum completion rate of 70% is required to continue.</b>
                                    Your seller profile is off to a the great start. Take a moment to complete the following steps, to help build more trust between you and your customers so you can get more work.
                                </div>
                                <div className="profile-modal-text">
                                    <b>Please complete the following steps:</b>
                                    {this.state.user && this.state.user.profile_progress < 50
                                        ?
                                            <>
                                                <p>Personal Information</p>
                                                <p>Professional Information</p>
                                                <p>Portfolio</p>
                                                <p>Connect your social acconts</p>
                                            </>
                                        :
                                            this.state.user && this.state.user.profile_progress < 60
                                                ?
                                                    <>
                                                        <p>Professional Information</p>
                                                        <p>Portfolio</p>
                                                        <p>Connect your social acconts</p>
                                                    </>
                                                :
                                                    this.state.user && this.state.user.profile_progress < 66
                                                        ?
                                                            <>
                                                                <p>Portfolio</p>
                                                                <p>Connect your social acconts</p>
                                                            </>
                                                        :
                                                            null
                                    }
                                </div>
                                <div className="profile-modal-footer mt-1">
                                    <Button className="btn" onClick={() => {
                                        this.setState({display_modal: false});
                                    }}>Edit Profile</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Main>
            </>
        )
    }

    loadFields = (account_token) => {

        const formData = new FormData();
        var that = this;

        if(account_token){

            formData.append('account_token', account_token);
        }

        Api.post('general/fields', formData, function(data){
            
            that.setState({
                tools: data.tools,
                languages: data.languages,
                industry: data.industry,
                skills: data.skills,
                job_options: data.job_options,
                job_status_options: data.job_status_options,
                work_hour_options: data.work_hour_options,
                countries_list: data.countries_list,
                industries_list: data.industries_list,
                bpo_options: data.bpo_options
            });

            if(data.user){

                console.log(data.user);
                
                var _user = data.user;
                that.setState({user: _user});

                that.updateUser(_user);

                if(_user.profile_progress < 70){

                    that.setState({display_modal: true});
                }

            }else{

                that.setState({logout: true});
            }
        });
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(ProfileUpdate);
