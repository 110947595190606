import React, { Component } from 'react';

import { Link } from "react-router-dom";

// add form components
import { Table, Tabs, Empty, Avatar, Rate } from 'antd';

import FeatherIcon from 'feather-icons-react';

import Api from 'api/Api';

class ProjectsBuyerBlock extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            projects: [],
            no_data: false,

            type: 'active',

            user: false,
            account_token: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});
        }else{

            // window.location = Api.url + '/logout';
        }

        this.loadProjects(account_token, this.state.type);
    }

    loadProjects = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('status', type);
        
        var that = this;

        this.setState({loading: true});
        Api.post('buyer/projects', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status){
            
                if(data.projects.length > 0){

                    that.setState({projects: data.projects, no_data: false});
                }else{

                    that.setState({no_data: true, projects: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // that.props.errorMessage(data.message);
            }
        });
    }

    getColumns = (key) => {

        const columns = [
            {title: 'Project Date', dataIndex: 'added_on_formatted', key: 'added_on_formatted',},
        ];

        if(key === 'active'){

            columns.splice(0, 0, {title: 'Project', dataIndex: 'title', key: 'title', render: (name, row) => {

                if(row.proposals_count > '0'){
                
                    return <Link to={"/project-proposals/" + row.project_unq_id}><span className='fs-14 fw-500 c-dg2'>{row.title}</span></Link>
                }else{

                    return <Link to={"/account/post-project/initial/" + row.project_unq_id}><span className='fs-14 fw-500 c-dg2'>{row.title}</span></Link>
                }
            }})

            columns.splice(2, 0, {title: 'Proposals', align:'center', dataIndex: 'proposals_count', key: 'proposals_count', render: (name, row) => {

                return <Link to={"/project-proposals/" + row.project_unq_id}><span className='fs-14 fw-b c-b'>{row.proposals_count}</span></Link>
            }});
            columns.splice(3, 0, {title: 'Messages', dataIndex: 'messages', key: 'messages'});
            columns.splice(4, 0, {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted'});
            columns.splice(5, 0, {title: 'Visibility', dataIndex: 'visibility', key: 'visibility'});
        }

        if(key === 'awarded'){

            columns.splice(0, 0, {title: 'Project', dataIndex: 'title', key: 'title', render: (name, row) => {

                return <Link to={`/project-flow/${row.project_unq_id}/${row.awarded_freelancer}`}><span className='fs-14 fw-500 c-dg2'>{row.title}</span></Link>
            }})

            columns.splice(2, 0, {title: 'Awarded', align:'left', dataIndex: 'freelancer_name', key: 'freelancer_name', render: (name, row) => {

                return (
                    <div>
                        <Avatar size={20} src={row.profile_pic_full} />
                        <span className="ml-5 fs-13">{name}</span>
                    </div>
                )
            }});

            columns.splice(3, 0, {title: 'Awarded On', dataIndex: 'awarded_on_date_formatted', key: 'awarded_on_date_formatted'});
            columns.splice(4, 0, {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted'});
            columns.splice(5, 0, {title: 'Visibility', dataIndex: 'visibility', key: 'visibility'});
        }

        if(key === 'delivered'){

            columns.splice(0, 0, {title: 'Project', dataIndex: 'title', key: 'title', render: (name, row) => {

                return <Link to={`/project-flow/${row.project_unq_id}/${row.awarded_freelancer}`}><span className='fs-14 fw-500 c-dg2'>{row.title}</span></Link>
            }})

            columns.splice(2, 0, {title: 'Awarded', align:'left', dataIndex: 'freelancer_name', key: 'freelancer_name', render: (name, row) => {

                return (
                    <div>
                        <Avatar size={20} src={row.profile_pic_full} />
                        <span className="ml-5">{name}</span>
                    </div>
                )
            }});

            columns.splice(3, 0, {title: 'Awarded On', dataIndex: 'awarded_on_date_formatted', key: 'awarded_on_date_formatted'});
            columns.splice(4, 0, {title: 'End Date', dataIndex: 'ended_on_date_formatted', key: 'ended_on_date_formatted'});
            columns.splice(5, 0, {title: 'Service Provider Rating', dataIndex: 'seller_feedback', key: 'seller_feedback', render: (name, row) => {

                if(row.seller_feedback > 0){
                
                    return <div><span className="rating justify-start"><Rate className='rating sm' disabled value={(row.seller_feedback)} /></span></div>
                }else{

                    return <span className='fs-12 op-7'>Pending</span>
                }
            }});
            columns.splice(6, 0, {title: 'Your Rating', dataIndex: 'buyer_feedback', key: 'buyer_feedback', render: (name, row) => {

                if(row.buyer_feedback > 0){
                
                    return <div><span className="rating justify-start"><Rate className='rating sm' disabled value={(row.buyer_feedback)} /></span></div>
                }else{

                    return <span className='fs-12 op-7'>Pending</span>
                }
            }})
        }

        return columns;
    }
    
    render () {
        const tableColumns = [
            {
                title: 'Buyer / Client',
                dataIndex: 'buyer',
                key: 'buyer',
            },
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project',
            },
            {
                title: 'Target Date',
                dataIndex: 'targetdate',
                key: 'targetdate',
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
        ];

        return (

            <>
                <Tabs className="manage-projects-list" defaultActiveKey="active" tabPosition={this.props.source === 'projects' ? 'left' : 'top'} onTabClick={(key, event) => {

                    this.setState({type: key});
                    this.loadProjects(this.state.account_token, key);
                }}>
                    
                    <Tabs.TabPane tab={
                        <a>
                            <FeatherIcon icon="slack" /><span>Open For Bids</span>
                        </a>
                        } key="active">

                        <div className="tab-heading">Open For Bids</div>
                        <Table columns={this.getColumns('active')} dataSource={this.state.projects}>
                            <Empty />
                        </Table>

                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <FeatherIcon icon="award" /><span>Awarded</span>
                        </a>
                        } key="awarded">
                        
                        <div className="tab-heading">Awarded</div>
                        <Table columns={this.getColumns('awarded')} dataSource={this.state.projects}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <FeatherIcon icon="thumbs-up" /><span>Delivered</span>
                        </a>
                        } key="delivered">
                        
                        <div className="tab-heading">Delivered</div>
                        <Table columns={this.getColumns('delivered')} dataSource={this.state.projects}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane>

                    {/* <Tabs.TabPane tab={
                        <a>
                            <FeatherIcon icon="check-circle" /><span>Completed</span>
                        </a>
                        } key="completed">
                        
                        <div className="tab-heading">Completed</div>
                        <Table columns={projectTableColumns}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane> */}

                    <Tabs.TabPane tab={
                        <a>
                            <FeatherIcon icon="x-circle" /><span>Cancelled</span>
                        </a>
                        } key="cancelled">
                        
                        <div className="tab-heading">Cancelled</div>
                        <Table columns={tableColumns}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane>

                    {/* <Tabs.TabPane tab={
                        <a>
                            <FeatherIcon icon="pause-circle" /><span>On-Hold</span>
                        </a>
                        } key="on_hold">
                        
                        <div className="tab-heading">On-Hold</div>
                        <Table columns={tableColumns}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <FeatherIcon icon="alert-triangle" /><span>Suspended</span>
                        </a>
                        } key="suspended">
                        
                        <div className="tab-heading">Suspended</div>
                        <Table columns={pendingTableColumns}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane> */}

                </Tabs>
            </>
        )
    }
}

export default ProjectsBuyerBlock;