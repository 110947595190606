import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { Typography, Tag, Button } from 'antd';

import { UserOutlined, ClockCircleOutlined, HeartOutlined, MailOutlined, CheckCircleOutlined } from '@ant-design/icons';

import ProfilePic from 'components/ProfilePic';

import Price from 'components/Price';

class ProjectRow extends Component { 
    
    linkWrapper = (project) => {

        return (
            <>
                <span className='fs-22 fw-600 l-h-11 c-b'>{project.title}</span>
                {project.verified && (project.verified == 1)
                    ?
                        <span className="verified"><CheckCircleOutlined /> Verified</span>
                    :
                        null
                }
            </>
        )
    }

    render () {

        var project = this.props.project;
        
        if(project){
            
            return (

                <div className="row-item vertical">
                    <div className="top-section flex justify-between w-100">

                        {this.props.source && this.props.source === 'project'
                            ?
                                <a href={"/projects/details/" + project.slug} className="title">
                                    {this.linkWrapper(project)}
                                </a>
                            :
                                <Link to={"/projects/details/" + project.slug} className="title">
                                    {this.linkWrapper(project)}
                                </Link>
                        }

                        <div className="price-section c-b vertical align-right mt-10">
                            <div className="price fs-20 fw-b l-h-1">
                                <Price>{project.budget_formatted}</Price>
                            </div>
                            <div className="fs-12">{project.work_type_label}</div>
                        </div>
                    </div>
                    
                    <div className="mid-section">
                        {project.featured && (project.featured == 1)
                            ?
                                <div className="tags">
                                    <Tag>Featured</Tag>
                                </div>
                            :
                                null
                        }
                        
                        <Typography.Paragraph className="l-h-15 fs-15 c-dg op-8 mt-10" ellipsis={{rows: this.props.rows ? this.props.rows : 2}}>{project.description}</Typography.Paragraph>
                    </div>
                    <div className="bottom-section mt-10 justify-between w-100">

                        <div className="details flex-start wrap w-80">
                            <div className="flex-start w-25">
                                <ProfilePic src={project.profile_pic_full} size="small" />
                                <div className="fs-14 fw-500 c-b ml-3">{project.freelancer_name}</div>
                            </div>
                            <div className="flex-start w-25">
                                <ClockCircleOutlined className='c-o' />
                                <div className="fs-14 fw-500 c-b ml-3">{project.posting_time}</div>
                            </div>
                            <div className="flex-start w-25">
                                <HeartOutlined className='c-o' />
                                <div className="fs-14 fw-500 c-b ml-3">{project.likes_count}</div>
                            </div>
                            <div className="flex-start w-25">
                                <MailOutlined className='c-o' />
                                <div className="fs-14 fw-500 c-b ml-3">{project.proposals_count} proposal{project.proposals_count > 1 ? "'s" : ''}</div>
                            </div>
                        </div>

                        {this.props.user
                            ?
                                this.props.user.unq_index_id === project.user_unq_id
                                    ?
                                        null
                                    :
                                        <Link to={"/projects/details/" + project.slug}>
                                            <Button type="primary">Send Proposal</Button>
                                        </Link>
                            :
                                <Link to={"/projects/details/" + project.slug}>
                                    <Button type="primary">Send Proposal</Button>
                                </Link>
                        }
                    </div>
                </div>
            )
        }else{

            return null;
        }
    }
}

export default ProjectRow;