import React, { Component } from 'react';

import { Tabs } from 'antd';

import Api from '../../api/Api';

import Main from '../../components/Main';

class TermsOfServicePage extends Component { 
    constructor(props) {
        super();
        this.state = {
            main_heading: '',
            sp_main_content: '',
            ss_main_content: '',
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    render () {

        return (

            <Main page_title="Terms Of Service" hide_page_title="true">
                <div className="inner-page white-bg">
                    <div className="container">
                        <div className="heading text-center">{this.state.main_heading}</div>
                        {/* <div className="content">
                            <div dangerouslySetInnerHTML={{ __html: this.state.main_content }} />
                        </div> */}
                    </div>

                    <div className="container">
                        <Tabs className="btn-tabs" defaultActiveKey="1" centered>
                            <Tabs.TabPane tab="Service Provider" key="1">
                                <div className="content">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.sp_main_content }} />
                                </div>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Service Seeker" key="2">
                                <div className="content">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.ss_main_content }} />
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </Main>
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/terms_of_service', formData, function(data){
            
            that.setState({
                main_heading: data.main_heading,
                sp_main_content: data.service_provider_main_content,
                ss_main_content: data.service_seeker_main_content
            });
        });
    }
}

export default TermsOfServicePage;