import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { Row, Col, Tabs, Checkbox, Form, Input, Alert, Modal, Popconfirm, Button } from 'antd';

import Loader from 'components/Loader';
import File from 'components/File';

import LoginBlock from '../../auth/LoginBlock';

import { CloseOutlined, CloudUploadOutlined, MessageOutlined, FilePdfFilled } from '@ant-design/icons';

import FeatherIcon from 'feather-icons-react';

import MessageRow from '../../messages/MessageRow';

import ProfileCard from 'components/ProfileCard';
import ProjctItemsGallery from './ProjctItemsGallery';

import Price from 'components/Price';

import Api from 'api/Api';

class ProjectInfo extends Component { 
    constructor(props) {
        super();
        this.state = {

            categories: [],
            total: 0,

            account_token: false,
            user: false,

            loading: false,

            is_login: false,

            media_url: '',
            gallery: [],

            login_modal: false,

            selected_message: false,

            milestones: [{
                title: '',
                amount: 0
            }],

            milestones_total: 0,

            ask_question: false,

            message: '',
            tnc_accepted: true,

            milestones_total_error: false,
            nda_terms: false
        }

        this.proposal_form = React.createRef();
        this.proposal_submission = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(user){

            this.setState({user: JSON.parse(user)});
        }
        if(account_token){

            this.setState({is_login: true, account_token: account_token});
        }
    }

    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        var project = this.props.project;
        return (

            <div className="projects-details inner-page pt-2">

                {project
                    ?
                        <div className="container">
                            {/* <div className="page-heading">Project Details</div> */}

                            {!this.state.user &&
                            
                                <div className="page-subheading mb-30">To bid on a project or send proposal to get this project, create an account now.</div>
                            }

                            <Row className="left-right-column" gutter={16}>
                                <Col className="gutter-row" span={16}>
                                    
                                    <div className="project-view">

                                        <div className="top-section box-white p-40" style={{position:'relative', overflow:'hidden'}}>
                                            <div className="fs-26 fw-500 c-b">{project.title}</div>

                                            <div className="stats">

                                                {project.featured == 1 &&
                                                
                                                    <div className="tag-wrapper">
                                                        <span className="tag-info active fs-13">Featured</span>
                                                    </div>
                                                }

                                                <div className="stats-list">
                                                    <div className="single">
                                                        <i className="mat-ico">watch_later</i>
                                                        <div className="fs-14">{project.posting_time}</div>
                                                    </div>
                                                    <div className="single">
                                                        <i className="mat-ico">favorite</i>
                                                        <div className="fs-14">{project.likes_count}</div>
                                                    </div>
                                                    <div className="single">
                                                        <i className="mat-ico">visibility</i>
                                                        <div className="fs-14">{project.views_count}</div>
                                                    </div>
                                                </div>

                                                <div className="right-block">
                                                    {project.proposals_count > 0
                                                        ?
                                                            <>
                                                                <span className="px-10 py-5 bg-brown br-5 mr-5">
                                                                    <span className="fw-b c-w fs-16">{project.proposals_count}</span>
                                                                </span>
                                                                <span className="fs-14 fw-500 op-7">person have already sent a proposal</span>
                                                            </>
                                                        :
                                                            this.state.user && (this.state.user.unq_index_id == project.user_unq_id)
                                                                ?
                                                                    null
                                                                :
                                                                    <span className="profile-text-block">Be the first to send the proposal</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <Tabs defaultActiveKey="1" className="button-tabs blue mt-50">
                                            <Tabs.TabPane tab="Info" key="1">

                                                <div className="info-tab box-white p-30">
                                                    <div className="section main-details">
                                                        
                                                        <div className="stats-list lg flex-start">

                                                            {project.estimate_duration != '' &&

                                                                <span className="single w-33">
                                                                    <div className="fw-600 fs-18 c-b">{project.estimate_duration_formatted}</div>
                                                                    <div className="fs-16 c-b op-6">Project Duration</div>
                                                                </span>
                                                            }

                                                            <span className="single w-33">
                                                                <div className="fw-600 fs-18 c-b">
                                                                    <Price>{project.budget_formatted}</Price>
                                                                </div>
                                                                <div className="fs-16 c-b op-6">{project.work_type_label} PRICE</div>
                                                            </span>

                                                            <span className="single w-33">
                                                                <div className="fw-600 fs-18 c-b">{project.work_type_label}</div>
                                                                <div className="fs-16 c-b op-6">Work Type</div>
                                                            </span>
                                                        </div>

                                                        <div className="desc-section mt-20">
                                                            <div className="fs-22 fw-500 c-b">Description</div>
                                                            <div className="text">
                                                                <p className='fs-16 c-dg2'>{project.description}</p>
                                                            </div>
                                                        </div>

                                                        {(project.hasOwnProperty('gallery') && project.gallery.length > 0) &&

                                                            <div className="attachments-section">
                                                                <div className="title">Attachments</div>
                                                                <div className="blocks">

                                                                    <div className="form-gallery sm">

                                                                        {/* <ul>
                                                                            {this.renderGallery()}
                                                                        </ul> */}

                                                                        <ProjctItemsGallery gallery={project.gallery} />
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.props.project.status == '1'
                                                            ?
                                                        
                                                                <div ref={this.proposal_form}>
                                                                    {this.state.user
                                                                        ?

                                                                            this.props.proposal_submitted
                                                                                ?

                                                                                    <Row>
                                                                                        <Col span={24} className="left-section">
                                                                                        <div className="new-proposal-wrap flex gap-5 justify-content-between align-end">
                                                                                                <div><h3><i className="mat-ico">thumb_up</i> Proposal Submitted</h3>
                                                                                                <p className='text-left fs-16 c-g'>Thank you for submitting your proposal. Now you can start conversation with the Project owner. </p>
                                                                                                </div>

                                                                                                {this.chatInfo()}
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                :

                                                                                    (this.state.user.unq_index_id != project.user_unq_id)
                                                                                        ?

                                                                                            this.state.user.profile_progress < 70
                                                                                                ?
                                                                                                    <Row>
                                                                                                        <Col span={24} className="left-section">
                                                                                                            <div className="new-proposal-wrap" style={{padding:'35px 100px'}}>
                                                                                                                <h3>Incomplete Profile</h3>
                                                                                                                <p><strong>A minimum completion rate of 70% is required to continue.</strong></p>

                                                                                                                <p>Your seller profile is off to a the great start. Take a moment to complete the following steps, to help build more trust between you and your customers so you can get more work.</p>
                                                                                                                <a className="btn solid purple square btn-sm" href={Api.url + 'account/profile-update'}>Edit Profile</a>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                :
                                                                                                    this.proposalForm(layout, validateMessages)
                                                                                        :
                                                                                            null
                                                                        :
                                                                            <div className="signup-section">
                                                                                <div className="vertical w-60">
                                                                                    <div className="fs-22 fw-500 c-o">New Proposal</div>
                                                                                    <div className="fs-16 c-dg">Create an account now and send a proposal now to get this project.</div>
                                                                                </div>
                                                                                <div className="mt-10 w-40 text-right">
                                                                                    <Button htmlType="button" type="primary" onClick={() => {

                                                                                        this.setState({login_modal: true})
                                                                                    }}>Sign In</Button>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            :
                                                            
                                                            <Row>
                                                                <Col span={24} className="left-section">
                                                                    <div className="new-proposal-wrap">
                                                                        <h3>Project Awarded</h3>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }

                                                    </div>

                                                </div>
                                            </Tabs.TabPane>

                                            {this.state.user && (this.state.user.unq_index_id != project.user_unq_id) && this.props.project.status == '1'
                                                ?
                                                    <Tabs.TabPane tab="Clarification Board" key="2">
                                                        <div className="clarification-board-tab box-white p-30">

                                                            {!this.props.proposal_submitted
                                                                ?
                                                                    <>

                                                                        {!this.state.ask_question &&

                                                                            <div className="section no-messages p-30 flex justify-between">
                                                                                <div className="left">
                                                                                    <div className="fw-600 fs-20 c-b">Clarification Board</div>
                                                                                    {/* <div className="fs-14 c-b">There are no clarification messages.</div> */}
                                                                                </div>
                                                                                <div className="right">
                                                                                    <Button type="primary" onClick={() => {

                                                                                        this.setState({ask_question: true})
                                                                                    }}>Ask a Question</Button>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {this.state.ask_question &&

                                                                            <>
                                                                        
                                                                                <div className="clarification-details">
                                                                                    <div className="section">
                                                                                        <div className="left">
                                                                                            <div className="fs-20 fw-600 c-b mb-10">Clarification Board</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Form layout="vertical" requiredMark="true">
                                                                                        <Form.Item label="Please type your question" onChange={(e) => {

                                                                                            this.setState({message: e.target.value})
                                                                                        }} value={this.state.message}>
                                                                                            <Input.TextArea style={{fontSize:14}} rows={6} />
                                                                                        </Form.Item>
                                                                                        <Form.Item onChange={(e) => {

                                                                                            this.setState({tnc_accepted: e.target.checked})
                                                                                        }}>
                                                                                            <Checkbox checked={this.state.tnc_accepted}>I confirm that this question is essential for the submission of my proposal and I understand that it will be publicly posted in the project’s clarification board and in case it is used to spam or solely advertise my skills, my rankings will be severely affected.</Checkbox>
                                                                                        </Form.Item>
                                                                                        <Form.Item className="buttons-block align-right">
                                                                                            
                                                                                            <div className='justify-end'>
                                                                                                <Popconfirm title="Do you really want to cancel?" onConfirm={() => {

                                                                                                    this.setState({ask_question: false, message: ''})
                                                                                                }}>
                                                                                                    <Button type="link" size="small" className='c-dg'>Cancel</Button>
                                                                                                </Popconfirm>
                                                                                                
                                                                                                <Button disabled={!this.state.tnc_accepted} onClick={() => {

                                                                                                    if(this.state.message !== ''){

                                                                                                        this.sendMessage();
                                                                                                    }
                                                                                                }} type="primary" className="btn dark-blue-btn">Send</Button>
                                                                                            </div>
                                                                                        </Form.Item>
                                                                                    </Form>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                :
                                                                    this.chatInfo()
                                                            }

                                                            {this.props.messages && this.props.messages.length > 0
                                                                ?
                                                                    <div className="clarification-chat d-flex vertical">

                                                                        {this.props.messages.map((_message, index) => {

                                                                            return <MessageRow
                                                                                        key={`message_${index}`}
                                                                                        type="large"
                                                                                        message={_message}
                                                                                        className="mb-10"
                                                                                    />
                                                                        })}
                                                                    </div>
                                                                :
                                                                    null
                                                            }
                                                            
                                                        </div>
                                                    </Tabs.TabPane>
                                                :
                                                    null
                                            }

                                        </Tabs>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <ProfileCard
                                        user={this.props.project}
                                    >

                                        {project.last_project_date_formatted != '' &&

                                            <div className="last-project mt-10 c-b">
                                                Last Project: <span className="date-text">{project.last_project_date_formatted}</span>
                                            </div>
                                        }
                                        
                                        <div className="flex justify-center mt-10 mb-20 px-10 w-100">
                                            <div className="align-center vertical w-45 px-10">
                                                <div className="fs-18 fw-b c-b">{project.project_completed}</div>
                                                <span className="fs-14 c-b text-center op-6">Project Completed</span>
                                            </div>
                                            <div className="align-center vertical w-55">
                                                <div className="fs-18 fw-b c-b">{project.freelancers_worked_with}</div>
                                                <span className="fs-14 c-b text-center op-6">Freelancers worked with</span>
                                            </div>
                                        </div>
                                    </ProfileCard>
                                </Col>
                            </Row>
                        </div>
                    :
                        <div style={{minHeight:600}}>
                            <Loader />
                        </div>
                }

                <Modal
                    visible={this.state.login_modal}
                    width={600}
                    onCancel={() => {
                        this.setState({login_modal: false})
                    }}
                    footer={null}
                >
                    <div className="login-popup">
                        <LoginBlock
                            onSuccess={(account_token) => {
                                window.location = window.location.href;
                            }}
                            onError={(message) => {
                                this.props.onError(message);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        )
    }

    chatInfo = () => {

        return (
            <div className='flex justify-center vertical mb-20'>

                {/* <MessageOutlined className="fs-60 op-3 mt-30" />

                <p className='fs-18 op-6 c-b mt-20'>Use the below link for communication</p> */}

                <Link to={`/project-flow/${this.props.project.project_unq_id}/${this.state.user.unq_index_id}`}>
                    <Button type="primary" className='px-20 py-5 h-100'>Start Conversation</Button>
                </Link>
            </div>
        )
    }

    proposalForm = (layout, validateMessages) => {

        return (
            <div className="new-proposal-details">
                                                                                                        
                <Form {...layout} scrollToFirstError={true} name="login_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>

                    <div className="page-main-content">
                        <h3 className="fs-22 fw-600 c-dbrown border-top-1 pt-20 mt-20">New Proposal</h3>
                        <div className="form-wrap mb-10">
                            <h4 className="title fs-14 c-dg">Enter your proposal details</h4>
                            <Form.Item name="description" label="" rules={[{required:true, message: 'Please enter your proposal details!'}]}>
                                <Input.TextArea autoSize={{ minRows: 3, maxRows: 7 }} style={{fontSize: 14}} placeholder="Provide general information about your project." />
                            </Form.Item>
                        </div>
                        <div className="form-wrap">
                            <h4 className="title fs-14 c-dg">Upload Files</h4>

                            <div className="form-gallery">

                                {this.displayGallery()}
                                {this.state.image_uploading
                                    ?   
                                        <Loader is_small={true} />
                                    :
                                        null
                                }
                            </div>

                            <label className="cloud-uploader">
                                <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e)}} ref={(input)=> this.myinput = input}/>
                                <CloudUploadOutlined />
                                <p className="ant-upload-text">Click here to upload</p>
                            </label>
                        </div>
                        <div className="form-wrap">
                            <Row>
                                <Col span={24} className="milestones-block border-right">
                                    <Row>
                                        <Col span={17}>
                                            {/* <h5 className="label">Project Milestone</h5> */}
                                            <label className="fs-14 c-dg" title="Project Milestone">Project Milestone</label>
                                        </Col>
                                        <Col span={5}>
                                            <label className="fs-14 c-dg pl-15" title="Project Milestone">Amount</label>
                                        </Col>
                                    </Row>
                                    {this.displayMilestones()}

                                    {this.state.milestones_total_error &&
                                    
                                        <Row>
                                            <Col span={24}>
                                                <Alert message="Milestones total is exceeding the project budget!" type="error" />
                                            </Col>
                                        </Row>
                                    }

                                    <Row>
                                        <Col span={24}>
                                            <Button type="primary" className="btn dark-blue-btn add-milestone mt-15" onClick={() => {

                                                let milestones = this.state.milestones;
                                                milestones.push({
                                                    title: '',
                                                    amount: 0
                                                })
                                                this.setState({milestones: milestones, milestones_total_error: false});
                                            }}>Add</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </div>

                        <div className="milestone-details justify-end vertical">
                            <span className="block flex mb-10">
                                <span className="label fs-16 c-b">Milestones Total</span>
                                <span className="text ml-20 fs-16 c-b fw-600">
                                    <Price>{this.state.milestones_total}</Price>
                                </span>
                            </span>
                            <span className="block mb-10 flex">
                                <span className="label fs-16 c-b">Project Budget</span>
                                <span className="text ml-20 fs-16 c-b fw-600">
                                    <Price>{this.props.project.budget}</Price>
                                </span>
                            </span>
                        </div>

                        <div className="terms justify-end vertical">
                            <Row justify="space-between" align="bottom">
                                <Col span={24}>
                                    <p className='c-dg'>Please note that the minimum service fee is 15% excluding GST. </p>
                                    {/* <p className="info">
                                        You have <b>15 Proposal Credits available</b>. You can always <span>buy more</span>
                                    </p> */}
                                </Col>
                            </Row>

                            {this.props.project.nda_request == '1'
                                ?
                                    <Row justify="space-between" align="bottom">
                                        <Col span={24}>
                                            <strong>
                                                <FeatherIcon icon="edit-2" />
                                                <span>NDA</span>
                                            </strong>
                                        </Col>
                                        <Col span={24}>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet massa interdum, vehicula ipsum nec, mattis erat. Nunc sed velit volutpat, placerat sem ac, aliquam magna. Phasellus tincidunt nisi ante, a porta nisl ultricies sed. Sed rhoncus, arcu ut semper finibus, urna eros lobortis odio, a varius ante enim rutrum tellus. Nam elementum, ante in semper laoreet, nibh arcu rhoncus metus, a accumsan ligula metus id ex.</p>
                                        </Col>
                                        <Col span={24}>
                                            <div className='justify-between'>
                                            
                                                <Checkbox name="nda" checked={this.state.nda_terms} onChange={(e) => {
                                                    this.setState({nda_button: !e.target.checked, nda_terms: !this.state.nda_terms})
                                                }}>You must agree the NDA before proceeding</Checkbox>

                                                <Button type="default" disabled={!this.state.nda_terms} loading={this.state.loading} htmlType="submit" className="btn blank-blue-btn"><span>Send</span></Button>
                                            </div>
                                        </Col>
                                    </Row>
                                :
                                    <Row justify="space-between" align="bottom">
                                        <Col span={24}>

                                            <div className='justify-between'>

                                                <Button type="default" loading={this.state.loading} htmlType="submit" className="btn blank-blue-btn"><span>Send</span></Button>
                                            </div>
                                            {/* <Button type="primary" loading={this.state.loading} htmlType="submit" className="btn dark-blue-btn ml-10"><span>Send & Feature</span></Button> */}
                                        </Col>
                                    </Row>
                            }
                            
                        </div>
                    </div>
                </Form>
            </div>
        )
    }

    renderGallery = () => {

        return this.props.project.attachments.map((_attachment, index) => {

            const file_type = _attachment.split('.')[1];

            return (

                <li key={"attachment_" + index}>

                    {(file_type === 'png' || file_type === 'jpg' || file_type  === 'jpeg')
                        ?
                    
                            <img className="preview-image" src={_attachment} />
                        :
                            <a className='align-center vertical' href={_attachment} target="_blank">
                                
                                <FilePdfFilled className="fs-40 c-b" />

                                <strong className='fs-10 mt-5 txt-uppercase'>{file_type}</strong>
                            </a>
                    }
                </li>
            )
        })
    }

    displayGallery = () => {

        var gallery = this.state.gallery;
        var _gallery = [];

        if(gallery && gallery.length > 0){

            for(var i = 0; i < gallery.length; i++){

                var key = 'gallery_item_' + i;
                const file_type = gallery[i].split('.')[1];
                
                _gallery.push(
                    <li key={key}>

                        <Popconfirm title="Are you sure" placement="topRight" onConfirm={() => {
                            gallery.splice(gallery.indexOf(i), 1);
                            this.setState({gallery: gallery});

                        }} okText="Yes" cancelText="No">
                            <button className="close" type="button">
                                <FeatherIcon icon="x" size={15} />
                            </button>
                        </Popconfirm>

                        {file_type === 'png' || file_type === 'jpg' || file_type  === 'jpeg'
                            ?
                                <img className="preview-image" src={this.state.media_url + gallery[i]} />
                            :
                                <a target="_blank" href={this.state.media_url + gallery[i]} style={{width: '100%', height: '100%'}} className="align-center vertical">

                                    <FilePdfFilled className="fs-40 c-b" />

                                    <strong className='fs-12 mt-5 txt-uppercase'>{file_type}</strong>
                                </a>
                        }
                    </li>
                );
            }

            return <ul>{_gallery}</ul>;
        }
        return '';
    }

    calculateTotal = (milestones) => {

        if(milestones.length > 0){

            var total = 0;
            for(var i = 0; i < milestones.length; i++){
                
                total = parseFloat(total) + parseFloat(milestones[i]['amount']);
            }

            this.setState({milestones_total: total});
        }
    }

    displayMilestones = () => {

        var milestones = this.state.milestones;

        if(milestones.length > 0){

            var _milestones = [];

            for(var n = 0; n < milestones.length; n++){

                var i = n;

                _milestones.push(
                    <Row key={`milestone_${n}`} className='gap-10 mb-10'>
                        <Col span={17} className="">
                            <Form.Item name={"milestone_title["+i+"]"} rules={[{ required: true, message: 'Please enter milestones details' }]}>
                                <Input value={milestones[i]['title']} onChange={(e) => {

                                    milestones[i]['title'] = e.target.value;
                                    this.setState({milestones: milestones});
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>

                            <input
                                type="number"
                                name={"milestone_amount["+i+"]"}
                                className='ant-input-number-input'
                                value={parseInt(milestones[i]['amount']) > 0 ? milestones[i]['amount'] : ''}
                                onChange={(e) => {

                                    const value = e.target.value;

                                    let milestones = this.state.milestones;

                                    if(!isNaN(value) && value > 0){

                                        milestones[i]['amount'] = value;
                                        
                                    }else{

                                        milestones[i]['amount'] = '';
                                    }

                                    let amount = 0;
                                        
                                    this.setState({milestones: milestones, milestones_total_error: false}, () => {

                                        milestones.forEach((_milestones) => {

                                            if(!isNaN(_milestones.amount) && parseInt(_milestones.amount) > 0){
                                            
                                                amount = parseInt(amount) + parseInt(_milestones.amount)
                                            }
                                        })

                                        if(parseInt(amount) > parseInt(this.props.project.budget)){

                                            milestones[i]['amount'] = '';

                                            amount = 0;

                                            milestones.forEach((_milestones) => {

                                                if(!isNaN(_milestones.amount) && parseInt(_milestones.amount) > 0){
                                                
                                                    amount = parseInt(amount) + parseInt(_milestones.amount)
                                                }
                                            })

                                            this.setState({milestones: milestones, milestones_total: amount, milestones_total_error: true})
                                        }else{

                                            this.setState({milestones_total: amount, milestones_total_error: false})
                                        }
                                    });
                                }}
                            />
                        </Col>

                        {i > 0 &&
                            <Col span={1}>
                                <Popconfirm title="Are you sure" onConfirm={() => {

                                    milestones.splice(milestones.indexOf(i), 1);
                                    this.setState({milestones: milestones});

                                }} okText="Yes" cancelText="No">
                                    <span className="close-danger ml-5 mt-5"><CloseOutlined /></span>
                                </Popconfirm>
                            </Col>
                        }
                    </Row>
                );
            }
            return _milestones;
        }
        return '';
    }

    sendMessage = () => {

        var formData = new FormData();

        formData.append('account_token', this.state.account_token);

        formData.append('project', this.props.project.project_unq_id);

        formData.append('receiver', this.props.project.user_unq_id);
        formData.append('message', this.state.message);
        
        var that = this;

        this.setState({loading: true});

        Api.post('messages/send', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){

                that.props.updateMessage(data.row);
                that.props.onSuccess(data.message);
                that.setState({message: '', ask_question: false});

            }else{
                
                that.props.onError(data.message);
            }
        });
    }

    onFinish = (values) => {
        
        var formData = new FormData();
        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key]);
            }
        });

        formData.append('account_token', this.state.account_token);

        var milestones = this.state.milestones;
        milestones = JSON.stringify(milestones);
        
        formData.append('milestones_data', milestones);

        var gallery = this.state.gallery;
        gallery = JSON.stringify(gallery);
        
        formData.append('gallery', gallery);
        formData.append('project_id', this.props.project.project_unq_id);

        if(this.state.nda_terms){

            formData.append('nda', this.props.project.project_unq_id);
        }
        
        var that = this;

        this.setState({loading: true});

        Api.post('proposals/submission', formData, function(data){
            
            that.setState({loading: false});

            if(data.status == true){

                that.props.onSuccess(data.message);
                that.setState({proposal_submitted: true});

                var project = that.props.project;
                project.proposals_count = data.total_submissions;

                that.props.updateProject(project, true);

                that.scrollToSubmission();

            }else{
                
                that.props.onError(data.message);
            }
        });
    }

    scrollToSubmission = () => {

        window.setTimeout(() => {
            this.proposal_form.current.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }

    updateGallery = (image) => {

        var gallery = this.state.gallery;
        gallery.push(image);
        this.setState({gallery: gallery});
    }

    _handleImageChange = async (e) => {

        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        const _file = file;
        
        this.setState({image_uploading: true});
    
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,svg,webp,pdf,doc,docx,ppt,pptx,txt');
        formData.append('upload_dir', 'project_propsals/');
        formData.append('project_id', this.props.project.project_unq_id);
        
        // if(_file.type.indexOf('png') > -1 && _file.type.indexOf('jpg') > -1 && _file.type.indexOf('jpeg') > -1){
            
        //     formData.append('image_sizes', JSON.stringify(_sizes));
        // }

        this.setState({image_uploading: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({image_uploading: false});

            if(data.status == 'success'){

                var url = data.random_dir + data.main_file_name;
                that.setState({media_url: data.media_url + 'project_propsals/'});

                that.updateGallery(url);
            }
        });
    }
}

export default ProjectInfo;