import React, { Component } from 'react';

import { Typography, Popconfirm } from 'antd';

import { Carousel  } from 'antd';

import Tags from '../../Tags';

class FreelancerPortfolioItem extends Component {

    constructor(props) {
        super();
        this.state = {
            sizeSmall: 'small',
            sizeLarge: 'large',
        }
    }

    render () {

        const portfolio_item = this.props.portfolio_item;

        if(portfolio_item){

            var gallery = portfolio_item.gallery_formatted;
        
            return (
            
                <div className={"row-portfolio-slider row-portfolio-slider-"}>
                    <div className={"block " + (this.props.mode && this.props.mode === 'lite' ? 'vertical w-full' : '')}>

                        <div className="img-block">
                        
                            <Carousel dots={true} autoplay={false}>
                                <div>
                                    <div className="portfolio-image" style={{backgroundImage:'url(' + portfolio_item.main_image_preview + ')'}}>

                                    </div>
                                </div>
                                {gallery.length > 0
                                    ?
                                        gallery.map((_gallery) => {

                                            var key = Math.floor(Math.random() * Math.floor(100000000));
                                            return (

                                                <div key={key}>
                                                    <div className="portfolio-image" style={{backgroundImage:'url(' + _gallery + ')'}}></div>
                                                </div>
                                            )
                                        })
                                    :
                                        null
                                }
                            </Carousel>
                        </div>
                        <div className="text-block">
                            <div className="d-flex justify-content-between title">
                                <div className="sl-title">{portfolio_item.title}</div>

                                <div className="sl-buttons">

                                    {this.props.onEdit &&
                                        <div className="pointer" onClick={()=>{

                                            this.props.onEdit(portfolio_item)
                                        }}>
                                            Edit
                                        </div>
                                    }

                                    {this.props.onRemove &&
                                        <Popconfirm placement="top" title="Do you really want to remove this item?" onConfirm={() => {
                                            
                                            this.props.onRemove(portfolio_item)
                                        }} okText="Yes" cancelText="Cancel">
                                            <div className="pointer">
                                                Remove    
                                            </div>
                                        </Popconfirm>
                                    }
                                    
                                </div>
                            </div>

                            {/* {
                                (!this.props.mode
                                ||
                                this.props.mode && this.props.mode !== 'lite')
                                ? */}
                                    <>
                                        <Typography.Paragraph className="sl-text" ellipsis={{rows: 3}}>{portfolio_item.description}</Typography.Paragraph>
                                        <div className="sl-tags">

                                            <Tags tags={portfolio_item.skills}/>
                                            <Tags tags={portfolio_item.tools}/>
                                        </div>
                                    </>
                                {/* :
                                    null
                            } */}
                        </div>
                    </div>
                </div>
            )
        }else{

            return null
        }
    }
}

export default FreelancerPortfolioItem;