import React, { Component } from 'react';

import Api from '../../api/Api';

import Main from '../../components/Main';

class PrivacyPolicyPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            main_heading: '',
            main_content: ''
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    render () {

        return (

            <Main page_title="Privacy Policy" hide_page_title="true">
                <div className="inner-page white-bg">
                    <div className="container">
                        <div className="heading text-center">{this.state.main_heading}</div>
                        <div className="content">
                            <div dangerouslySetInnerHTML={{ __html: this.state.main_content }} />

                        </div>
                    </div>
                </div>
            </Main>
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/privacy_policy', formData, function(data){
            
            that.setState({
                main_heading: data.main_heading,
                main_content: data.main_content
            });
        });
    }
}

export default PrivacyPolicyPage;