import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { Button } from 'antd';

class HomeInfoBlock extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    renderCategories = (categories) => {

        return categories.map((category, i) => {

            return (

                <Link key={'ic_'+i} to={`/category/${category.parent_slug}/${category.slug}`}>
                    <Button type="primary bg-white c-dbrown">{category.name}</Button>
                </Link>
            )
        })
    }

    render () {

        return (

            <div className={this.props.classes} key={this.props.bkey}>
                <div className="text-block half-container">
                    <div className="block">
                        <div className="small-heading">{this.props.info_block.small_title}</div>
                        <div className="heading">{this.props.info_block.title}</div>
                        <div className="text" dangerouslySetInnerHTML={{ __html: this.props.info_block.text }} />
                        <Link to={this.props.info_block.button_link}>
                            <Button type="primary" className='px-20 py-10 h-100'>{this.props.info_block.button_text}</Button>
                        </Link>

                        {this.props.info_block.second_heading && this.props.info_block.buttons && this.props.info_block.buttons.length > 0
                            ?
                                <div className="current-block-categories">
                                    <div className="title">{this.props.info_block.second_heading}</div>
                                    <div className="categories-list">
                                        {this.renderCategories(this.props.info_block.buttons)}
                                    </div>
                                </div>
                            :
                                null
                        }
                        
                    </div>
                </div>
                <div className="img-block">
                    <img src={this.props.info_block.image_url} alt={this.props.info_block.image_alt} />
                </div>
            </div>
        )
    }
}

export default HomeInfoBlock;