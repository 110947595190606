import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class About extends Component { 
    constructor(props) {
        super();
        this.state = {

            page_title: ''
        }
    }

    componentDidMount = () => {
        var page = this.props.page;
        this.setState({page_title: page});
    }

    render () {

        return (

            <>
                <div className="title">{this.state.page_title}</div>
                <Link to="/">Homeage</Link>
                <Link to="/page/about-us">About Us</Link>
                <Link to="/page/contact-us">Contact Us</Link>
                <Link to="/page/faq">FAQ</Link>
            </>
        )
    }
}

export default About;