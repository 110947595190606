import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";

import { Provider } from 'react-redux'
import configureStore from './store';

const client = new ApolloClient({
    uri: 'http://localhost/myratecompass_new/blog/graphql',
    cache: new InMemoryCache()
});

ReactDOM.render(
      <Provider store={configureStore()}>
          <ApolloProvider client={client}>
              <App />
          </ApolloProvider>
      </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
