import React, { Component } from 'react';

import { Link } from "react-router-dom";

import renderHTML from 'react-render-html';

import { Typography } from 'antd';

import { FilePdfFilled } from '@ant-design/icons';

import ProfilePic from '../../components/ProfilePic';

class MessageRow extends Component { 
    constructor(props) {
        super();
        this.state = {

        }
    }
    
    wrapper = (message) => {

        let style = 'message';

        if(this.props.row_style && this.props.row_style === 'chat'){

            style = 'chat';
        }

        return (

            <>
                {style !== 'chat'
                    ?
                        <div className="img-block">

                            <ProfilePic src={message.src} size={this.props.type && this.props.type === 'small' ? 35 : 40} />
                        </div>
                    :
                        null
                }

                <div className='vertical flex-one'>
                    <div className={`chat-block flex vertical flex-one ${style === 'chat' ? ' vertical-reverse' : ' ml-10'}`}>

                        <div className="text-block justify-between">

                            {style !== 'chat'
                                ?
                                    <div className="name">
                                        <div className={`fw-500 c-b ${this.props.type && this.props.type === 'large' ? 'fs-18' : 'fs-15'}`}>{message.sender_name}</div>
                                    </div>
                                :
                                    null
                            }

                            <span className="align-center">
                                <span className="fs-12 op-6 c-dg">{message.time}</span>
                            </span>
                        </div>

                        {this.props.type && this.props.type === 'large'
                            ?
                                <div className="fs-13 c-dg2 mt-5">
                                    {renderHTML(message.message)}
                                </div>
                            :
                                style === 'chat'
                                    ?
                                        renderHTML(message.message)
                                    :
                                        <Typography.Paragraph className="fs-13 mt-5 c-dg2" ellipsis={{ rows: 2 }}>{message.message}</Typography.Paragraph>
                        }
                    </div>

                    {this.renderGallery(message)}
                </div>
            </>
        )
    }

    box = (message) => {

        return (

            <div className={`justify-start p-20 message-row ${this.props.className ? this.props.className : ''} ${message.hasOwnProperty('left_align') ? 'left-align' : ''}`} style={{...this.props.style}} onClick={() => {

                if(this.props.onClick){

                    this.props.onClick(message)
                }
            }}>

                {this.wrapper(message)}
            </div>
        )
    }

    render () {

        const message = this.props.message;

        if(message){

            if(this.props.onClick){

                return this.box(message)
                
            }else{
        
                if(message.link){

                    return (

                        <Link to={message.link} className={`justify-center justify-content-start px-20 py-15 message-row ${this.props.className} ${message.hasOwnProperty('left_align') ? 'left-align' : ''}`} style={{...this.props.style}}>

                            {this.wrapper(message)}
                        </Link>
                    )
                }else{

                    return this.box(message)
                }
            }
        }else{

            return null;
        }
    }

    renderGallery = (message) => {

        if(message.hasOwnProperty('_gallery')){

            if(message._gallery.length > 0){

                const message_gallery = message._gallery.map((_gallery_item, index) => {

                    return <li key={`message_gallery_${index}`}>
                        
                                <a href={_gallery_item.url} target="_blank">
                                    {(_gallery_item.ext === 'png' || _gallery_item.ext === 'jpg' || _gallery_item.ext  === 'jpeg')
                                        ?
                                    
                                            <img className="preview-image" src={_gallery_item.url} />
                                        :

                                            <a className='align-center vertical' href={_gallery_item.url} target="_blank">
                                                
                                                <FilePdfFilled className="fs-40 c-b" />

                                                <strong className='fs-10 mt-5 txt-uppercase'>{_gallery_item.ext}</strong>
                                            </a>
                                    }
                                </a>
                            </li>
                })

                return <div className='mt-10 ml-10 form-gallery xs'><ul>{message_gallery}</ul></div>
            }
        }
    }
}

export default MessageRow;