import React, { Component } from 'react';

import { Avatar } from 'antd';

import { UserOutlined } from '@ant-design/icons';

class ProfilePic extends Component { 

    render () {

        return (

            <>
                {this.props.src && this.props.src != ''
                    ?
                        <Avatar src={this.props.src} size={this.props.size ? this.props.size : 50} />
                    :
                        <Avatar icon={<UserOutlined />} size={this.props.size ? this.props.size : 50} />
                }
            </>
        )
    }
}

export default ProfilePic;