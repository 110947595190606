import React, { Component } from 'react';

import Main from '../../components/Main';

import FreelancersProfileSection from './FreelancersProfileSection';

class FreelancersProfilePage extends Component { 

    render () {

        return (

            <Main page="account_profile_view">
                <div className="freelancers-profile inner-page pt-0">
                    
                    {this.props.keyword &&
                    
                        <FreelancersProfileSection
                            keyword={this.props.keyword}
                        />
                    }
                </div>
            </Main>
        )
    }
}

export default FreelancersProfilePage;