import React, { Component } from 'react';

// add form components
import { Empty, Button, List, Input } from 'antd';

import{ MessageOutlined, SendOutlined } from '@ant-design/icons';

import MessageRow from './MessageRow';

import NoData from '../../components/NoData';

import Api from '../../api/Api';

import '../../assets/styles/messages.css';

import Loader from 'components/Loader';

class Messages extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            init: false,
            loading: false,

            messages: [],

            active_project: false,

            receiver: false,

            last_row_id: false
        }

        this.timeline = React.createRef();
        this.last_message = React.createRef();
    }

    componentDidMount = () => {

        if(this.props.receiver && this.props.project_id){
        
            this.loadMessages(this.props.project_id, this.props.receiver, true);
        }
    }

    componentDidUpdate = (oldProps) => {

        if(oldProps.receiver !== this.props.receiver){

            this.setState({messages: [], last_row_id: false}, function(){

                this.loadMessages(this.props.project_id, this.props.receiver, true)
            })
        }
    }

    componentWillUnmount() {

        clearInterval(this.interval);
    }

    repeatLoadMessages = () => {

        var that = this;
        this.interval = setInterval(() => {

            if(!that.state.loading){

                that.loadMessages(this.props.project_id, this.props.receiver, false);
            }
        }, 5000);
    }

    render () {

        return (

            <>
                <div className="messages-body flex-one">

                    <div className="project-details">
                        <div className={`timeline-block ${this.props.row_style ? this.props.row_style : ''} ${this.props.className ? this.props.className : ''}`} ref={this.timeline}>

                            {this.state.messages.length > 0 &&
                            
                                this.state.messages.map((_message, index) => {

                                    return <MessageRow
                                                key={`message_row_${index}`}
                                                message={_message}
                                                row_style={this.props.row_style ? this.props.row_style : null}
                                                className="message-row"
                                            />
                                })
                            }

                            <div ref={this.last_message}></div>

                            {(this.state.messages.length <= 0 && !this.state.init)
                                ?
                                    <NoData hide_image={true} icon={<MessageOutlined />} size="small" message="Please choose a client from left to start the conversation" />
                                :
                                    null
                            }

                            <Loader loading={this.state.init} />
                        </div>
                    </div>
                </div>
                
                <div className="message-footer p-20 flex align-center">

                    {/* <div>
                        <FeatherIcon icon="paperclip" />
                    </div> */}
                    <div className="message-input flex-one">

                        <Input.TextArea
                            id="message_input"
                            onChange={(e) => {

                                this.setState({message_text: e.target.value})
                            }}
                            style={{padding:10, fontSize:14}}
                            autoSize={{ minRows: 1, maxRows: 6 }}
                            placeholder="Type your message here.."
                            value={this.state.message_text}
                        >
                        </Input.TextArea>
                    </div>
                    <div className="message-action ml-10">

                        <Button loading={this.state.loading} type="danger" shape="circle" onClick={() => {

                            this.sendMessage()
                            this.setState({message_text: ''})
                        }}>
                            <SendOutlined />
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    scrollToBottom = () => {

        this.timeline.current.scrollTo(0, this.last_message.current.offsetTop);
    }

    sendMessage = () => {

        if(this.props.project_id){

            var formData = new FormData();
            formData.append('account_token', this.props.account_token);
            formData.append('project', this.props.project_id);
            formData.append('receiver', this.props.receiver);

            var message = this.state.message_text;

            if(message == ''){

                return false;
            }else{

                formData.append('message', message);
            
                var that = this;

                this.setState({loading: true});
                Api.post('messages/send', formData, function(data){
                    
                    that.setState({loading: false, message_text: ''});
                    document.getElementById('message_input').value = '';
                    
                    if(data.status){
                    
                        var messages = that.state.messages;
                        messages.push(data.row);

                        var last_row_id = data.row.ref;

                        that.setState({messages: messages, last_row_id: last_row_id});
                        that.scrollToBottom();
                    
                    }else{
                        // that.props.onError(data.message);
                    }
                });
            }
        }
    }

    loadMessages = (project_id, receiver, reload) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('project', project_id);

        formData.append('receiver', receiver);
        
        if(this.state.last_row_id){

            formData.append('ref', this.state.last_row_id);
        }

        if(this.props.source){

            formData.append('source', this.props.source);
        }

        var that = this;

        this.setState({loading: reload, init: reload});
        
        Api.post('messages/load', formData, function(data){
            
            that.setState({loading: false, init: false});
            
            if(data.status){
            
                // that.setState({messages: data.messages});
                // that.scrollToBottom();

                var last_row_id = that.state.last_row_id;
                var has_new_entry = false;

                var messages = that.state.messages;

                if(data.messages.length > 0){

                    has_new_entry = true;
                    for(var i in data.messages){

                        var entry = data.messages[i];
                        last_row_id = entry.ref;
                    }
                }
                
                messages.push(...data.messages);

                that.setState({
                    last_row_id: last_row_id,
                    no_data: false,
                });

                if(has_new_entry){

                    that.scrollToBottom();
                }

                if(reload){

                    that.repeatLoadMessages(project_id, receiver)
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // LayoutHelper.addErrorMessage(data.message);
            }
        });
    }
}

export default Messages;