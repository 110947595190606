import React, { Component } from 'react';

import FeatherIcon from 'feather-icons-react';

import Api from '../../../api/Api';
// import headimg from '../../../assets/images/head.jpg';

import moment from 'moment';

import Loader from '../../../components/Loader';

import { Form, Select, Input, DatePicker, Button, Carousel, Popconfirm } from 'antd';

class Experience extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            list: [],

            remove_loading: false,
            item: false
        }
    }

    componentDidMount = () => {

        window.setTimeout(() => {
            this.loadExperienceList(this.props.account_token);
        });
    }

    render () {

        return (

            <>

                {this.props.account_token &&

                    <div style={{position:'relative'}}>
                        <div className="custom-carousel custom-employment-carousel">
                            <Carousel>
                                {this.renderList()}
                            </Carousel>

                        </div>
                        {this.state.remove_loading &&
                            <Loader />
                        }
                    </div>
                }
                {this.props.display
                    ?
                        this.experienceForm()
                    :
                        null
                }
            </>
        )
    }

    onExperienceSubmit = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.props.account_token);

        if(this.state.item != false){

            formData.append('item_id', this.state.item.id);
        }

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/profile_update_experience', formData, function(data){
            
            that.setState({loading: false, item: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});
                that.props.hide_form();
                that.props.updateUser(data.user);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    experienceForm = () => {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className={"table-row table-row-employment-form"}>
            
                <Form {...layout} scrollToFirstError={true} name="update_experience_details" ref="update_experience_details" validateMessages={validateMessages} onFinish={this.onExperienceSubmit.bind(this)}>
            
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-50" label="Title" name="title" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>

                        <Form.Item className="w-50" label="Url" name="url" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="d-flex form-item-block">
                        <Form.Item className="w-100" label="Description (Upto 100 words)" name="description" rules={[{required:true}]}>
                            <Input.TextArea />
                        </Form.Item>
                    </div>

                    <div className="text-right buttons">
                        <button type="button" className="btn btn-md btn-pink-txt" onClick={() => {
                            this.setState({item: false});
                            this.props.hide_form();
                        }}>Cancel</button>
                        <Button className="btn btn-md btn-blue-txt" type="danger" htmlType="submit" loading={this.state.loading}>+ Add</Button>
                    </div>
                </Form>
                
            </div>
        )
    }

    renderList = () => {

        if(this.state.list.length > 0){

            return this.state.list.map((experience_item) => {

                var key = 'experience_item_' + experience_item.id;
                return (

                    <div key={key} className={"row-employment-slider-"}>

                        <div className="block d-flex justify-content-between">
                            <div className="icon-block">
                                {/* <img className="img-fluid" src={headimg} alt="Experience" /> */}
                            </div>
                            <div className="text-block">
                                <div className="sl-title">{experience_item.title}</div>
                                <div className="sl-company-name">{experience_item.url}</div>
                                <div className="sl-text">{experience_item.description}</div>
                            </div>
                            <div className="sl-buttons">
                                
                                <div className="pointer" onClick={() => {
                                    this.editItem(experience_item);
                                }}>
                                    <FeatherIcon icon="edit-2"/>
                                </div>

                                <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                    this.setState({item: false});
                                    this.remove(experience_item);
                                }} okText="Yes" cancelText="Cancel">
                                    <div className="pointer">
                                        <FeatherIcon icon="trash-2" />
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    editItem = (item) => {

        this.props.display_form();

        window.setTimeout(() => {

            if(item.emp_from != '0000-00-00'){
            
                item.from = moment(item.emp_from);
            }
            if(item.emp_to != '0000-00-00'){
            
                item.to = moment(item.emp_to);
            }

            this.setState({item: item});
            this.refs.update_experience_details.setFieldsValue(item);
        });
        
    }

    renderTags = (tags) => {

        if(tags.length > 0){

            return tags.map((tag) => {

                var key = "tag_" + tag;
                return (

                    <span key={key} className="sl-single-tag">{tag}</span>
                )
            })
        }
    }

    remove = (item) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('item_id', item.id);
        
        var that = this;

        this.setState({remove_loading: true});
        
        Api.post('freelancer/remove_exeperience', formData, function(data){
            
            that.setState({remove_loading: false});
            if(data.status){
                that.setState({list: data.list});
            }
        });
    }

    loadExperienceList = (account_token) => {
        var formData = new FormData();
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/get_experience', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.setState({list: data.list});
            }
        });
    }
}

export default Experience;
