import React, { Component } from 'react';

// add form components
import { Form, Input, Button, Select, Row, Col, Popconfirm } from 'antd';

import FeatherIcon from 'feather-icons-react';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import FreelancerPortfolioItem from '../../components/sections/freelancers/FreelancerPortfolioItem';

const { Option } = Select;

class Portfolio extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,

            loading: false,

            account_token: '',

            remove_loading: false,

            redirect: false,
            count_employment: 0,

            portfolio_image: '',

            item_remove_warning: false,

            portfolio: [],

            profile_preview_url: '',

            image_uploading: false,

            current_portfolio_item: false,

            gallery: [],
            media_url: '',
            gallery_loading: false
        }

        this.form = React.createRef();
        this.page = React.createRef();
    }

    arrangePortfolio = (portfolio) => {

        var n = 0;
        var _items = [];
        _items[n] = [];

        for(var i = 0; i < portfolio.length; i++){

            _items[n].push(portfolio[i]);
            if((i+1)/4 == 1){
                n++;
                _items[n] = [];
            }
        }
        return _items;
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});
        
        if(account_token){
            
            this.setState({account_token: account_token});
            this.loadPortfolio(account_token);
        }else{
            this.setState({redirect: true});
        }
    }

    onFinish = (values) => {
        var formData = new FormData();

        if(this.state.current_portfolio_item){
            formData.append('item_id', this.state.current_portfolio_item);
        }
        
        formData.append('account_token', this.state.account_token);
        formData.append('portfolio_image', this.state.portfolio_image);

        if(this.state.gallery && this.state.gallery.length > 0){
            formData.append('gallery', JSON.stringify(this.state.gallery));
        }

        Object.keys(values).forEach(key => formData.append(key, values[key]))
        
        var that = this;
        
        this.setState({loading: true});

        Api.post('freelancer/profile_update_portfolio', formData, function(data){
            
            var _portfolio = data.portfolio;
            var portfolio = that.arrangePortfolio(_portfolio);

            that.setState({loading: false, portfolio: portfolio, display_block: false, current_portfolio_item: false, profile_preview_url: '', gallery: []});
            that.props.updateUser(data.user);

            that.page.current.scrollIntoView({ behavior: 'smooth' });
            that.refs.portfolio_form.resetFields();
        });
    }

    loadPortfolio = (account_token) => {

        var formData = new FormData();
        formData.append('account_token', account_token);
        
        var that = this;
        
        Api.post('freelancer/get_portfolio', formData, function(data){
            
            if(data.status){
                var _portfolio = data.portfolio;
                var portfolio = that.arrangePortfolio(_portfolio);

                that.setState({portfolio: portfolio, media_url: data.media_url});
            }
        });
    }

    render () {

        const portfolio = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: <div><div className="slider-arrows"><FeatherIcon icon="chevron-left" className="ico" size={30} /></div></div>,
            nextArrow: <div><div className="slider-arrows"><FeatherIcon icon="chevron-right" className="ico" size={30} /></div></div>
        };

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className="update-profile-page d-flex">
                    
                <div className="profile-wrapper">
                    <Row>
                        <Col span={24}>
                            
                            <div className="profile-main-wrapper" ref={this.page}>

                                <div className="page-heading">Portfolio</div>

                                <Form {...layout} name="update_profile_form" ref="portfolio_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>

                                    <div className="custom-carousel custom-portfolio-carousel" style={{position:'relative'}}>
                                        
                                        {!this.state.display_block &&
                                            <div className="form-item-block portfolio-add-block">
                                                <Button type="primary" id="add_portfolio_btn" onClick={() => {
                                                    this.setState({display_block: true});
                                                    this.form.current.scrollIntoView({ behavior: 'smooth' });
                                                }}>+ Add Item</Button>
                                            </div>
                                        }

                                        <div className="custom-form-block" ref={this.form}>
                                            {this.addPortfolioFormBlock()}
                                        </div>

                                        {this.state.display_block &&
                                            <div className="d-flex form-item-block justify-content-end">
                                                <Form.Item {...tailLayout}>
                                                    <Button type="secondary" onClick={()=>{
                                                        this.setState({display_block: false, current_portfolio_item: false, portfolio_image: '', profile_preview_url: '', gallery: []});
                                                        this.page.current.scrollIntoView({ behavior: 'smooth' });
                                                        this.refs.portfolio_form.resetFields();
                                                    }}>Cancel</Button>
                                                    <Button className="btn ml-10" type="primary" htmlType="submit" loading={this.state.loading}>
                                                        {this.state.current_portfolio_item ? 'Update' : '+ Add'}
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        }

                                        {/* <Carousel {...portfolio}> */}
                                            {this.displayPortfolio()}
                                        {/* </Carousel> */}
                                        
                                        {this.state.remove_loading &&
                                            <Loader />
                                        }
                                    </div>
                                    
                                </Form>
                            </div>
                        </Col>
                        
                        <Col span={24}>

                            <div className="d-flex justify-content-end">
                                <Button className="btn ml-10" type="primary" htmlType="button" onClick={() => {

                                    this.props.jumpToNext();
                                }}>
                                    Continue
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    displayPortfolio = () => {

        var portfolio = this.state.portfolio;
        var _portfolio = [];

        if(portfolio.length > 0){

            for(var i = 0; i < portfolio.length; i++){

                var _portfolio_row = portfolio[i];

                if(_portfolio_row.length){

                    var key = 'portfolio_row_' + i;

                    _portfolio.push(
                        <div key={key}>
                            <div className="slider-inner">
                                <Row>
                                    {this.portfolioItems(_portfolio_row)}
                                </Row>
                            </div>
                        </div>
                    );
                }
            }
        }

        return _portfolio;
    }

    editPortfolioItem = (item) => {

        this.setState({display_block: true, current_portfolio_item: item.id, portfolio_image: item.main_image, profile_preview_url: item.main_image_preview, gallery: item.gallery});
        this.refs.portfolio_form.setFieldsValue(item);
        this.form.current.scrollIntoView({ behavior: 'smooth' });
    }

    removePortfolioItem = (portfolio_item) => {

        var formData = new FormData();
        
        formData.append('account_token', this.state.account_token);
        formData.append('item_id', portfolio_item.id);
        
        var that = this;

        this.setState({remove_loading: true, profile_preview_url: ''});
        
        Api.post('freelancer/remove_portfolio', formData, function(data){
            
            that.setState({remove_loading: false, gallery: []});
            if(data.status){

                var _portfolio = data.portfolio;
                var portfolio = that.arrangePortfolio(_portfolio);

                that.setState({portfolio: portfolio});
            }
        });
    }

    portfolioItems = (portfolio) => {

        return portfolio.map((portfolio_item) => {

            if(portfolio_item.hasOwnProperty('id')){

                return (
                    <Col key={'portfolio_' + portfolio_item.id} span={24}>
                        
                        <FreelancerPortfolioItem
                            portfolio_item={portfolio_item}
                            onRemove={(portfolio_item) => {

                                this.removePortfolioItem(portfolio_item);
                            }}
                            onEdit={(portfolio_item) => {

                                this.editPortfolioItem(portfolio_item)
                            }}
                        />
                    </Col>
                )
            }else{

                return null;
            }
        })
    }

    addPortfolioFormBlock = () => {

        if(this.state.display_block){

            return (
                <div className={"table-row table-row-portfolio-form-"}>
                    
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-100" label="Title" name="title" rules={[{required:true}]}>
                            <Input />
                        </Form.Item>
                        {/* <Form.Item className="w-50" label="Link" name="link" rules={[{required:true}]}>
                            <Input />
                        </Form.Item> */}
                    </div>

                    <div className="d-flex form-item-block" style={{position:'relative'}}>
                                        
                        <div className="w-100 picture">

                            <div className="ant-col ant-col-24 ant-form-item-control">
                                <div className="profile-pic-box type-input">
                                    <div className="picture" onClick={() => {
                                        this.profilepicinput.click();
                                    }}>
                                        <label htmlFor="profile_pic">Upload Main Image</label>
                                        <div className="profile-pic-banner-bg solid">
                                            
                                            {this.state.profile_preview_url && this.state.profile_preview_url != ''
                                                ?
                                                    <span className="portfolio-image-preview" style={{backgroundImage:'url('+(this.state.profile_preview_url)+')'}}></span>
                                                :
                                                    <>
                                                        <FeatherIcon icon="image" size={40} />
                                                        <p>Click here to upload the file.</p>
                                                    </>
                                            }
                                            <input type="file" className="hidden" id="profile_pic" value={this.state.profile_pic} onChange={(e) => {this._handleImageChange(e, 'main')}} ref={(input)=> this.profilepicinput = input}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.image_uploading &&
                            <Loader />
                        }
                    </div>

                    <div className="form-wrap">

                        <div className="form-gallery-container">
                            <div className="form-gallery lg">

                                <ul>
                                    {this.displayGallery()}
                                    <li>
                                        <label className="gallery-increment">
                                            <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e, 'gallery')}} ref={(input)=> this.myinput = input}/>
                                            <FeatherIcon icon="plus" />
                                            <p className="ant-upload-text">Add Images</p>
                                        </label>
                                    </li>
                                </ul>
                                {this.state.gallery_loading
                                    ?   
                                        <Loader is_small={true} />
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="d-flex form-item-block">
                        <Form.Item className="w-100" label="Description (Upto 100 words)" name="description" rules={[{required:true}]}>
                            <Input.TextArea />
                        </Form.Item>
                    </div>

                    <div className="d-flex form-item-block">
                        <Form.Item className="w-100" label="Skills/Tools" name="tools" rules={[{required:true}]}>
                            <Select mode="tags">

                            </Select>
                        </Form.Item>
                    </div>
                    
                </div>
            )
        }else{
            return null;
        }
    }

    _handleImageChange = async (e, type) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        if(type == 'main'){

            reader.onloadend = () => {
                this.setState({
                    updated: false,
                    file: file,
                    profile_preview_url: reader.result
                });
            }
        }
    
        reader.readAsDataURL(file);

        var _sizes = {};
        _sizes['profile_pic_small'] = [100, 100];
        _sizes['profile_pic_medium'] = [300, 300];

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,webp');
        formData.append('upload_dir', 'freelancer/portfolio_gallery/');
        formData.append('image_sizes', JSON.stringify(_sizes));

        if(type == 'main'){
            this.setState({image_uploading: true});
        }else{
            this.setState({gallery_loading: true});
        }
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({media_url: data.media_url});
            if(type == 'main'){

                that.setState({image_uploading: false});

                if(data.status == 'success'){

                    that.setState({portfolio_image: data.random_dir + data.main_file_name});
                }else{

                    that.props.onError(data.message)
                }
            }else{

                that.setState({gallery_loading: false});

                if(data.status == 'success'){

                    var _image = 'freelancer/portfolio_gallery/' + data.random_dir + data.main_file_name;
                    var _gallery = that.state.gallery;
                    _gallery.push(_image);
                    that.setState({gallery: _gallery});
                }else{

                    that.props.onError(data.message)
                }
            }
            
        });
    }

    displayGallery = () => {

        var gallery = this.state.gallery;
        var _gallery = [];

        if(gallery && gallery.length > 0){

            for(var i = 0; i < gallery.length; i++){

                var key = 'gallery_item_' + i;
                
                _gallery.push(
                    <li key={key}>
                        <Popconfirm title="Are you sure" placement="topRight" onConfirm={() => {
                            gallery.splice(gallery.indexOf(i), 1);
                            this.setState({gallery: gallery});

                        }} okText="Yes" cancelText="No">
                            <button className="close" type="button">
                                <FeatherIcon icon="x" size={15} />
                            </button>
                        </Popconfirm>
                        
                        <img className="preview-image" src={this.state.media_url + gallery[i]} />
                    </li>
                );
            }

            return _gallery;
        }
        return '';
    }
}

export default Portfolio;