import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Select, Rate } from 'antd';

import Price from 'components/Price';

class FreelancerProductBlock extends Component { 

    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: false,

            rate_tooltips: ['terrible', 'bad', 'normal', 'good', 'wonderful'],
            size: 'large',
        }
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }

        if(user){
            user = JSON.parse(user);

            this.setState({user: user});
        }
    }
    
    render () {

        var product = this.props.product;

        var product_link = '/product-view/'+product.product_id;
        
        if(product){
            
            return (

                <div className="single-offer">
                    <Link to={product_link} className="top-block">
                        <img src={product.image_full} className="offer-image" alt="Offer Image" />
                        {(product.featured == '1')
                            ?
                                <span className="featured-text">Featured</span>
                            :
                                null
                        }
                        
                        {/* <span className="wishlist-count">
                            <i className="ico heart"></i> {product.likes_count}
                        </span> */}
                    </Link>
                    <div className="mid-block">
                        <Link to={product_link} className="title">{product.title}</Link>
                        <div className="last-activity">Last activity: {product.last_activity_formatted}</div>
                    </div>
                    <div className="bottom-block">
                        {/* <div className="ratings-price w-65">
                            <div className="ratings">
                                <Rate tooltips={this.state.rate_tooltips} size={this.state.size} value={product.reviews_rating_round} />
                                <div className="rating-text"><strong>{product.reviews_rating}</strong> ({product.reviews_count})</div>
                            </div>
                        </div> */}

                        <div className="sold-text">
                            <span className="text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.547" height="23.061" viewBox="0 0 26.547 23.061">
                                    <path data-name="Path 1892" d="M23.726,10.173h-.87L15.2,3.014a2.078,2.078,0,0,0,.207-.907,2.108,2.108,0,1,0-4.217,0,2.064,2.064,0,0,0,.207.9L3.751,10.173H2.88A2.892,2.892,0,0,0,0,13.038v7.186a2.867,2.867,0,0,0,2.88,2.836H23.726a2.816,2.816,0,0,0,2.821-2.836V13.038A2.842,2.842,0,0,0,23.726,10.173ZM13.3.953a1.158,1.158,0,1,1-1.158,1.158A1.158,1.158,0,0,1,13.3.953ZM12,3.758a2.085,2.085,0,0,0,2.609,0l6.858,6.417H5.141ZM25.6,20.21a1.9,1.9,0,0,1-1.9,1.9H2.851a1.9,1.9,0,0,1-1.9-1.9V13.024a1.9,1.9,0,0,1,1.9-1.9H23.7a1.9,1.9,0,0,1,1.9,1.9Zm0,0" transform="translate(0 0)" fill="#962C08"/>
                                    <path data-name="Path 1893" d="M103.7,243.559c0-.25.234-.484.806-.484.661,0,.814.306,1.1.306.355,0,.5-.443.5-.661,0-.629-1.177-.709-1.6-.709-1.007,0-2.063.459-2.063,1.668,0,1.886,2.555,1.717,2.555,2.692,0,.387-.411.532-.782.532-.75,0-.991-.6-1.37-.6-.306,0-.548.4-.548.677,0,.532.886,1.048,1.942,1.048a1.78,1.78,0,0,0,2.015-1.789C106.257,244.212,103.7,244.365,103.7,243.559Zm0,0" transform="translate(-98.846 -228.639)" fill="#962C08"/>
                                    <path data-name="Path 1894" d="M169.97,242.609a1.743,1.743,0,0,0-1.97,1.9v2.136a1.976,1.976,0,0,0,3.949,0v-2.136A1.749,1.749,0,0,0,169.97,242.609Zm.77,4.038c0,.556-.278.806-.729.806s-.721-.25-.721-.806v-2.136c0-.556.27-.806.721-.806s.729.25.729.806Zm0,0" transform="translate(-159.251 -229.188)" fill="#962C08"/>
                                    <path data-name="Path 1895" d="M240.127,247.419h-1.837v-4.431c0-.266-.331-.379-.645-.379s-.645.113-.645.379v5.125c0,.258.306.353.58.353h2.547c.242,0,.363-.25.363-.524S240.369,247.419,240.127,247.419Zm0,0" transform="translate(-222.69 -229.188)" fill="#962C08"/>
                                    <path data-name="Path 1896" d="M298.942,242.289h-1.427c-.33,0-.516.2-.516.4v5.134c0,.2.185.344.516.344h1.427c1.112,0,2.006-.482,2.006-1.868v-2.087A1.788,1.788,0,0,0,298.942,242.289Zm.717,4.015c0,.548-.266.82-.717.82h-.733v-3.707h.733c.451,0,.717.252.717.8Zm0,0" transform="translate(-277.855 -228.894)" fill="#962C08"/>
                                </svg>
                            </span>
                            <span className="num">{product.purchase_count}</span>
                        </div>

                        <span className="price-text">
                            <span className="price fw-600 fs-18">
                                <Price>{product.price_formatted}</Price>
                            </span>
                        </span>
                    </div>

                    {this.props.can_edit === true &&
                    
                        <>

                            {(this.state.user && (product.freelancer_id === this.state.user.unq_index_id)) &&
                            
                                <div className='justify-start'>
                                    <Link to={`/account/product-add/${product.product_id}`} className="fs-12">Edit</Link>
                                </div>
                            }
                        </>
                    }
                </div>
            )
        }else{

            return null;
        }
    }
}

export default FreelancerProductBlock;