import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, Input, Button, InputNumber, Alert } from 'antd';

import { connect } from 'react-redux';
import {User} from '../../actions/user';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import Main from '../../components/Main';

class MobilePassword extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            account_token: '',

            message: false,
            error_message: false,

            heading_text: 'Please update mobile number!',

            contact_no: false,
            password: false,
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');

        if(account_token){

            this.setState({account_token: account_token});
            this.verify(account_token)
        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {
        
        if(this.state.redirect){

            return <Redirect to={`/account`} />;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: 'Email is not valid!',
              number: 'Mobile number is not a valid number!',
            },
            // number: {
            //   range: '${label} must be between ${min} and ${max}',
            // },
        };

        const onFinish = values => {
            var formData = new FormData();

            Object.keys(values).forEach(key => formData.append(key, values[key]))

            var that = this;

            formData.append('account_token', this.state.account_token);

            Api.post('auth/update_mobile', formData, function(data){
                
                if(data.status){
                
                    that.setState({loading: false});
                    that.setState({message: true});

                    if(data.user){

                        localStorage.setItem('user', JSON.stringify(data.user));
                        that.props.User(data.user);
                    }

                    window.setTimeout(() => {

                        that.setState({redirect: true});
                    }, 2000);
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});

                    if(data.code && data.code === 'no_account'){

                        window.location = Api.url + 'logout';
                    }
                }

                window.setTimeout(() => {
                    that.setState({error_message: false, message: false});
                }, 3000);
            });
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        return (

            <>
                <Main page="mobile-password">

                    <div className="container">
                        <div className="d-flex align-center">
                            
                            <div className=" half-container auth-block" style={{position:'relative'}}>
                                <div className="section-heading">{this.state.heading_text}</div>

                                {/* using if statement */}
                                {this.state.message &&
                                    <Alert message="Success" description={this.state.message} type="success" closable />
                                }

                                {this.state.error_message &&
                                    <Alert message="Error" description={this.state.error_message} type="error" closable />
                                }
                                
                                <Form {...layout} name="register_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                    
                                    <div className="form-item-block d-flex">
                                        <div className="w-100">
                                            <Form.Item name="contact_no" rules={[
                                                
                                                () => ({

                                                    validator(rule, value) {

                                                        if(
                                                            (value && !isNaN(value) && value.toString().length >= 10)
                                                        ){

                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('Please enter valid mobile number!');
                                                    }
                                                })
                                            ]}>
                                                <InputNumber placeholder="Mobile number" max={9999999999} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    {this.state.password
                                        ?
                                            <>
                                    
                                                <div className="form-item-block d-flex">
                                                    <div className="w-100">
                                                        <Form.Item name="password" rules={[{required:true, message:'Please enter your password!'}]}>
                                                            <Input.Password placeholder="Password" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="form-item-block d-flex">
                                                    <div className="w-100">
                                                        <Form.Item name="con_password" dependencies={['password']} rules={[{required:true, message:'Please enter confirm password!'},
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject('Entered passwords do not match!');
                                                            },
                                                        }),]}>
                                                            <Input.Password placeholder="Confirm Password" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </>
                                        :
                                            null
                                    }

                                    <Form.Item {...tailLayout} className="text-center">

                                        <div className="form-item-block d-flex">
                                            <div className="w-100">
                                                <Button type="primary" size="large" htmlType="submit" className="btn-orange btn-wide">Update</Button>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Form>

                                {/* using if statement */}
                                {(this.state.loading || this.state.ln_login) &&
                                    <Loader />
                                }
                            </div>
                        </div>
                    </div>
                </Main>
            </>
        )
    }

    verify = (account_token) => {

        var formData = new FormData();

        formData.append('account_token', account_token);
        let that = this;

        this.setState({loading: true})

        Api.post('auth/verify', formData, function(data){
                
            if(data.status){
            
                that.setState({loading: false});

                if(data.resp.password){

                    that.setState({password: true, heading_text: 'Please update mobile number and password!'})
                }
            
            }else{
                
                window.location = Api.url + 'logout';
            }
        });
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(MobilePassword);