import React, { Component } from 'react';

import { Rate } from 'antd';
import { StarFilled } from '@ant-design/icons';

import ProfilePic from 'components/ProfilePic';

import renderHTML from 'react-render-html';

class SingleReview extends Component { 
    constructor(props) {
        super();
        this.state = {
            sizeSmall: 'small',
            sizeLarge: 'large',
        }
    }
    
    render () {

        var review = this.props.review;
        var replies = this.props.review.replies;

        // {review.freelancer_city}, {review.freelancer_country}

        if(review){
            return (
                <>
                    <div className="section mb-20">
                        <div className="block orange-light-bg">
                            <div className="title mb-0">{review.title}</div>
                            
                            <div className="flex align-center flex-start">
                                <Rate className='sm' disabled character={<StarFilled style={{fontSize:16}} />} value={review.reviews_rating_round} />
                                <div className="rating-text ml-3 mt-3">{review.reviews_rating} ({review.reviews_count})</div>
                            </div>

                            <div className="flex links">
                                <span className='flex align-center'>
                                    <ProfilePic src={review.profile_pic_full} size={30} />
                                    <span className="num ml-3">{review.freelancer_name}</span>
                                </span>
                                <span className='flex align-center ml-5'>
                                    <i className="ico clock"></i>
                                    <span className="num ml-3">{review.added_on_formatted}</span>
                                </span>
                            </div>
                        </div>
                        
                        <div className="block review mt-10">
                            <div className="text">{renderHTML(review.review)}</div>
                        </div>
                    </div>

                    {replies && replies.length > 0
                        ?
                            <>
                                {replies.map((reply, index) => {
                                        var key = 'rep_' + index;

                                        return (
                                            <div key={key} className="block question reply">
                                                <div className="img-block">
                                                    <img src={reply.profile_pic_full} className="profile-image" alt="Profile Image" />
                                                </div>
                                                <div className="text-block">
                                                    <div className="name">
                                                        <div className="text">{reply.freelancer_name}</div>
                                                        <div className="ratings-reviews">
                                                            <Rate disabled tooltips={reply.rate_tooltips} size="large" value={reply.reviews_rating_round} />
                                                            <div className="rating-text">{reply.reviews_rating} ({reply.reviews_count})</div>
                                                        </div>
                                                    </div>
                                                    <div className="location">{reply.freelancer_city}, {reply.freelancer_country}</div>
                                                    <div className="text">{reply.review}</div>
                                                </div>
                                                <div className="added-block">
                                                    <span>
                                                        <i className="ico clock"></i>
                                                        <span className="num">{reply.added_on_formatted}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                                    
                        :
                            null
                    }
                                                                            
                </>
            )
        }else{

            return null;
        }
    }
}

export default SingleReview;