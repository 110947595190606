import React, { Component } from 'react';

import { Form, Button, Checkbox } from 'antd';

import { CheckCircleFilled } from '@ant-design/icons';

import Api from '../../api/Api';

import Loader from '../../components/Loader';

import Price from 'components/Price';

class PostProjectStepOne extends Component { 
    constructor(props) {
        super();
        this.state = {

            loeding: false,

            feature: false,
            urgent: false,
            nda: false,
            scouting: false,

            prices: {'feature': 0, 'nda': 0},

            selected_options: [],

            disable_button: true,
        }
    }

    updateSelected = (event, key) => {

        if(event){
                                    
            var selected_options = this.state.selected_options;
            selected_options.push(key);
            this.setState({selected_options: selected_options});
        }else{

            var selected_options = this.state.selected_options;

            if(selected_options.indexOf(key) > -1){
            
                selected_options.splice(selected_options.indexOf(key), 1);
            }
            this.setState({selected_options: selected_options});
        }

        if(this.state.selected_options.length > 0){

            this.setState({disable_button: false});
        }else{

            this.setState({disable_button: true});
        }
    }

    render () {

        return (

            <div className="mt-30">
                <div className="fs-24 fw-500 c-brown">Get higher quality with less effort</div>

                <div className="card mt-10 br-5" style={{position:'relative'}}>
                    <form className="">

                        <div className="justify-center" style={{alignItems:'stretch'}}>
                            <div className="d-flex form-item-block border-block extra-item box-white bordered mr-10 w-45 flex-one">
                                <Form.Item name="" valuePropName="checked" className='flex-one'>

                                    {(this.props.project.payment_method === '' && this.props.project.paid_amount <= '0')
                                        ?
                                    
                                            <Checkbox onChange={(e) => {

                                                this.updateSelected(e.target.checked, 'feature');
                                            }}><span className='fs-18 fw-500'>Feature your project</span> <span className="btn btn-xs btn-rounded bg-dbrown c-w px-10 br-5 ml-5 fs-14">Featured</span></Checkbox>
                                        :
                                            <div className='c-green flex-start'>
                                                <CheckCircleFilled className="fs-26" />
                                                <b className='ml-5'>Paid</b>
                                            </div>
                                    }
                                    
                                    <div className="fs-14 mt-15 op-8">{this.props.featured_project_text}</div>
                                </Form.Item>

                                <div className="price fw-600 c-dbrown fs-18 ml-20">
                                    <Price>{this.props.featured_project_price}</Price>
                                </div>
                            </div>
                            <div className="d-flex form-item-block border-block extra-item box-white bordered ml-10 w-45 flex-one">
                                <Form.Item name="" valuePropName="checked" className='flex-one'>

                                    {(this.props.project.payment_method === '' && this.props.project.paid_amount <= '0')
                                        ?
                                    
                                            <Checkbox onChange={(e) => {

                                                this.updateSelected(e.target.checked, 'nda');
                                            }}><span className='fs-18 fw-500'>Request for NDA <small>(Non disclosure agreement)</small></span> <span className="btn btn-xs btn-rounded bg-b c-w px-10 br-5 ml-5 fs-14">NDA</span></Checkbox>
                                        :
                                            <div className='c-green flex-start'>
                                                <CheckCircleFilled className="fs-26" />
                                                <b className='ml-5'>Paid</b>
                                            </div>
                                    }
                                    
                                    <div className="fs-14 mt-15 op-8">{this.props.nda_text}</div>
                                </Form.Item>

                                <div className="price fw-600 c-dbrown fs-18 ">
                                    <Price>{this.props.nda_price}</Price>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end buttons mt-30">

                            {(this.props.project.payment_method === '' && this.props.project.paid_amount <= '0')
                                ?
                                    <>
                                        <Button className="btn btn-xs btn-pink-txt" type="secondary" onClick={() => {

                                            this.save()
                                        }}>Skip</Button>
                                        <Button type="primary" disabled={this.state.disable_button} onClick={() => {

                                            this.save()
                                        }} className="btn ml-10">Proceed</Button>
                                    </>
                                :
                                    <>
                                        <Button className="btn btn-xs btn-pink-txt" type="secondary" onClick={() => {

                                            this.props.onBack()
                                        }}>Back</Button>
                                        <Button type="primary" onClick={() => {

                                            this.props.onClick()
                                        }} className="btn ml-10">Next</Button>
                                    </>
                            }
                        </div>
                    </form>

                    <Loader loading={this.state.loading} />
                </div>
            </div>
        )
    }

    save = () => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);

        formData.append('project_id', this.props.project.project_unq_id);

        var selected_options = this.state.selected_options;
        var prices = this.state.prices;

        if(selected_options.indexOf('feature') > -1){

            formData.append('feature', 1);
        }else{

            formData.append('feature', 0);
        }

        if(selected_options.indexOf('nda') > -1){

            formData.append('nda', 1);
        }else{

            formData.append('nda', 0);
        }
        
        var that = this;
        this.setState({loading: true});

        Api.post('projects/update_details', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){
                
                that.props.onClick()
            
            }else{

                that.props.onError(data.message)
            }
            
        });
    }
}

export default PostProjectStepOne;