import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class PostRow extends Component { 
    
    render () {

        var post = this.props.post;
        
        if(post){
            
            return (

                <div className="single-item d-flex">
                    <div className="left">
                        <img className="img-fluid" src={post.post_image} alt={post.post_title} />
                    </div>
                    <div className="right">
                        <div className="top-section">
                            <div className="profile-block">
                                <img className="img-fluid" src={post.profile_image} alt={post.profile_name} />
                                <div className="profile-detail">
                                    <span className="name">{post.profile_name}</span>
                                    <span className="category-name">{post.category_name}</span>
                                </div>
                            </div>
                            <Link to="" className="title">{post.post_title}</Link>
                            <div className="description">{post.post_description}</div>
                        </div>
                        <div className="bottom-section">
                            <div className="comment-block">
                                <i className="mat-ico">comment</i><span className="text">{post.total_comments} Comments</span>
                            </div>
                            <Link to={"/blog/"+post.post_slug} className="btn blank-blue-btn" size={this.setState.sizeSmall}>Read more</Link>
                        </div>
                    </div>
                </div>
            )
        }else{

            return null;
        }
    }
}

export default PostRow;