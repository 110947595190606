import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { Table, Tabs, Empty, Avatar } from 'antd';

import SingleProduct from 'components/products/FreelancerProductBlock';

import LayoutHelper from 'helpers/LayoutHelper';

import Api from 'api/Api';

class ProductsListComponent extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            active_tab: 'all_products',

            account_token: false,
            user: false,

            all_products: [],
            active_products: [],
            progressing: [],
            delivered: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});

            this.loadProducts(account_token, 'all');
        }else{
            // window.location = Api.url + '/logout';
        }
    }
    
    list = (products, type) => {

        return (

            <div className="products_offers">
                {products && products.length > 0
                    ?
                        products.map((product, index) => {

                            return <SingleProduct key={type + index} can_edit={true} product={product} user={this.state.user} />
                        })
                    :
                        null
                }
            </div>
        )
    }

    render () {

        return (

            <>
                <Tabs className="manage-products-list" defaultActiveKey="all_products" onTabClick={(key, event) => {
                    this.setState({active_tab: key});
                    if(key == 'all_products'){

                        this.loadProducts(this.state.account_token, 'all');
                    }
                    if(key == 'active_products'){

                        this.loadProducts(this.state.account_token, 'active');
                    }
                    if(key == 'progressing'){

                        this.loadProducts(this.state.account_token, 'progressing');
                    }
                    if(key == 'delivered'){

                        this.loadProducts(this.state.account_token, 'delivered');
                    }
                }}>
                    
                    <Tabs.TabPane tab={
                        <a>
                            <span>All</span>
                        </a>
                    } key="all_products">

                            {this.list(this.state.all_products, 'all')}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <span>Active</span>
                        </a>
                    } key="active_products">

                        {this.list(this.state.active_products, 'active')}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <span>In Progress</span>
                        </a>
                    } key="progressing">

                        <Table columns={this.getColumns()} dataSource={this.state.progressing}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={
                        <a>
                            <span>Sold</span>
                        </a>
                    } key="delivered">

                        <Table columns={this.getColumns()} dataSource={this.state.delivered}>
                            <Empty />
                        </Table>
                    </Tabs.TabPane>

                </Tabs>
            </>
        )
    }

    getColumns = () => {

        const columns = [

            {title: 'Buyer/Client', dataIndex: 'buyer_name', key: 'buyer_name', render: (name, row) => {
                return (
                    <Link to={`/project-flow/${row.project_unq_id}/${row.buyer_id}`}>
                        <Avatar size={45} src={row.buyer_photo} />
                        <span className="ml-5 fw-b c-b">{name}</span>
                    </Link>
                )
            }},
            {title: 'Product', dataIndex: 'title', key: 'title',},
            {title: 'Start Date', dataIndex: 'awarded_on_date_formatted', key: 'awarded_on_date_formatted',},
            {title: 'Budget', dataIndex: 'budget', key: 'budget',},
            {title: 'Action', key: 'action', render: (name, row) => {

                return (

                    <Link className="btn" key="details" to={`/project-flow/${row.project_unq_id}/${row.buyer_id}`}>Details</Link>
                )
            }},
        ];

        return columns;
    }

    loadProducts = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('type', type);
        
        var that = this;

        this.setState({loading: true});
        Api.post('products/list', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status){
            
                if(type == 'active'){

                    that.setState({active_products: data.products});

                }else if(type == 'progressing'){
                    
                    that.setState({progressing: data.products});

                }else if(type == 'delivered'){
                    
                    that.setState({delivered: data.products});

                }else{
                    
                    that.setState({all_products: data.products});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default ProductsListComponent;