import React, { Component } from 'react';

import certificateimg from '../../../assets/images/certificate-bg.png';

import { Popconfirm } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';

class CertificationItem extends Component { 

    render () {

        let certification = this.props.certificate;

        if(certification){
        
            return (

                <div className={(this.props.className ? this.props.className : '')}>

                    <div className="block d-flex justify-content-between">
                        <div className="icon-block">
                            <img className="img-fluid" src={certificateimg} alt={certification.certificate_name} />
                            <span>{this.props.index + 1}</span>
                        </div>
                        <div className="text-block d-flex wrap">
                            <div className="sl-title w-100">{certification.certificate_name}</div>
                            <div className="sl-company-name w-50">{certification.certificate_from}</div>
                            <div className="sl-from-to w-50 text-right">{certification.year_of_certificate}</div>
                       

                            {(this.props.editable && certification.hasOwnProperty('file_url') && certification.file_url !== '') &&
                            
                                <div className="sl-from-to mt-1">
                                    <a href={certification.file_url} target="_blank">
                                        <FileDoneOutlined style={{color:'#003965', fontSize:25}} />
                                    </a>
                                </div>
                            }
                        </div>
                        
                        <div className="sl-buttons">

                            {this.props.editable &&
                            
                                <>
                                    <div className="pointer" onClick={() => {

                                        this.props.editItem(certification);
                                    }}>
                                        Edit
                                    </div>

                                    <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                        
                                        this.setState({item: false});
                                        this.props.remove(certification);
                                    }} okText="Yes" cancelText="Cancel">
                                        <div className="pointer">
                                            Remove
                                        </div>
                                    </Popconfirm>
                                </>
                            }
                        </div>
                    </div>
                </div>
            )
        }else{

            return null
        }
    }
}

export default CertificationItem;
