import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { LinkedIn } from 'react-linkedin-login-oauth2';

import { Form, Input, Button, Checkbox } from 'antd';

import FeatherIcon from 'feather-icons-react';
import Loader from '../../components/Loader';

import Api from '../../api/Api';

class LoginBlock extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            login: false,

            error_message: '',
            success_message: '',

            fb_login: false,
            ln_login: false,
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        
        // if(account_token){
        //     window.location.href = Api.server_url + "app/landing/" + account_token;
        // }
    }
    
    onFinish = (values) => {

        var formData = new FormData();
        Object.keys(values).forEach(key => formData.append(key, values[key]))
        
        var that = this;

        Api.post('auth/login', formData, function(data){
            
            if(data.status == true){
            
                localStorage.setItem('account_token', data.account_token);
                localStorage.setItem('user', JSON.stringify(data.user));
                
                that.setState({loading: false});
                that.setState({message: true});

                that.props.onSuccess(data.account_token);
            
            }else{
                that.setState({loading: false});
                
                that.props.onError(data.message, false, false);

                if(data.code == 'pending_verification'){

                    window.setTimeout(() => {

                        that.setState({pending_verification: data.user_code});
                    }, 1000);
                }
            }
            window.setTimeout(() => {
                that.setState({error_message: false});
            }, 10000);
        });
    }

    socialLogin = (response) => {

        if(response.hasOwnProperty('userID') && response.hasOwnProperty('email')){

            var formData = new FormData();

            if(response.hasOwnProperty('picture') && response.picture.hasOwnProperty('data') && response.picture.data.hasOwnProperty('url')){

                formData.append("fb_profile_picture", response.picture.data.url);
            }
            
            formData.append("user_id", response.userID);
            formData.append("email", response.email);
            
            var that = this;

            this.setState({fb_login: true});

            Api.post('login_social', formData, function(data){
                
                if(data.status == true){
                
                    localStorage.setItem('account_token', data.account_token);
                    localStorage.setItem('user', JSON.stringify(data.user));

                    that.props.onSuccess(data.account_token);
                
                }else{

                    that.setState({fb_login: false});
                    
                    if(data.code){
                    
                        that.props.onError(data.message, data.code, data.user_code);
                    }else{

                        that.props.onError(data.message, false, false);
                    }
                }
            });
        }else{

            this.props.onError("FB authentication failed! Please try again after sometime.");
        }
    }

    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <>
                <Form {...layout} name="login_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>
                                
                <div className="signup-link fs-15 c-g mb-40">
                    Don't have an account? <Link to="/get-started" className="c-o fw-600">Sign up</Link>
                </div>

                    <Form.Item name="email" rules={[{required:true, message: 'Please enter your email'}, {type: 'email', message: 'Please enter valid email address'}]}>
                        <Input placeholder="Email address" />
                    </Form.Item>
                    
                    <Form.Item name="password" rules={[{required:true, message:'Please input your password!'}]}>
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    
                    <div className="remember-forgot d-flex justify-content-between">
                        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Link to="/forgot-password">Forgot Password</Link>
                    </div>
                    
                    <Form.Item {...tailLayout} className="">
                        <Button type="primary" size="large" htmlType="submit" className="btn-orange btn-wide">Login</Button>
                    </Form.Item>
                </Form>

                <div className="text-center my-10">
                    <span className="or-link"><span>Or</span></span>
                </div>

                <div className="social-logins">
                    <div className="buttons d-flex align-center mt-2">

                        {/* {this.state.fb_login
                            ?
                                <div style={{position:'relative', width:200, height:50}}>
                                    <Loader is_small={true} />
                                </div>
                            :
                                <FacebookLogin
                                    appId="348923879499164"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    scope="public_profile"
                                    returnScopes={true}
                                    callback={(response) => {
                                        this.socialLogin(response)
                                    }}
                                    onFailure={(response) => {
                                        this.props.onError("FB Login failed! Please try again after sometime.");
                                    }}
                                    cssClass="fb-login-button"
                                    icon={<FeatherIcon icon="facebook" />}
                                />
                        } */}

                        {this.state.ln_login
                            ?
                                <div style={{position:'relative', width:200, height:50}}>
                                    <Loader is_small={true} />
                                </div>
                            :
                                <LinkedIn
                                    clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                                    onFailure={(error) => {

                                        this.props.onError("LinkedIn Authentication failed! Please try again after sometime.");
                                    }}
                                    onSuccess={(data) => {

                                        this.authenticateLinkedin(data)
                                    }}
                                    scope="r_emailaddress r_liteprofile"
                                    redirectUri={`${Api.url}linkedin`}
                                >
                                    {({ linkedInLogin }) => (
                                        <div
                                            className="btn-default btn-linked-in text-center"
                                            onClick={linkedInLogin}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>Login with Linkedin
                                        </div>
                                    )}
                                </LinkedIn>
                        }
                    </div>
                </div>
            </>
        )
    }

    authenticateLinkedin = (code) => {

        var formData = new FormData();

        formData.append("key", code);
        formData.append("page", 'login');
            
        var that = this;

        this.setState({ln_login: true});

        Api.post('auth/authenticate_linkedin', formData, function(data){
            
            that.setState({ln_login: false});

            if(data.status == true){
            
                localStorage.setItem('account_token', data.account_token);
                localStorage.setItem('user', JSON.stringify(data.user));

                that.props.onSuccess(data.account_token);
            
            }else{
                
                if(data.code){
                
                    that.props.onError(data.message, data.code, data.user_code);
                }else{

                    that.props.onError(data.message, false, false);
                }
            }
        });
    }
}

export default LoginBlock;
