import React, { Component } from 'react';

import { Redirect} from 'react-router-dom';

import { Carousel } from 'antd';

import Main from 'components/Main';

import Api from 'api/Api';

import LayoutHelper from 'helpers/LayoutHelper';

import ProjectInfo from './blocks/ProjectInfo';
import ProjectRow from 'components/projects/ProjectRow';

class ProjectDetailsPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',
            
            back: false,
            project: false,
            related_projects: [],
            
            user: false,
            account_token: false,

            proposal_submitted: false,

            loading: false,

            last_message_id: false,
            messages: []
        }

        this.proposal_form = React.createRef();
        this.proposal_submission = React.createRef();
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        user = JSON.parse(user);

        if(user){

            this.setState({user: user});
        }

        var account_token = localStorage.getItem('account_token');
        if(account_token){

            this.setState({account_token: account_token});
        }

        const project_slug = this.props.slug;

        if(project_slug && project_slug != ''){

            this.loadData(project_slug, account_token);
        }else{

            this.setState({back: true});
        }
    }

    render () {

        const props = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };

        if(this.state.back){

            return <Redirect to="/projects-search" />
        }

        return (

            <Main full_content={true} error_message={this.state.error_message} success_message={this.state.success_message}>

                <ProjectInfo
                    project={this.state.project}
                    messages={this.state.messages}
                    updateMessage={(row) => {

                        let messages = this.state.messages;

                        messages.push(row);

                        this.setState({messages: messages})
                    }}

                    onError={(message) => {
                        LayoutHelper.addErrorMessage(this, message)
                    }}
                    onSuccess={(message) => {
                        LayoutHelper.addSuccessMessage(this, message)
                    }}
                    updateProject={(project, status) => {
                        this.setState({project: project, proposal_submitted: status});
                    }}
                    proposal_submitted={this.state.proposal_submitted}
                    updateProposalSubmitted={(status) => {
                        this.setState({proposal_submitted: status})
                    }}
                />
                
                <div className="related-projects-section inner-page listing-page">
                    <div className="container">
                        <div className="heading fs-22 pb-20 ">Related Projects</div>
                        
                        <div className="related-projects-listing">

                            {this.state.related_projects && this.state.related_projects.length == 1
                                ?

                                    this.state.related_projects.map((project_item, index) => {

                                        return <ProjectRow key={'project_item_' + project_item.id + index} project={project_item} rows={6} source="project" />
                                    })
                                :
                                    <Carousel ref={node => (this.carousel = node)} {...props}>

                                        {this.state.related_projects && this.state.related_projects.length > 0
                                            ?
                                                this.state.related_projects.map((project_item, index) => {

                                                    return <ProjectRow key={'project_item_' + project_item.id + index} project={project_item} rows={2} source="project" />
                                                })
                                                
                                            :
                                                null
                                        }
                                        
                                    </Carousel>
                            }
                        </div>
                    </div>
                </div>
            </Main>
        )
    }

    loadData = (slug, account_token) => {

        var formData = new FormData();
        formData.append('slug', slug);
        formData.append('source', 'project_view');

        if(account_token){

            formData.append('account_token', account_token);
        }

        var that = this;
        this.setState({loading: true});

        Api.post('projects/single', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){

                if(data.submitted){
                    that.setState({proposal_submitted: data.submitted});
                }

                that.setState({project: data.project});
                that.setState({related_projects: data.related_projects});

                let last_message_id = false;

                if(data.messages){

                    that.setState({messages: data.messages})

                    if(data.messages.length > 0){
                    
                        last_message_id = data.messages[data.messages.length - 1]['ref'];
                    }
                }

                that.setState({last_message_id: last_message_id}, () => {

                    that.repeatLoadMessages(data.project.project_unq_id, last_message_id);
                })

            }else{
                that.setState({back: true});
            }
        });
    }

    componentWillUnmount() {

        clearInterval(this.interval);
    }

    repeatLoadMessages = (project_id, last_message_id) => {

        var that = this;
        this.interval = setInterval(() => {

            that.loadMessages(project_id, last_message_id);
        }, 5000);
    }

    loadMessages = (project_id, last_message_id) => {

        var formData = new FormData();
        
        formData.append('account_token', this.state.account_token);
        formData.append('project', project_id);
        
        if(last_message_id){

            formData.append('ref', last_message_id);
        }

        var that = this;

        this.setState({loading: true});
        Api.post('projects/messages', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status){
            
                // that.scrollToBottom();

                var has_new_entry = false;

                var messages = that.state.messages;

                if(data.messages.length > 0){

                    has_new_entry = true;
                    
                    last_message_id = data.messages[data.messages.length - 1]['ref'];

                    data.messages.forEach((_message, index) => {

                        let message_row_id = messages.findIndex(row => row.ref === _message.ref);

                        if(message_row_id < 0){

                            messages.push(_message)
                        }
                    })
                }
                
                // messages.push(...data.messages);

                that.setState({
                    last_message_id: last_message_id,
                    messages: messages,
                });

                if(has_new_entry){

                    // that.scrollToBottom();
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // LayoutHelper.addErrorMessage(data.message);
            }
        });
    }
}

export default ProjectDetailsPage;