import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Table, Tabs, Empty, Avatar, Tag } from 'antd';

import Main from 'components/Main';

import Api from 'api/Api';

import LayoutHelper from 'helpers/LayoutHelper';

import Price from 'components/Price';

class InvoicesList extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            active_tab: 'raised_invoices',

            account_token: false,
            user: false,

            raised_invoices: [],
            received_invoices: [],
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token && user){
            
            this.setState({account_token: account_token});
            this.loadInvoices(account_token, 'raised')

        }else{
            window.location = Api.url + '/logout';
        }
    }

    render () {

        return (

            <>
                <Main page_title="Manage Invoices" hide_page_title="true" className="account-bg" page="invoices" error_message={this.state.error_message} success_message={this.state.success_message}>
                    
                    <div className="container-lg page-main-content">
                        
                        <div className="page-heading flex justify-between">
                            <span>Invoices</span>
                        </div>

                        <Tabs className="manage-products-list" defaultActiveKey="raised_invoices" onTabClick={(key, event) => {
                    
                            this.setState({active_tab: key});

                            if(key == 'raised_invoices'){

                                this.loadInvoices(this.state.account_token, 'raised');
                            }
                            if(key == 'received_invoices'){

                                this.loadInvoices(this.state.account_token, 'received');
                            }
                        }}>
                        
                            <Tabs.TabPane tab={
                                <a>
                                    <span>Raised Invoices</span>
                                </a>
                            } key="raised_invoices">

                                <Table columns={this.getColumns()} dataSource={this.state.raised_invoices}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <span>Received Invoices</span>
                                </a>
                            } key="received_invoices">

                                <Table columns={this.getColumns()} dataSource={this.state.received_invoices}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </Main>
            </>
        )
    }

    getColumns = () => {

        const columns = [

            {title: '#Invoice', dataIndex: 'invoice_number', key: 'invoice_number', render: (name, row) => {

                return (
                    <Link to={`/project-flow/${row.project_unq_id}/${row.buyer_id}`} className="flex vertical">
                        <div className='flex vertical'>
                            <span className='c-o fw-600'>#{row.invoice_number}</span>
                            <span className='op-6 fs-13'>{row.invoice_date_formatted}</span>
                        </div>
                    </Link>
                )
            }},
            {title: 'Project', dataIndex: 'title', key: 'title', render: (name, row) => {
                return (
                    <Link to={`/project-flow/${row.project_unq_id}/${row.buyer_id}`} className="flex vertical">

                        {this.state.active_tab === 'raised_invoices'
                            ?
                                <div className='align-center justify-content-start'>
                                    <Avatar size={30} src={row.receiver_photo} />
                                    <span className="ml-5 c-b">{row.receiver_name}</span>
                                </div>
                            :
                                null
                        }

                        <span className='op-8 fs-14 fw-b c-b mt-2'>{row.title}</span>
                    </Link>
                )
            }},
            {title: 'Amount', align:'right', dataIndex: 'total', key: 'total', render: (name, row) => {
                return (
                    <div className='justify-end'>
                        <Price>{name}</Price>
                    </div>
                )
            }},
            {title: 'Invoice Date', dataIndex: 'added_on', key: 'added_on', render: (name, row) => {
                return (
                    <span className='op-6 fs-13'>{row.invoice_date_formatted}</span>
                )
            }},
            {title: 'Status', dataIndex: 'status', key: 'status_label', render: (name, row) => {

                return (
                    <Tag color={row.label_color}>
                        <strong>{row.status_label}</strong>
                    </Tag>
                )
            }},
        ];

        return columns;
    }

    loadInvoices = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('type', type);
        
        var that = this;

        this.setState({loading: true});
        Api.post('invoices/list', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status){
            
                if(type == 'raised'){

                    that.setState({raised_invoices: data.invoices});

                }else if(type == 'received'){
                    
                    that.setState({received_invoices: data.invoices});

                }
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default InvoicesList;