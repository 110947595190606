import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

export default class HeaderSearchRouter extends Component { 
    constructor(props) {
        super();
        this.state = {
            redirect: false,
            module: ''
        }
    }

    componentDidMount = () => {

        let url = this.props.url;

        if(url){

            const arg_one = this.props.arg_one;
            const arg_two = this.props.arg_two;
            const arg_three = this.props.arg_three;
            const arg_four = this.props.arg_four;

            if(arg_one){

                url = url + '/' + arg_one;
            }

            if(arg_two){

                url = url + '/' + arg_two;
            }

            if(arg_three){

                url = url + '/' + arg_three;
            }

            if(arg_four){

                url = url + '/' + arg_four;
            }

            this.setState({redirect: `/${url}`})
        }else{

            this.setState({redirect: '/'})
        }
    }

    render () {
        
        if(this.state.redirect){

            return <Redirect to={this.state.redirect} />;
        }

        return null;
    }
}