import React, { Component } from 'react';

import { Link, Redirect } from "react-router-dom";

import { List, Carousel, Modal, Row, Col, Button } from 'antd';

import LeftOutlined from '@ant-design/icons/LeftOutlined';
import FileSearchOutlined from '@ant-design/icons/FileSearchOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';

import FeatherIcon from 'feather-icons-react';
import Main from 'components/Main';

import ProposalWrapper from '../../project_flow/ProposalWrapper';

import FreelancerCard from 'components/freelancer/FreelancerCard';
import FreelancerRow from 'components/freelancer/FreelancerRow';

import ProfilePic from 'components/ProfilePic';

import MessageRow from '../../messages/MessageRow';
import MessagesListComponent from '../../messages/MessagesListComponent';

import Api from 'api/Api';

import ProjectProposalHeader from './ProjectProposalsHeader';

import NoData from 'components/NoData';
import LayoutHelper from 'helpers/LayoutHelper';

class Projects extends Component {
    constructor(props) {
        super();
        this.state = {

            initial_loading: false,
            loading: false,

            error_message: '',
            success_message: '',

            account_token: false,
            user: false,

            go_back: false,

            has_data: false,

            messages: [],

            project: false,
            proposals: [],

            per_page: 10,
            total: 0,

            featured_proposals: [],

            proposal_view: false,

            active_proposal: false,

            selected_message: false,
            message_view: 'messages',
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user, account_token: account_token});

        var project_id = this.props.project_id;

        if(project_id){
         
            this.loadProposals(account_token, project_id, 1, true);
        }else{

            this.setState({go_back: true});
        }
    }

    render () {

        if(this.state.go_back){

            return <Redirect to="/" />
        }

        return (

            <>
                <Main page_title="Project Proposals" hide_page_title={true} page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message}>
                    
                    <ProjectProposalHeader
                        project={this.state.project}
                    />
                    
                    <div>

                        {this.featuredProposals()}
                    </div>

                    <div className="container">

                        <div className="page-section-heading">
                            {/* <div className="tab-heading">Everything else</div> */}
                            <div className="tab-sub-heading">Proposals from freelancers</div>
                        </div>

                        <Row gutter={20}>
            
                            <Col span={16}>
                                
                                <div className="freelancers-list">

                                    {this.state.proposals.length <= 0 && !this.state.initial_loading
                                        ?
                                            <div className='box-white' style={{minHeight: 500}}>
                                            
                                                <NoData className="mt-30 p-20" message="No proposals received for this project yet!" hide_image={true} icon={<FileSearchOutlined />} />
                                            </div>
                                        :
                                            <List
                                                // header={this.header()}
                                                className="projects-list"
                                                itemLayout="horizontal"
                                                size="large"
                                                loading={this.state.loading}
                                                pagination={this.state.proposals.length <= 0 ? false : {
                                                    onChange: page => {
                                                        this.props.onLoad();
                                                        window.setTimeout(() => {

                                                            this.loadProposals(this.state.account_token, this.state.project_id, page, false);
                                                        }, 200);
                                                    },
                                                    pageSize: this.state.per_page,
                                                    total: this.state.total
                                                }}
                                                dataSource={this.state.proposals}
                                                renderItem={item => (
                                                    
                                                    <FreelancerRow
                                                        popupView={true}
                                                        freelancer={item}
                                                        source="proposals"
                                                        project={this.state.project}
                                                        rowAction={(proposal) => {

                                                            if(this.state.project.status === '1'){
                                                            
                                                                return (
                                                                    <div className='flex vertical justify-between'>
                                                                        <Button type="link" onClick={() => {
                                                            
                                                                            this.setState({active_proposal: item}, () => {
                                                                            
                                                                                this.setState({proposal_view: true})
                                                                            })

                                                                        }} className="dark-blue-btn border-none h-100 c-w py-8 px-20 br-5 bg-brown fs-14 fw-500">
                                                                            View Proposal
                                                                        </Button>

                                                                        {proposal.proposal_status == '3' &&
                                                                        
                                                                            <strong className='fs-14 c-dbrown ml-10'>{proposal.proposal_status_label}</strong>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                    }
                                </div>
                            </Col>
                            <Col span={8}>

                                <div className="messages-list sub-chat-messages bg-w">

                                    {this.state.message_view === 'messages'
                                        ?
                                            <div className='p-15'>
                                                <strong className='fw-b fs-16 c-b'>Messages</strong> 

                                                {this.state.messages.length > 0 &&
                                                
                                                    <span className='fw-b fs-16 c-o ml-5 op-7'>({this.state.messages.length})</span>
                                                }
                                            </div>
                                        :
                                            <div className='flex flex-start p-10'>
                                                <Button type="text" className='flex' size="small" onClick={() => {

                                                    this.setState({message_view: 'messages'})
                                                }}>
                                                    <LeftOutlined />
                                                </Button>
                                                <div className='p-10 justify-center'>
                                                    <ProfilePic src={this.state.selected_message.src} size={30} />
                                                    <strong className='fw-b fs-16 c-b ml-5'>{this.state.selected_message.sender_name}</strong>
                                                </div>
                                            </div>
                                    }

                                    <div style={{minHeight: 400}} className="flex vertical p-10">
                                    
                                        {this.state.message_view === 'messages'
                                            ?
                                                <>
                                                    {this.state.messages && this.state.messages.length > 0
                                                        ?

                                                            this.state.messages.map((message) => {

                                                                return (

                                                                    <MessageRow
                                                                        key={'message_row_' + message.ref}
                                                                        message={message}
                                                                        style={{cursor: 'pointer'}}
                                                                        className="hoverable"
                                                                        onClick={(message) => {

                                                                            this.setState({message_view: 'list', selected_message: message})
                                                                        }}
                                                                    />
                                                                )
                                                            })
                                                        :
                                                            <NoData className="mt-30 p-20" size="small" message="New messages will appear here!" hide_image={true} icon={<MessageOutlined />} />
                                                    }
                                                </>
                                            :
                                                <MessagesListComponent
                                                    user={this.state.user}
                                                    project_id={this.state.project.project_unq_id}
                                                    account_token={this.state.account_token}
                                                    receiver={this.state.selected_message ? this.state.selected_message.sender_id : false}
                                                    row_style='chat'
                                                    className="sub-chat proposal-timeline"
                                                />
                                        }
                                    </div>

                                    {(this.state.message_view === 'messages' && this.state.messages.length > 0) &&
                                        
                                        <div className='align-center'>

                                            <Link className='fw-b' to="/messages">View All</Link>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Modal
                        visible={this.state.proposal_view}
                        style={{ top: 0, left: 0, maxWidth: '100%', paddingBottom:0 }}
                        // onOk={this.handleOk}
                        closable={true}
                        onCancel={() => {
                            this.setState({proposal_view: false, active_proposal: false});
                        }}
                        width={1200}
                        footer={null}
                        title="Proposal"
                    >
                        <div className="proposal-overview">

                            {this.state.active_proposal &&
                            
                                <ProposalWrapper

                                    project_id={this.state.project.project_unq_id}
                                    seller_id={this.state.active_proposal.unq_index_id}
                                    onError={(message) => {

                                        LayoutHelper.addErrorMessage(this, message)
                                    }}
                                    onSuccess={(message) => {

                                        LayoutHelper.addSuccessMessage(this, message)
                                    }}

                                    updateMainProposal={(proposal) => {

                                        let proposals = this.state.proposals;
                                        console.log(proposals);

                                        const _proposal_row_index = proposals.findIndex(row => row.id === proposal.id);

                                        if(_proposal_row_index > -1){

                                            proposals[_proposal_row_index] = proposal;
                                        }

                                        this.setState({proposals: proposals})
                                    }}
                                />
                            }
                        </div>
                    </Modal>
                </Main>
            </>
        )
    }

    featuredProposals = () => {

        if(this.state.featured_proposals.length > 0){

            return (
            
                <div className="container">

                    <div className="page-section-heading">
                        <div className="tab-heading">Recommended</div>
                        <div className="tab-sub-heading">We recommends this proposal</div>
                    </div>
                    <div className="articles-grid featured-slider">
                        
                        <Carousel
                            dots={false}
                            autoplay={false}
                            slidesToScroll={1}
                            slidesToShow={this.state.featured_proposals.length < 2 ? (this.state.featured_proposals.length - 0) : 2}
                            arrows={true}
                            prevArrow={<div><div className="slider-arrows"><FeatherIcon icon="chevron-left" className="ico" size={30} /></div></div>}
                            nextArrow={<div><div className="slider-arrows"><FeatherIcon icon="chevron-right" className="ico" size={30} /></div></div>}
                        >
                            {this.state.featured_proposals.map((_proposal) => {

                                var key = 'featured_card_' + _proposal.inc_id;
                                return (
                                    <FreelancerCard
                                        key={key}
                                        user={_proposal}
                                        onRightActionClick={(proposal) => {
                                            this.setState({active_proposal: proposal, proposal_view: true});
                                        }}
                                        right_action_label="View Proposal"
                                        no_link={true}
                                    />
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            )
        }
    }

    loadProposals = (account_token, project_id, page, init) => {

        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('project', project_id);
        formData.append('page', page);
        
        var that = this;

        this.setState({loading: true});

        if(init){

            this.setState({initial_loading: true});
        }

        Api.post('proposals/bids', formData, function(data){
            
            that.setState({loading: false, initial_loading: false});
            
            if(data.status){

                if(data.featured_proposals && data.featured_proposals.length > 0){

                    that.setState({featured_proposals: data.featured_proposals});
                }else{

                    that.setState({featured_proposals: []});
                }

                if(data.messages){

                    that.setState({messages: data.messages})
                }
            
                if(data.proposals){

                    that.setState({proposals: data.proposals, project: data.project, no_data: false, total: data.proposals.length});
                }else{

                    that.setState({no_data: true, proposals: [], project: false});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // that.props.errorMessage(data.message);
            }
        });
    }
}

export default Projects;