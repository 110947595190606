import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Avatar, Typography, Rate, Button, Modal } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';

import FreelancersProfileSection from '../../pages/freelancers/FreelancersProfileSection';

import Tags from '../Tags';

import Price from 'components/Price';

class FreelancerRow extends Component { 
    
    constructor(props) {
        super();
        this.state = {

            popup: false,
        }
    }

    userLink = (html, user) => {

        if(this.props.popupView){

            return (
                <div style={{cursor: 'pointer'}} onClick={() => {

                    this.setState({popup: true})
                }}>
                    {html}
                </div>
            )
        }else{

            return <Link to={this.props.link ? this.props.link : "/service-partner/" + user.slug}>{html}</Link>
        }
    }

    render () {

        var user = this.props.freelancer;
        
        if(user){
            
            return (

                <div className={`row-item wrap ${this.props.className ? this.props.className : ''}`}>
                    <div className="left">
                        <div className="profile-image">

                            {this.userLink(<Avatar className={user.profile_pic_full != '' ? '' : 'empty'} size={100} src={user.profile_pic_full != '' ? user.profile_pic_full : require('../../assets/graphics/user.svg')} shape="square" />, user)}

                            {(user.featured && user.featured == 1) && 
                                    
                                <span className="featured-text">Featured</span>
                            }
                        </div>                       
                    </div>
                    <div className="right">
                        <div className="top-section d-flex justify-content-between">
                            <div>

                                {this.userLink(<strong className="fs-22 m-fs-16 fw-b c-b l-h-1">{user.name}</strong>, user)}

                                {(user.verified && (user.verified == 1)) &&
                                
                                    <span className="verified"><CheckCircleOutlined /> Verified</span>
                                }

                                {/* {this.props.source === 'proposals' &&
                                
                                    user.proposal_status_label
                                } */}
                            </div>
                        </div>
                        <div className="mid-section">
                            <div className="fs-12 fw-500 c-b mt-2">{user.city} {user.country_name && user.country_name !== '' ? `, ${user.country_name}` : ''}</div>
                            <div className='flex rating_box items-center'>
                              <Rate disabled value={user.avg_rating} />
                              <div className="fs-13 c-b"><strong>{user.avg_rating}</strong> ({user.reviews_count})</div>
                            </div>

                        </div>
                        
                        {this.props.source
                            ?
                                this.props.source === 'proposals'
                                    ?
                                        <>
                                            <strong className='fs-14 fw-b c-b mt-20'>Cover Letter</strong>
                                            <Typography.Paragraph rows={2} ellipsis={false} className='fs-12 mt-0 c-b op-7'>{user.description}</Typography.Paragraph>
                                        </>
                                    :
                                        null
                            :
                                null
                        }

                    </div>
                    <div className='mt-10'>
                      <Typography.Paragraph rows={2} ellipsis={{rows: 2}} className='fs-14 mt-5 c-dg2 op-8'>{user.story}</Typography.Paragraph>
                        <div className="bottom-section flex align-center justify-between">                           
                            {user.skills_formatted &&
                                <Tags tags={user.skills_formatted} limit={4} limit_suffex="skills" />
                            }
                        </div>
                    </div>
                    <div className='d-flex w-100 justify-content-between justify-center mt-10'>
                           {this.props.rowAction
                                ?
                                    this.props.rowAction(user)
                                :
                                    <Link to={"/service-partner/" + user.slug} className="dark-blue-btn c-w py-8 px-20 br-5 bg-brown fs-14 fw-500">View Profile</Link>
                            }

                        <div className="price-section">

                            {user.price > 0 &&

                                <div className="price text-right fs-18 c-b fw-600">
                                    <Price>{user.price_formatted}</Price>
                                </div>
                            }
                        </div>

                    </div>

                    {this.props.popupView &&
                    
                        <Modal
                            visible={this.state.popup}
                            style={{ top: 0 }}
                            // onOk={this.handleOk}
                            closable={true}
                            onCancel={() => {

                                this.setState({popup: false, active_proposal: false});
                            }}
                            width={1200}
                            footer={null}
                            title=""
                            className='no-title'
                        >
                            <div className="proposal-overview">

                                <FreelancersProfileSection
                                
                                    keyword={user.slug}
                                    source="popup"
                                />
                            </div>
                        </Modal>
                    }
                </div>

            )
        }else{

            return null;
        }
    }
}

export default FreelancerRow;