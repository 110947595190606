import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Button } from 'antd';

import Main from 'components/Main';

import Api from 'api/Api';

import ProductsListComponent from './ProductsListComponent';

class Products extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            active_tab: 'all_products',

            account_token: false,
            user: false,

        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});

        }else{
            window.location = Api.url + '/logout';
        }
    }

    render () {

        return (

            <>
                <Main page_title="Manage Products" hide_page_title="true" className="products-list account-bg" page="products" error_message={this.state.error_message} success_message={this.state.success_message}>
                    
                    <div className="container-lg page-main-content">
                        
                        <div className="page-heading flex justify-between">
                            <span>Manage Products / Offers</span>
                            <Link to="/account/product-add">
                                <Button type="primary" size="large">Add Product</Button>
                            </Link>
                        </div>

                        <ProductsListComponent />
                    </div>
                </Main>
            </>
        )
    }
}

export default Products;