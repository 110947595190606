import React, { Component } from 'react';

import { Link } from "react-router-dom";

import Api from '../../api/Api';

import Main from '../../components/Main';

import '../../assets/styles/gig_hiring_style.css';

class GigChallengesPage extends Component { 
   

    render () {

        return (

            <Main page_title="NDA Page" hide_page_title="true">
                <div className="bg-white tacs_wrapper">
                <section class="tacs-banner">
                    <div class="gig_container">
                        <div class="row">
                        <div class="col-md-3 col-xs-6">
                        </div>
                        <div class="col-md-9">
                            <h4 className='c-w'>Why Choose</h4>
                            <h1 className='c-w'>Managed Gig Hiring?</h1>
                            <div class="flex hero-buttons">
                            <a href="http://localhost:3000/gig-hiring" className="df-bg-rust">Client</a> <a href="http://localhost:3000/service-partners" className="df-bg-blue">Service Partners</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div class="gig_container">
                        <div class="row">
                        <div class="w-100">
                            <h6 class="tacs-subheading text-center">Problem</h6>
                            <h2 class="tacs-mainheading text-center w-70">Challenges in Digital and Data Science Talent Acquisition</h2>
                        </div>
                        </div>
                    </div>
                    <div class="gig_container gig-challenges">
                        <div class="row">
                        <div class="col-xs-12 col-md-7">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/prohibitve_cost.svg" alt="" />
                            <h2 class="tacs-sectionheading">Prohibitive costs</h2>
                            <ul>
                            <li>Redundant bench full of skills</li>
                            <li>Short term augmentation requirements</li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-5">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/complex_env.svg" alt="" />
                            <h2 class="tacs-sectionheading">Complex Environment</h2>
                            <ul>
                            <li>Matrix of Tech and Skills to be maintained</li>
                            </ul>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-xs-12 col-md-7">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/scarce_skills.svg" alt="" />
                            <h2 class="tacs-sectionheading">Scarce Skills &amp; Obsolescence</h2>
                            <ul>
                            <li>Difficult to hire</li>
                            <li>Retention is an issue as poaching is rampant.</li>
                            <li>Constant L&amp;D requirements as the technologies get obsolete.</li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-5">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/hypothesis.svg" alt="" />
                            <h2 class="tacs-sectionheading">Hypothesis Testing &amp; Proof of Concept</h2>
                            <ul>
                            <li>Expedite requests by business to spruce up hiring</li>
                            <li>Constant hypothesis testing leads to frequent mandates for expensive POCs</li>
                            </ul>
                        </div>
                        </div>
                    </div>

                    </section>

                    <a name="value_proposition"></a>
                    <section>
                    <div class="gig_container">
                        <div class="row">
                        <div class="w-100">
                            <h6 class="tacs-subheading text-center">Value proposition</h6>
                            <h2 class="tacs-mainheading text-center width_50">In Tune With Talent Strategy 5.0</h2>
                        </div>
                        </div>
                    </div>
                    <div class="gig_container talent_5_0 margin_top_6">
                        <div class="row">
                        <div class="col-md-2 order-2">
                            <ul>
                            <li><span class="tacs-subheading df-font-bold">Large Pool</span> Availability</li>
                            <li><span class="tacs-subheading df-font-bold">Multiple talent</span> deployment options</li>
                            <li><span class="tacs-subheading df-font-bold">Least risk</span> project ownership/hiring</li>
                            </ul>
                        </div>
                        <div class="col-md-8 text-center order-1">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/industry_5_0.jpg" alt="" />
                        </div>
                        <div class="col-md-2 order-3">
                            <ul>
                            <li><span class="tacs-subheading df-font-bold">Support for expedited</span> Requirements</li>
                            <li><span class="tacs-subheading df-font-bold">Support for</span> Short-term Requirements</li>
                            <li><span class="tacs-subheading df-font-bold">Least</span> Adninistrative Overheads</li>
                            <li><span class="tacs-subheading df-font-bold">Optimized</span> Costs</li>
                            </ul>
                        </div>
                        </div>
                    </div>

                </section>

                    <a name="actionable_insights"></a>
                <section class="tacs-grey-bg">
                    <div class="gig_container">
                        <div class="row">
                        <div class="w-100">
                            <h6 class="tacs-subheading text-center">TALENT AVAILABLE SERVICE AREAS </h6>
                            <h2 class="tacs-mainheading text-center width_50">Actionable Insights</h2>
                        </div>
                        </div>
                    </div>
                    <div class="gig_container actionable_insights margin_top_2">
                        <div class="row">
                        <div class="w-100 text-center">
                            <ul>
                            <li>No Complex Skills Hiring</li>
                            <li>Reduced Cost of Operations </li>
                            <li>Timebound Outcomes</li>
                            </ul>
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/actionable_insights.png" alt="" class="margin_top_2" />
                        </div>
                        </div>
                    </div>

                    </section>

                    <a name="talent_engagement_options"></a>
                    <section>
                    <div class="gig_container">
                        <div class="row">
                        <div class="w-100">
                            <h2 class="tacs-mainheading text-center width_90">Talent Engagement Options</h2>
                        </div>
                        </div>
                    </div>
                    <div class="gig_container margin_top_6 max-w-1100 mb-row">
                        <div class="row">
                        <div class="w-50">
                            <div class="talent_options">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/contractual_hiring.jpg" alt="" />
                            <div>
                                <h3 class="tacs-sectionheading">Contractual hiring</h3>
                                <p>Project based</p>
                            </div>
                            </div>
                        </div>
                        <div class="w-50">
                            <div class="talent_options">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/team_augumentation.jpg" alt="" />
                            <div>
                                <h3 class="tacs-sectionheading">Team Augmentations</h3>
                                <p>Short and Long Term</p>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="w-50">
                            <div class="talent_options">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/payroll_hiring.jpg" alt="" />
                            <div>
                                <h3 class="tacs-sectionheading">Payroll Hiring</h3>
                                <p>Vanilla Hirings at Aggressive Costs</p>
                            </div>
                            </div>
                        </div>
                        <div class="w-50">
                            <div class="talent_options">
                            <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/resource_pooling.jpg" alt="" />
                            <div>
                                <h3 class="tacs-sectionheading">Resource Pooling</h3>
                                <p>Turn Under-leveraged Bench Into a Revenue Center </p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                </section>

            </div>
          </Main>
        )
    }
  
}

export default GigChallengesPage;