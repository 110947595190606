import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, Input, Button, Checkbox, Alert } from 'antd';

import { WarningOutlined } from '@ant-design/icons';

import LayoutHelper from '../../helpers/LayoutHelper';

import Main from '../../components/Main';
import Api from '../../api/Api';

class PendingVerification extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: false,

            loading: false,
            redirect: false,

            error_message: '',
            success_message: '',

            code: false,

            can_resend: true
        }

        let interval = 0;
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        let code = this.props.code;

        if(account_token){

            this.setState({redirect: true})
        }

        if(code){

            this.setState({code: code})
        }else{

            this.setState({redirect: true})
        }
    }

    render () {
        if(this.state.redirect){
            return <Redirect to={'/'} />;
        }

        return (

            <>
                <Main page="email_verification" error_message={this.state.error_message} success_message={this.state.success_message}>

                    <div className="new-proposal-wrap">
                    
                        <WarningOutlined style={{fontSize:100, marginBottom:10, color:'#ec38bd'}} />

                        <h3>Email verification pending</h3>
                        <p>Your email is not verified yet!. Please check your inbox or spam folder in case not received in inbox.</p>

                        {this.state.can_resend
                            ?
                                <>
                                    <p className="mt-5">Didn't received email yet!</p>
                                    <p>
                                        <Button onClick={() => {
                                            this.resendEmail();
                                        }} className="btn link" loading={this.state.loading}>
                                            Resend verification email
                                        </Button>
                                    </p>
                                </>
                            :
                                <p className="mt-5">You can resend verification mail after 15 minutes.</p>
                        }
                    </div>
                </Main>
            </>
        )
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    enableResend = () => {

        var that = this;
        this.interval = setTimeout(() => {
            that.setState({can_resend: true});
        }, 900000);
    }

    resendEmail = () => {

        var formData = new FormData();
        formData.append('user_code', this.state.code);

        var that = this;
        this.setState({loading: true});

        Api.post('auth/resend_verification', formData, function(data){
            
            that.setState({loading: false});
            if(data.status == true){
                
                LayoutHelper.addSuccessMessage(that, data.message);

                that.setState({can_resend: false});
                that.enableResend();

            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
            
        });
    }
}

export default PendingVerification;