import React, { Component } from 'react';

export default class Price extends Component { 
    
    render () {

        return (
            <div className='price-container'>

                <span className='currency'>
                    <svg xmlns="http://www.w3.org/2000/svg" height={this.props.size ? this.props.size : "22"} width={this.props.size ? this.props.size : "22"}>
                        <path d="M13.725 21 7 14v-2h3.5q1.325 0 2.288-.863.962-.862 1.162-2.137H6V7h7.65q-.425-.875-1.262-1.438Q11.55 5 10.5 5H6V3h12v2h-3.25q.35.425.625.925T15.8 7H18v2h-2.025q-.2 2.125-1.75 3.562Q12.675 14 10.5 14h-.725l6.725 7Z"/>
                    </svg>
                </span>
                <span className='fw-500'>
                    {this.props.children}
                </span>
            </div>
        )
    }
}