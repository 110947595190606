import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { Tag, Button } from 'antd';

import { ClockCircleOutlined, LeftOutlined } from '@ant-design/icons';

class ProjectProposalsHeader extends Component { 
    
    render () {

        return (

            <div className="page-static-banner">

                <div className="container">
                
                    {this.props.project
                        ?
                            <>

                                {this.props.hide_header
                                    ?
                                        null
                                    :
                                        <div className='c-b mt-30'>

                                            <Link to="/projects-as-buyer" className='mr-10'>
                                                <Button type="link" icon={<LeftOutlined style={{ fontSize: '22px', color: '#434343' }}/>} />
                                            </Link>

                                            {this.props.project.status == 2 &&
                                            
                                                <>
                                                    <Tag className='bg-dbrown py-3 px-20 br-20 c-w'>Closed</Tag>
                                                    <span>No longer accepting proposals</span>
                                                </>
                                            }

                                            {this.props.project.status == 1 &&
                                            
                                                <>
                                                    <Tag className='bg-green py-3 px-20 br-20 c-w'>Open</Tag>
                                                    <span className='c-dg'>Accepting new proposals</span>
                                                </>
                                            }
                                        </div>
                                }

                                <h2 className='fs-28 fw-b c-b mt-5 mb-5'>{this.props.project.title}</h2>
                                <div>
                                    <ClockCircleOutlined />
                                    <span className='fs-14 c-b op-7 ml-5'>{this.props.project.posted}</span>
                                </div>
                            </>
                        :
                            null
                    }
                </div>
            </div>
        )
    }
}

export default ProjectProposalsHeader;