import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

import Main from 'components/Main';

import ProposalWrapper from './ProposalWrapper';

import LayoutHelper from 'helpers/LayoutHelper';

class ProjectFlow extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            error_message: '',
            success_message: '',

            account_token: false,
            user: false,

            project_id: false,
            seller_id: false,

            go_back: false,
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user, account_token: account_token});

        var project_id = this.props.project_id;
        var seller_id = this.props.seller_id;

        if(project_id && seller_id){
         
            this.setState({project_id: project_id, seller_id: seller_id})
        }else{

            this.setState({go_back: true});
        }
    }
    
    render () {

        if(this.state.go_back){

            return <Redirect to="/" />
        }

        return (

            <>
                <Main page_title="Manage Projects" page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message} hide_page_title={true}>
                    
                    <div className="container-lg project-details project-details-page mt-50">

                        {(this.state.project_id && this.state.seller_id ) &&
                        
                            <ProposalWrapper

                                project_id={this.state.project_id}
                                seller_id={this.state.seller_id}

                                onSuccess={(message) => {

                                    LayoutHelper.addSuccessMessage(this, message)
                                }}
                                onError={(message) => {

                                    LayoutHelper.addErrorMessage(this, message)
                                }}
                            />
                        }
                    </div>
                </Main>
            </>
        )
    }
}

export default ProjectFlow;