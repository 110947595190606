import React, { Component } from 'react';

import { Tabs } from 'antd';

import BannerSlider from '../../components/sections/common/BannerSlider';
import CategoryBlock from '../../components/sections/category/CategoryBlock';
import SPProjectsTabs from '../../components/sections/common/SPProjectsTabs';

import Api from '../../api/Api';

import Main from '../../components/Main';

class CategoryListingPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            banners: [],

            category_level: 0,

            child_categories: [],
            related_categories: [],

            sp_tab_blocks: [],
            project_tab_blocks: [],
            products_list: [],

            default_category: '',
            category_slug: '',
            sub_category_slug: '',

            parent_category: false,
            sub_category: false,
        }
    }

    componentDidMount = () => {

        const category_slug = this.props.cat_slug;
        const sub_category_slug = this.props.sub_category;

        if(category_slug){

            this.setState({category_slug: category_slug});

            if(sub_category_slug){

                this.setState({sub_category_slug: sub_category_slug, category_level: 1});
            }else{

                this.setState({category_level: 0})
            }

            var account_token = localStorage.getItem('account_token');
            this.setState({account_token: account_token});

            this.loadData(sub_category_slug);
        }else{

            window.location = Api.url;
        }
    }

    renderChildCategoryTabs = () => {

        if(this.state.child_categories.length > 0){

            return this.state.child_categories.map((_category, index) => {
                
                return (
                    <Tabs.TabPane tab={<a href={`/category/${this.state.category_slug}/${_category.slug}`}><img src={_category.thumb} className="img-responsive w-60p" alt={_category.name} /><div className="c-lb fs-16 fw-500 text-center title">{_category.name}</div></a>} key={`category_${_category.slug}`}>

                        <div className="heading">{_category.name}</div>
                        <div className="block" dangerouslySetInnerHTML={{ __html: _category.description }} />
                    </Tabs.TabPane>
                )
            })
        }
    }

    renderRelatedCategories = () => {

        if(this.state.related_categories.length > 0){

            return this.state.related_categories.map((_related_category, index) => {

                return <CategoryBlock
                            key={`related_category_${index}`}
                            category={_related_category}
                            link={`${this.state.category_slug}/${_related_category.slug}`}
                        />
            })
        }
    }

    render () {

        return (

            <Main full_content={true}>
                <div className={this.state.category_level > 0 ? 'inner-page white-bg pt-0 sub-category' : 'inner-page white-bg pt-0'}>
                    <BannerSlider
                        banners={this.state.banners}
                        preHeading={() => {

                            if(this.state.category_level > 0){

                                return (
                                    <>
                                        <a href={`/category/${this.state.parent_category.slug}`} className='chip'>{this.state.parent_category.name}</a>
                                    </>
                                )
                            }
                        }}
                    />

                    <SPProjectsTabs
                        sp_tab_blocks={this.state.sp_tab_blocks}
                        project_tab_blocks={this.state.project_tab_blocks}
                        products_list={this.state.products_list}
                    />

                    {(this.state.sub_category && this.state.category_level > 0) &&
                    
                        <div className="child-category-section pt-20">
                            <div className="container">
                                <div className="heading">{this.state.sub_category.name}</div>
                                <div className="block" dangerouslySetInnerHTML={{ __html: this.state.sub_category.description }} />
                            </div>
                        </div>
                    }

                    {/* {this.state.category_level > 0 &&
                    
                        <div className="child-category-section pt-50">
                            <div className="container">

                                <Tabs
                                    defaultActiveKey={this.state.default_category}
                                    className="img-btn-tabs p-tab-10 img-60"
                                    activeKey={this.state.sub_category_slug !== '' ? `category_${this.state.sub_category_slug}` : null}
                                    centered
                                >
                                    {this.renderChildCategoryTabs()}
                                </Tabs>
                            </div>
                        </div>
                    } */}

                    {this.state.related_categories.length > 0 &&
                    
                        <div className="categories-section related text-center">
                            <div className="container">
                                <div className="heading">{this.state.related_section_title}</div>

                                <div className="categories-list flex wrap w-100">
                                    {this.renderRelatedCategories()}
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </Main>
        )
    }


    loadData = (sub_category_slug) => {

        const formData = new FormData();
        var that = this;

        formData.append('parent', this.props.cat_slug);

        if(sub_category_slug){
        
            formData.append('sub_category', sub_category_slug);
        }

        Api.post('categories/top_single', formData, function(data){
            
            that.setState({

                banners: data.banners,

                child_categories: data.child_categories,

                related_section_title: data.related_section_title,
                related_categories: data.related_categories,

                sp_tab_blocks: data.sp_tab_blocks,
                project_tab_blocks: data.project_tab_blocks,
                products_list: data.products_list
            });

            if(data.sub_category){

                that.setState({sub_category: data.sub_category})
            }

            if(data.parent_category){

                that.setState({parent_category: data.parent_category})
            }
        });
    }
}

export default CategoryListingPage;