import React, { Component } from 'react';

import renderHTML from 'react-render-html';

// add form components
import { Typography, Progress, Button, Popconfirm, Row, Col, Input, Form, Rate, Tag } from 'antd';

import FeatherIcon from 'feather-icons-react';

import ProfilePic from 'components/ProfilePic';

import ProposalActions from './ProposalActions';
import ProposalPopup from './ProposalPopup';

import MessageRow from '../messages/MessageRow';

import Api from 'api/Api';

import ProjctItemsGallery from 'pages/projects/blocks/ProjctItemsGallery';

import { StarFilled, EditOutlined, DollarCircleFilled, FileTextFilled, CrownFilled, StopFilled } from '@ant-design/icons';

import Price from 'components/Price';

class ProposalView extends Component { 
    constructor(props) {
        super();
        this.state = {
            proposal_modal: false,

            expended: {},

            rejection_note: '',

            submission_reject: false,
            rejection_note_error: false
        }
    }

    render () {

        return (

            <>
                <div>
                    {this.renderProposals()}

                    {/* {this.props.project.project_view == 1 && this.props.can_resend && this.props.area == 'main' &&
                        
                        <>
                            <div className="block">
                                <div className="details" style={{display:'flex', justifyContent:'center'}}>
                                    <Button onClick={() => {
                                        this.setState({proposal_modal: true})
                                    }} className="button">Resend Proposal</Button>
                                </div>
                            </div>
                        </>
                    } */}
                </div>

                <ProposalPopup
                    proposal_modal={this.state.proposal_modal}
                    project={this.props.project}
                    account_token={this.props.account_token}
                    appendRow={(row) => {
                        this.props.appendRow(row);
                        this.props.updateCanResend(false);
                    }}
                    onError={(message) => {
                        this.props.onError(message);
                    }}
                    onSuccess={(message) => {
                        this.props.onSuccess(message);
                    }}
                    hideModal={() => {
                        this.setState({proposal_modal: false})
                    }}
                />
            </>
        )
    }

    renderProposals = () => {

        var proposals = this.props.proposals;
        
        if(proposals.length > 0){

            return proposals.map((proposal) => {

                var key = 'proposal_' + proposal.inc_id;

                if(proposal.type == 'proposal' || proposal.type == 'invoice'){

                    return this.proposalView(key, proposal);
                }

                if(proposal.type == 'message' && this.props.area == 'main'){

                    return this.messageView(key, proposal);
                }

                if(
                    (
                        proposal.type == 'notification'
                        ||
                        proposal.type == 'proposal_acceptence'
                        ||
                        proposal.type == 'proposal_rejection'
                        ||
                        proposal.type == 'proposal_cancel'
                        ||
                        proposal.type == 'invoice_paid'
                        ||
                        proposal.type == 'invoice_rejection'
                        ||
                        proposal.type == 'invoice_cancel'
                        ||
                        proposal.type == 'nda_requested'
                        ||
                        proposal.type == 'nda_accepted'
                        ||
                        proposal.type == 'status_delivered'
                        ||
                        proposal.type == 'submission' || proposal.type == 'submission_rejection'
                        ||
                        proposal.type == 'product'
                    )
                    &&
                    this.props.area == 'main'
                ){

                    return this.notificationView(key, proposal);
                }

                if((proposal.type == 'buyer_feedback' || proposal.type == 'seller_feedback' || proposal.type == 'delivered') && this.props.area == 'main'){

                    return this.awardView(key, proposal);
                }
            })
        }
    }

    ratingView = (key, proposal) => {

        var user = this.props.project.project_view == 1 ? this.props.buyer : this.props.seller;
        var percent = (proposal.feedback_rating / 5) * 100;
        return (

            <div key={key} className="proposal-block block chat feedback">
                <div className="icon"><FeatherIcon icon="bell" /></div>
                <div className="details">
                    <div className="top">
                        <div className="title">Feedback</div>
                        <div className="user-details">
                            <span className="img-block">
                                <ProfilePic src={user.profile_pic_full} size={50} />
                            </span>
                            <div className="ratings-block">
                                <span className="rating">
                                    <div className="ratings">
                                        <span>{proposal.feedback_rating}</span>
                                        <StarFilled className="ico" />
                                    </div>
                                    <div className="top-text">
                                        <Progress percent={percent} showInfo={false} strokeColor="#FF4500" trailColor="#FFF" type="line" strokeWidth={6} />
                                    </div>
                                </span>
                                <div className="bottom-text">{proposal.feedback_review}</div>
                            </div>
                        </div>
                    </div>
                    <div className="time-block">2 hours ago</div>
                </div>
            </div>
        )
    }

    awardView = (key, proposal) => {

        return (
            <React.Fragment key={key}>

                <div className="w-100 mt-10">

                    <div className='proposal-block-container justify-start ml-30 mr-30 mb-10'>
                        
                    <div className='flex box-white items-center wrap'>

                            {(proposal.type === 'seller_feedback') &&
                            
                                <>
                                    {(this.props.project.project_view == 1 && (this.props.project.project_type === 'p' || this.props.project.project_type === '')) &&

                                        <strong className='c-b mb-10 w-100 '>Your feedback for Client</strong>
                                    }

                                    {(this.props.project.project_view == 1 && (this.props.project.project_type === 'q')) &&

                                        <strong className='c-b mb-10 w-100 '>Feedback for Service Provider</strong>
                                    }

                                    {(this.props.project.project_view == 0 && (this.props.project.project_type === 'q')) &&

                                        <strong className='c-b mb-10 w-100 '>Feedback from Client</strong>
                                    }

                                    {(this.props.project.project_view == 0 && (this.props.project.project_type === 'p' || this.props.project.project_type === '')) &&

                                        <strong className='c-b mb-10 w-100 '>Feedback from Service Provider</strong>
                                    }

                                    
                                </>
                            }

                            {(proposal.type === 'buyer_feedback') &&
                            
                                <>
                                    {(this.props.project.project_view == 1 && (this.props.project.project_type === 'p' || this.props.project.project_type === '')) &&

                                        <strong className='c-b mb-10 w-100 '>Feedback from Client</strong>
                                    }
                                    {(this.props.project.project_view == 1 && (this.props.project.project_type === 'q')) &&

                                        <strong className='c-b mb-10 w-100 '>Feedback from Service Provider</strong>
                                    }

                                    {(this.props.project.project_view == 0 && (this.props.project.project_type === 'p' || this.props.project.project_type === '')) &&

                                        <strong className='c-b mb-10 w-100 '>Your feedback for Service Provider</strong>
                                    }
                                    {(this.props.project.project_view == 0 && (this.props.project.project_type === 'q')) &&

                                        <strong className='c-b mb-10 w-100 '>Your feedback for client</strong>
                                    }
                                </>
                            }
                        
                            <div className="round justify-center" style={{backgroundColor:proposal.type === 'delivered' ? 'rgba(71, 163, 9, .08)' : 'rgba(240, 126, 34, .05)', width: 100, height: 100}}>
                                
                                {proposal.type === 'delivered' &&
                                    
                                    <CrownFilled className="fs-60 op-7 c-green" />
                                }

                                {(proposal.type === 'seller_feedback' || proposal.type === 'buyer_feedback') &&
                                    
                                    <StarFilled className="fs-60 op-7 c-o op-8" />
                                }
                            </div>

                            <div className='ml-10'>

                                {(proposal.type === 'seller_feedback' || proposal.type === 'buyer_feedback') &&
                                
                                    <div className='vertical'>

                                        <Row className='w-100'>

                                            <Col span={24}>

                                                <span className="rating justify-start">
                                                    <Rate disabled defaultValue={proposal.feedback_rating} />
                                                </span>
                                            </Col>
                                        </Row>
                                        
                                        <div class="mt-10 op-8">{proposal.feedback_review}</div>
                                    </div>
                                }

                                {proposal.type === 'delivered' &&
                                  <strong className="fw-b c-green fs-20">Project Delivered</strong>
                                }

                                <div className="fw-b fs-12 op-6 mt-10">{proposal.added_on_formatted}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    notificationView = (key, proposal) => {

        var user = this.props.project.project_view == 1 ? this.props.buyer : this.props.seller;

        if(proposal.type === 'product'){

            user = this.props.project.project_view == 1 ? this.props.seller : this.props.buyer;
        }

        return (
            <React.Fragment key={key}>
                <div className="w-100 mt-10">
                <div className='proposal-block-container justify-start ml-30 mr-30 mb-20'>
                    <div className="round mr-10 justify-center" style={{backgroundColor:'#A24921', width: 50, height: 50}}>

                            {(proposal.type === 'invoice_paid' || proposal.type === 'invoice_rejection' || proposal.type === 'invoice_cancel') &&
                            
                            <DollarCircleFilled className="fs-30 c-w op-7" />
                        }

                            {(proposal.type === 'proposal_acceptence' || proposal.type === 'proposal_rejection' || proposal.type === 'proposal_cancel') &&
                            
                            <FileTextFilled className="fs-20 c-w op-7" />
                        }

                            {proposal.type === 'notification' &&
                            
                            <FeatherIcon icon="bell" style={{marginRight: 0}} />
                        }

                            {proposal.type === 'submission' &&
                            
                            <EditOutlined className="fs-20 c-w op-7" />
                        }

                            {proposal.type === 'product' &&
                            
                                <ProfilePic src={user.profile_pic_full} size={50} />
                            }

                            {(proposal.type === 'submission_rejection') &&

                                <StopFilled className="fs-20 op-5" />
                            }
                        </div>
                        <div className="p-15 bg-w border-orange flex-one br-10 justify-start vertical">
                            
                            {proposal.type === 'product' &&
                            
                                <div className='justify-start vertical'>
                                    <div className="fw-500 c-brown fs-16">Product Purchased</div>
                                    <div className="c-dg text-left mt-10 fw-b">{this.props.project.title}</div>
                                    <div className="c-dg text-left mt-10 fs-12">{this.props.project.description}</div>

                                    {this.projectAddons(proposal)}
                                </div>
                            }

                            {proposal.type === 'product'
                                ?
                                    <>

                                        {/* {(this.props.project.project_view == 1) &&
                                        
                                            
                                        } */}
                                    </>
                                :
                                    <>
                                        {proposal.type === 'submission'
                                            ?
                                            <div className='justify-start vertical'>
                                                <div className="fw-500 c-green">Project Submitted</div>
                                                <div className="c-dg text-left mt-10">{proposal.description}</div>
                                            </div>
                                                :

                                                proposal.type === 'proposal_rejection'
                                                    ?
                                                    <div className='justify-start vertical'>
                                                        <div className="fw-500 c-dbrown">Project Rejected</div>
                                                        <div className="c-dg text-left mt-10">{proposal.description}</div>
                                                    </div>
                                                        :
                                                        <div className="fw-500 c-brown">{proposal.description}</div>
                                        }
                                    </>
                            }

                            {
                                (
                                    // (this.props.project.project_view == 1 && proposal.type === 'submission_rejection' && (this.props.project.project_type === 'p' || this.props.project.project_type === ''))
                                    // ||
                                    // (this.props.project.project_view == 0 && proposal.type === 'submission_rejection' && this.props.project.project_type === 'q')
                                    proposal.type === 'submission_rejection'
                                )
                                &&
                            
                                <div className='justify-start vertical'>
                                    <div className="fw-b c-w">Submission Rejected</div>
                                    <div className="c-w text-left mt-10">{proposal.message}</div>
                                </div>
                            }

                            <div className="fw-500 fs-12 c-g mt-10">{proposal.added_on_formatted}</div>
                        </div>
                    </div>
                </div>

                {proposal.type === 'product' &&
                
                    <div className="w-100 mb-10 flex-one">

                        <div className='proposal-block-container justify-start mr-30 ml-30'>

                            <div className="round mr-10 justify-center" style={{width: 50, height: 50}}></div>
                            
                            <div className="flex-one br-10 justify-start vertical">

                                <strong className='mb-5 c-b'>Product Orders</strong>

                                <div className="item-block proposal-items-block w-100">
                                    <div className="title">
                                        <span className="fw-b fs-14 w-30"><FeatherIcon icon="layers" />Order Number</span>
                                        <span className="fw-b fs-14 w-30">Date</span>
                                        <span className="fw-b fs-14 w-20 text-right">Amount</span>
                                        <span className="fw-b fs-14 w-20 text-right">Status</span>
                                    </div>

                                    <div className="details">

                                        {this.props.project.product_orders && this.props.project.product_orders.length > 0 &&

                                            this.props.project.product_orders.map((item, index) => {

                                                var key = `product_order_${index}`;
                                                return (

                                                    <div className="detail-block justify-between p-15" key={key}>
                                                        <span className='w-30'>{item.order_number}</span>
                                                        <span className='w-30'>{item.purchased_on_formatted}</span>
                                                        <span className='w-20 text-right'>
                                                            <Price>{item.purchased_amount}</Price>
                                                        </span>
                                                        <span className='w-20 text-right'>
                                                            {item.payment_status === 'paid' ? <Tag color="green">Paid</Tag> : <Tag color="red">Unpaid</Tag>}
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {(proposal.type === 'submission' && (proposal.status == '1' || proposal.status == '2')) &&
                
                    <div className="w-100 mb-10">

                        <div className='proposal-block-container justify-start mr-30 ml-30'>

                            <div className="round mr-10 justify-center" style={{width: 50, height: 50}}></div>
                            
                            <div className="flex-one br-10 justify-start vertical">
                            
                                {proposal.status == '1' &&
                                
                                    <span className='fs-16 c-brown fw-500'>Submission rejected</span>
                                }

                                {proposal.status == '2' &&
                                
                                    <span className='fs-16 c-green fw-500'><FeatherIcon icon="check" style={{fontSize: 30}}/>Submission accepted</span>
                                }

                                <div className='fs-14 op-8 text-left mt-5'>{proposal.message}</div>
                                <div className="fw-b fs-12 op-6 mt-5 c-b">{proposal.updated_on_formatted}</div>
                            </div>
                        </div>
                    </div>
                }

                {
                    (
                        this.props.project.project_type === 'q'
                        &&
                        this.props.project.project_view === 1
                        &&
                        (proposal.type === 'submission' && proposal.status == '0')
                        
                    )
                    &&

                        <div className="w-100 mb-10">

                            {this.state.submission_reject &&

                                <div className='proposal-block-container box-white justify-start'>
                                        
                                    <div className="round mr-10 mt-30 justify-center" style={{backgroundColor:'#A24921', width: 50, height: 50}}>
                                        <EditOutlined className="fs-20 op-5 c-w" />
                                    </div>
                                    <div className='vertical flex-one'>
                                        <div className="flex-one br-10">
                                            <div>
                                                <div className='ant-form ant-form-vertical'>

                                                    <Row gutter={4}>

                                                        <Col span={24}>

                                                            <Form.Item
                                                                label="Rejection Note"
                                                                validateStatus={this.state.rejection_note_error ? "error" : "validating"}
                                                                help={this.state.rejection_note_error ? "Rejection note is required!" : ""}
                                                            >
                                                                <Input.TextArea
                                                                    rows={4}
                                                                    onChange={(e) => {

                                                                        this.setState({rejection_note: e.target.value, rejection_note_error: false})
                                                                    }}
                                                                    value={this.state.rejection_note}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="flex-one br-10 justify-end">
                                    
                                                <Button type="link" onClick={() => {

                                                    this.setState({submission_reject: false, rejection_note: '', rejection_note_error: false})
                                                }} loading={this.state.loading}>Cancel</Button>
                                                
                                                <Button className='ml-10' type="primary" onClick={() => {

                                                    if(this.state.rejection_note === ''){

                                                        this.setState({rejection_note_error: true})
                                                    }else{

                                                        this.rejectSubmission(proposal)
                                                    }
                                                }} loading={this.state.loading}>Submit</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {!this.state.submission_reject &&
                            
                                <div className='proposal-block-container justify-start mr-10 mb-30'>

                                    <div className="flex-one br-10 justify-end mr-20">
                                        
                                        <Button type="link" onClick={() => {

                                            this.setState({submission_reject: true})
                                        }} loading={this.state.loading}>Reject</Button>
                                        
                                        <Popconfirm
                                            title="You really want to close this project?"
                                            onConfirm={() => {

                                                this.acceptSubmission(proposal)
                                            }}
                                            cancelText="No"
                                            okText="Yes"
                                        >
                                            <Button loading={this.state.loading} className='ml-10' type="primary">Accept</Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            }
                        </div>
                }

                {(this.props.project.project_view === 0 && proposal.type === 'submission' && proposal.status == '0') &&
                
                    <div className="w-100 mb-10">
                            
                        {this.state.submission_reject &&

                            <div className='proposal-block-container box-white justify-start'>
                                    
                                <div className="round mr-10 mt-30 justify-center" style={{backgroundColor:'rgba(0,0,0,.1)', width: 50, height: 50}}>
                                    <EditOutlined className="fs-20 op-5" />
                                </div>
                                <div className='vertical flex-one'>
                                    <div className="flex-one br-10">
                                        <div>
                                            <div className='ant-form ant-form-vertical'>

                                                <Row gutter={4}>

                                                    <Col span={24}>

                                                        <Form.Item
                                                            label="Rejection Note"
                                                            validateStatus={this.state.rejection_note_error ? "error" : "validating"}
                                                            help={this.state.rejection_note_error ? "Rejection note is required!" : ""}
                                                        >
                                                            <Input.TextArea
                                                                rows={4}
                                                                onChange={(e) => {

                                                                    this.setState({rejection_note: e.target.value, rejection_note_error: false})
                                                                }}
                                                                value={this.state.rejection_note}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="flex-one br-10 justify-end">
                                
                                            <Button type="link" onClick={() => {

                                                this.setState({submission_reject: false, rejection_note: '', rejection_note_error: false})
                                            }} loading={this.state.loading}>Cancel</Button>
                                            
                                            <Button className='ml-10' type="primary" onClick={() => {

                                                if(this.state.rejection_note === ''){

                                                    this.setState({rejection_note_error: true})
                                                }else{

                                                    this.rejectSubmission(proposal)
                                                }
                                            }} loading={this.state.loading}>Submit</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            (this.props.project.project_type === 'p' || this.props.project.project_type === '')
                            &&
                            !this.state.submission_reject
                        &&

                            <div className='proposal-block-container justify-start mr-10 mb-30'>

                                <div className="flex-one br-10 justify-end mr-20">
                                    
                                    <Button type="link" onClick={() => {

                                        this.setState({submission_reject: true})
                                    }} loading={this.state.loading}>Reject</Button>
                                    
                                    <Popconfirm
                                        title="You really want to close this project?"
                                        onConfirm={() => {

                                            this.acceptSubmission(proposal)
                                        }}
                                        cancelText="No"
                                        okText="Yes"
                                    >
                                        <Button loading={this.state.loading} className='ml-10' type="primary">Accept</Button>
                                    </Popconfirm>
                                </div>
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }

    projectAddons = (proposal) => {

        if(this.props.project.project_addons && this.props.project.project_addons.length > 0){

            return (
                <div className='c-w mt-20 w-100'>

                    <div style={{borderBottom:'1px solid rgba(255,255,255,.5)', paddingBottom:5}} className="mb-10">
                        <strong className='mb-10'>{this.props.project.project_addons.length} Addons</strong>
                    </div>

                    <ul>
                        {this.props.project.project_addons.map((_addon, index) => {

                            return (
                                <li key={`addons_${index}`}>
                                    <div className='fs-14'>
                                        {_addon.description}
                                        <span className='ml-3 op-7'>(<Price>{_addon.price}</Price>)</span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
    }

    acceptSubmission = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);
        formData.append('proposal', proposal.inc_id);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/deliver_request_accept', formData, function(data){
            
            that.setState({loading: false, rejection_note: '', rejection_note_error: false, submission_reject: false});
            
            if(data.status){

                that.props.updateRow(data.row)
                that.props.onSuccess(data.message)

                that.props.updateProject(data.project)
                that.props.appendRow(data.delivered)
            }else{

                that.props.errorMessage(data.message);
            }
        });
    }

    rejectSubmission = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);
        formData.append('proposal', proposal.inc_id);
        formData.append('rejection_note', this.state.rejection_note);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/deliver_request_reject', formData, function(data){
            
            that.setState({loading: false, rejection_note: '', rejection_note_error: false, submission_reject: false});
            
            if(data.status){

                that.props.updateRow(data.row)
                that.props.onSuccess(data.message)
            }else{

                that.props.errorMessage(data.message);
            }
        });
    }

    messageView = (key, proposal) => {

        var direction = 'left';

        proposal.src = this.props.seller.profile_pic_full;
        proposal.sender_name = this.props.seller.name;
        
        if(this.props.project.project_view == 1){

            proposal.src = this.props.buyer.profile_pic_full;
            proposal.sender_name = this.props.buyer.name

            if(proposal.message_type == 'buyer'){

                direction = 'right';
                
            }else{

                direction = 'left';
                proposal.left_align = 'left';
            }
        }

        if(this.props.project.project_view == 0){

            if(proposal.message_type == 'seller'){

                direction = 'right';
                
            }else{

                direction = 'left';
                proposal.left_align = 'left';
            }
        }

        if(direction === 'right'){
        
            proposal.src = this.props.user.profile_pic_full;
            proposal.sender_name = this.props.user.name;
            
        }

        return <MessageRow
                    key={key}
                    message={proposal}
                    type="large"
                />
    }

    statusLabel = (key, type) => {

        var invoice = {0: 'Raised', 4: 'Cancelled', 5: 'Rejected', 6: 'Paid'};
        var proposal = {0: 'Submitted', 1: 'Accepted', 2: 'Cancelled', 3: 'Rejected'};

        if(type == 'invoice'){

            return invoice[key];
        }
        if(type == 'proposal'){

            return proposal[key];
        }
    }

    proposalView = (key, proposal) => {

        var project = this.props.project;

        if(project){

            return (

                <div key={key} className="proposal-block-container box-white">

                    <div className="proposal-block flex">
                        <div className="icon">
                            {this.props.area == 'main'
                                ?
                                    <ProfilePic src={this.props.seller.profile_pic_full} />
                                :
                                    proposal.invoice
                                        ?
                                            <FeatherIcon icon="file-text" />
                                        :
                                            <FeatherIcon icon="edit-2" />
                            }
                        </div>
                        <div className="details ml-10 flex-one">
                            <div className="details-block vertical">
                                <div className="bordered p-25 mb-15 br-10 w-100">

                                    {this.props.area == 'sidebar' &&

                                        <div className="time-block">{proposal.date}</div>
                                    }

                                    {this.props.area == 'main'
                                        ?
                                            proposal.invoice
                                                ?
                                                    <>
                                                        <div className="fw-b fs-16 c-b">Invoice #{proposal.invoice.invoice_number}</div>
                                                    </>
                                                :
                                                    <div className="justify-between">
                                                    
                                                        <div className="fw-b fs-16 c-b">Proposal #{proposal.proposal_id}</div>
                                                        <span className="c-b op-7">{proposal.added_on_formatted}</span>
                                                    </div>
                                        :
                                            <div onClick={() => {
                                                var expended = this.state.expended;
                                                if(this.state.expended.hasOwnProperty(proposal.inc_id)){

                                                    delete expended[proposal.inc_id];
                                                }else{
                                                    
                                                    expended[proposal.inc_id] = 1;
                                                }
                                                this.setState({expended: expended});
                                            }}>
                                                {proposal.invoice
                                                    ?
                                                        <div className="title">Invoice #{proposal.invoice.invoice_number} {this.statusLabel(proposal.status, 'invoice')}</div>
                                                    :
                                                        <div className="title">Proposal #{proposal.proposal_id} {this.statusLabel(proposal.status, 'proposal')}</div>
                                                }
                                            </div>
                                    }

                                    {this.props.area == 'main'
                                        ?
                                            proposal.invoice
                                                ?
                                                    null
                                                :
                                                    <div className="mt-20 fs-14 c-b l-h-15">
                                                        {proposal.description != ''
                                                            ?
                                                                renderHTML(proposal.description)
                                                            :
                                                                null
                                                        }
                                                    </div>
                                        :
                                            this.state.expended.hasOwnProperty(proposal.inc_id)
                                                ?
                                                    <div className="mt-20">
                                                        <Typography.Paragraph>{proposal.description}</Typography.Paragraph>
                                                    </div>
                                                :
                                                    null
                                    }

                                    {proposal.hasOwnProperty('_gallery') && proposal._gallery && proposal._gallery.length > 0 && this.props.area == 'main'
                                        ?
                                            <div className="form-gallery sm mt-20">

                                                <ProjctItemsGallery gallery={proposal._gallery} />
                                            </div>
                                        :
                                            null
                                    }
                                </div>

                                {this.props.area == 'main'
                                    ?
                                        this.itemFooter(proposal, project)
                                    :
                                        null
                                }
                            </div>
                            {this.props.area == 'main' &&

                                <div className="time-block p-15">{proposal.added_on_formatted}</div>
                            }
                        </div>
                    </div>

                    {this.props.area == 'sidebar'
                        ?
                            this.state.expended.hasOwnProperty(proposal.inc_id)
                                ?
                                    this.itemFooter(proposal, project)
                                :
                                    null
                        :
                            null
                    }
                </div>
            )
        }
    }

    itemFooter = (proposal, project) => {

        return (
            <>

                {proposal.invoice
                    ?

                        <div className="item-block proposal-items-block">
                            <div className="title flex">
                                <span className="" style={{width:'70%'}}><FeatherIcon icon="layers" />Item</span>
                                <span className="" style={{width:'25%', textAlign:'right'}}>Amount</span>
                            </div>
                            <div className="details">
                                {proposal.invoice.invoice_items && proposal.invoice.invoice_items.length > 0 &&

                                    proposal.invoice.invoice_items.map((item) => {

                                        var key = 'invoice_item_' + item.id;
                                        return (

                                            <div className="detail-block flex p-15 w-100" key={key}>
                                                <span style={{width:'70%'}}>{item.item}</span>
                                                <span style={{width:'30%', textAlign:'right'}} className="fw-b c-b">
                                                    <Price>{item.amount}</Price>
                                                </span>
                                            </div>
                                        )
                                    })
                                }

                                {/* <div className="detail-block flex p-15 w-100">
                                    <span style={{width:'55%'}}></span>
                                    <span style={{width:'20%', textAlign:'right'}}>Tax</span>
                                    <span style={{width:'25%', textAlign:'right'}} className="fw-b c-b">${proposal.invoice.tax}</span>
                                </div> */}
                            </div>
                        </div>
                    :
                        <>
                            {proposal.items && proposal.items.length > 0 &&

                                <>
                                    <strong className='mb-5 c-b'>Project Milestones</strong>

                                    <div className="item-block proposal-items-block">
                                        <div className="title">
                                            <span className="fw-b fs-14"><FeatherIcon icon="layers" />Item</span>
                                            <span className="fw-b fs-14">Amount</span>
                                        </div>
                                        <div className="details">
                                            {proposal.items && proposal.items.length > 0 &&

                                                proposal.items.map((item) => {

                                                    var key = 'milestone_item_' + item.id;
                                                    return (

                                                        <div className="detail-block justify-between p-15" key={key}>
                                                            <span>{item.title}</span>
                                                            <span>
                                                                <Price>{item.amount}</Price>
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                }
                <div className="price-block justify-between p-15">
                    <div className="left">

                        {proposal.invoice
                            ?
                                <>
                                    <div className="fs-12">
                                        <strong className='op-6'>Issue Date</strong>
                                        <span className='ml-3 c-b'>{proposal.invoice.added_on_formatted}</span>
                                    </div>
                                </>
                            :
                                <div className="price-detail-block fw-b">
                                    <span>Proposal ID:</span>
                                    <span className='ml-5'>{proposal.proposal_id}</span>
                                </div>
                        }
                    </div>
                    <div className="right">

                        {(this.props.seller.unq_index_id !== this.props.user.unq_index_id)
                            ?
                                <ProposalActions
                                    proposal={proposal}
                                    project={project}

                                    updateProposal={(proposal, notification) => {

                                        this.updateProposal(proposal, notification)
                                    }}
                                    
                                    updateMainProposal={(user_proposal) => {

                                        this.props.updateMainProposal(user_proposal)
                                    }}

                                    updateCanResend={(status) => {
                                        this.props.updateCanResend(status)
                                    }}

                                    updateProject={(project) => {
                                        this.props.updateProject(project)
                                    }}

                                    onError={(message) => {
                                        this.props.onError(message)
                                    }}
                                    onSuccess={(message) => {
                                        this.props.onSuccess(message)
                                    }}
                                    account_token={this.props.account_token}
                                />
                            :
                                <div className='justify-start vertical'>

                                    {proposal.invoice && proposal.invoice.status == '4' &&
                                    
                                        <>
                                            <strong className='c-b'>Paid</strong>
                                        </>
                                    }

                                    {proposal.invoice && proposal.invoice.status == '3' &&
                                    
                                        <>
                                            <strong className='c-b'>Rejected</strong>
                                        </>
                                    }

                                    {proposal.invoice && proposal.invoice.paid_on_date_formatted !== '' &&
                                                        
                                        <div className="fs-12 mt-5">
                                            <strong className='op-6'>Paid on</strong>
                                            <span className='ml-3 c-b'>{proposal.invoice.paid_on_date_formatted}</span>
                                        </div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }

    updateProposal = (_proposal, notification) => {

        var proposals = this.props.proposals;

        const _proposal_row_index = proposals.findIndex(row => row.inc_id === _proposal.inc_id);

        if(_proposal_row_index > -1){

            proposals[_proposal_row_index] = _proposal;
        }

        if(notification){
        
            proposals.push(notification);
        }

        this.props.updateProposals(proposals);
    }
}

export default ProposalView;