import React from 'react';
import './App.css';

import 'antd/dist/antd.css';
import './assets/styles/theme.css';

import {BrowserRouter, Route, useParams, Switch} from 'react-router-dom';

import Homepage from './pages/Homepage';
import RegisterSelect from './pages/auth/Registerselect';
import Register from './pages/auth/Register';
import PendingVerification from './pages/auth/PendingVerification';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import EmailVerification from './pages/auth/EmailVerification';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import MobilePassword from './pages/auth/MobilePassword';

import HeaderSearchRouter from './pages/HeaderSearchRouter';

import ProfileUpdate from './pages/account/ProfileUpdate';
import ProductsList from './pages/account/products/ProductsList';
import ProductsBuyerList from './pages/account/products/ProductsBuyerList';
import ProductAdd from './pages/account/products/ProductAdd';
import ProductView from './pages/account/products/ProductView';
import InvoicesList from './pages/account/InvoicesList';

import ProductsSearchPage from './pages/products/ProductsSearchPage';
import ProductDetailsPage from './pages/products/ProductDetailsPage';

import ProjectsSearchPage from './pages/projects/ProjectsSearchPage';
import FreelancersSearchPage from './pages/freelancers/FreelancersSearchPage';

import NDAPage from './pages/cms/NDAPage';
import PrivacyPolicyPage from './pages/cms/PrivacyPolicyPage';
import TermsOfServicePage from './pages/cms/TermsOfServicePage';
import TrustSafetyPage from './pages/cms/TrustSafetyPage';
import AboutPage from './pages/cms/AboutPage';
import OurStoryPage from './pages/cms/OurStoryPage';
import CategoryListingPage from './pages/category/CategoryListingPage';
import BlogListingPage from './pages/blog/BlogListingPage';
import PricesPage from './pages/cms/PricesPage';
import HowWeWorkPage from './pages/cms/HowWeWorkPage';
import TACSPage from './pages/cms/TACSPage';

import GigHiringPage from './pages/cms/GigHiringPage';
import ServicePartnerPage from './pages/cms/ServicePartnerPage';
import GigChallengesPage from './pages/cms/GigChallengesPage';

import FreelancersDashboardPage from './pages/account/Dashboard';
import FreelancersPublicProfilePage from './pages/freelancers/FreelancersProfilePage';

import Projects from './pages/projects/freelancer/Projects';
import ProjectsBuyer from './pages/projects/employer/ProjectsBuyer';
import ProjectProposals from './pages/projects/employer/ProjectProposals';
import ProjectFlow from './pages/project_flow/ProjectFlow';

import Messages from './pages/messages/Messages';

import PostProject from './pages/projects/PostProject';

import ProjectDetailsPage from './pages/projects/ProjectDetailsPage';

import Page from './pages/cms/Page';

import ScrollToTop from './components/ScrollToTop';

function PageComponent(){

    let { page } = useParams();
        
    return (
        <div className="app-container">
            <Page page={page} />
        </div>
    )
}

function App() {
    return (
        <BrowserRouter basename={'/'}>
            {/* <Switch> */}
            <ScrollToTop />
            
                <Route exact={true} path='/' render={() => (
                    <div className="cms-page gig-hiring">
                        <GigHiringPage />
                    </div>
                )}/>

                <Route exact={true} path='/marketplace' render={() => (
                    <div className="homepage">
                        <Homepage />
                    </div>
                )}/>

                <Route exact={true} path='/page-reroute/:url?/:arg_one?/:arg_two?/:arg_three?/:arg_four?' render={(props) => (
                    <HeaderSearchRouter {...props.match.params} />
                )}/>

                <Route exact={true} path='/get-started' render={() => (
                    <div className="register-select">
                        <RegisterSelect />
                    </div>
                )}/>

                <Route exact={true} path='/update-account' render={() => (
                    <MobilePassword />
                )}/>

                <Route exact={true} path='/login' render={() => (
                    <div className="login">
                        <Login />
                    </div>
                )}/>

                <Route exact={true} path='/linkedin' render={() => (
                    <div className="">
                        <LinkedInCallback />
                    </div>
                )}/>

                <Route exact={true} path='/logout' render={() => (
                    <div className="logout">
                        <Logout />
                    </div>
                )}/>

                <Route exact={true} path='/forgot-password' render={() => (
                    <div className="logout">
                        <ForgotPassword />
                    </div>
                )}/>

                <Route exact={true} path='/reset-password/:code?' render={(props) => (
                    <ResetPassword {...props.match.params} />
                )} />

                <Route exact={true} path='/email-verified' render={() => (
                    <div className="email-verified">
                        <EmailVerification />
                    </div>
                )}/>

                <Route exact={true} path='/register/:type?' render={(props) => (
                    <div className="register">
                        <Register {...props.match.params} />
                    </div>
                )} />

                <Route exact={true} path='/pending-verification/:code?' render={(props) => (
                    <div className="register">
                        <PendingVerification {...props.match.params} />
                    </div>
                )} />

                <Route exact={true} path='/service-partner/:keyword?' render={(props) => (
                    <div className="freelancer-profile-page">
                        <FreelancersPublicProfilePage {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/account/dashboard/:account_type?' render={(props) => (
                    <div className="freelancer-dashboard-page">
                        <FreelancersDashboardPage {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/account/profile-update' render={() => (
                    <div className="freelancer-profile-page">
                        <ProfileUpdate />
                    </div>
                )}/>

                <Route exact={true} path='/account/invoices' render={() => (
                    <div>
                        <InvoicesList />
                    </div>
                )}/>

                <Route exact={true} path='/projects-as-seller' render={() => (
                    <div className="freelancer-wrapper">
                        <Projects />
                    </div>
                )}/>

                <Route exact={true} path='/project-proposals/:project_id?' render={(props) => (
                    <ProjectProposals {...props.match.params} />
                )}/>

                <Route exact={true} path='/projects-as-buyer' render={(props) => (
                    <div className="freelancer-wrapper">
                        <ProjectsBuyer {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/project-flow/:project_id?/:seller_id?/:invoice_id?' render={(props) => (
                    <ProjectFlow {...props.match.params} />
                )}/>

                <Route path="/messages/:project_id?" render={(props) => (
                    <div>
                        <MessagesView {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/account/post-project/:step?/:project_id?' render={(props) => (
                    <div className="post-project">
                        <PostProject {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/projects-search/:keyword?' render={(props) => (
                    <div className="projects-search">
                        <ProjectsSearchPage {...props.match.params} />
                    </div>
                )} />

                <Route exact={true} path='/projects/details/:slug?' render={(props) => (
                    <div className="project-details-page">
                        <ProjectDetailsPage {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/account/products' render={(props) => (
                    <div className="freelancer-wrapper">
                        <ProductsList {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/account/products-buyer' render={(props) => (
                    <div className="freelancer-wrapper">
                        <ProductsBuyerList {...props.match.params} />
                    </div>
                )}/>
                
                <Route exact={true} path='/account/product-add/:product_id?' render={(props) => (
                    <div className="product-add">
                        <ProductAdd {...props.match.params} />
                    </div>
                )}/>
                
                <Route exact={true} path='/account/product-view/:product_id?' render={(props) => (
                    <div className="product-view">
                        <ProductView {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/products-search/:keyword?' render={(props) => (
                    <div className="products-search">
                        <ProductsSearchPage {...props.match.params} />
                    </div>
                )} />

                <Route exact={true} path='/product-view/:product_id?' render={(props) => (
                    <div className="product-view">
                        <ProductDetailsPage {...props.match.params} />
                    </div>
                )} />

                <Route exact={true} path='/service-partners-search/:keyword?' render={(props) => (
                    <div className="freelancers-search">
                        <FreelancersSearchPage {...props.match.params} />
                    </div>
                )}/>

                <Route exact={true} path='/nda' render={() => (
                    <div className="cms-page nda">
                        <NDAPage />
                    </div>
                )}/>
                
                <Route exact={true} path='/privacy-policy' render={() => (
                    <div className="cms-page privacy-policy">
                        <PrivacyPolicyPage />
                    </div>
                )}/>
                
                <Route exact={true} path='/terms-of-service' render={() => (
                    <div className="cms-page terms-of-service">
                        <TermsOfServicePage />
                    </div>
                )}/>

                <Route exact={true} path='/trust-safety' render={() => (
                    <div className="cms-page trust-safety">
                        <TrustSafetyPage />
                    </div>
                )}/>

                <Route exact={true} path='/about' render={() => (
                    <div className="cms-page about">
                        <AboutPage />
                    </div>
                )}/>

                <Route exact={true} path='/our-story' render={() => (
                    <div className="cms-page our-story">
                        <OurStoryPage />
                    </div>
                )}/>

                <Route exact={true} path='/category/:cat_slug?/:sub_category?' render={(props) => (
                    <div className="category">
                        <CategoryListingPage {...props.match.params} />
                    </div>
                )}/>
                
                <Route exact={true} path='/blog' render={() => (
                    <div className="blogs-listing">
                        <BlogListingPage />
                    </div>
                )}/>

                <Route exact={true} path='/prices' render={() => (
                    <div className="prices-page">
                        <PricesPage />
                    </div>
                )}/>

                <Route exact={true} path='/how-we-work' render={() => (
                    <div className="how-we-work-page">
                        <HowWeWorkPage />
                    </div>
                )}/>

                <Route exact={true} path='/tacs' render={() => (
                    <div className="cms-page tacs-page">
                        <TACSPage />
                    </div>
                )}/>

                <Route exact={true} path='/service-partners' render={() => (
                    <div className="cms-page service-partners">
                        <ServicePartnerPage />
                    </div>
                )}/>

                <Route exact={true} path='/challenges' render={() => (
                    <div className="cms-page challenges">
                        <GigChallengesPage />
                    </div>
                )}/>


                {/* <Route exact={true} path='/register-instructions' render={() => (
                    <div className="register-instructions">
                        <RegisterInstructions />
                    </div>
                )}/> */}
                
                <Route path="/page/:page?" children={<PageComponent />} />
                
            {/* </Switch> */}
        </BrowserRouter>
    );
}

function MessagesView(){

    let {project_id} = useParams();

    return (
        <div className="app-container">
            <Messages project_id={project_id} />
        </div>
    )
}

export default App;
