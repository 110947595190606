import React, { Component } from 'react';

import { Link } from "react-router-dom";

class CategoryBlock extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    wrapper = (content) => {

        if(this.props.link){

            return <a className="category-block bordered c-b fs-20 mb-20 ov-hidden relative w-23" href={"/category/" + this.props.link}>{content}</a>
        }else{

            return <div className="category-block bordered c-b fs-20 mb-20 ov-hidden relative w-23">{content}</div>
        }
    }

    content = () => {

        return (
            <>
                <div className="main px-20 py-50">
                    <img src={this.props.category.thumb} className="img-responsive" alt={this.props.category.name} />
                    <div className="cat-heading">{this.props.category.name}</div>
                </div>
                <div className="hover-div c-w fs-16 h-100 px-20 py-10 absolute w-100" style={{backgroundColor:`#${this.props.category.color}`}}>
                    <div className="title fs-20">{this.props.category.name}</div>
                    <div className="text"><div dangerouslySetInnerHTML={{ __html: this.description(this.props.category.description) }} /></div>
                    <div className="explore-btn d-flex justify-content-center mt-15">Explore<i className="mat-ico">keyboard_backspace</i></div>
                </div>
            </>
        )
    }

    render () {

        return (

            <React.Fragment key={this.props.blockkey}>
                {this.wrapper(this.content())}
            </React.Fragment>
        )
    }

    description = (text) => {

        if(text.length > 100){
        
            return `${text.substring(0, 100)}...`;
        }
        
        return text;
    }
}

export default CategoryBlock;