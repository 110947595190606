import React, { Component } from 'react';

import { Link } from "react-router-dom";

import Api from '../../api/Api';

import Main from '../../components/Main';

import '../../assets/styles/gig_hiring_style.css';

class GigHiringPage extends Component { 
   

    render () {

        return (

            <Main page_title="NDA Page" hide_page_title="true">
                <div className="bg-white tacs_wrapper">
                    <section className="tacs-banner">
                        <div className="gig_container mb-container">
                            <div className="row">
                            <div className="w-50 col-xs-6">
                            </div>
                            <div className="w-50 col-xs-12 ml-10">
                                <h4 className='c-w'>Managed</h4>
                                <h1 className='c-w'>Gig Hiring</h1>
                                <h5 className='c-w'>Data Science &amp; Digital Technologies</h5>
                                <div className="flex hero-buttons">
                                <a href="http://localhost:3000/gig-hiring" className="df-bg-rust">Client</a> <a href="http://localhost:3000/service-partners" className="df-bg-blue">Service Partners</a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>

                        <section>
                        <div className="gig_container">
                            <div className="row">
                            <div className="w-100">
                                <a name="jump-down"></a>
                                <h6 className="tacs-subheading text-center">Managed</h6>
                                <h2 className="tacs-mainheading text-center">Gig Hiring Services</h2>

                                <div className="gig_container tacs-services">
                                <div className="row">
                                    <div className="w-50">
                                    <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/project_deliveries.png" alt="" />
                                    </div>
                                    <div className="w-50">
                                    <h4 className="tacs-sectionheading">Project Deliveries</h4>
                                    <ul className="df-color-fawn">
                                        <li>Program managed by Cogniticx.</li>
                                        <li>Project based Contractual Hiring.</li>
                                        <li>Scale up and down with project requirements.</li>
                                        <li>Self sustained teams architected to deliver a project mandate.</li>
                                    </ul>
                                    <a href="http://beta.cogniticx.com/challenges" target="_blank">View Challenges <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 256 512"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg></a>
                                    </div>
                                </div>
                                </div>
                                <div className="gig_container tacs-services">
                                <div className="row mb-reverse">
                                    <div className="w-50">
                                    <h4 className="tacs-sectionheading">Proof of Concepts</h4>
                                    <ul className="df-color-fawn">
                                        <li>Program managed by Cogniticx.</li>
                                        <li>Committed availabilities for those critical POCs.</li>
                                        <li>Support for expedited requirements.</li>
                                        <li>Support for startups for On-demand talent scale up for product and service delivery.</li>
                                    </ul>
                                    <a href="http://beta.cogniticx.com/challenges#value_proposition" target="_blank">View <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 256 512"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg></a>
                                    </div>
                                    <div className="w-50">
                                    <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/proof_of_concepts.png" alt="" />
                                    </div>
                                </div>
                                </div>
                                <div className="gig_container tacs-services">
                                <div className="row">
                                    <div className="w-50">
                                    <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/short_term_projects.png" alt="" />
                                    </div>
                                    <div className="w-50 mb-container">
                                    <h4 className="tacs-sectionheading">Short Term Projects</h4>
                                    <ul className="df-color-fawn">
                                        <li>Program managed by Cogniticx.</li>
                                        <li>Short-fuse deployment.</li>
                                        <li>Support for short term projects.</li>
                                        <li>Easily create and disband a team.</li>
                                    </ul>
                                    <a href="http://beta.cogniticx.com/challenges#actionable_insights" target="_blank">View <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 256 512"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg></a>
                                    </div>
                                </div>
                                </div>
                                <div className="gig_container tacs-services">
                                <div className="row mb-reverse">
                                    <div className="w-50">
                                    <h4 className="tacs-sectionheading">Team Augmentations</h4>
                                    <ul className="df-color-fawn">
                                        <li>Program managed by Cogniticx.</li>
                                        <li>Large cross technology pool of resources.</li>
                                        <li>Support for expedited requirements.Leverage gig hiring to scale up on demand.</li>
                                        <li>Technology driven talent ratification and assessment.</li>
                                        <li>Cost effective so as to optimize cash burn.</li>
                                        <li>Aligns with the business continuity strategy.</li>
                                        <li>Nimble – seamless process with least administrative overhead.</li>
                                        <li>Agile – easy to respond to altering market realities.</li>
                                    </ul>
                                    <a href="http://beta.cogniticx.com/challenges#talent_engagement_options" target="_blank">View <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 256 512"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg></a>
                                    </div>
                                    <div className="w-50">
                                    <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/team_augumentation.png" alt="" />
                                    </div>
                                </div>
                                </div>

                            </div>
                            </div>
                        </div>
                        </section>

                        <section>
                        <div className="gig_container-fluid">
                            <div className="row">
                            <div className="w-100 mb-container">
                                <h2 className="tacs-mainheading text-center">Amazon of Skillsets!</h2>
                                <h6 className="text-center tacs-headingdesc pt-5 pb-5">25000+ ratified Data Science and Digital professionals</h6>
                                <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/amazon_of_skillset.jpg" alt="" />

                                <div className="gig_container">
                                <div className="row">
                                    <div className="w-100 trusted-by">
                                    <h4 className="text-center mb-sm tacs-sectionheading">Trusted by</h4>
                                    <div id="client-carousel">
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/itc_infotech.jpg" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/genpact.webp" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/tcg_digital.jpg" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/exl.webp" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/apollo_munich.jpg" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/infosys.webp" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/hdfc_ergo.jpg" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/panasonic.jpg" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/yes_bank.jpg" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/aplabs.jpg" alt="" /></figure>
                                        <figure className='mb-hidden'></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/ey_build.jpg" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/grant_thornton.webp" alt="" /></figure>
                                        <figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/pwc.jpg" alt="" /></figure>
                                        <figure className='mb-hidden'></figure>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>

                        <section className="tacs-grey-bg">
                        <div className="gig_container">
                            <div className="row">
                            <div className="w-100">
                                <h2 className="tacs-mainheading text-center">How it works</h2>
                                <h6 className="text-center tacs-headingdesc">Completely Managed Process</h6>
                                <ol className="how_it_works_list">
                                    <li><span className="tacs-subheading df-font-bold">SHARE</span> the requirement with Cogniticx SPOC</li>
                                    <li><span className="tacs-subheading df-font-bold">Recieve</span>multiple options</li>
                                    <li><span className="tacs-subheading df-font-bold">SHORTLIST</span> &amp; deploy</li>
                                </ol>
                            </div>
                            </div>
                            <div className="row how_it_works">
                            <div className="w-50">
                                <img src="https://api.cogniticx.com/app/media/uploads/tacs_images/how_it_work.jpg" alt="" />
                            </div>
                            <div className="w-50">
                                <ul className="how_it_works_bullet">
                                <li>GIG Hiring with Cogniticx is a 100% managed process with zero administrative overhead from the clients. </li>
                                <li>Simply share your requirements with our SPOC, Address any clarifications, Lay back and relax!</li>
                                <li>our AI engine does the algorithmic skill matching.</li>
                                <li>Reverse auction is executed so you get your skills at your price.</li>
                                <li>Within 72-96 hours Cogniticx SPOC will revert with profile options, optimized for skills, experience and budget.</li>
                                <li>Simply screen, shortlist & deploy.</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </section>




                        <section>
                        <div className="gig_container">
                            <div className="row">
                            <div className="w-100">
                                <h1 className="tacs-mainheading text-center mb-left">Rigorous Assessments</h1>
                                <div className="tacs-steps-wrap">
                                <div className="tacs-steps">
                                    <div className="tac-step-icon"><figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/eyeballing.svg" alt="" /></figure>
                                    <h3>Eyeballing</h3></div>
                                    <p>Human-machine interface: selecting the right talent can’t be left to the machines alone. hence, we put in the rigour to eyeball every profile.</p>
                                </div>
                                <div className="tacs-steps">
                                    <div className="tac-step-icon"><figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/algorithmic_scoring.svg" alt="" /></figure>
                                    <h3>Algorithimic Scoring</h3></div>
                                    <p>Our AI engine then adds our secret sauce to it – The Algo Scoring</p>
                                </div>
                                <div className="tacs-steps">
                                    <div className="tac-step-icon"><figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/tie-ups.svg" alt="" /></figure>
                                    <h3>Tie-ups for Third Party Assessments</h3></div>
                                    <p>We bake in the option of third-party assessments should they be preferred by our clients</p>
                                </div>
                                <div className="tacs-steps">
                                    <div className="tac-step-icon"><figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/background_verification.svg" alt="" /></figure>
                                    <h3>Tie-ups for Background Verifications</h3></div>
                                    <p>Background verifications are of course available.</p>
                                </div>
                                <div className="tacs-steps">
                                    <div className="tac-step-icon"><figure><img src="https://api.cogniticx.com/app/media/uploads/tacs_images/interlock.svg" alt="" /></figure>
                                    <h3>Executive Interlock</h3></div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>


                        <section>
                        <div className="gig_container">
                            <div className="row">
                            <div className="w-100">
                                <p className="final-text">"A talent platform that curates best in class data science talent, one that clients and partners could leverage, ondemand, and source talent or outcome based projects for their business requirements"</p>
                            </div>
                            </div>
                        </div>
                        </section>

                    </div>
            </Main>
        )
    }


  
}

export default GigHiringPage;