import React, { Component } from 'react';

// add form components
import { Form, Input, Button, Select, Row, Col } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';

import FeatherIcon from 'feather-icons-react';

import Api from '../../api/Api';

const { Option } = Select;

class AccountSecurity extends Component { 
    constructor(props) {
        super();
        this.state = {
            account_token: '',
            updated: false,
            redirect: false,
            user: false,

            pan_number: '',
            pan_image: '',

            pan_uploading: false,
            aadhar_uploading: false,

            aadhar_number: '',
            aadhar_front: '',
            aadhar_back: '',

            media_url: '',
            random_dir: '',
            main_file_name: ''
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(account_token){
            this.setState({account_token: account_token});
        }else{
            this.setState({redirect: true});
        }

        if(user){

            var user = JSON.parse(user);
            this.setState({user: user});

            this.setState({pan_number: user.pan_number, media_url: user.media_url, pan_image: user.pan_doc, aadhar_number: user.aadhar_number, aadhar_front: user.aadhar_front, aadhar_back: user.aadhar_back});

            this.populateForm({
                pan_number: user.pan_number,
                aadhar_number: user.aadhar_number
            })
        }
    }

    populateForm = (details) => {

        this.refs.account_form.setFieldsValue(details);
    }

    updateDocs = (type) => {
        var formData = new FormData();
        
        formData.append('account_token', this.state.account_token);

        if(type == 'pan'){

            formData.append('pan_number', this.state.pan_number);
            formData.append('pan_image', this.state.pan_image);

            this.setState({pan_uploading: true});
        }

        if(type == 'aadhar'){

            formData.append('aadhar_number', this.state.aadhar_number);
            formData.append('aadhar_front', this.state.aadhar_front);
            formData.append('aadhar_back', this.state.aadhar_back);

            this.setState({aadhar_uploading: true});
        }
        
        var that = this;
        
        Api.post('freelancer/profile_update_docs', formData, function(data){
            
            that.setState({pan_uploading: false, aadhar_uploading: false});

            if(data.status == true){
            
                that.props.updateUser(data.user);
                that.props.onSuccess(data.message);

                that.populateForm({
                    pan_number: data.user.pan_number,
                    aadhar_number: data.user.aadhar_number
                })
            
            }else{
                
                that.props.onError(data.message);
            }
        });
    }

    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };
        return (

            <div className="update-profile-page d-flex">
                    
                <div className="profile-wrapper">
                    <Row>
                        <Col span={24}>
                            
                            <div className="profile-main-wrapper">

                                <div className="page-heading">Account Security</div>

                                <Form {...layout} name="account_form" ref="account_form" initialValues={{ remember: true }} validateMessages={validateMessages}>
                                    <div className="d-flex w-100 justify-content-between mt-10 align-center" style={{padding:20, border:'1px solid #ccc'}}>
                                        <div>
                                            <div className="justify-center" style={{alignItems:'flex-start'}}>

                                                <div className="ml-10">

                                                    <Form.Item className="w-100" label="PAN Number" name="pan_number" placeholder="Enter PAN Number" rules={[{required:true, message: 'This is required entry'}, {min: 9, message: 'Please enter valid PAN Number. Min 9 digits'}]} onChange={(v) => {
                                                        this.setState({pan_number: v.target.value})
                                                    }}>
                                                        <Input />
                                                    </Form.Item>

                                                    {this.state.pan_image != ''
                                                        ?
                                                            <div className="mt-1">
                                                                <a href={this.state.media_url + 'freelancer/docs/' + this.state.pan_image} target="_blank">
                                                                    <FileDoneOutlined style={{color:'#003965', fontSize:25}} />
                                                                    <span className="ml-5">PAN Card</span>
                                                                </a>
                                                            </div>                                                                
                                                        :
                                                            <div className="profile-pic-box mt-1">
                                                                <div className="w-100 picture">
                                                                    <label htmlFor="profile_pic">PAN Card Image</label>
                                                                    <div className="profile-pic-bg" style={{cursor:'pointer'}} onClick={() => {
                                                                        this.profilepicinput.click();
                                                                    }}>

                                                                        <FeatherIcon icon="image" />
                                                                        <input type="file" className="hidden" id="profile_pic" value={this.state.profile_pic} onChange={(e) => {this._handleImageChange(e, 'pan')}} ref={(input)=> this.profilepicinput = input}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <Button loading={this.state.pan_uploading} htmlType="button" type="primary" onClick={() => {
                                            this.updateDocs('pan')
                                        }} className="btn outlined disabled" disabled={(this.state.pan_image == '' || this.state.pan_number == '') ? true : false}>Update</Button>
                                    </div>

                                    <div className="d-flex w-100 justify-content-between mt-10 mb-30 align-center" style={{padding:20, border:'1px solid #ccc'}}>
                                        <div>
                                            <div className="justify-center" style={{alignItems:'flex-start'}}>
                                                <div className="ml-10">

                                                    <Form.Item className="w-100" label="AADHAR Number" name="aadhar_number" placeholder="Enter AADHAR Number" rules={[{required:true, message: 'This is required entry'}, {min: 12, message: 'Please enter valid AADHAR Number'}]} onChange={(v) => {
                                                        this.setState({aadhar_number: v.target.value})
                                                    }}>
                                                        <Input />
                                                    </Form.Item>

                                                    {this.state.aadhar_front != ''
                                                        ?
                                                            <div className="mt-1">
                                                                <a href={this.state.media_url + 'freelancer/docs/' + this.state.aadhar_front} target="_blank">
                                                                    <FileDoneOutlined style={{color:'#003965', fontSize:25}} />
                                                                    <span className="ml-5">AADHAR Card Front</span>
                                                                </a>
                                                            </div>                                                                
                                                        :
                                                            <div className="profile-pic-box mt-1">
                                                                <div className="w-100 picture">
                                                                    <label>AADHAR Card Front Image</label>
                                                                    <div className="profile-pic-bg" style={{cursor:'pointer'}} onClick={() => {
                                                                        this.aadhar_front_input.click();
                                                                    }}>

                                                                        <FeatherIcon icon="image" />
                                                                        <input type="file" className="hidden" id="aadhar_front_input" value="" onChange={(e) => {this._handleImageChange(e, 'aadhar_front')}} ref={(input)=> this.aadhar_front_input = input}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {this.state.aadhar_back != ''
                                                        ?
                                                            <div className="mt-1">
                                                                <a href={this.state.media_url + 'freelancer/docs/' + this.state.aadhar_back} target="_blank">
                                                                    <FileDoneOutlined style={{color:'#003965', fontSize:25}} />
                                                                    <span className="ml-5">AADHAR Card Back</span>
                                                                </a>
                                                            </div>                                                                
                                                        :
                                                            <div className="profile-pic-box mt-1">
                                                                <div className="w-100 picture">
                                                                    <label>AADHAR Card Back Image</label>
                                                                    <div className="profile-pic-bg" style={{cursor:'pointer'}} onClick={() => {
                                                                        this.aadhar_back_input.click();
                                                                    }}>

                                                                        <FeatherIcon icon="image" />
                                                                        <input type="file" className="hidden" id="aadhar_back_input" value="" onChange={(e) => {this._handleImageChange(e, 'aadhar_back')}} ref={(input)=> this.aadhar_back_input = input}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                            <Button loading={this.state.aadhar_uploading} type="primary" htmlType="button" onClick={() => {
                                            
                                                this.updateDocs('aadhar')
                                            }}>Update</Button>
                                        </div>

                                        {/* {this.state.aadhar_number == '' || this.state.aadhar_back == '' || this.state.aadhar_front == ''
                                            ?
                                                <Button type="primary" htmlType="button" disabled>Verify</Button>
                                            :
                                                <Button loading={this.state.aadhar_uploading} type="primary" htmlType="button" onClick={() => {
                                                    this.updateDocs('aadhar')
                                                }}>Update</Button>
                                        } */}
                                    </div>

                                </Form>
                            </div>
                        </Col>

                        <Col span={24}>

                            <div className="d-flex justify-content-end">
                                <Button className="btn ml-10 mt-2" type="primary" htmlType="button" onClick={() => {

                                    this.props.jumpToNext();
                                }}>
                                    Continue
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    _handleImageChange = async (e, type) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        this.setState({image_uploading: true});
    
        reader.onloadend = () => {
            this.setState({
                updated: false,
                file: file,
                profile_preview_url: reader.result
            });
        }
    
        reader.readAsDataURL(file);

        var _sizes = {};
        _sizes['profile_pic_small'] = [100, 100];
        _sizes['profile_pic_medium'] = [300, 300];

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,webp');
        formData.append('upload_dir', 'freelancer/docs/');

        this.setState({image_uploading: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({image_uploading: false});

            if(data.status == 'success'){

                that.setState({random_dir: data.random_dir, media_url: data.media_url});

                if(type == 'pan'){

                    that.setState({pan_image: data.random_dir + data.main_file_name});
                }

                if(type == 'aadhar_front'){

                    that.setState({aadhar_front: data.random_dir + data.main_file_name});
                }

                if(type == 'aadhar_back'){

                    that.setState({aadhar_back: data.random_dir + data.main_file_name});
                }
            }
        });
    }
}

export default AccountSecurity;