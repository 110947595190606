import React, { Component } from 'react';
import { Link } from "react-router-dom";

// add form components
import { Table, Tabs, Avatar, Typography, Empty, Rate, Switch, Button, Image, Col, Row } from 'antd';

import LinesEllipsis from 'react-lines-ellipsis'

import FeatherIcon from 'feather-icons-react';
import Main from 'components/Main';
import { UserOutlined, MessageFilled } from '@ant-design/icons';

import LayoutHelper from 'helpers/LayoutHelper';

import dashboardbg from 'assets/images/dashboard-bg.png';

import ProjectsBlock from '../projects/freelancer/ProjectsBlock';
import ProjectsBuyerBlock from '../projects/employer/ProjectsBuyerBlock';

import ProductsListComponent from './products/ProductsListComponent';
import MessageRow from '../messages/MessageRow';

import NoData from 'components/NoData';
import Price from 'components/Price';

import 'assets/styles/dashboard.css';

import Api from 'api/Api';

class Dashboard extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,
            error_message: '',
            success_message: '',

            account_token: false,
            user: false,

            stats: {
                'seller_active_projects': 0,
                'seller_invitations': 0,
                'seller_recommendations': 0,
                'seller_pending_proposals': 0,

                'seller_escrow': 0,
                'seller_pending_invoices': 0,

                'buyer_active_projects': 0,
                'buyer_pending_proposals': 0,
                
                'buyer_invoice_pending_payment': 0,
                'buyer_invoice_raised': 0,
            },
            messages: [],

            reviews: [],
            no_reviews: false,

            full_view: false,

            userTabChange: false,
            userTab: 'seller_tabs',

            active_tab: 'projects',

            projects: [],
            invitations: [],
            pending_proposals: [],
            recommendations: [],

            buyer_projects: []
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token){
            
            this.setState({account_token: account_token, user: user})
        }else{
            this.setState({redirect: true});
        }

        if(user){
            this.setState({user: JSON.parse(user)});

            this.loadStats(account_token);
        }

        const active_tab = this.props.account_type;

        if(active_tab && active_tab === 'buyer'){

            this.setState({userTab: 'buyer_tabs'})
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(this.props.account_type){

            if(this.props.account_type === 'buyer' || this.props.account_type === 'seller'){

                if(prevProps.account_type !== this.props.account_type){

                    if(this.props.account_type === 'buyer'){

                        this.setState({userTab: 'buyer_tabs'})
                    }

                    if(this.props.account_type === 'seller'){

                        this.setState({userTab: 'seller_tabs'})
                    }
                }
            }else{

                this.setState({userTab: 'seller_tabs'})
            }
        }else{

            if(prevProps.account_type){

                this.setState({userTab: 'seller_tabs'})
            }
        }
    }

    loadStats = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        Api.post('dashboard/stats', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status){
            
                that.setState({stats: data.stats, messages: data.messages, reviews: data.reviews, user: data.user});

                if(data.reviews.length === 0){

                    that.setState({no_reviews: true})
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    onUserTabChange(e){

        var isChecked = e;
        if(isChecked){

            this.setState({userTab: 'buyer_tabs'});
        }else{

            this.setState({userTab: 'seller_tabs'});
        }

        this.setState({userTabChange: true});
    }

    profileText = () => {

        if(this.state.user){

            if(this.state.user.profile_pic_full !== ''){

                return <Avatar src={<Image preview={false} className='profile-image' src={this.state.user.profile_pic_full} />} style={{width: 80, height: 80}} />
            }else{

                const name = this.state.user.name;

                let username = name.substring(0, 1);

                if(name.indexOf(" ") > -1){

                    const names = name.split(" ");
                    username = names[0].substring(0, 1);
                    username += names[1].substring(0, 1);
                }
                
                return <Avatar style={{ color: '#ee7d22', backgroundColor: '#ffe9d8' }} size={80}><strong>{username}</strong></Avatar>
            }
        }else{

            return <Avatar icon={<UserOutlined />} />
        }
    }

    render () {

        return (

            <>
                <Main page_title="Dashboard" page="dashboard" error_message={this.state.error_message} success_message={this.state.success_message}>

                    {this.state.user &&
                        <>
                    
                            <div className="banner-block dashboard-bg-img">
                                {/* <img src={this.state.user.profile_banner_full} alt="Banner Image" /> */}
                                <img src={dashboardbg} alt="Banner Image" />
                                <div className="container-lg">
                                    <div className="page-heading">Dashboard</div>

                                    <div className="profile-details">
                                        <div className="user-details">
                                            <div className="profile-img">

                                                {this.profileText()}
                                            </div>
                                            <div className="profile-basic-details">
                                                <div className="name pb-5">{this.state.user ? this.state.user.name : ''}</div>
                                                <div className="location op-7 fs-14"><FeatherIcon icon="map-pin" size="15"/>{this.state.user ? ((this.state.user.city !== '' ? this.state.user.city + ', ' : '') + this.state.user.country_name) : ''}</div>
                                                <div className="price fw-600">
                                                    <Price>{this.state.user ? this.state.user.price_formatted : ''}</Price>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="section ratings-section">
                                            <div className="block seller">
                                                <span className="num">{this.state.user ? this.state.user.avg_rating : 0}/<span className="">5</span></span>
                                                <span className="text">Seller Rating</span>
                                            </div>
                                            <div className="block buyer">
                                                <span className="num">{this.state.user ? this.state.user.avg_rating : 0}/<span className="">5</span></span>
                                                <span className="text">Buyer Rating</span>
                                            </div>
                                        </div>

                                        <div className="section details-section">
                                            <div className="small-text">Member since {this.state.user.member_since}</div>
                                            <div className="title">{this.state.user.industry_role}</div>
                                            <div className="text fs-14 c-dg l-h-14">

                                                {this.state.user.description.length > 200
                                                    ?
                                                        <>
                                                            {this.state.full_view
                                                                ?
                                                                    <p>{this.state.user.description}</p>
                                                                :
                                                                    <>
                                                                        <LinesEllipsis
                                                                            text={this.state.user.description}
                                                                            maxLine='2'
                                                                            ellipsis={<div>...<Button onClick={() => {

                                                                                this.setState({full_view: true})
                                                                            }} type="link" style={{padding:'4px 2px'}}>Read more</Button></div>}
                                                                            trimRight
                                                                            basedOn='letters'
                                                                        />
                                                                    </>
                                                            }
                                                        </>
                                                    :
                                                        <p>{this.state.user.description}</p>
                                                }
                                            </div>
                                        </div>

                                        <div className="section links">
                                            <Link to={`/service-partner/${this.state.user.slug}`}>View</Link>
                                            <Link to="/account/profile-update">Edit</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="page-main-content dashboard-page container-lg">

                                <Row gutter={24} justify="center" align='stretch'>

                                    <Col span={7}>
                                    
                                        <div className="left-block bg-white p-5" style={{height:'100%'}}>
                                            <div className="block-title">
                                                <span className="">Latest Reviews</span>
                                            </div>
                                                
                                            {this.state.reviews && this.state.reviews.length > 0 &&

                                                <>
                                                    <ul className="messages-list reviews-list">

                                                        {this.state.reviews.map((reviews) => {

                                                            var key = 'reviews_row_' + reviews.ref;
                                                            return (
                                                                this.reviewsRow(key, reviews)
                                                            )
                                                        })}
                                                    </ul>

                                                    <div className="text-center mt-2">
                                                        <Link to="/reviews">View All</Link>
                                                    </div>
                                                </>
                                            }
                                            
                                            {this.state.no_reviews &&
                                            
                                                <NoData className="mt-100" message="Reviews not found" />
                                            }
                                        </div>
                                        
                                        {/* <div className="left-block p-5 bg-white" style={{height: '100%'}}>
                                            <div className="block-title">
                                                <span className="c-dg3">Inbox</span>
                                            </div>
                                            <div className="messages-list">
                                                
                                                {this.state.messages && this.state.messages.length > 0
                                                    ?

                                                        this.state.messages.map((message) => {

                                                            return (

                                                                <MessageRow
                                                                    key={'message_row_' + message.ref}
                                                                    message={message}
                                                                    className="hoverable"
                                                                />
                                                            )
                                                        })
                                                    :
                                                        <NoData size="small" message="New messages will appear here!" icon={<MessageFilled />} hide_image={true} />
                                                }
                                            </div>

                                            {this.state.messages.length > 0 &&
                                            
                                                <div className="fs-15 mt-2 pl-20">
                                                    <Link to="/messages">View All</Link>
                                                </div>
                                            }
                                        </div> */}
                                    </Col>
                                    <Col span={17}>

                                        <div className='pl-20'>

                                            <div className="user-switch pt-10">
                                                
                                                <span className={`red ${this.state.userTab == 'seller_tabs' ? '' : 'dull'} fw-b`} style={{cursor: 'pointer'}} onClick={() => {

                                                    this.setState({userTab: 'seller_tabs'});
                                                }}>Seller <small className='fw-400'>(Service Partner)</small></span>

                                                <Switch className={`${this.state.userTab == 'seller_tabs' ? 'red' : ''}`} checked={this.state.userTab === 'buyer_tabs' ? true : false} size="small" onChange={this.onUserTabChange.bind(this)} />
                                                
                                                <span className={`${this.state.userTab == 'buyer_tabs' ? 'colored' : 'dull'} fw-b`} style={{cursor: 'pointer'}} onClick={() => {

                                                    this.setState({userTab: 'buyer_tabs'});
                                                }}>Buyer <small className='fw-400'>(Client/Project Creator)</small></span>
                                            </div>

                                            {this.state.userTab && (this.state.userTab == 'seller_tabs')
                                                ?
                                                    <div className="details-block seller_tabs">

                                                        <Row gutter={24}>
                                                            <Col span={24}>
                                                                <div className="left-section">

                                                                    {/* <Row gutter={24}>
                                                                        <Col span={16}>
                                                                            <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">Snapshot</div>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                        <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">In Escrow / Invoices</div>
                                                                        </Col>
                                                                    </Row> */}
                                                                    <Row gutter={24}>
                                                                        <Col span={8}>

                                                                            <div className="block color-blocks vertical">
                                                                                <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">Snapshot</div>
                                                                                
                                                                                {this.statCard("Active Projects", this.state.stats.seller_active_projects, '#a24921', 'projects-as-seller')}
                                                                                {this.statCard("Pending Proposals", this.state.stats.seller_pending_proposals, '#928a87', 'projects-as-seller')}
                                                                            </div>
                                                                        </Col>

                                                                        <Col span={8}>
                                                                            <div className='vertical'>

                                                                                {this.statCard("Recommended Projects", this.state.stats.seller_recommendations, '#003965', 'projects-as-seller')}
                                                                                {this.statCard("Project Invites", this.state.stats.seller_invitations, '#6e939c', 'projects-as-seller')}
                                                                            </div>
                                                                        </Col>

                                                                        <Col span={8}>
                                                                        <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">In Escrow / Invoices</div>
                                                                            {this.statCard("Seller Escrow", this.state.stats.seller_escrow, '#ffffff', 'projects-as-seller')}

                                                                            {this.statCard("Pending Invoices", this.state.stats.seller_pending_invoices, '#ffffff', 'projects-as-seller')}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>

                                                            <Col span={24}>

                                                                <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">Manage Projects</div>
                                                                <div className="right-section bg-white p-30 mt-10">
                                                                    
                                                                    <ProjectsBlock
                                                                        source="dashboard"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                :

                                                    <div className="details-block buyer_tabs">

                                                        <Row gutter={24}>
                                                            <Col span={24}>

                                                                <Row gutter={24}>
                                                                    <Col span={12}>
                                                                        <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">At Glance</div>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">In Escrow / Invoices</div>
                                                                    </Col>
                                                                </Row>
                                                            
                                                                <Row gutter={24}>

                                                                    <Col span={6}>
                                                                        {this.statCard("Active Projects", this.state.stats.buyer_active_projects, '#928a87', 'projects-as-buyer')}
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        {this.statCard("Pending Proposals", this.state.stats.buyer_pending_proposals, '#003965', 'projects-as-buyer')}
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        {this.statCard("Pending for Payments", this.state.stats.buyer_invoice_pending_payment, '#a24921', 'projects-as-buyer')}
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        {this.statCard("Invoice Raised", this.state.stats.buyer_invoice_raised, '#6e939c', 'projects-as-buyer')}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                
                                                                <div className="fs-18 fw-600 c-dg3 mt-40 mb-10">Manage Projects</div>

                                                                <div className="right-section bg-white p-30 mt-10">
                                                                    <ProjectsBuyerBlock
                                                                        source="dashboard"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                
                                <div className="main-content d-flex justify-content-between mt-30">

                                    <Row gutter={24} align="stretch" style={{width: '100%'}}>

                                        <Col span={7}>
                                            
                                        </Col>

                                        <Col span={17}>

                                            <div className="right-block pl-25">

                                                <div className="details-block">

                                                    <div className="full-section">
                                                        <div className="block-heading">Manage Product / Offer</div>
                                                        <div className="block">
                                                            
                                                            <div>
                                                                
                                                                <ProductsListComponent />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        </>
                    }
                </Main>
            </>
        )
    }

    statCard = (label, num, color, link) => {

        let html = <div className="sm-block p-15 br-5 mb-10 shadow h-82" style={{backgroundColor:color}}><div className={`num fw-b fs-30 ${color === '#ffffff' ? 'c-b' : 'c-w'}`}>{num}</div><div className={`text fw-500 fs-14 ${color === '#ffffff' ? 'c-b' : 'c-w'}`}>{label}</div></div>

        if(link){

            return <Link to={`/${link}`}>{html}</Link>
        }

        return html;
    }

    reviewRow = (key, data) => {

        return (
            <li key={key}>
                <div className="img-block">
                    {this.getUserImage(data)}
                </div>
                <Link to={"/reviews/" + data.project_id} className="text">
                    <div className="name">
                        <Typography.Paragraph ellipsis={{rows: 1}}>{this.userName(data)}</Typography.Paragraph>
                        <Rate tooltips={this.state.rate_tooltips} size={this.state.sizeLarge} value="4" />
                    </div>
                    <div className="msg">
                        <Typography.Paragraph ellipsis={{rows: 1}}>{data.review}</Typography.Paragraph>
                    </div>
                </Link>
            </li>
        )
    }

    getUserImage = (user) => {

        if(user.buyer_profile_pic != ''){

            return <Avatar src={user.buyer_profile_pic} size={45} />
        }else{

            return <Avatar src={user.freelancer_profile_pic} />
        }
    }

    userName = (user) => {

        if(user.buyer_name != ''){

            return user.buyer_name;
        }else{

            return user.freelancer_name;
        }
    }
}

export default Dashboard;
