import React, { Component } from 'react';

import { Avatar, Rate } from 'antd';

import { UserOutlined } from '@ant-design/icons';

class ProfileCard extends Component { 

    render () {

        const user = this.props.user;

        if(user){
        
            return (

                <div className={`profile-card ml-20 br-5 ${this.props.className ? this.props.className : ''}`}>

                    <div className="section">

                        <div className="profile-basic-details">
                            <div className="top"></div>
                            <div className="align-center vertical bg-w">
                                
                                {user.profile_pic_full != ''
                                    ?
                                        <Avatar size={100} src={user.profile_pic_full} />
                                    :
                                        <Avatar size={100} icon={<UserOutlined />} />
                                }

                                <div className="fs-18 fw-b c-b my-10">{user.freelancer_name}</div>
                                <div className="fs-14 c-b op-7 align-center"><i className="mat-ico fs-16">location_on</i> {user.country_name}</div>

                                <div className="ratings-reviews align-center">
                                    <Rate disabled size="small" value={user.freelancer_avg_rating} />
                                    <div className="fs-14 ml-4"><strong>{user.freelancer_avg_rating}</strong> ({user.freelancer_reviews_count})</div>
                                </div>
                                
                                {this.props.children}
                            </div>
                        </div>
                    </div>

                </div>
            )
        }else{

            return null
        }
    }
}

export default ProfileCard;