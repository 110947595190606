import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

import { Row, Col, Skeleton, Tabs, Checkbox, Modal, Button } from 'antd';

import Loader from 'components/Loader';

import LoginBlock from '../../auth/LoginBlock';

import { MinusOutlined, PlusOutlined, ClockCircleFilled, EyeFilled, HeartFilled, TagFilled } from '@ant-design/icons';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import InjectedCheckoutForm from '../../stripe/InjectedCheckoutForm';

import renderHTML from 'react-render-html';

import SingleReview from 'components/products/SingleReview';

import Api from 'api/Api';

import ProfileCard from 'components/ProfileCard';
import NoData from 'components/NoData';

import Price from 'components/Price';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);

class ProductInfo extends Component {
    constructor(props) {
        super();
        this.state = {

            categories: [],
            total: 0,

            payment_loader: false,

            account_token: false,
            user: false,

            loading: false,

            is_login: false,

            media_url: '',
            gallery: [],

            paying: false,

            qty: 1,

            secret: '',
            payment_data: [],

            selected_addons: [],

            login_modal: false,

            to_project: false,

            product: false,
            main_image: false,

            active_index: 0,

            loading_feedbacks: false,
            feedbacks: [],
            no_feedbacks: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(user && account_token){

            this.setState({user: JSON.parse(user)});
            this.setState({is_login: true, account_token: account_token});
        }else{

            this.setState({user: false});
        }
    }

    componentDidUpdate = (oldProps) => {

        if(oldProps.product !== this.props.product){

            this.setState({product: [], main_image: this.props.product.image_full})
        }
    }

    finalTotal = () => {

        let qty = this.state.qty;
        let total = parseInt(this.props.product.price) * parseInt(qty);

        if(this.state.selected_addons.length > 0){

            this.state.selected_addons.forEach((_addon) => {

                total = parseInt(total) + parseInt(_addon.price);
            })
        }

        return total;
    }

    render () {

        if(this.state.to_project){

            return <Redirect to={this.state.to_project} />
        }

        var product = this.props.product;
        return (

            <>
            
                <div className="product-view inner-page pt-2">

                    {product
                        ?
                            <div className="container">
                        
                                <Row className="left-right-column" gutter={16}>
                                    <Col className="gutter-row" span={16}>
                                        <div className="product-view-left-section">
                                            <div className="top-section box-white p-30 relative ov-hidden">
                                                
                                                {product.featured == 1 &&

                                                    <div className="tag-wrapper">
                                                        <span className="tag-info active fs-13">Featured</span>
                                                    </div>
                                                }

                                                <div className="fs-30 l-h-12 fw-500 c-b pr-20 py-20">{product.title}</div>

                                                <div className="product-image">

                                                    {this.state.main_image
                                                        ?

                                                            <img src={this.state.main_image} />
                                                        :
                                                            null
                                                    }
                                                </div>

                                                <div className="product-gallery">

                                                    {this.props.gallery && this.props.gallery.length > 0
                                                        ?
                                                    
                                                            this.props.gallery.map((_gallery, index) => {

                                                                return <div className={`g-img ${this.state.active_index === index ? 'active' : ''}`} key={`_gallery_${index}`} onClick={() => {

                                                                            this.setState({main_image: _gallery.image, active_index: index})
                                                                        }}>
                                                                            <img src={_gallery.image} alt="Product Image" />
                                                                        </div>
                                                            })
                                                        :
                                                            null
                                                    }
                                                </div>
                                            </div>


                                            <Tabs defaultActiveKey="1" className='button-tabs blue mt-50'>

                                                <Tabs.TabPane tab="Info" key="1">
                                                
                                                    <div className="info-tab">

                                                        <div className="section main-details">
                                                            <div className="desc-section pr-20">
                                                                <div className="title fs-20 fw-500 c-b">What you get with this Offer</div>
                                                                <div className="text fs-16 c-dg">
                                                                    {renderHTML(product.description)}
                                                                </div>
                                                            </div>

                                                            {product.addons && product.addons.length > 0
                                                                ?

                                                                    <div className="addons-section">
                                                                        <div className="fs-20 fw-600 c-brown mt-50 mb-20">Get more with offer add-ons</div>
                                                                            
                                                                        <div className="addons">
                                                                
                                                                            {product.addons.map((_addon, index) => {

                                                                                return (
                                                                                
                                                                                    <div className="p-20 mb-20 bg-white" key={`_addon_${index}`} style={{border:'1px solid rgba(0,0,0,.2)'}}>

                                                                                        <div className="flex justify-between">
                                                                                            <div className="fs-18 c-b fw-500">{_addon.description}</div>
                                                                                            <Checkbox checked={this.isChecked(_addon)} onChange={(e) => {

                                                                                                let selected_addons = this.state.selected_addons;

                                                                                                if(e.target.checked){

                                                                                                    selected_addons.push(_addon)
                                                                                                }else{

                                                                                                    const addon_index = selected_addons.findIndex(row => row.id === _addon.id);

                                                                                                    if(addon_index > -1){

                                                                                                        selected_addons.splice(addon_index, 1);
                                                                                                    }
                                                                                                }

                                                                                                this.setState({selected_addons: selected_addons})
                                                                                            }} />
                                                                                        </div>
                                                                                        <div className="flex justify-between">
                                                                                            <div className="fs-16 c-dg op-8">Additional {_addon.days} working days</div>
                                                                                            <div className="fs-22 fw-600 c-dbrown">+{_addon.price_formatted}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                :
                                                                    null
                                                            }

                                                        </div>
                                                    </div>
                                                </Tabs.TabPane>

                                                <Tabs.TabPane tab="Reviews" key="reviews">
                                                    <div className="clarification-board-tab">

                                                        <div className="reviews-tab ">
                                                        
                                                            {this.props.product.hasOwnProperty('reviews') && this.props.product.reviews && this.props.product.reviews.length > 0
                                                                ?

                                                                    this.props.product.reviews.map((_review, index) => {

                                                                        return <SingleReview key={`_review_${index}`} review={_review} />
                                                                    })
                                                                :
                                                                    <NoData className="small" message="Reviews not available!" />
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </Tabs.TabPane>

                                            </Tabs>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={8}>

                                        <div className="product-view-right-section">

                                            <div className="section">
                                            
                                                <ProfileCard
                                                    user={this.props.product}
                                                >

                                                    <div className='border-top-1 w-100 pt-20 mt-20 mb-10'>
                                                    
                                                        <div className="flex justify-center wrap px-20">

                                                            <div className='w-40 fs-24 fw-b c-b'>
                                                                <Price>{product.price}</Price>
                                                            </div>

                                                            <div className='w-60 flex justify-center wrap gap-10'>
                                                                
                                                                <span className='fs-16 c-b fw-500'>Qty:</span>
                                                                
                                                                <div className='flex' style={{border:'1px solid #003965', borderRadius:4}}>

                                                                    <Button icon={<MinusOutlined />} style={{border:'0 none'}} onClick={(e) => {

                                                                        let qty = this.state.qty;
                                                                        qty = parseInt(qty) - 1;

                                                                        if(qty < 1){

                                                                            qty = 1;
                                                                        }

                                                                        this.setState({qty: qty})
                                                                    }} />
                                                                
                                                                    <div style={{width: 40, height: 34, borderRight:'1px solid rgba(0,0,0,.1)', borderLeft:'1px solid rgba(0,0,0,.1)'}} className="flex justify-center">{this.state.qty}</div>
                                                                    
                                                                    <Button icon={<PlusOutlined />} style={{border:'0 none'}} onClick={(e) => {

                                                                        let qty = this.state.qty;
                                                                        qty = parseInt(qty) + 1;

                                                                        this.setState({qty: qty})
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {this.state.selected_addons.length > 0 &&
                                                            
                                                            <div className='p-20' style={{margin:'20px', backgroundColor:'#efae9121', borderRadius:5}}>

                                                                <strong className='fs-16 c-b'>Selected Addon's</strong>

                                                                <ul>
                                                                    {this.state.selected_addons.map((_addon, index) => {

                                                                        return <li key={`selected_addons_${index}`} style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}>
                                                                                    
                                                                                    <div className='flex justify-between mb-5'>

                                                                                        <div className='flex vertical'>
                                                                                            <strong className='fs-14 fw-500 lh-1 pt-10'>{_addon.description}</strong>
                                                                                            <span className='fs-18 fw-600 lh-1 c-brown mt-10'>
                                                                                                <Price>{_addon.price_formatted}</Price>
                                                                                            </span>
                                                                                        </div>

                                                                                        <div>
                                                                                            <Checkbox checked={() => {

                                                                                                let selected_addons = this.state.selected_addons;
                                                                                                const addon_index = selected_addons.findIndex(row => row.id === _addon.id);
                                                                                                
                                                                                                if(addon_index > -1){

                                                                                                    return true
                                                                                                }

                                                                                                return false;

                                                                                            }} onChange={(e) => {

                                                                                                let selected_addons = this.state.selected_addons;

                                                                                                if(e.target.checked){

                                                                                                    selected_addons.push(_addon)
                                                                                                }else{

                                                                                                    const addon_index = selected_addons.findIndex(row => row.id === _addon.id);

                                                                                                    if(addon_index > -1){

                                                                                                        selected_addons.splice(addon_index, 1);
                                                                                                    }
                                                                                                }

                                                                                                this.setState({selected_addons: selected_addons})
                                                                                            }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                    })}
                                                                </ul>

                                                                <div className='mt-20 flex justify-between wrap'>
                                                                    <strong className='c-b fw-b fs-16'>Total: </strong>
                                                                    <span className='c-b fw-b fs-16 ml-3'>
                                                                        <Price>{this.finalTotal()}</Price>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="flex justify-center mt-30">

                                                            {this.state.account_token
                                                                ?

                                                                    <>

                                                                        {this.state.user.unq_index_id !== product.freelancer_id
                                                                            ?
                                                                                <>
                                                                                    {!this.state.secret &&
                                                                                            
                                                                                        <Button disabled={this.state.paying} loading={this.state.paying} type="primary" size="large" onClick={() => {

                                                                                            this.generateOrder(product)
                                                                                        }}>Buy Now</Button>
                                                                                    }
                                                                                </>
                                                                            :
                                                                                null
                                                                        }
                                                                    </>
                                                                :
                                                                
                                                                    <Button type="primary" size="large" onClick={() => {

                                                                        this.setState({login_modal: true})
                                                                    }}>Buy Now</Button>
                                                            }
                                                        </div>

                                                        {/* {product.delivery_time > 0 &&
                                                        
                                                            <div className="flex justify-center mt-30">

                                                                <div className='flex vertical' style={{border:'1px solid rgba(0,0,0,.1)', borderRadius:4, padding:'10px 30px'}}>
                                                                
                                                                    <ClockCircleFilled style={{fontSize:18, color:'#003965'}} />

                                                                    <div className='c-b mt-5 fs-12'>
                                                                        <span>Response time</span>
                                                                        <strong className='ml-3'>within {product.delivery_time} day's</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        } */}
                                                    </div>
                                                    
                                                    <div className="flex justify-center mt-5 mb-20 wrap gap-30 w-100">
                                                        {/* <div className="align-center vertical">
                                                            <EyeFilled style={{fontSize:18, color:'#a24921'}} />
                                                            <span className="fs-13 c-b op-8">{product.views_count} Views</span>
                                                        </div>
                                                        <div className="align-center vertical">
                                                            <HeartFilled style={{fontSize:18, color:'#a24921'}} />
                                                            <span className="fs-13 c-b op-8">{product.likes_count} Likes</span>
                                                        </div> */}
                                                        <div className="align-center gap-5">
                                                            <TagFilled style={{fontSize:19, color:'#a24921'}} />
                                                            <span className="fs-16 fw-500 c-b">{product.purchase_count} Sold</span>
                                                        </div>
                                                    </div>
                                                </ProfileCard>

                                                <div className="money-protection mt-20">
                                                    <img src={require('../../../assets/images/100.png')} alt="Money Protection" />
                                                    <div className="details">
                                                        <div className="title">Money Protection Guarantee</div>
                                                        <div className="text">Project done or your money back</div>
                                                    </div>
                                                </div>

                                                <Tabs defaultActiveKey="buyer_tips" className="button-tabs blue mt-50 ml-20">
                                                    <Tabs.TabPane tab="Buyer Tips" key="buyer_tips">
                                                        <div className="tips-tab white-bg p-20">
                                                            <ol className='pl-20'>

                                                                {this.props.buyer_tips.length > 0
                                                                    ?
                                                                        this.props.buyer_tips.map((_buyer_tips, index) => {

                                                                            return <li key={`buyer_tip_${index}`}>{_buyer_tips.text}</li>
                                                                        })
                                                                    :
                                                                        null
                                                                }
                                                            </ol>
                                                        </div>
                                                    </Tabs.TabPane>

                                                    <Tabs.TabPane tab="How It Works" key="how_it_works">
                                                        <div className="tips-tab white-bg p-20">
                                                            <ol className='pl-20'>
                                                                {this.props.how_it_works.length > 0
                                                                    ?
                                                                        this.props.how_it_works.map((_how_it_works, index) => {

                                                                            return <li>{_how_it_works.text}</li>
                                                                        })
                                                                    :
                                                                        null
                                                                }
                                                            </ol>
                                                        </div>
                                                    </Tabs.TabPane>

                                                </Tabs>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        :
                            <div style={{minHeight:600}}>
                                <Loader />
                            </div>
                    }

                    <Modal
                        visible={this.state.secret !== '' ? true : false}
                        width={600}
                        footer={null}
                        closable={false}
                    >
                        <div className="login-popup" style={{position: 'relative'}}>
                            
                            {this.state.secret
                                ?
                            
                                    <Elements stripe={stripePromise} options={{
                                        clientSecret: this.state.secret,
                                    }}>
                                        {/* <CheckoutForm /> */}
                                        <InjectedCheckoutForm
                                            secret={this.state.secret}
                                            cancel={() => {

                                                this.setState({secret: '', purchased_exam: false})
                                            }}
                                            paymentProcessing={(status) => {

                                                this.setState({payment_loader: status})
                                            }}
                                            onError={(message) => {

                                                this.setState({screen_error: message, payment_loader: false})
                                            }}
                                            onSuccess={(data) => {

                                                this.setState({payment_data: data, secret: ''});
                                                this.buyProduct();
                                            }}
                                        />
                                    </Elements>
                                :
                                    null
                            }

                            <Loader loading={this.state.payment_loader} />
                        </div>
                    </Modal>

                    <Modal
                        visible={this.state.login_modal}
                        width={600}
                        onCancel={() => {
                            this.setState({login_modal: false})
                        }}
                        footer={null}
                    >
                        <div className="login-popup">
                            <LoginBlock
                                onSuccess={(account_token) => {
                                    window.location = window.location.href;
                                }}
                                onError={(message) => {
                                    this.props.onError(message);
                                }}
                            />
                        </div>
                    </Modal>
                </div>
            </>
        )
    }

    isChecked = (_addon) => {

        let selected_addons = this.state.selected_addons;
        const addon_index = selected_addons.findIndex(row => row.id === _addon.id);

        if(addon_index > -1){

            return true
        }

        return false;
    }

    generateOrder = (product) => {

        const formData = new FormData();

        var that = this;

        this.setState({paying: true});

        formData.append('account_token', this.state.account_token);
        formData.append('product_id', product.product_id);
        formData.append('qty', this.state.qty);

        if(this.state.selected_addons.length > 0){

            let selected_addons = [];

            this.state.selected_addons.map((_addon) => {

                selected_addons.push({id: _addon.id})
            })

            formData.append('addons', JSON.stringify(selected_addons));
        }

        Api.post('products/init_order', formData, function(data){
            
            that.setState({paying: false});

            if(data.status){

                if(data.client_secret){

                    that.setState({secret: data.client_secret});
                }

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    // that.props.errorMessage(data.message);
                }
            }
        });
    }

    buyProduct = () => {

        const formData = new FormData();

        var that = this;

        this.setState({payment_loader: true});

        formData.append('account_token', this.state.account_token);
        formData.append('product_id', this.props.product.product_id);
        formData.append('qty', this.state.qty);

        if(this.state.selected_addons.length > 0){

            let selected_addons = [];

            this.state.selected_addons.map((_addon) => {

                selected_addons.push({id: _addon.id})
            })

            formData.append('addons', JSON.stringify(selected_addons));
        }

        formData.append('payment_data', JSON.stringify(this.state.payment_data));

        Api.post('products/order', formData, function(data){
            
            that.setState({payment_loader: false});

            if(data.status){

                let user_id = that.state.user.unq_index_id;

                that.setState({to_project: `/project-flow/${data.project_id}/${user_id}`})

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    // that.props.errorMessage(data.message);
                }
            }
        });
    }
}

export default ProductInfo;