import React, { Component } from 'react';

import briefcaseimg from '../../../assets/images/employement-bg.png';

import { Popconfirm } from 'antd';

import Tags from '../../../components/Tags';

class EmployementItem extends Component { 

    render () {

        let emp_item = this.props.emp_item;

        if(emp_item){
        
            return (

                <div className={(this.props.className ? this.props.className : '')}>
                    <div className="block d-flex justify-content-between">
                        <div className="icon-block">
                            <img className="img-fluid" src={briefcaseimg} alt={emp_item.title} />
                            <span>{this.props.index + 1}</span>
                        </div>
                        <div className="text-block">
                            <div className="sl-title">{emp_item.title}</div>
                            <div className="sl-company-name c-dg">{emp_item.company_name}</div>
                            <div className="sl-from-to fw-500">{emp_item.emp_from_formatted} - {emp_item.emp_to_formatted}</div>
                            <div className="sl-text">{emp_item.description}</div>
                            <div className="sl-tags">

                                <strong className="heading">Technologies:</strong>

                                <Tags tags={emp_item.technologies} />
                            </div>
                        </div>
                        <div className="sl-buttons">
                            
                            {this.props.editable &&

                                <>    
                                    <div className="pointer" onClick={() => {

                                        this.props.editItem(emp_item);
                                    }}>
                                        Edit
                                    </div>

                                    <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                        
                                        this.setState({item: false});
                                        this.props.remove(emp_item);
                                    }} okText="Yes" cancelText="Cancel">
                                        <div className="pointer">
                                            Remove
                                        </div>
                                    </Popconfirm>
                                </>
                            }
                        </div>
                    </div>
                </div>
            )
        }else{

            return null
        }
    }
}

export default EmployementItem;
