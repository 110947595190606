import React, { Component } from 'react';

import { Row, Col, Divider, Form, Select, List, Button, Checkbox, Slider } from 'antd';

import FeatherIcon from 'feather-icons-react';

import Main from '../../components/Main';
import FreelancerRow from '../../components/freelancer/FreelancerRow';

import PageSubHeader from '../../components/PageSubHeader';

import Api from '../../api/Api';

class FreelancersSearchPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: false,

            loading: false,
            current_page: 1,

            freelancers: [],
            total: 0,
            per_page: 0,
            total_pages:0,

            search_keyword: '',
            search_category: '',

            checkedFreelancerTypes: [],
            checkedSkills: [],
            checkedWorkExperiences: [],
            checkedDataScientistExperiences: [],
            checkedTools: [],
            checkedVerifiedTypes: [],
            checkedHighQualifications: [],

            freelancer_types: [{key:'individual', value:'Individual'}, {key:'company', value:'Company'}],
            skills: [],
            work_experiences: [{key:0, value:'0 Year'}, {key:1, value:'1 Year'}, {key:2, value:'2 Years'}, {key:3, value:'3 Years'}, {key:4, value:'4 Years'}, {key:5, value:'5 Years'}],
            data_scientist_experiences: [{key:0, value:'0 Year'}, {key:1, value:'1 Year'}, {key:2, value:'2 Years'}, {key:3, value:'3 Years'}, {key:4, value:'4 Years'}, {key:5, value:'5 Years'}],
            tools: [],
            verified_types: [{key:1, value:'Yes'}, {key:0, value:'No'}],
            // high_qualifications: [{key:'Graduation/Diploma', value:'Graduation/Diploma'}, {key:'Masters/Post-Graduation', value:'Masters/Post-Graduation'}],
            high_qualifications: [],
        }
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }

        if(user){
            user = JSON.parse(user);
            this.setState({user: user});
        }

        this.loadData();

        if(this.props.keyword){
            this.setState({
                search_keyword: this.props.keyword
            }, () => {
                this.loadFreelancers(this.state.current_page, false, this.props.keyword);
            });
        }else{
            this.loadFreelancers(this.state.current_page, false, false);
        }
    }

    loadData = () => {

        var formData = new FormData();
        formData.append('slug', 'products');

        var that = this;

        Api.post('general/initial', formData, function(data){
            
            that.setState({
                categories: data.categories,
                skills: data.skills,
                tools: data.tools,
                high_qualifications: data.highest_qualification_data,
            });

        });
    }

    loadFreelancers = (page, values, keyword, category) => {

        var formData = new FormData();
        formData.append('page', page);

        var account_token = localStorage.getItem('account_token');

        if(account_token){
            formData.append('account_token', account_token);
        }

        if(keyword){
            formData.append('keyword', keyword);
        }else if(this.props.keyword){
            formData.append('keyword', this.props.keyword);
        }

        if(category){
            // formData.append('category_id', category);
            formData.append('category', category);
        }

        if(values){
            Object.keys(values).forEach((key) => {

                if(values[key]){
                    formData.append(key, values[key]);
                }
            });
        }

        if(this.state.checkedFreelancerTypes.length > 0){
            formData.append('freelancer_types', this.state.checkedFreelancerTypes);
        }

        if(this.state.checkedSkills.length > 0){
            formData.append('skill_ids', this.state.checkedSkills);
        }

        if(this.state.checkedTools.length > 0){
            formData.append('tool_ids', this.state.checkedTools);
        }

        if(this.state.checkedWorkExperiences.length > 0){
            formData.append('work_experiences', this.state.checkedWorkExperiences);
        }

        if(this.state.checkedDataScientistExperiences.length > 0){
            formData.append('data_scientist_experiences', this.state.checkedDataScientistExperiences);
        }

        if(this.state.checkedVerifiedTypes != ''){
            formData.append('verified_types', this.state.checkedVerifiedTypes);
        }

        if(this.state.checkedHighQualifications != ''){
            formData.append('high_qualifications', this.state.checkedHighQualifications);
        }

        var that = this;
        this.setState({loading: true});

        Api.post('freelancers/listing', formData, function(data){
            
            that.setState({loading: false});

            that.setState({
                freelancers: data.results,
                total: data.total,
                per_page: data.per_page,
                current_page: data.page
            });
        });
    }

    applyFilters = (values) => {

        this.loadFreelancers(1, values, this.state.search_keyword, this.state.search_category);
    }

    submitSearch = (values) => {

        this.setState({current_page: 1});
        this.loadFreelancers(1, false, this.state.search_keyword, this.state.search_category);
    }

    filterContainer = () => {
        return (
            <>
                <div className="filters">

                    <div className="header">
                        <div className="main-title">Filter by</div>
                    </div>
                    <div className="body">

                        <Form ref="filter_form_ref" labelCol={{span: 24}} wrapperCol={{span: 24}} name="projects_filter" onFinish={this.applyFilters.bind(this)}>

                            <div className="filter-heading">Type</div>
                            <div className="filter-list type">
                                <div className="single">
                                    {this.state.freelancer_types && this.state.freelancer_types.length &&

                                        this.state.freelancer_types.map((f_type) => {

                                            var key = 'f_type_' + f_type.key;
                                            return (
                                                <Checkbox key={key} value={f_type.key} onChange={this.onFilterFreelancerTypeChange.bind(this)}>
                                                    {f_type.value}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="filter-heading">Skills</div>
                            <div className="filter-list skills">
                                <div className="single">
                                    {this.state.skills && this.state.skills.length &&

                                        this.state.skills.map((skill) => {

                                            var key = 'skill_' + skill.id;
                                            return (
                                                <Checkbox key={key} value={skill.id} onChange={this.onFilterSkillsChange.bind(this)}>
                                                    {skill.label}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="filter-heading">Tools</div>
                            <div className="filter-list tools">
                                <div className="single">
                                    {this.state.tools && this.state.tools.length &&

                                        this.state.tools.map((tool_item) => {

                                            var key = 'tool_item_' + tool_item.id;
                                            return (
                                                <Checkbox key={key} value={tool_item.id} onChange={this.onFilterToolsChange.bind(this)}>
                                                    {tool_item.label}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="filter-heading">Min Total Work Experience</div>
                            <div className="filter-list min-work-exp">
                                <div className="single">
                                    {this.state.work_experiences && this.state.work_experiences.length &&

                                        this.state.work_experiences.map((w_experience) => {

                                            var key = 'w_experience_' + w_experience.key;
                                            return (
                                                <Checkbox key={key} value={w_experience.key} onChange={this.onFilterWorkExperienceChange.bind(this)}>
                                                    {w_experience.value}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="filter-heading">Min Data Scientist Experience</div>
                            <div className="filter-list min-data-science-exp">
                                <div className="single">
                                    {this.state.data_scientist_experiences && this.state.data_scientist_experiences.length &&

                                        this.state.data_scientist_experiences.map((ds_experience) => {

                                            var key = 'ds_experience_' + ds_experience.key;
                                            return (
                                                <Checkbox key={key} value={ds_experience.key} onChange={this.onFilterDataScientistExperienceChange.bind(this)}>
                                                    {ds_experience.value}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/* <div className="filter-heading">Business Domain</div>
                            <div className="filter-list business-domain">
                                <div className="single">
                                    <Checkbox>
                                        Retail
                                    </Checkbox>
                                    <Checkbox>
                                        Finance
                                    </Checkbox>
                                    <Checkbox>
                                        Finance
                                    </Checkbox>
                                </div>
                            </div> */}

                            <div className="filter-heading">Verified</div>
                            <div className="filter-list verified">
                                <div className="single">
                                    {this.state.verified_types && this.state.verified_types.length &&

                                        this.state.verified_types.map((verified_type) => {

                                            var key = 'verified_type_' + verified_type.key;
                                            return (
                                                <Checkbox key={key} value={verified_type.key} onChange={this.onFilterVerifiedTypeChange.bind(this)}>
                                                    {verified_type.value}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="filter-heading">Highest Qualifications</div>
                            <div className="filter-list verified">
                                <div className="single">
                                    {this.state.high_qualifications && this.state.high_qualifications.length &&

                                        this.state.high_qualifications.map((high_qualification) => {

                                            var key = 'high_qualification_' + high_qualification.id;
                                            return (
                                                <Checkbox key={key} value={high_qualification.label} onChange={this.onFilterHighQualificationChange.bind(this)}>
                                                    {high_qualification.label}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <Divider>Or set a range:</Divider>
                            <div className="filter-row slider">
                                <Form.Item label="" name="price_range" rules={[{required:false}]}>
                                    <Slider
                                        range
                                        defaultValue={[0, 1000]}
                                        tooltipVisible={true}
                                        tooltipPlacement="top"
                                        min={1}
                                        max={1000}
                                        marks={{
                                            1: '1',
                                            1000: '1000+'
                                        }}
                                        // onChange={this.onPriceRangeChange.bind(this)}
                                    />
                                </Form.Item>
                            </div>

                            <div className="filter-row mt-1">
                                <div className="d-flex justify-content-between">
                                    <Form.Item>
                                        <Button className="btn purple solid btn-sm" type="submit" htmlType="submit">Filter</Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button className="btn outlined purple btn-sm" type="button" htmlType="button" onClick={() => {
                                            this.refs.filter_form_ref.resetFields();
                                        }}>Reset</Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>

                </div>
                {/* <Divider>Or set a range:</Divider>
                <Slider range marks={{
                                        1: '1',
                                        1000: '1000+'
                                    }} defaultValue={[0, 100]} />
                <div className="button">
                    <Button type="primary" className="dark-blue-btn">Set a Range</Button>
                </div> */}
            </>
        )
    }

    onCategoryChange(value) {
    
        this.setState({
            search_category: value
        });
    }

    onFilterFreelancerTypeChange(e){
        let newArray = [...this.state.checkedFreelancerTypes, e.target.value];
        if (this.state.checkedFreelancerTypes.includes(e.target.value)) {
            newArray = newArray.filter(_f_type => _f_type !== e.target.value);
        } 
        this.setState({
            checkedFreelancerTypes: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterSkillsChange(e){
        let newArray = [...this.state.checkedSkills, e.target.value];
        if (this.state.checkedSkills.includes(e.target.value)) {
            newArray = newArray.filter(_skill => _skill !== e.target.value);
        } 
        this.setState({
            checkedSkills: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterWorkExperienceChange(e){
        let newArray = [...this.state.checkedWorkExperiences, e.target.value];
        if (this.state.checkedWorkExperiences.includes(e.target.value)) {
            newArray = newArray.filter(_w_experience => _w_experience !== e.target.value);
        } 
        this.setState({
            checkedWorkExperiences: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterDataScientistExperienceChange(e){
        let newArray = [...this.state.checkedDataScientistExperiences, e.target.value];
        if (this.state.checkedDataScientistExperiences.includes(e.target.value)) {
            newArray = newArray.filter(_ds_experience => _ds_experience !== e.target.value);
        } 
        this.setState({
            checkedDataScientistExperiences: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterToolsChange(e){
        let newArray = [...this.state.checkedTools, e.target.value];
        if (this.state.checkedTools.includes(e.target.value)) {
            newArray = newArray.filter(_tool => _tool !== e.target.value);
        } 
        this.setState({
            checkedTools: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterVerifiedTypeChange(e){
        let newArray = [...this.state.checkedVerifiedTypes, e.target.value];
        if (this.state.checkedVerifiedTypes.includes(e.target.value)) {
            newArray = newArray.filter(_verify_type => _verify_type !== e.target.value);
        } 
        this.setState({
            checkedVerifiedTypes: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterHighQualificationChange(e){
        let newArray = [...this.state.checkedHighQualifications, e.target.value];
        if (this.state.checkedHighQualifications.includes(e.target.value)) {
            newArray = newArray.filter(_high_qualification => _high_qualification !== e.target.value);
        } 
        this.setState({
            checkedHighQualifications: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onKeywordChange(value) {
    
        this.setState({
            search_keyword: value
        });
    }


    render () {

        const marks ={
            0: {
                style: {
                    color: '#F07E22',
                },
                label: <strong>$0</strong>,
            },
            100: {
              style: {
                color: '#F07E22',
              },
              label: <strong>$1000</strong>,
            },
        };

        return (

            <Main page_title="FreelancersSearchPage" hide_page_title={true} full_content={true} keyword={this.props.keyword}>
                
                <div className="freelancers-search-section inner-page pt-0 listing-page">

                    <PageSubHeader title="1000 Android developers, ready to work for you" />

                    <div className="container">

                        <Form onFinish={this.submitSearch.bind(this)} className="search-frm">
                            <div className="search-block">
                                <Row className="search-row" gutter={16}>
                                    <Col className="gutter-row" span={6}>
                                        <Select className="filter-dropdown" name="category_id" placeholder="Category" onChange={this.onCategoryChange.bind(this)}>
                                            <Select.Option value="">Category</Select.Option>
                                            {this.state.categories && this.state.categories &&

                                                this.state.categories.map((category_item) => {

                                                    var key = 'category_item_' + category_item.id;
                                                    return (
                                                        <Select.Option key={key} value={category_item.name}>{category_item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        {/* <Input.Search placeholder="Search for freelancers by Skills / Tools / Domains" size="large" enterButton={<ArrowRightOutlined size="large" />} /> */}

                                        <input name="search" placeholder="Search for freelancers by Skills / Tools / Domains" value={this.state.search_keyword} onChange={(t) => {
                                            this.setState({search_keyword: t.target.value});
                                            this.onKeywordChange.bind(this);
                                        }} />

                                        <button type="button" className="search-button" onClick={this.submitSearch.bind(this)}>
                                            <FeatherIcon icon="arrow-right" className="search-btn-icon" />
                                        </button>
                                    </Col>
                                </Row>
                                
                            </div>
                        </Form>
                    </div>

                    <div className="container mt-50">

                        <Row gutter={16}>
                            <Col className="gutter-row" span={17}>
                                <div className="listing-section px-20">
                                    <div className="top-filters d-flex justify-content-between sort-by-filter">
                                        <div className="search-count">{this.state.total} Service Providers Found</div>
                                    </div>

                                    <div className="listing">
                                            
                                        <List
                                            // header={this.header()}
                                            className="projects-list"
                                            itemLayout="horizontal"
                                            size="large"
                                            loading={this.state.loading}
                                            pagination={{
                                                onChange: page => {
                                                    // this.props.onLoad();
                                                    window.setTimeout(() => {
                                                        this.loadFreelancers(page);
                                                    }, 200);
                                                },
                                                pageSize: this.state.per_page,
                                                total: this.state.total,
                                                size: "small",
                                                // itemRender: this.pagingItemRender
                                            }}
                                            dataSource={this.state.freelancers}
                                            renderItem={item => (
                                                <FreelancerRow freelancer={item} />
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col className="gutter-row" span={7}>
                                <div className="right-section white-bg">
                                    {this.filterContainer()}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Main>
        )
    }
}

export default FreelancersSearchPage;