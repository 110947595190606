import React, { Component } from 'react';

import { Avatar, List, Tabs } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import BannerSlider from '../../components/sections/common/BannerSlider';

import Api from '../../api/Api';

import Main from '../../components/Main';

class TACSPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            banners: [],

            tac_tabs: [],

            challenges_subheading: '',
            challenges_heading: '',
            challenges_img: '',
            challenges_points: [],
            
            gigfying_subheading: '',
            gigfying_heading: '',
            gigfying_content: '',
            
            tacs_reviews: [],
            
            value_proposition_subheading: '',
            value_proposition_heading: '',
            value_proposition_img: '',
            value_propositions: [],
            
            candidate_assessment_subheading: '',
            candidate_assessment_heading: '',
            candidate_assessments: [],
            
            talent_service_areas_img: '',
            talent_service_areas_subheading: '',
            talent_service_areas_heading: '',
            talent_service_areas: [],
            
            actionable_insights_heading: '',
            actionable_insights: '',
            
            engagement_functions_subheading: '',
            engagement_functions_heading: '',
            engagement_functions_img: '',
            engagement_functions: [],
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderChildCategoryTabs = () => {

        const ccategoriesList = [];
        for(let i = 0; i < this.state.tac_tabs.length; i++) {
            let key = 'tt_'+i;

            ccategoriesList.push(
                <Tabs.TabPane tab={<div><div className="image d-flex-column align-center justify-center h-55p mb-10"><img src={this.state.tac_tabs[i].thumb} className="img-responsive" alt={this.state.tac_tabs[i].name} /></div><div className="fs-19 fw-500 title">{this.state.tac_tabs[i].name}</div></div>} key={key}>
                    <div className="bordered px-50 py-30">
                        <div className="c-b fs-30 fw-500 mb-10 mt-20">{this.state.tac_tabs[i].name}</div>
                        <div className="fs-15 block" dangerouslySetInnerHTML={{ __html: this.state.tac_tabs[i].description }} />
                    </div>
                </Tabs.TabPane>
            );
        }
        return ccategoriesList;
    }

    renderChallengesBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.challenges_points.length; i++) {
            let key = 'cp_'+i;

            finalBlocks.push(
                <div key={key} className="pr-50 mt-30 fb-50">
                    <div className="img">
                        <img src={this.state.challenges_points[i].image} alt={this.state.challenges_points[i].title} />
                    </div>
                    <div className="c-dbrown fs-24 fw-600 mb-10 mt-20">{this.state.challenges_points[i].title}</div>
                    <div className="fs-16 fw-500">{this.state.challenges_points[i].text}</div>
                </div>
            );
        }
        return finalBlocks;
    }

    renderReviewBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.tacs_reviews.length; i++) {
            let key = 'tr_'+i;

            finalBlocks.push(
                <div key={key} className="w-45">
                    <div className="c-o fs-18 fw-500 l-h-11 mb-10 mt-10">{this.state.tacs_reviews[i].subheading}</div>
                    <div className="c-dg fs-24 fw-600 l-h-12 mb-15 pl-60 relative title">{this.state.tacs_reviews[i].heading}</div>
                    <div className="img-block">
                        <img src={this.state.tacs_reviews[i].image} alt={this.state.tacs_reviews[i].heading} />
                    </div>
                </div>
            );
        }
        return finalBlocks;
    }

    renderCandidateAssessBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.candidate_assessments.length; i++) {
            let key = 'ca_'+i;

            finalBlocks.push(
                <div key={key} className="blk d-flex-column align-center justify-center flex-20 px-30 index-100">
                    <div className="num bg-w d-flex-column justify-center fs-16 h-40p mb-30 round w-40p">{this.state.candidate_assessments[i].num}</div>
                    <div className="img-block align-center bg-lo d-flex-column h-65p justify-center ov-hidden round w-65p">
                        <img src={this.state.candidate_assessments[i].image} alt={this.state.candidate_assessments[i].title} />
                    </div>
                    <div className="title fs-20 fw-b l-h-12 mt-20 mb-10">{this.state.candidate_assessments[i].title}</div>
                </div>
            );
        }
        return finalBlocks;
    }

    renderActionableBlocks = () => {

        const finalBlocks = [];
        for(let i = 0; i < this.state.actionable_insights.length; i++) {
            let key = 'ai_'+i;

            finalBlocks.push(
                <div key={key} className="blk bg-w p-25 text-center br-10 w-23">
                    <div className="title c-o fs-18 fw-600">{this.state.actionable_insights[i].title}</div>
                    <div className="text fs-16 fw-500 mb-10">{this.state.actionable_insights[i].text}</div>
                </div>
            );
        }
        return finalBlocks;
    }

    render () {

        return (

            <Main page_title="Talent Acquisition & Continuity Services (TACS)" hide_page_title="true">
                <div className="inner-page pt-0 white-bg">

                    <BannerSlider banners={this.state.banners} />

                    <div className="child-category-section tac-tabs mb-75 mt-75">
                        <div className="container">
                            <Tabs className="img-btn-tabs" defaultActiveKey="cc_1" centered>
                                {this.renderChildCategoryTabs()}
                            </Tabs>
                        </div>
                    </div>

                    <div className="challenges-block bg-lg py-50">
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex-column justify-content-center w-58">
                                    <div className="c-brown fs-20 fw-500">{this.state.challenges_subheading}</div>
                                    <div className="c-b fs-40 fw-600 l-h-12">{this.state.challenges_heading}</div>

                                    <div className="d-flex justify-between wrap">
                                        {this.renderChallengesBlocks()}
                                    </div>
                                </div>
                                <div className="relative w-42 img-block">
                                    <img src={this.state.challenges_img} alt={this.state.challenges_heading} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gigfy-block pb-80 pt-80">
                        <div className="container bordered-bottom pb-80">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex-column justify-content-center">
                                    <div className="c-brown fw-500 fs-20">{this.state.gigfying_subheading}</div>
                                    <div className="c-b mb-10 mt-5 fs-40 fw-600">{this.state.gigfying_heading}</div>
                                </div>
                                <div className="w-42">
                                    <div className="c-g fs-24 fw-500 l-h-15">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.gigfying_content }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container reviews-block pt-80">
                            <div className="blocks d-flex justify-content-between">
                                {this.renderReviewBlocks()}
                            </div>
                        </div>
                    </div>

                    <div className="value-prop-block bg-lg pb-80 pt-80">
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <div className="img-block">
                                    <img src={this.state.value_proposition_img} alt={this.state.value_proposition_heading} />
                                </div>
                                <div className="text-block d-flex-column justify-content-center w-33">
                                    <div className="c-dbrown fs-20 fw-500">{this.state.value_proposition_subheading}</div>
                                    <div className="c-b fs-40 fw-600 l-h-12 mt-5 mb-20">{this.state.value_proposition_heading}</div>

                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.state.value_propositions}
                                        renderItem={item => (
                                        <List.Item className="fs-18 fw-500 l-h-15 pb-10 pt-0 tick-list">
                                            <List.Item.Meta
                                            avatar = {<Avatar icon={<CheckOutlined />} />}
                                            description={item}
                                            />
                                        </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container candidate-assess-block text-center pb-80 pt-80">
                        <div className="c-dbrown fs-20 fw-500 text-center">{this.state.candidate_assessment_subheading}</div>
                        <div className="c-b fs-40 fw-600 l-h-12 mt-5 mb-75 text-center">{this.state.candidate_assessment_heading}</div>

                        <div className="tac-steps d-flex justify-content-between align-start c-b fw-600 relative">
                            {this.renderCandidateAssessBlocks()}
                        </div>
                    </div>

                    <div className="tsa-block bg-lg pb-80 pt-80">
                        <div className="container">
                            <div className="d-flex justify-content-between align-center">
                                <div className="img-block">
                                    <img src={this.state.talent_service_areas_img} alt={this.state.talent_service_areas_heading} />
                                </div>
                                <div className="text-block d-flex-column justify-content-center bg-sk p-70 w-50">
                                    <div className="c-dbrown fs-20 fw-500">{this.state.talent_service_areas_subheading}</div>
                                    <div className="c-b fs-40 fw-600 l-h-12 mt-5 mb-20">{this.state.talent_service_areas_heading}</div>

                                    <List
                                        className="h-auto"
                                        itemLayout="horizontal"
                                        dataSource={this.state.talent_service_areas}
                                        renderItem={item => (
                                        <List.Item className="fs-18 fw-500 l-h-15 pb-10 pt-0 tick-list">
                                            <List.Item.Meta
                                            avatar = {<Avatar icon={<CheckOutlined />} />}
                                            description={item}
                                            />
                                        </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ai-block bg-lbrown pt-50 pb-50">
                        <div className="container">

                            <div className="c-w fs-40 fw-600 l-h-12 mt-5 mb-0 text-center">{this.state.actionable_insights_heading}</div>

                            <div className="blocks d-flex justify-content-between mt-50">
                                {this.renderActionableBlocks()}
                            </div>
                        </div>
                    </div>

                    <div className="container hiw-block pt-80 pb-80">
                        <div className="d-flex justify-content-between">
                            <div className="img-block">
                                <img src={this.state.engagement_functions_img} alt={this.state.engagement_functions_heading} />
                            </div>
                            <div className="text-block d-flex-column justify-content-center w-50">
                                <div className="c-dbrown fs-20 fw-500">{this.state.engagement_functions_subheading}</div>
                                <div className="c-b fs-40 fw-600 l-h-12 mt-5">{this.state.engagement_functions_heading}</div>

                                <List
                                    className="h-auto"
                                    itemLayout="horizontal"
                                    dataSource={this.state.engagement_functions}
                                    renderItem={item => (
                                    <List.Item className="fs-16 fw-500 l-h-15 mb-15 mt-15 pt-0 pb-0 tick-list num-list">
                                        <List.Item.Meta
                                        avatar = {<Avatar className="bg-lo py-5 fs-18 fw-b h-30p w-30p round" icon={item.num} />}
                                        description={item.text}
                                        />
                                    </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Main>
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/tacs', formData, function(data){
            
            that.setState({
                banners: data.banners,

                tac_tabs: data.tac_tabs,

                challenges_subheading: data.challenges_subheading,
                challenges_heading: data.challenges_heading,
                challenges_img: data.challenges_img,
                challenges_points: data.challenges_points,
            
                gigfying_subheading: data.gigfying_subheading,
                gigfying_heading: data.gigfying_heading,
                gigfying_content: data.gigfying_content,
            
                tacs_reviews: data.tacs_reviews,
            
                value_proposition_subheading: data.value_proposition_subheading,
                value_proposition_heading: data.value_proposition_heading,
                value_proposition_img: data.value_proposition_img,
                value_propositions: data.value_propositions,
            
                candidate_assessment_subheading: data.candidate_assessment_subheading,
                candidate_assessment_heading: data.candidate_assessment_heading,
                candidate_assessments: data.candidate_assessments,
            
                talent_service_areas_img: data.talent_service_areas_img,
                talent_service_areas_subheading: data.talent_service_areas_subheading,
                talent_service_areas_heading: data.talent_service_areas_heading,
                talent_service_areas: data.talent_service_areas,
            
                actionable_insights_heading: data.actionable_insights_heading,
                actionable_insights: data.actionable_insights,

                engagement_functions_subheading: data.engagement_functions_subheading,
                engagement_functions_heading: data.engagement_functions_heading,
                engagement_functions_img: data.engagement_functions_img,
                engagement_functions: data.engagement_functions,
            });
        });
    }
}

export default TACSPage;