import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

// add form components
import { Dropdown, Button, Menu, Popconfirm, Modal, Row, Col, Form, Input } from 'antd';

import { DownOutlined, EditOutlined } from '@ant-design/icons';

import Api from 'api/Api';

import Loader from 'components/Loader';

class ProposalActions extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,
            action_menu_visible: false,

            project: false,

            project_flow: false,

            rejection_note: '',
            proposal_rejection_note: false,

            rejection_note_error: false,

            rejecting_proposal: false
        }
    }
    
    render () {

        if(this.state.project_flow){
            
            return <Redirect to={`/project-flow/${this.state.project.project_unq_id}/${this.state.project.awarded_freelancer}`} />
        }

        return (

            <>
                {this.proposalActions()}

                <Modal
                    title=""
                    closable={false}
                    centered
                    visible={this.state.proposal_rejection_note}
                    onOk={() => {

                        if(this.state.rejection_note === ''){

                            this.setState({rejection_note_error: true})
                        }else{
                        
                            this.rejectProposal(this.props.proposal)
                        }
                    }}
                    onCancel={() => {

                        this.setState({proposal_rejection_note: false, rejection_note: ''})
                    }}
                    okText="Submit"
                    cancelText="Cancel"
                    okButtonProps={{disabled: this.state.rejecting_proposal, loading: this.state.rejecting_proposal}}
                    cancelButtonProps={{disabled: this.state.rejecting_proposal}}
                >
                    <div className='proposal-block-container justify-start'>
                                    
                        <div className='vertical flex-one'>
                            <div className="flex-one br-10">
                                <div>
                                    <div className='ant-form ant-form-vertical'>

                                        <Row gutter={4}>

                                            <Col span={24}>

                                                <Form.Item
                                                    label="Proposal Rejection Note"
                                                    validateStatus={this.state.rejection_note_error ? "error" : "validating"}
                                                    help={this.state.rejection_note_error ? "Rejection note is required!" : ""}
                                                >
                                                    <Input.TextArea
                                                        rows={4}
                                                        onChange={(e) => {

                                                            this.setState({rejection_note: e.target.value, rejection_note_error: false})
                                                        }}
                                                        value={this.state.rejection_note}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

    proposalActions = () => {

        var project = this.props.project;
        var proposal = this.props.proposal;

        if(project && project.project_view == 1 && proposal){

            if(proposal.status == 0){

                const menu = (
                    <Menu className="workflow-action-menu">
                        <Menu.Item key="cancel">

                            {proposal.type == 'invoice'
                                ?
                                    <Popconfirm
                                        title="You really want to cancel your invoice?"
                                        onConfirm={() => {
                                            this.invoiceAction(proposal, 'cancel')
                                        }}
                                        cancelText="No"
                                        okText="Yes"
                                    >
                                        <Button loading={this.props.loading}>Cancel Invoice</Button>
                                    </Popconfirm>
                                :
                                    <Popconfirm
                                        title="You really want to cancel your proposal?"
                                        onConfirm={() => {
                                            this.cancelProposal(proposal)
                                        }}
                                        cancelText="No"
                                        okText="Yes"
                                    >
                                        <Button loading={this.props.loading}>Cancel Proposal</Button>
                                    </Popconfirm>
                            }
                        </Menu.Item>
                    </Menu>
                );
                
                return (
                    <Dropdown
                        className="workflow-action-dropdown"
                        overlay={menu}
                        trigger={['click']}
                        visible={this.state.action_menu_visible}
                        onVisibleChange={(e) => {
                            this.setState({action_menu_visible: e})
                        }}
                    >
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                )
            }

            return <span className='fw-b'>{this.invoiceStatus(proposal.status)}</span>
        }

        if(project && project.project_view == 0 && proposal){

            if(proposal.status == 0){

                if(proposal.type == 'invoice'){

                    var menu = (
                        <Menu className='dropdown-menu'>
                            <Menu.Item key="accept">
                                <Popconfirm
                                    title="You really want to pay this invoice?"
                                    onConfirm={() => {
                                        this.invoiceAction(proposal, 'pay');
                                    }}
                                    cancelText="No"
                                    okText="Yes"
                                >
                                    <Button loading={this.props.loading}>Pay Invoice</Button>
                                </Popconfirm>
                            </Menu.Item>
                            <Menu.Item key="reject">
                                <Popconfirm
                                    title="You really want to reject this invoice?"
                                    onConfirm={() => {
                                        this.invoiceAction(proposal, 'reject')
                                    }}
                                    cancelText="No"
                                    okText="Yes"
                                >
                                    <Button loading={this.props.loading}>Reject Invoice</Button>
                                </Popconfirm>
                            </Menu.Item>
                        </Menu>
                    );
                }else{

                    var menu = (
                        <Menu className='dropdown-menu'>
                            <Menu.Item key="accept">
                                <Popconfirm
                                    title="You really want to accept this proposal?"
                                    onConfirm={() => {

                                        this.acceptProposal(proposal)
                                    }}
                                    cancelText="No"
                                    okText="Yes"
                                >
                                    <Button loading={this.props.loading} className="p-20">Accept Proposal</Button>
                                </Popconfirm>
                            </Menu.Item>
                            <Menu.Item key="reject">
                                <Popconfirm
                                    title="You really want to reject this proposal?"
                                    onConfirm={() => {

                                        this.setState({proposal_rejection_note: true, action_menu_visible: false})
                                    }}
                                    cancelText="No"
                                    okText="Yes"
                                >
                                    <Button loading={this.props.loading} className="p-20">Reject Proposal</Button>
                                </Popconfirm>
                            </Menu.Item>
                        </Menu>
                    );
                }
                return (
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="bottomRight"
                        visible={this.state.action_menu_visible}
                        onVisibleChange={(e) => {
                            this.setState({action_menu_visible: e})
                        }}
                    >
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                )
            }

            if(proposal.invoice){

                return <div className='justify-end vertical'>
                            <span className='fw-b c-b fs-16'>{this.invoiceStatus(proposal.invoice.status)}</span>
                            {proposal.invoice.paid_on_date_formatted !== '' &&
                                                        
                                <div className="fs-12 mt-5">
                                    <strong className='op-6'>Paid on</strong>
                                    <span className='ml-3 c-b'>{proposal.invoice.paid_on_date_formatted}</span>
                                </div>
                            }
                        </div>
            }
        }
    }

    invoiceStatus = (status) => {

        var statuses = {
            1: 'Unpaid',
            2: 'Cancelled',
            3: 'Rejected',
            4: 'Paid',
        };

        return statuses[status];
    }

    invoiceAction = (proposal, action) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('invoice', proposal.invoice_id);
        
        var that = this;

        if(action == 'cancel'){
        
            var url = 'invoices/cancel';
        }
        if(action == 'pay'){
        
            var url = 'invoices/pay';
        }
        if(action == 'reject'){
        
            var url = 'invoices/reject';
        }

        this.setState({loading: true});
        Api.post(url, formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposal(data.proposal, data.notification);
                that.props.onSuccess(data.message);
            }else{

                that.props.onError(data.message);
            }
        });
    }

    acceptProposal = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('proposal', proposal.proposal_unq_id);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/accept', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposal(data.proposal, false);
                that.props.onSuccess(data.message);
                that.props.updateProject(data.project);

                that.setState({project: data.project}, () => {

                    that.setState({project_flow: true})
                }) 

            }else{

                that.props.onError(data.message);
            }
        });
    }

    rejectProposal = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('proposal', proposal.proposal_unq_id);
        formData.append('rejection_note', this.state.rejection_note);
        
        var that = this;

        this.setState({rejecting_proposal: true});
        Api.post('proposals/reject', formData, function(data){
            
            that.setState({rejecting_proposal: false, proposal_rejection_note: false, rejection_note: ''});
            
            if(data.status){
            
                that.props.updateProposal(data.proposal, data.notification);
                that.props.updateMainProposal(data.user_proposal);
                that.props.onSuccess(data.message);

            }else{

                that.props.onError(data.message);
            }
        });
    }

    cancelProposal = (proposal) => {

        var formData = new FormData();
        
        formData.append('account_token', this.props.account_token);
        formData.append('proposal', proposal.proposal_unq_id);
        
        var that = this;

        this.setState({loading: true});
        Api.post('proposals/cancel', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposal(data.proposal, data.notification);
                that.props.onSuccess(data.message);
                that.props.updateCanResend(true);
            }else{

                that.props.onError(data.message);
            }
        });
    }
}

export default ProposalActions;