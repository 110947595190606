import React, { Component } from 'react';

import { Link } from "react-router-dom";

// add form components
import { Empty, Menu, Select, Row, Col, Dropdown, List, Avatar } from 'antd';

import FeatherIcon from 'feather-icons-react';

import{ MessageOutlined } from '@ant-design/icons';

import Api from '../../api/Api';

import Main from '../../components/Main';

import MessagesListComponent from './MessagesListComponent';

import ProfilePic from 'components/ProfilePic';

import NoData from 'components/NoData';

const { Option } = Select;

class Messages extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            account_token: false,
            redirect: false,
            loading: false,

            user: false,

            users: [],
            no_users: false,

            messages: [],

            message_text: '',

            active_project: false,

            last_row_id: false
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token){
            
            this.setState({account_token: account_token, user: user})
        }else{
            this.setState({redirect: true});
        }

        if(user){
            this.setState({user: JSON.parse(user)});

            this.loadProjects(account_token);
        }
    }

    loadProjects = (account_token) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('messages/users', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.users.length > 0){

                    that.setState({users: data.users, no_users: false});
                    
                    var project_id = that.props.project_id;

                    const _row = data.users.find(row => row.project_id === project_id);

                    that.setState({active_project: _row})

                    // var project_id = that.props.project_id;
                    // if(project_id){
                        
                    //     that.loadMessages(project_id, false);
                    // }

                }else{

                    that.setState({no_users: true, users: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                // LayoutHelper.addErrorMessage(data.message);
            }
        });
    }

    filterDropdown = () => {

        return (
            <Menu>
                <Menu.Item key="0">
                    <Link to="/post-a-project">Post a project</Link>
                </Menu.Item>
                <Menu.Item key="0">
                    <Link>Find An Expert</Link>
                </Menu.Item>
                <Menu.Item key="0">
                    <Link>Browse Categories</Link>
                </Menu.Item>
                <Menu.Item key="0">
                    <Link>How It Works</Link>
                </Menu.Item>
            </Menu>
        )
    }

    msgFilterDropdown = () => {

        return (
            <Menu>
                <Menu.Item key="0">
                    <Link to="/">Oldest</Link>
                </Menu.Item>
            </Menu>
        )
    }

    userName = (user) => {

        if(user.freelancer_id == this.state.user.unq_index_id){

            return user.buyer_name;
        }else{

            return user.freelancer_name;
        }
    }

    usersList = () => {

        if(this.state.users.length > 0){

            return (

                <List
                    className="user-messages-sidebar-list"
                    itemLayout="horizontal"
                    dataSource={this.state.users}
                    renderItem={item => (
                        <List.Item className={this.state.active_project && this.state.active_project.project_id == item.project_id ? 'active' : ''} style={{backgroundColor:this.state.active_project && this.state.active_project.project_id == item.project_id ? '#f3f6f9' : ''}}>
                            <List.Item.Meta
                                avatar={<ProfilePic src={item.user_profile_pic} size={35} />}
                                title={<a className="title" onClick={() => {

                                    this.setState({active_project: item});
                                }}>
                                    <div className='flex vertical'>
                                        <strong>{this.userName(item)}</strong>
                                        <span className='fs-12'>{item.project_title}</span>
                                        <span className='fs-12 op-6'>{item.message}</span>
                                    </div>
                                </a>}
                            />
                        </List.Item>
                    )}
                />
            )
        }else{

            return <Empty />
        }
    }

    render () {

        return (

            <Main page_title="PostProjectpage" hide_page_title="true" className="messages account-bg" page="messages" error_message={this.state.error_message} success_message={this.state.success_message} hide_footer={true}>

                <div className="section d-flex">
                    
                    <div className="messages-wrapper">
                        
                        {/* <div className="sidebar-filter-dropdown-wrapper">
                            <div className="sidebar-filter-dropdown">
                                <Dropdown overlay={this.msgFilterDropdown()} trigger={['click']} placement="bottomCenter">
                                    <div>
                                        <span>Filter By:</span>
                                        <strong>All Recent</strong>
                                        <FeatherIcon icon="chevron-down" />
                                    </div>
                                </Dropdown>
                            </div>
                        </div> */}
                        <div className="sidebar-col">
                            <div className="sidebar-col-section">
                                <h3>Clarifications</h3>
                            </div>
                            <div className="sidebar-col-section main">
                                
                                {this.usersList()}
                            </div>
                        </div>

                        <div className='messages-container'>
                            
                            {this.state.active_project
                                ?
                                    <>
                                        <div className='message-header'>
                                            <ProfilePic src={this.state.active_project.user_profile_pic} size={35} />
                                            <div className='flex vertical ml-5'>
                                                <strong>{this.userName(this.state.active_project)}</strong>
                                                <span className='fs-12'>{this.state.active_project.project_title}</span>
                                            </div>
                                        </div>

                                        <MessagesListComponent
                                            user={this.state.user}
                                            project_id={this.state.active_project.project_id}
                                            account_token={this.state.account_token}
                                            receiver={this.state.active_project ? this.state.active_project.receiver : false}
                                            row_style='chat'
                                            className="sub-chat proposal-timeline"
                                            source="messages"
                                        />
                                    </>
                                :
                                    <NoData hide_image={true} icon={<MessageOutlined />} size="small" message="Please choose a client from left to start the conversation" />
                            }
                        </div>
                            
                        <div className="sidebar-col">
                            <div className="sidebar-col-section">
                                <h3>Orders</h3>
                            </div>
                            <div className="sidebar-col-section main">
                                <Empty />
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }
}

export default Messages;