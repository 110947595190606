import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, Input, Button, InputNumber, Radio, Alert, Checkbox } from 'antd';

import { LinkedIn } from 'react-linkedin-login-oauth2';

import FeatherIcon from 'feather-icons-react';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import Main from '../../components/Main';

class Registerpage extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            code: false,
            message: false,
            error_message: false,

            registration_type: '',

            mobile_number: '',
            mobile_error: false,

            type: 'provider',

            accept_tnc: false,
            tnc_error: false,

            ln_login: false,
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){
            this.setState({code: true});
        }

        let type = this.props.type;
        if(type && (type === 'provider' || type === 'seeker')){

            this.setState({type: type});
        }
    }

    render () {
        
        if(this.state.type === false){
            return <Redirect to='/'/>;
        }

        if(this.state.code){
            return <Redirect to={`/pending-verification/${this.state.code}`} />;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: 'Email is not valid!',
              number: 'Mobile number is not a valid number!',
            },
            // number: {
            //   range: '${label} must be between ${min} and ${max}',
            // },
        };

        const onFinish = values => {
            var formData = new FormData();
            
            formData.append('account_for', this.state.type);
            Object.keys(values).forEach(key => formData.append(key, values[key]))

            var that = this;

            if(!this.state.accept_tnc){

                that.setState({error_message: "Please accept terms & conditions."});
                return false;
            }

            Api.post('auth/registration', formData, function(data){
                
                if(data.status == true){
                
                    that.setState({loading: false});
                    that.setState({message: true});

                    window.setTimeout(() => {
                        that.setState({code: data.code});
                    }, 500);
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({error_message: false});
                }, 10000);
            });
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        return (

            <>
                <Main className="signup-main-page" page="signup">

                    <div className="container">
                        <div className="register-page d-flex items-end">
                            
                            <div className="right half-container self-right auth-block" style={{position:'relative'}}>
                                <div className="section-heading">Create a Partner Account</div>

                                {/* using if statement */}
                                {this.state.message &&
                                    <Alert message="Success" description={this.state.message} type="success" closable />
                                }

                                {this.state.error_message &&
                                    <Alert message="Error" description={this.state.error_message} type="error" closable />
                                }
                                
                                <Form {...layout} name="register_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={onFinish} onFinishFailed={onFinishFailed}>

                                <div className="fs-16 c-g login-link mb-20">
                                    Already have an account? <Link to="/login" className="c-o fw-600">Login</Link>
                                </div>

                                    <div className="form-item-block d-flex w-100">
                                        <Form.Item className="type-box" name="type" label="" rules={[{required:true}]}>
                                            <Radio.Group onChange={(e) => {
                                                this.setState({registration_type: e.target.value});
                                            }}>
                                                <Radio value="company">Agency</Radio>
                                                <Radio value="individual">Individual</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>

                                    <div className="form-item-block d-flex">
                                        {this.state.registration_type == 'company' &&
                                            <div className="w-100">
                                                <Form.Item name="company_name" rules={[{required:true, message: 'This is a equired entry'}]}>
                                                    <Input placeholder="Company Name" />
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className="form-item-block d-flex">
                                        <div className="w-100">
                                            <Form.Item name="name" rules={[{required:true, message: 'This is a equired entry'}]}>
                                                <Input placeholder="Full Name" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-item-block d-flex">
                                        <div className="w-50">
                                            <Form.Item name="email" rules={[{required:true, message: 'Please enter valid email address'}, {type:'email'}]}>
                                                <Input placeholder="Email Address" />
                                            </Form.Item>
                                        </div>                                        
                                        <div className="w-50">
                                            <Form.Item name="contact_no" rules={[
                                                
                                                () => ({

                                                    validator(rule, value) {

                                                        if(
                                                            (value && !isNaN(value) && value.toString().length >= 10)
                                                        ){

                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('Please enter valid mobile number!');
                                                    }
                                                })
                                            ]}>
                                                <InputNumber placeholder="Mobile number" max={9999999999} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-item-block d-flex">
                                        <div className="w-50">
                                            <Form.Item name="password" rules={[{required:true, message:'Please enter your password!'}]}>
                                                <Input.Password placeholder="Password" />
                                            </Form.Item>
                                        </div>
                                        <div className="w-50">
                                            <Form.Item name="con_password" dependencies={['password']} rules={[{required:true, message:'Please enter confirm password!'},
                                            ({ getFieldValue }) => ({

                                                validator(rule, value) {
                                                
                                                    if (!value || getFieldValue('password') === value) {
                                                        
                                                        return Promise.resolve();
                                                    }
                                                
                                                    return Promise.reject('Entered passwords do not match!');
                                                },
                                            }),]}>
                                                <Input.Password placeholder="Confirm Password" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-item-block d-flex mb-10">
                                        <div className="w-100">
                                            <div>
                                                <Checkbox checked={this.state.accept_tnc} onChange={() => {
                                                    this.setState({accept_tnc: !this.state.accept_tnc})
                                                }} />

                                                <span className="ml-5">By Signing up, I confirm that I accept the <Link className='c-o'>terms of service</Link> and <Link className='c-o'>privacy policy</Link>.</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <Form.Item {...tailLayout} className="text-center">
                                        <Button type="primary" size="large" htmlType="submit" className="btn-orange btn-wide">Register</Button>
                                    </Form.Item>
                                </Form>

                                <div className="text-center login-link mt-5 d-flex align-center">
                                    {this.state.ln_login
                                        ?
                                            <div style={{position:'relative', width:200, height:50}}>
                                                <Loader is_small={true} />
                                            </div>
                                        :
                                            <LinkedIn
                                                clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                                                onFailure={(error) => {

                                                    this.props.onError("LinkedIn Authentication failed! Please try again after sometime.");
                                                }}
                                                onSuccess={(data) => {

                                                    this.authenticateLinkedin(data)
                                                }}
                                                scope="r_emailaddress r_liteprofile"
                                                redirectUri={`${Api.url}linkedin`}
                                            >
                                                {({ linkedInLogin }) => (
                                                    <div
                                                        className="btn-default btn-linked-in"
                                                        onClick={linkedInLogin}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>Login with Linkedin
                                                    </div>
                                                )}
                                            </LinkedIn>
                                    }
                                </div>

                                {/* using if statement */}
                                {(this.state.loading || this.state.ln_login) &&
                                    <Loader />
                                }
                            </div>
                        </div>
                    </div>
                </Main>
            </>
        )
    }

    authenticateLinkedin = (code) => {

        var formData = new FormData();

        formData.append('account_for', this.state.type);
        formData.append("key", code);
        formData.append("page", 'signup');
            
        var that = this;

        this.setState({ln_login: true});

        Api.post('auth/authenticate_linkedin', formData, function(data){
            
            that.setState({ln_login: false});

            if(data.status){
            
                localStorage.setItem('account_token', data.account_token);
                localStorage.setItem('user', JSON.stringify(data.user));

                window.location = Api.url + 'account';
            
            }else{
                
                that.setState({error_message: data.message});
            }
            
            window.setTimeout(() => {
                that.setState({error_message: false, message: false});
            }, 3000);
        });
    }
}

export default Registerpage;
