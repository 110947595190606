import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { Form, Button, Checkbox, Modal } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import paymentmethodimg from '../../assets/images/stripe-logo.svg';

import FeatherIcon from 'feather-icons-react';

import Loader from '../../components/Loader';

import Api from '../../api/Api';

import InjectedCheckoutForm from 'pages/stripe/InjectedCheckoutForm';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import Price from 'components/Price';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);

class PostProjectStepTwo extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            options_text: {
                feature: 'Feature your project',
                urgent: 'Mark your project as Urgent',
                nda: 'Request for NDA (Non disclosure aggrement)',
                scouting: 'Let us do the scouting for you',
            },

            costings: {
                feature: 20,
                urgent: 20,
                nda: 34,
                scouting: 15
            },

            secret: '',
            payment_data: [],
            paying: false,

            payment_loader: false,

            checked: false
        }
    }

    onFinish = (values) => {
        return false;
    }

    calculateSelected = () => {
        
        let project = this.props.project;

        let amount = 0;

        if(project){

            amount = parseInt(project.budget);

            amount += this.itemsTotal(project)
        }

        return amount;
    }

    projectCost = () => {
        
        let project = this.props.project;

        if(project){

            return parseInt(project.budget);
        }
    }

    itemsTotal = (project) => {

        let total = 0;

        if(project.featured_price > 0){

            total += parseInt(project.featured_price);
        }

        if(project.nda_request_price > 0){

            total += parseInt(project.nda_request_price);
        }

        return total;
    }

    renderSelected = () => {

        let project = this.props.project;
        let options_text = this.state.options_text;

        let items = [];

        if(project){

            if(project.featured_price > 0){

                items.push(
                    <div className="justify-between pb-5 mb-5" key={`featured_price`}>
                        <div className="fs-14 c-b">{options_text['feature']}</div>
                        <div className="fs-14 c-b">
                            <Price>{project.featured_price}</Price>
                        </div>
                    </div>
                )
            }

            if(project.nda_request_price > 0){

                items.push(
                    <div className="justify-between pb-5 mb-5" key="nda_price">
                        <div className="fs-14 c-b">{options_text['nda']}</div>
                        <div className="fs-14 c-b">
                            <Price>{project.nda_request_price}</Price>
                        </div>
                    </div>
                )
            }
        }

        return items;
    }

    render () {

        return (

            <Elements stripe={stripePromise}>
                <div className="mt-30">
                    <div className="fs-24 fw-500 c-brown">Upgrade your project</div>

                    <div className='card mt-10 br-5' style={{position:'relative'}}>

                        {this.props.project
                            ?
                                <>
                                    <div className="justify-center" style={{width:'100%'}}>
                                        <div className="left-block w-50">
                                            
                                            <div className="payment-blocks">

                                                <div className="d-flex form-item-block border-block">

                                                    {(this.props.project.payment_method === '' && this.props.project.paid_amount <= '0')
                                                        ?
                                                    
                                                            <Form.Item name="stripe">
                                                                <label>
                                                                    <img className="img-fluid" style={{width: 200}} src={paymentmethodimg} alt="Payment Method" />
                                                                </label>
                                                            </Form.Item>
                                                        :
                                                            <div className='c-green flex-start'>
                                                                <img className="img-fluid" style={{width: 200}} src={paymentmethodimg} alt="Payment Method" />
                                                                <CheckCircleFilled className="fs-26" />
                                                                <b className='ml-5'>Paid</b>
                                                            </div>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right-block extras-payment-block box-white bordered mr-10 w-50">
                                            <div className="summary-block">
                                                <div className="justify-between bordered-bottom pb-10 mb-10">
                                                    <div className="fw-b fs-18 c-b">Summary</div>
                                                    {/* <div className="fw-b fs-18 c-b">
                                                        <Price>{this.calculateSelected()}</Price>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="project-block border-bottom">

                                                <div className="justify-between pb-5 mb-5">
                                                    <div className="fs-14 c-b">Project Cost</div>
                                                    <div className="fs-14 c-b">
                                                        <Price>{this.projectCost()}</Price>
                                                    </div>
                                                </div>

                                                {this.renderSelected()}
                                            </div>

                                            <div className="price-block bordered-bottom mb-10">
                                                
                                                {/* <div className="d-flex">
                                                    <div className="text">Discount</div>
                                                    <div className="text">-</div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="text">Promo Code <FeatherIcon icon="arrow-right" /></div>
                                                    <div className="text"></div>
                                                </div> */}
                                            </div>
                                            <div className="total-block">
                                                <div className="justify-between pb-5 mb-5">
                                                    <div className="fs-18 fw-b c-b">Total</div>
                                                    <div className="fs-18 fw-b c-b">
                                                        <Price>{this.calculateSelected()}</Price>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div className="justify-between" style={{width:'100%', marginTop:40}}>
                                        <div className="d-flex form-item-block agree-block vertical">

                                            {(this.props.project.payment_method === '' && this.props.project.paid_amount <= '0')

                                                ?
                                                    <>
                                                        <Form.Item name="tnc">
                                                            <Checkbox onChange={(e) => {

                                                                if(e.target.checked){

                                                                    this.setState({checked: true})
                                                                }else{

                                                                    this.setState({checked: false})
                                                                }
                                                            }}>I agree to the <Link target="_blank" className="link" to='/terms-of-service'>terms and conditions</Link>, <Link target="_blank" className="link" to='/privacy-policy'>refund and cancelation policy</Link>, <Link target="_blank" className="link" to='/privacy-policy'>Fees</Link></Checkbox>
                                                        </Form.Item>
                                                        <div className="justify-start">
                                                            <FeatherIcon icon="lock" className="c-b" />
                                                            <div className="text">
                                                                <div className="fw-b c-b fs-16">Secure Payment</div>
                                                                <div className="fs-13 op-7">Your information is protected by 256-bit SSL encryptions.</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                :
                                                    null
                                            }
                                        </div>

                                        <div className="d-flex justify-content-between form-end">
                                            
                                            {(this.props.project.payment_method === '' && this.props.project.paid_amount <= '0')

                                                ?
                                                    <>
                                                        <Button type="secondary" onClick={() => {

                                                            this.props.goBack()
                                                        }} className="btn btn-xs">Back</Button>
                                                        <Button type="primary" disabled={!this.state.checked} onClick={() => {

                                                            this.load(this.props.account_token);
                                                        }} className="btn ml-10">Pay Now</Button>
                                                    </>
                                                :
                                                    <>
                                                        <Button type="secondary" onClick={() => {

                                                            this.props.goBack()
                                                        }} className="btn btn-xs">Back</Button>
                                                        <Button type="primary" onClick={() => {

                                                            this.props.onClick()
                                                        }} className="btn ml-10">Next</Button>
                                                    </>
                                            }
                                        </div>
                                    </div>

                                    <Loader loading={this.state.loading} />
                                </>
                            :
                                <div style={{height:300, position:'relative'}}>
                                    <Loader loading={true} />
                                </div>
                        }
                    </div>

                    <Modal
                        visible={this.state.secret !== '' ? true : false}
                        width={600}
                        footer={null}
                        closable={false}
                    >
                        <div className="login-popup">
                            
                            {this.state.secret !== ''
                                ?
                            
                                    <InjectedCheckoutForm
                                        secret={this.state.secret}
                                        cancel={() => {

                                            this.setState({secret: '', purchased_exam: false})
                                        }}
                                        paymentProcessing={(status) => {

                                            this.setState({payment_loader: status})
                                        }}
                                        onError={(message) => {

                                            this.props.onError(message)
                                        }}
                                        onSuccess={(data) => {

                                            this.setState({payment_data: data, secret: ''});
                                            this.buyProject();
                                        }}
                                    />
                                :
                                    null
                            }
                        </div>
                    </Modal>
                </div>
            </Elements>
        )
    }

    save = () => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);

        formData.append('project_id', this.props.project.project_unq_id);

        formData.append('paid', 1);
        
        var that = this;
        this.setState({loading: true});

        Api.post('projects/update_details', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){
                
                that.props.onClick()
            
            }else{

                that.props.onError(data.message)
            }
            
        });
    }

    load = (account_token) => {

        var formData = new FormData();

        formData.append('account_token', account_token);
        formData.append('project_id', this.props.project.project_unq_id);

        var that = this;
        this.setState({loading: true});

        Api.post('projects/init_payment', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){
                
                if(data.client_secret){

                    that.setState({secret: data.client_secret});
                }
            
            }else{

                that.props.onError(data.message)
            }
            
        });
    }

    buyProject = () => {

        const formData = new FormData();

        var that = this;

        this.setState({paying: true});

        formData.append('account_token', this.props.account_token);
        formData.append('project_id', this.props.project.project_unq_id);

        formData.append('payment_data', JSON.stringify(this.state.payment_data));

        Api.post('projects/order', formData, function(data){
            
            that.setState({paying: false});

            if(data.status){

                that.props.onClick()

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    // that.props.errorMessage(data.message);
                }
            }
        });
    }
}

export default PostProjectStepTwo;