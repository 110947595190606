import React, { Component } from 'react';

import { Redirect, Link } from "react-router-dom";

import { Row, Col, Button, Tabs, Carousel, Rate, Skeleton } from 'antd';

import SubSection from './SubSection';

import Api from '../../api/Api';

import Tags from '../../components/Tags';

import CertificationItem from '../account/blocks/CertificationItem';
import EducationItem from '../account/blocks/EducationItem';
import EmployementItem from '../account/blocks/EmployementItem';

import FreelancerPortfolioItem from '../../components/sections/freelancers/FreelancerPortfolioItem';

import FreelancerProductBlock from '../../components/products/FreelancerProductBlock';

import NoData from 'components/NoData';

import FreelancerReviewsRow from './FreelancerReviewsRow';

class FreelancersProfileSection extends Component { 
    constructor(props) {
        super();
        this.state = {
            // edit_link: true,
            redirect: false,

            freelancer: false,
            freelancer_domains: [],
            freelancer_skills: [],
            freelancer_tools: [],

            experience_details: [],

            certification_details: [],

            education_details: [],

            employment_details: [],

            portfolios: [],
            products: [],

            loading_feedbacks: false,
            feedbacks: [],
            no_feedbacks: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');

        this.loadData();
    }

    render () {
        if(this.state.redirect){
            return <Redirect to='/'/>;
        }

        const props = {
            arrows: true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        }

        return (

            <>
                    
                <div className={`banner-block profile-bg-img ${this.props.source || ''}`}>

                    {this.state.freelancer.profile_banner_full != '' &&
                
                        <div className="profile-banner" style={{backgroundImage:'url('+this.state.freelancer.profile_banner_full+')'}} />
                    }
                    <div className="container">
                        <div className="profile-details">

                            <div className={`profile-img`} style={{backgroundImage:'url('+this.state.freelancer.profile_pic_full+')'}}></div>

                            <div className="profile-basic-details">
                                <div className="name">{this.state.freelancer.name} {this.state.freelancer.status && (this.state.freelancer.status == 'Active')? <span className="verified-text"><i className="ico verified"></i> </span>: null } 
                                    {this.state.edit_link
                                        ?
                                            <Link to="/account/profile-update">Edit Profile</Link>
                                        :
                                            null
                                    }
                                </div>
                                
                                <div className="location"><i className="mat-ico">location_on</i>{this.state.freelancer.city}, {this.state.freelancer.country_name}</div>
                                <div className="ratings-reviews">
                                    
                                    <Rate size="large" value={this.state.freelancer.ratings_count} />
                                    
                                    <div className="rating-text">{this.state.freelancer.ratings_count} ({this.state.freelancer.reviews_count})</div>
                                    <Link to="" className="add-review">Review Me</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" style={{position:'relative', zIndex: 99}}>
                    
                    <Row className="left-right-column" gutter={16}>
                        <Col className="gutter-row" span={6}>

                            {this.state.freelancer &&

                                <div className="freelancers-profile-left-section box-white mt-65">

                                    <div className="section buttons justify-between mb-20">
                                        <Button type="primary" className='px-20 py-5 h-100'>Hire Me</Button>
                                        <Button type="primary" className='bg-white c-b px-20 py-5 h-100'>Contact Me</Button>
                                    </div>

                                    <SubSection title="Availability" type="text">
                                        {(this.state.freelancer.availability !== '')
                                            ? 
                                                this.state.freelancer.availability.replace('_', ' ').toUpperCase()
                                            :
                                                ''
                                        }
                                    </SubSection>

                                    <SubSection title="Category">
                                        <Tags tags={this.state.freelancer.job_industry} />
                                    </SubSection>

                                    <SubSection title="Skills">
                                        <Tags tags={this.state.freelancer_skills} />
                                    </SubSection>

                                    <SubSection title="Tools">
                                        <Tags tags={this.state.freelancer_tools} />
                                    </SubSection>

                                    {(this.state.fb_link && (this.state.fb_link != '')) || (this.state.linkedin_link && (this.state.linkedin_link != ''))
                                        ?
                                            <div className="section">
                                                <div className="heading">Social accounts</div>
                                                <ul className="list-inline d-flex social-links">
                                                    {/* {this.props.fb_link && (this.props.fb_link != '')
                                                        ?
                                                            <li><Link to={this.props.fb_link} target="_blank"><i className="ico facebook"></i></Link></li>
                                                        :
                                                            null
                                                    } */}
                                                    
                                                    {this.state.linkedin_link && (this.state.linkedin_link != '')
                                                        ?
                                                            <li>
                                                                <Link to={this.state.linkedin_link} target="_blank"><i className="ico linkedin"></i></Link>
                                                            </li>
                                                        :
                                                            null
                                                    }
                                                </ul>
                                            </div>
                                        :
                                            null
                                    }

                                </div>
                            }
                            
                        </Col>
                        <Col className="gutter-row" span={18}>

                            <div className="freelancers-profile-right-section">

                                {this.state.freelancer &&

                                    <Tabs className='button-tabs' defaultActiveKey="about" centered onChange={(activeKey) => {

                                        if(activeKey === 'reviews'){

                                            this.loadFeedbacks()
                                        }
                                    }}>

                                        <Tabs.TabPane tab="About" key="about">
                                            
                                            <div className="about-tab">
                                                <div className="section main-details box-white">
                                                    <div className="c-b fs-15">Member since {this.state.freelancer.member_since}</div>
                                                    <div className="fs-14">{this.state.freelancer.industry_role}</div>
                                                    <div className="fs-20 fw-600 mt-1 mb-5">{this.state.freelancer.story}</div>
                                                    <div className="fs-16 mt-1 op-7">{this.state.freelancer.description}</div>
                                                </div>

                                                <div className="section box-white mt-30">
                                                    <div className="fs-18 fw-500 c-o mb-20"><i className="ico degree"></i>Certification</div>
                                                    <div className="blocks">

                                                        <div className='custom-carousel'>
                                                        
                                                            {(this.state.certification_details && this.state.certification_details.length > 0) &&
                                                            
                                                                this.state.certification_details.map((_certificate, index) => {

                                                                    return <CertificationItem
                                                                                className="mb-10"
                                                                                editable={false}
                                                                                key={`certificate_${index}`}
                                                                                index={index}
                                                                                certificate={_certificate}
                                                                            />
                                                                })
                                                            }
                                                        </div>
                                                    </div>                                                        
                                                </div>

                                                <div className="section box-white mt-30">
                                                    <div className="fs-18 fw-500 c-o mb-20"><i className="ico degree"></i>Education</div>
                                                    <div className="blocks">

                                                        <div className='custom-carousel'>
                                                        
                                                            {(this.state.education_details && this.state.education_details.length > 0) &&
                                                            
                                                                this.state.education_details.map((education_item, index) => {

                                                                    return <EducationItem
                                                                                className="mb-10"
                                                                                editable={false}
                                                                                key={`education_${index}`}
                                                                                index={index}
                                                                                education_item={education_item}
                                                                            />
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="section box-white mt-30">
                                                    <div className="fs-18 fw-500 c-o mb-20"><i className="ico degree"></i>Employment</div>
                                                    <div className="blocks">

                                                        <div className='custom-carousel flex wrap gap-20'>
                                                        
                                                            {(this.state.employment_details && this.state.employment_details.length > 0) &&
                                                            
                                                                this.state.employment_details.map((emp_item, index) => {

                                                                    return <EmployementItem
                                                                                editable={false}
                                                                                className="w-48"
                                                                                key={`employement_${index}`}
                                                                                index={index}
                                                                                emp_item={emp_item} 
                                                                            />
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="Portfolio" key="portfolio">
                                            <div className="portfolio-tab custom-carousel box-white">
                                                <div className="blocks">

                                                    {(this.state.portfolios && this.state.portfolios.length > 0) &&
                                                    
                                                        this.state.portfolios.map((portfolio_item) => {

                                                            return (
                                            
                                                                <FreelancerPortfolioItem
                                                                    key={'portfolio_' + portfolio_item.id}
                                                                    portfolio_item={portfolio_item}
                                                                    mode="lite"
                                                                />
                                                            )
                                                        })
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="Products/Offers" key="products_offers">

                                            <div className="products-offers-tab box-white">

                                                <Carousel ref={node => (this.carousel = node)} {...props}>
                                                    
                                                    {(this.state.products && this.state.products.length > 0) &&
                                                    
                                                        this.state.products.map((_product, index) => {

                                                            return <FreelancerProductBlock key={'product_' + index} can_edit={false} product={_product} />
                                                        })
                                                    }
                                                </Carousel>
                                            </div>
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="Reviews" key="reviews">
                    
                                            <div className="reviews-tab box-white">
                                                {this.state.loading_feedbacks
                                                    ?
                                                        <div>
                                                            <Skeleton active />
                                                            <Skeleton active className='mt-30' />
                                                            <Skeleton active className='mt-30' />
                                                        </div>
                                                    :
                                                        <>
                                                            {this.state.no_feedbacks
                                                                ?
                                                                    <NoData className="small" message="Feedbacks not available!" />
                                                                :
                                                                    <>
                                                                        {this.state.feedbacks.length > 0
                                                                            ?
                                                                                <div>

                                                                                    {this.state.feedbacks.map((_feedback, index) => {

                                                                                        return (
                                                                                            <FreelancerReviewsRow
                                                                                                key={`feedback_row_${index}`}
                                                                                                feedback={_feedback}
                                                                                            />
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            :
                                                                                null
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </div>
                                        </Tabs.TabPane>
                                    </Tabs>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                
            </>
        )
    }


    loadData = () => {

        var slug = this.props.keyword;

        if(slug && slug !== ''){
        
            const formData = new FormData();
            formData.append('slug', slug);
            formData.append('ref', 'profile');

            var that = this;

            Api.post('freelancer/get_details', formData, function(data){

                if(data.status === false){

                    that.setState({redirect: true});
                }else{

                    var user = data.user;
                    
                    that.setState({
                        freelancer: data.user,

                        freelancer_domains: data.user.domain_formatted,
                        freelancer_skills: data.user.skills_formatted,
                        freelancer_tools: data.user.tools_formatted,
                        fb_link: data.user.fb_link,
                        linkedin_link: data.user.linkedin_link,

                        employment_details: data.employement,

                        certification_details: data.certificates,

                        education_details: data.education,

                        experience_details: data.experience,

                        portfolios: data.portfolio,
                        products: data.products,
                    });
                }
            });
        }else{

            this.setState({redirect: true})
        }
    }

    loadFeedbacks = () => {

        if(this.state.freelancer){

            const formData = new FormData();
            formData.append('user', this.state.freelancer.unq_index_id);

            if(this.state.account_token){
            
                formData.append('account_token', this.state.account_token);
            }

            var that = this;

            this.setState({loading_feedbacks: true})

            Api.post('freelancer/get_reviews', formData, function(data){

                that.setState({loading_feedbacks: false})

                if(data.status === false){

                    that.setState({redirect: true});
                }else{

                    if(data.list.length > 0){
                    
                        that.setState({
                            feedbacks: data.list, no_feedbacks: false
                        });
                    }else{

                        that.setState({no_feedbacks: true, feedbacks: []})
                    }
                }
            });
        }
    }
}

export default FreelancersProfileSection;