import React, { Component } from 'react';

import bachelorscapimg from '../../../assets/images/education-bg.png';

import { Popconfirm } from 'antd';

class EducationItem extends Component { 

    render () {

        let education_item = this.props.education_item;

        if(education_item){
        
            return (

                <div className={(this.props.className ? this.props.className : '')}>

                    <div className="block d-flex justify-content-between">
                        <div className="icon-block">
                            <img className="img-fluid" src={bachelorscapimg} alt={education_item.major} />
                            <span>{this.props.index + 1}</span>
                        </div>
                        <div className="text-block d-flex wrap">
                            <div className="sl-title w-100">{education_item.major}</div>
                                <div className="sl-company-name w-50">{education_item.university}</div>
                                <div className="sl-from-to text-right w-50">{education_item.year_of_passing}</div>
                        </div>
                        <div className="sl-buttons">

                            {this.props.editable &&
                            
                                <>
                                
                                    <div className="pointer" onClick={() => {
                                        
                                        this.props.editItem(education_item);
                                    }}>
                                        Edit
                                    </div>

                                    <Popconfirm placement="left" title="Do you really want to remove this item?" onConfirm={() => {
                                        
                                        this.setState({item: false});
                                        this.props.remove(education_item);
                                    }} okText="Yes" cancelText="Cancel">
                                        <div className="pointer">
                                            Remove
                                        </div>
                                    </Popconfirm>
                                </>
                            }
                        </div>
                    </div>
                </div>
            )
        }else{

            return null
        }
    }
}

export default EducationItem;
