import React, { Component } from 'react';

import Main from '../../../components/Main';

import ProjectsBlock from './ProjectsBlock';

class Projects extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            account_token: false,
            user: false,
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});
        }else{
            // window.location = Api.url + '/logout';
        }
    }
    
    render () {

        return (

            <>
                <Main page_title="Manage Projects" hide_page_title="true" className="projects-as-seller account-bg" page="as_seller" error_message={this.state.error_message} success_message={this.state.success_message}>
                    
                    <div className="container-lg page-main-content">
                        
                        <div className="page-heading">Manage Projects (Seller)</div>

                        <ProjectsBlock
                            source="projects"
                        />
                    </div>
                </Main>
            </>
        )
    }
}

export default Projects;