import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { Button} from "antd";

class HomeHowWeWork extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    renderHowWorkLeftBlocks = () => {

        const blocksList = [];

        for(let i = 0; i < this.props.how_work_left_blocks.length; i++) {
        
            let key = 'lb_'+i;

            blocksList.push(

                <div className="card mb-50" key={key}>
                    <div className="img-block" dangerouslySetInnerHTML={{ __html: this.props.how_work_left_blocks[i].icon }} />
                    <div className="block-title">{this.props.how_work_left_blocks[i].title}</div>
                    <span className="block-text">{this.props.how_work_left_blocks[i].text}</span>
                </div>
            );
        }
        return blocksList;
    }

    renderHowWorkRightBlocks = () => {

        const blocksList = [];
        for(let i = 0; i < this.props.how_work_right_blocks.length; i++) {
            let key = 'lb_'+i;

            blocksList.push(

                <div className="card mt-75 mb-50" key={key}>
                    <div className="img-block" dangerouslySetInnerHTML={{ __html: this.props.how_work_right_blocks[i].icon }} />
                    <div className="block-title">{this.props.how_work_right_blocks[i].title}</div>
                    <span className="block-text">{this.props.how_work_right_blocks[i].text}</span>
                </div>
            );
        }
        return blocksList;
    }

    render () {

        return (

            <div>
                <div className="how-we-work pb-50 pt-100">
                    <div className="container">
                        <div className="how-we-work-block d-flex justify-content-between">
                            <div className="left w-40">
                                <div className="heading">{this.props.how_work.title}</div>
                                <div className="text">{this.props.how_work.text}</div>
                                <div className="bottom-block d-flex justify-content-between mt-40">
                                    <Link to='/how-we-work'>
                                        <Button type="primary h-auto py-6 px-20">Explore</Button>
                                    </Link>
                                    <img src={this.props.how_work.left_image_url} alt={this.props.how_work.left_image_alt} />
                                </div>
                            </div>
                            <div className="right w-50">
                                <div className="blocks d-flex justify-content-between">
                                    <div className="left w-50 mr-50">
                                        {this.renderHowWorkLeftBlocks()}
                                    </div>
                                    <div className="right w-50">
                                        {this.renderHowWorkRightBlocks()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default HomeHowWeWork;