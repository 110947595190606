import React, { Component } from 'react';

import { Rate, Skeleton } from 'antd';

import { StarFilled } from '@ant-design/icons';

import renderHTML from 'react-render-html';

import ProfilePic from 'components/ProfilePic';

class FreelancerReviewsRow extends Component { 
    constructor(props) {
        super();
        this.state = {

        }
    }

    render () {

        const _feedback = this.props.feedback;

        if(_feedback){

            return (
                <div className="section pb-25 bordered-bottom mb-25">
                    <div className="block">
                        <h6 className='fs-12 c-o mb-0'>Project</h6>
                        <div className=" c-g fs-16">{_feedback.title}</div>
                        
                        <div className="flex links mt-10">
                            <span className='flex align-center mr-15'>
                                <ProfilePic src={_feedback.profile_pic_full} size={60} />
                            </span>
                            <div className='w-70'>
                                <div className='flex gap-5 items-center'>                                
                                    <span className="num c-b fw-600 fs-15 mr-20">{_feedback.name}</span>
                                    <div className="flex align-center flex-start">
                                        <Rate className='md' disabled character={<StarFilled style={{fontSize:16}} />} value={_feedback.rating} />
                                        <div className="rating-text ml-3 c-b">{_feedback.rating}</div>
                                    </div>
                                </div>
                                <div className="text fs-15 mt-2 c-dg">"{renderHTML(_feedback.review)}"</div>
                            </div>

                            <span className='flex ml-5 justify-content-end'>
                                <i className="ico clock mt-5"></i>
                                <span className="num ml-3 c-g">{_feedback.added_on_formatted}</span>
                            </span>
                        </div>
                    </div>
                    
                </div>
            )
        }else{

            return null
        }
    }
}

export default FreelancerReviewsRow;