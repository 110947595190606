import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { List, Button } from 'antd';

import Api from '../../api/Api';

import FreelancerRow from '../../components/freelancer/FreelancerRow';

import FeatherIcon from 'feather-icons-react';

class PostProjectStepThree extends Component { 
    constructor(props) {
        super();
        this.state = {

            freelancers: [],
            current_page: 1,
            total: 0,

            per_page: 0,

            user_inviting: false
        }
    }

    componentDidMount = () => {

        // this.loadData();
        this.loadFreelancers(this.state.current_page, false, false);
    }

    loadFreelancers = async(page, values, keyword) => {

        var user_token = localStorage.getItem('user_token');
        var user = localStorage.getItem('user');
        user = JSON.parse(user);
        
        var formData = new FormData();
        formData.append('page', page);
        formData.append('user_id', user.unq_index_id);
        formData.append('project_id', this.props.project.project_unq_id);

        if(keyword){
            formData.append('keyword', keyword);
        }

        if(values){
            Object.keys(values).forEach((key) => {

                if(values[key]){
                    formData.append(key, values[key]);
                }
            });
        }

        var that = this;
        this.setState({loading: true});
        Api.post('listing', formData, function(data){
            
            that.setState({loading: false});
            that.setState({
                freelancers: data.results,
                total: data.total,
                per_page: data.per_page,
                current_page: data.page
            });
        });
    }

    freelancerRow = (user) => {
        
        return (

            <FreelancerRow
                popupView={true}
                freelancer={user}
                user_inviting={this.state.user_inviting}
                inviteUser={(freelancer) => {
                    this.inviteUser(freelancer)
                }}
                className="mb-20"
                rowAction={(user) => {

                    if(user.is_invited == '0'){
                    
                        return <Button type="primary" className='dark-blue-btn c-w py-8 px-20 br-5 bg-brown fs-14 fw-500 h-100 border-none' onClick={() => {

                            this.inviteUser(user)
                        }}>Invite</Button>
                    }else{

                        return <span className='fs-16 p-5 c-green fw-500'><FeatherIcon icon="check" />Invited</span>
                    }
                }}
            />
        )
    }

    render () {

        return (

            <div className="mt-5">

                <List
                    // header={this.header()}
                    className="freelancers-list"
                    // grid={{ gutter: 0, column: 2 }}
                    size="large"
                    loading={this.state.loading}
                    pagination={{
                        onChange: page => {
                            this.page.current.scrollIntoView({ behavior: 'smooth' });
                            window.setTimeout(() => {
                                this.loadFreelancers(page, false, false);
                            }, 200);
                        },
                        pageSize: this.state.per_page,
                        total: this.state.total,
                        current_page: this.state.current_page
                    }}
                    dataSource={this.state.freelancers}
                    renderItem={item => (

                        <div>

                            {this.freelancerRow(item)}
                        </div>
                    )}
                />

                <div className="d-flex justify-content-end buttons mt-5">
                    <Link to="/projects-as-buyer" className="btn">
                        <Button type="primary">Done</Button>
                    </Link>
                </div>
            </div>
        )
    }

    inviteUser = (freelancer) => {

        var user_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        user = JSON.parse(user);
        
        if(this.props.project){

            var formData = new FormData();

            formData.append('account_token', user_token);
            formData.append('freelancer_id', freelancer.unq_index_id);
            formData.append('project_id', this.props.project.project_unq_id);

            var that = this;
            this.setState({user_inviting: user});
            Api.post('projects/invite_freelancer', formData, function(data){
                
                that.setState({user_inviting: false});

                that.updateUserStatus(freelancer);
            });
        }
    }

    updateUserStatus = (user) => {
        var freelancers = this.state.freelancers;

        if(freelancers.length){

            for(var i = 0; i < freelancers.length; i++){

                if(freelancers[i].unq_index_id == user.unq_index_id){

                    freelancers[i].is_invited = 1;
                }
            }
        }
        this.setState({freelancers: freelancers});
    }
}

export default PostProjectStepThree;