import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, InputNumber, Modal, Button, Popconfirm, Input, Row, Col } from 'antd';

import FeatherIcon from 'feather-icons-react';

import File from '../../components/File';
import Loader from '../../components/Loader';

import { InboxOutlined, QuestionCircleFilled, CloseOutlined, PlusCircleOutlined, FieldTimeOutlined, EnvironmentOutlined, HeartOutlined, ClockCircleFilled, StarFilled, FileJpgOutlined, FileWordOutlined, FilePdfOutlined, CloudUploadOutlined } from '@ant-design/icons';

import ProposalActions from './ProposalActions';

import Api from '../../api/Api';

class ProposalPopup extends Component { 
    constructor(props) {
        super();
        this.state = {

            gallery: [],
            image_uploading: false,

            milestones: [{
                title: '',
                amount: 0
            }],

            milestones_total: ''
        }
    }

    onFinish = (values) => {
        var formData = new FormData();
        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key]);
            }
        });

        formData.append('account_token', this.props.account_token);

        var milestones = this.state.milestones;
        milestones = JSON.stringify(milestones);
        
        formData.append('milestones_data', milestones);

        var gallery = this.state.gallery;
        gallery = JSON.stringify(gallery);
        
        formData.append('gallery', gallery);
        formData.append('project_id', this.props.project.project_unq_id);
        
        var that = this;

        this.setState({loading: true});

        Api.post('proposals/submission', formData, function(data){
            
            that.setState({loading: false, proposal_modal: false});

            if(data.status == true){

                that.props.onSuccess(data.message);
                that.props.appendRow(data.proposal);

                that.props.hideModal();
                that.setState({
                    milestones: [{
                        title: '',
                        amount: 0
                    }],
                    milestones_total: 0,
                    gallery: []
                });

                that.refs.proposal_form.resetFields();

            }else{
                
                that.props.onError(data.message);
            }
        });
    }
    
    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        return (

            <>
                <Modal
                    title="New Proposal"
                    visible={this.props.proposal_modal}
                    // onOk={this.handleOk}
                    closable={true}
                    onCancel={() => {
                        this.props.hideModal();
                        this.setState({milestones: [{
                            title: '',
                            amount: 0
                        }], milestones_total: 0, gallery: []});
                        this.refs.proposal_form.resetFields();
                    }}
                    width={1000}
                    footer={null}
                >
                    <div className="new-proposal-form">

                        <Form {...layout} scrollToFirstError={true} name="proposal_form" ref="proposal_form" initialValues={{ remember: true }} onFinish={this.onFinish.bind(this)}>

                            <div>
                                <div className="form-wrap">
                                    <h4 className="title">Enter your proposal details</h4>
                                    <Form.Item name="description" label="" rules={[{required:true, message: 'Please enter your proposal details!'}]}>
                                        <Input.TextArea />
                                    </Form.Item>
                                </div>
                                <div className="form-wrap">
                                    <h4 className="title">Upload Files</h4>

                                    <div className="form-gallery">

                                        {this.displayGallery()}
                                        {this.state.image_uploading
                                            ?   
                                                <Loader is_small={true} />
                                            :
                                                null
                                        }
                                    </div>

                                    <label className="cloud-uploader">
                                        <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e)}} ref={(input)=> this.myinput = input}/>
                                        <CloudUploadOutlined />
                                        <p className="ant-upload-text">Click this area to upload</p>
                                    </label>
                                </div>
                                <div className="form-wrap">
                                    <Row>
                                        <Col span={16} className="border-right">
                                            <Row>
                                                <Col span={18}>
                                                    <h5 className="label">Project Milestone</h5>
                                                </Col>
                                                <Col span={6}>
                                                    <h5 className="label">Amount<span className="ml-5 filled-circle"><QuestionCircleFilled /></span></h5>
                                                </Col>
                                            </Row>
                                            {this.displayMilestones()}
                                            <Row>
                                                <Col span={24}>
                                                    <span onClick={() => {
                                                        this.addMilestones()
                                                    }} className="add"><PlusCircleOutlined /></span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={8} className="milestone-info">
                                            <Row>
                                                <Col span={12}>Total</Col>
                                                <Col span={12} className="pink-txt">{this.state.milestones_total}</Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>You’ll earn<span className="ml-5 filled-circle"><QuestionCircleFilled /></span></Col>
                                                <Col span={12}>{this.state.milestones_total}</Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>Deposit<span className="ml-5 filled-circle"><QuestionCircleFilled /></span></Col>
                                                <Col span={12}>{this.state.milestones_total}</Col>
                                            </Row>
                                            <p>Featuring a proposal<span className="ml-5 filled-circle"><QuestionCircleFilled /></span></p>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="terms">
                                    <Row justify="space-between" align="bottom">
                                        <Col span={16}>
                                            <p>Please note that the minimum service fee is 15% excluding GST. </p>
                                            <p>Read more</p>
                                            <div className="info">
                                                You have <span className="bold">15 Proposal Credits available</span>. You can always <span>buy more</span>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <Button loading={this.state.loading} htmlType="submit" className="ant-btn btn btn-sm btn-pink-outline ant-btn-submit"><span>Send</span></Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </>
        )
    }

    calculateTotal = (milestones) => {

        if(milestones.length > 0){

            var total = 0;
            for(var i = 0; i < milestones.length; i++){
                
                total = parseFloat(total) + parseFloat(milestones[i]['amount']);
            }
            this.setState({milestones_total: total});
        }
    }

    displayMilestones = () => {

        var milestones = this.state.milestones;
        if(milestones.length > 0){

            var _milestones = [];

            for(var n = 0; n < milestones.length; n++){

                var i = n;
                var key = 'milestones_' + i;

                _milestones.push(
                    <Row key={key}>
                        <Col span={18} className="pr-15">
                            <Form.Item name={"milestone_title["+i+"]"} rules={[{ required: true, message: 'Please enter milestones details' }]}>
                                <Input className="milestones-input" value={milestones[i]['title']} onChange={(e) => {
                                    milestones[i]['title'] = e.target.value;
                                    this.setState({milestones: milestones});
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={"milestone_amount["+i+"]"} rules={[{ required: true, message: 'Please enter amount' }]}>
                                <InputNumber min={1} value={milestones[i]['amount']} onChange={(e) => {
                                    milestones[i]['amount'] = e;
                                    this.setState({milestones: milestones});
                                    window.setTimeout(() => {
                                        this.calculateTotal(milestones);
                                    },100);
                                }} />
                            </Form.Item>
                        </Col>

                        {i > 0 &&
                            <Col span={2}>
                                <Popconfirm title="Are you sure" onConfirm={() => {
                                    milestones.splice(milestones.indexOf(i), 1);
                                    this.setState({milestones: milestones});

                                }} okText="Yes" cancelText="No">
                                    <span className="close-danger"><CloseOutlined /></span>
                                </Popconfirm>
                            </Col>
                        }
                    </Row>
                );
            }
            return _milestones;
        }
        return '';
    }

    addMilestones = () => {

        var milestones = this.state.milestones;
        milestones.push({
            title: '',
            amount: 0
        })
        this.setState({milestones: milestones});
    }

    updateGallery = (image) => {

        var gallery = this.state.gallery;
        gallery.push(image);
        this.setState({gallery: gallery});
    }

    _handleImageChange = async (e) => {

        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        this.setState({image_uploading: true});
    
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,pdf,doc,docx');
        formData.append('upload_dir', 'project_propsals/');
        formData.append('project_id', this.props.project.project_unq_id);

        this.setState({image_uploading: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({image_uploading: false});

            if(data.status == 'success'){

                var url = data.random_dir + data.main_file_name;
                that.setState({media_url: data.media_url + 'project_propsals/'});

                that.updateGallery(url);
            }
        });
    }

    displayGallery = () => {

        var gallery = this.state.gallery;
        var _gallery = [];

        if(gallery && gallery.length > 0){

            for(var i = 0; i < gallery.length; i++){

                var key = 'gallery_item_' + i;
                
                _gallery.push(
                    <li key={key}>
                        <Popconfirm title="Are you sure" placement="topRight" onConfirm={() => {
                            gallery.splice(gallery.indexOf(i), 1);
                            this.setState({gallery: gallery});

                        }} okText="Yes" cancelText="No">
                            <button className="close" type="button">
                                <FeatherIcon icon="x" size={15} />
                            </button>
                        </Popconfirm>
                        
                        <File url={this.state.media_url + gallery[i]} />
                    </li>
                );
            }

            return <ul>{_gallery}</ul>;
        }
        return '';
    }
}

export default ProposalPopup;