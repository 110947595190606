import React, { Component } from 'react';

class SubSection extends Component { 
    constructor(props) {
        super();
        this.state = {

        }
    }

    render () {

        return (
            <div className="section mb-20">
                <h6 className="fs-18 c-b mb-5">{this.props.title}</h6>

                {this.props.type && this.props.type === 'text'
                    ?
                        <div className="text">
                            {this.props.children}
                        </div>
                    :
                        <div className="tags">

                            {this.props.children}
                        </div>
                }
                
            </div>
        )
    }
}

export default SubSection;