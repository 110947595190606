import React, { Component } from 'react';
import { Row, Col, Input, Checkbox, List, Collapse } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { Link } from "react-router-dom";

import PostRow from './PostRow';

import Main from '../../components/Main';
import Api from '../../api/Api';

import PageSubHeader from '../../components/PageSubHeader';

const { Panel } = Collapse;

class BlogListingPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: false,

            loading: false,
            current_page: 1,

            sizeSmall: 'small',
            sizeLarge: 'large',

            posts: [],
            total: 0,
            per_page: 10,
            total_pages:0,

            related_posts: [
                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-1', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},

                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-2', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},

                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-3', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},

                {post_title:'What the Corona Crisis will Teach Us, Eventually!', post_slug:'test-4', post_description:'All global crises, be it the economic ones such as the Great Depression of 1930, the Financial Crisis of 2008 or the...'},
            ],

            search_keyword: '',
            search_category: '',
        }
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }

        if(user){
            user = JSON.parse(user);
            this.setState({user: user});
        }

        if(this.props.keyword){
            this.setState({
                search_keyword: this.props.keyword
            }, () => {
                this.loadPosts(this.state.current_page, false, this.props.keyword);
            });
        }else{
            this.loadPosts(this.state.current_page, false, false);
        }
    }

    loadPosts = (page, values, keyword/*, category*/) => {

        /*var formData = new FormData();
        formData.append('page', page);

        var account_token = localStorage.getItem('account_token');
        if(account_token){
            formData.append('account_token', account_token);
        }

        if(keyword){
            formData.append('keyword', keyword);
        }else if(this.props.keyword){
            formData.append('keyword', this.props.keyword);
        }

        // if(category){
        //     formData.append('category_id', category);
        // }

        if(values){
            Object.keys(values).forEach((key) => {

                if(values[key]){
                    formData.append(key, values[key]);
                }
            });
        }

        // if(this.state.checkedCategories.length > 0){
        //     formData.append('category_ids', this.state.checkedCategories);
        // }

        var that = this;
        this.setState({loading: true});

        Api.post('projects/listing', formData, function(data){
            
            that.setState({loading: false});

            that.setState({
                projects: data.results,
                total: data.total,
                per_page: data.per_page,
                current_page: data.page
            });
        });*/
    }

    applyFilters = (values) => {

        this.loadPosts(1, values, this.state.search_keyword, this.state.search_category);
    }

    submitSearch = (values) => {

        this.setState({current_page: 1});
        this.loadPosts(1, false, this.state.search_keyword, this.state.search_category);
    }

    onKeywordChange(value) {
    
        this.setState({
            search_keyword: value
        });
    }

    renderRelatedPosts = () => {

        const related_posts = this.state.related_posts;

        return related_posts.map(function(item, index){
            var key = 'rp_'+index;

            return (
                <div className="single-item p-25 bigshadow my-20" key={key}>
                    <Link to={"/blog/"+item.post_slug} className="title fw-500 fs-18 l-h-11 c-b">{item.post_title}</Link>
                    <div className="desc fs-14 mt-10">{item.post_description}</div>
                    <Link to={"/blog/"+item.post_slug} className="link fs-14">View More</Link>
                </div>
            )
        });
    }

    render () {

        return (

            <Main>
                <div className="blog-listing-section inner-page pt-0 white-bg listing-page">

                    <PageSubHeader title="Blogging the reel world" />

                    <div className="container">
                        <Row gutter={16}>
                            <Col className="gutter-row" span={17}>
                                <div className="listing-section">
                                    <div className="heading">Recent Blogs</div>
                                    <div className="listing">

                                        <List
                                            className="posts-list"
                                            itemLayout="horizontal"
                                            size="large"
                                            loading={this.state.loading}
                                            pagination={{
                                                onChange: page => {
                                                    window.setTimeout(() => {
                                                        this.loadPosts(page, false, false);
                                                    }, 200);
                                                },
                                                pageSize: this.state.per_page,
                                                total: this.state.total,
                                                size: "small",
                                            }}
                                            dataSource={this.state.posts}
                                            renderItem={item => (
                                                <PostRow
                                                    post={item}
                                                    user={this.state.user}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col className="gutter-row" span={7}>
                                <div className="right-section white-bg pt-0">
                                    {/* <div className="social-links-text d-flex">
                                        <span className="text">Share this blog:</span>
                                        <ul className="list-inline d-flex social-links">
                                            <li><Link to="#" target="_blank"><i className="ico facebook"></i></Link></li>
                                            <li><Link to="#" target="_blank"><i className="ico twitter"></i></Link></li>
                                            <li><Link to="#" target="_blank"><i className="ico linkedin"></i></Link></li>
                                        </ul>
                                    </div>
 */}
                                    <div className="search-row">
                                        <Input.Search placeholder="Search By Article Title" size={this.state.sizeLarge} enterButton={<ArrowRightOutlined size={this.state.sizeLarge} />} />
                                    </div>

                                    <Collapse defaultActiveKey={['1']} expandIconPosition="right">
                                        <Panel header="Industry types" key="1">
                                            <div className="filter-list industry-types">
                                                <div className="single">
                                                    <Checkbox>Banking</Checkbox>
                                                    <Checkbox>Insurance</Checkbox>
                                                    <Checkbox>Manufacturing</Checkbox>
                                                    <Checkbox>Retail</Checkbox>
                                                    <Checkbox>Utilities</Checkbox>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse defaultActiveKey={['1']} expandIconPosition="right">
                                        <Panel header="Functional Areas" key="2">
                                            <div className="filter-list functional-areas">
                                                <div className="single">
                                                    <Checkbox>Banking</Checkbox>
                                                    <Checkbox>Insurance</Checkbox>
                                                    <Checkbox>Manufacturing</Checkbox>
                                                    <Checkbox>Retail</Checkbox>
                                                    <Checkbox>Utilities</Checkbox>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse defaultActiveKey={['1']} expandIconPosition="right">
                                        <Panel header="Objectives" key="3">
                                            <div className="filter-list objectives">
                                                <div className="single">
                                                    <Checkbox>Banking</Checkbox>
                                                    <Checkbox>Insurance</Checkbox>
                                                    <Checkbox>Manufacturing</Checkbox>
                                                    <Checkbox>Retail</Checkbox>
                                                    <Checkbox>Utilities</Checkbox>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    <div className="other-posts-block">
                                        <div className="fs-22 c-dbrown fw-600 mt-50 mb-10">You might also like..</div>

                                        <div className="other-posts">
                                            {this.renderRelatedPosts()}
                                        </div>
                                    </div>
                                    
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                
            </Main>
            
        )
    }
}

export default BlogListingPage;