import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Table, Tabs, Empty, Avatar } from 'antd';

import Api from 'api/Api';

class ProjectsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            projects: [],
            no_data: false,

            type: 'awarded',

            status_count: {
                awarded: 0, overdue: 0, delivered: 0, completed: 0, cancelled: 0, 'on-hold': 0
            }
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});
        }else{
            // window.location = Api.url + '/logout';
        }

        this.loadProjects(account_token, this.state.type);
    }

    loadProjects = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('status', type);
        
        var that = this;

        this.setState({loading: true});
        Api.post('seller/projects', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                if(data.projects.length > 0){

                    that.setState({projects: data.projects, no_data: false});
                }else{

                    that.setState({no_data: true, projects: []});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                that.props.errorMessage(data.message);
            }
        });
    }

    getColumns = (key) => {

        const columns = [

            {title: 'Buyer/Client', dataIndex: 'owner_name', key: 'owner_name', render: (name, row) => {
                return (
                    <div>
                        <Avatar size={30} src={row.owner_profile_pic} />
                        <span className="ml-5 fw-b c-b">{name}</span>
                    </div>
                )
            }},
            {title: 'Project', dataIndex: 'title', key: 'title',},
            {title: 'Start Date', dataIndex: 'start_date_formatted', key: 'start_date_formatted',},
            {title: 'Budget', dataIndex: 'budget_formatted', key: 'budget_formatted',},
            {title: 'Action', key: 'action', render: (name, row) => {

                return (

                    <Link className="btn" key="details" to={`/project-flow/${row.project_unq_id}/${row.awarded_freelancer}`}>Details</Link>
                )
            }},
        ];

        if(key === 'delivered'){

            columns.splice(3, 0, {title: 'End Date', dataIndex: 'ended_on_date_formatted', key: 'ended_on_date_formatted'});
        }

        return columns
    }
    
    render () {

        return (

            <>
                {this.props.source === 'projects'
                    ?

                        <Tabs defaultActiveKey="awarded" onTabClick={(key, event) => {

                            this.setState({type: key});
                            this.loadProjects(this.state.account_token, key);
                        }}>
                            <Tabs.TabPane tab={
                                <a>
                                    <span>Active</span>
                                    {this.statusCount('awarded')}
                                </a>
                            } key="awarded">
                                <div className="tab-heading">Projects</div>
                                <Table columns={this.getColumns('awarded')} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>
            
                            {/* <Tabs.TabPane tab={
                                <a>
                                    <span>Overdue</span>
                                </a>
                            } key="overdue">
                                <div className="tab-heading">Overdue</div>
                                <Table columns={projectTableColumns} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane> */}

                            <Tabs.TabPane tab={
                                <a>
                                    <span>Delivered</span>
                                </a>
                            } key="delivered">
                                <div className="tab-heading">Delivered</div>
                                <Table columns={this.getColumns('delivered')} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            {/* <Tabs.TabPane tab={
                                <a>
                                    <span>Completed</span>
                                </a>
                            } key="completed">
                                <div className="tab-heading">Completed</div>
                                <Table columns={projectTableColumns} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane> */}

                            <Tabs.TabPane tab={
                                <a>
                                    <span>Cancelled</span>
                                </a>
                            } key="cancelled">
                                <div className="tab-heading">Cancelled</div>
                                <Table columns={this.getColumns('cancelled')} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            {/* <Tabs.TabPane tab={
                                <a>
                                    <span>On-Hold</span>
                                </a>
                            } key="on-hold">
                                <div className="tab-heading">On-Hold</div>
                                <Table columns={projectTableColumns} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <span>All</span>
                                </a>
                            } key="all">
                                <div className="tab-heading">All</div>
                                <Table columns={projectTableColumns} dataSource={this.state.projects}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane> */}
            
                        </Tabs>
                    :
                        <>
                            <div className="tab-heading">Projects</div>
                            <Table columns={this.getColumns('awarded')} dataSource={this.state.projects}>
                                <Empty />
                            </Table>
                        </>
                }
            </>
        )
    }

    statusCount = (key) => {

        var _status_count = this.state.status_count;
        if(_status_count.hasOwnProperty(key) && _status_count[key] > 0){

            return <span class="count">{_status_count[key]}</span>
        }
    }
}

export default ProjectsList;