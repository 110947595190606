import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

// add form components
import { Form, Button, Select, Row, Col } from 'antd';
import Api from '../../api/Api';

import { LinkedIn } from 'react-linkedin-login-oauth2';

import Loader from 'components/Loader';

const { Option } = Select;

class SocialAccounts extends Component { 
    constructor(props) {
        super();
        this.state = {
            updated: false,
            redirect: false,
            count_employment: 0,
            fb_required: false,
            linkedin_required: false,
            github_required: false,

            ln_login: false
        }
    }

    triggerFbLink = () => {
        document.getElementById('fb_link').classList.remove('hidden');
        this.setState({fb_required: true});
    }

    triggerLinkedinLink = () => {
        document.getElementById('linkedin_link').classList.remove('hidden');
        this.setState({linkedin_required: true});
    }

    triggerGithubLink = () => {
        document.getElementById('github_link').classList.remove('hidden');
        this.setState({github_required: true});
    }

    render () {
        // if(!this.state.redirect){
        //     return <Redirect to='/login'/>;
        // }

        if(this.state.updated){
            return <Redirect to='/freelancer/update-profile-5'/>;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        const onFinish = values => {
            var formData = new FormData();
            var _fb_link = document.getElementById('fb_link').value;
            var _linkedin_link = document.getElementById('linkedin_link').value;
            var _github_link = document.getElementById('github_link').value;

            formData.append('account_token', this.state.account_token);
            formData.append('fb_link', _fb_link);
            formData.append('linkedin_link', _linkedin_link);
            formData.append('github_link', _github_link);
            Object.keys(values).forEach(key => formData.append(key, values[key]))
            
            var that = this;

            Api.post('freelancer/update_profile4', formData, function(data){
                
                if(data.status == true){
                
                    localStorage.setItem('account_token', data.account_token);
                    localStorage.setItem('user', JSON.stringify(data.user));
                    
                    window.setTimeout(() => {
                        that.setState({loading: false});
                        that.setState({updated: true});
                    }, 1000);
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({error_message: false});
                }, 10000);
            });
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        return (

            <div className="update-profile-page d-flex">
                    
                <div className="profile-wrapper">
                    <Row>
                        <Col span={24}>
                            
                            <div className="profile-main-wrapper">

                                <div className="page-heading">Social Accounts</div>

                                <Form
                                    {...layout}
                                    name="update_profile_form"
                                    initialValues={{ remember: true }}
                                    validateMessages={validateMessages}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    {/* <div className="d-flex w-100 justify-content-between" style={{padding:20, border:'1px solid #ccc'}}>
                                        <label htmlFor="fb_link"><FeatherIcon icon="facebook" /> Facebook</label>
                                        <div>
                                            <input type="hidden" className="input hidden fb-link" id="fb_link" value={this.state.user.fb_link} />
                                            <Button type="primary" onClick={this.triggerFbLink}>Connect</Button>
                                        </div>
                                        
                                    </div> */}

                                    <div className="d-flex w-100 justify-content-between mt-3" style={{padding:20, border:'1px solid #ccc'}}>
                                        <label htmlFor="linkedin_link" className='d-flex flex-start gap-5 linkedin-fill'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg> LinkedIn
                                        </label>

                                        {this.props.user && this.props.user.linkedin_id == ''
                                            ?
                                                <div>

                                                    {this.state.ln_login
                                                        ?
                                                            <div style={{position:'relative', width:200, height:50}}>
                                                                <Loader is_small={true} />
                                                            </div>
                                                        :
                                                            <LinkedIn
                                                                clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                                                                onFailure={(error) => {

                                                                    this.props.onError("LinkedIn Authentication failed! Please try again after sometime.");
                                                                }}
                                                                onSuccess={(data) => {

                                                                    this.authenticateLinkedin(data)
                                                                }}
                                                                scope="r_emailaddress r_liteprofile"
                                                                redirectUri={`${Api.url}linkedin`}
                                                            >
                                                                {({ linkedInLogin }) => (
                                                                    <div
                                                                        className="btn-default btn-linked-in text-center"
                                                                        onClick={linkedInLogin}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>Connect with Linkedin
                                                                    </div>
                                                                )}
                                                            </LinkedIn>
                                                    }

                                                </div>
                                            :
                                                <div>
                                                    <div>Connected</div>
                                                </div>
                                        }
                                    </div>
                                </Form>
                            </div>
                        </Col>

                        <Col span={24}>

                            <div className="d-flex justify-content-end mt-20">
                                <Button className="btn ml-10 mt-2" type="primary" htmlType="button" onClick={() => {

                                    this.props.jumpToNext();
                                }}>
                                    Continue
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    authenticateLinkedin = (code) => {

        var formData = new FormData();

        formData.append("key", code);
            
        var that = this;

        this.setState({ln_login: true});

        Api.post('freelancer/connect_linkedin', formData, function(data){
            
            that.setState({ln_login: false});

            if(data.status){
            
                that.props.onSuccess(data.user, data.message);
            }else{
                
                if(data.code){
                
                    that.props.onError(data.message, data.code, data.user_code);
                }else{

                    that.props.onError(data.message, false, false);
                }
            }
        });
    }
}

export default SocialAccounts;