import React, { Component } from 'react';

import { Row, Col, Divider, Form, Select, List, Button, Checkbox, Switch, Slider } from 'antd';

import ProjectRow from 'components/projects/ProjectRow';

import FeatherIcon from 'feather-icons-react';

import Main from 'components/Main';
import PageSubHeader from 'components/PageSubHeader';

import Api from 'api/Api';

class ProjectsSearchPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: false,

            loading: false,
            current_page: 1,

            projects: [],
            total: 0,
            per_page: 0,
            total_pages:0,

            // show_filters: false,

            search_keyword: '',
            search_category: '',

            checkedCategories: [],
            checkedIndustries: [],
            checkedSkills: [],
            checkedTools: [],
            checkedTypes: [],
            budgetTypeChange: false,
            budgetType: 'hourly',


            categories: [],
            industry: [],
			skills: [],
			tools: [],
            project_types: {}
        }
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }

        if(user){
            user = JSON.parse(user);
            this.setState({user: user});
        }

        this.loadData();

        if(this.props.keyword){
            this.setState({
                search_keyword: this.props.keyword
            }, () => {
                this.loadProjects(this.state.current_page, false, this.props.keyword);
            });
        }else{
            this.loadProjects(this.state.current_page, false, false);
        }
    }

    loadData = () => {

        var formData = new FormData();
        formData.append('slug', 'projects');

        var that = this;

        Api.post('general/initial', formData, function(data){
            
            that.setState({
                // logos: data.logos,
                // banners: data.banners,

                categories: data.all_categories,
                industry: data.industry,
			    skills: data.skills,
			    tools: data.tools,
                project_types: data.project_types
            });
        });
    }

    loadProjects = (page, values, keyword, category) => {

        var formData = new FormData();
        formData.append('page', page);

        var account_token = localStorage.getItem('account_token');
        if(account_token){
            formData.append('account_token', account_token);
        }

        if(keyword){
            formData.append('keyword', keyword);
        }else if(this.props.keyword){
            formData.append('keyword', this.props.keyword);
        }

        if(category){
            formData.append('category_id', category);
        }

        if(values){
            Object.keys(values).forEach((key) => {

                if(values[key]){
                    formData.append(key, values[key]);
                }
            });
        }

        if(this.state.checkedCategories.length > 0){
            formData.append('category_ids', this.state.checkedCategories);
        }

        if(this.state.checkedIndustries.length > 0){
            formData.append('industry_ids', this.state.checkedIndustries);
        }

        if(this.state.checkedSkills.length > 0){
            formData.append('skill_ids', this.state.checkedSkills);
        }

        if(this.state.checkedTools.length > 0){
            formData.append('tool_ids', this.state.checkedTools);
        }

        if(this.state.checkedTypes.length > 0){
            formData.append('types', this.state.checkedTypes);
        }

        if(this.state.budgetTypeChange != ''){
            formData.append('budgetType', this.state.budgetType);
        }

        var that = this;
        this.setState({loading: true});

        Api.post('projects/listing', formData, function(data){
            
            that.setState({loading: false});

            that.setState({
                projects: data.results,
                total: data.total,
                per_page: data.per_page,
                current_page: data.page
            });
        });
    }

    applyFilters = (values) => {

        this.loadProjects(1, values, this.state.search_keyword, this.state.search_category);
    }

    submitSearch = (values) => {

        this.setState({current_page: 1});
        this.loadProjects(1, false, this.state.search_keyword, this.state.search_category);
    }

    render () {

        return (

            <Main page_title="ProductsSearchPage" hide_page_title={true} full_content={true} keyword={this.props.keyword}>
                <div className="projects-search-section inner-page pt-0 listing-page"> {/*white-bg*/}
    
                    <PageSubHeader title="Find your right project according to your skills" />

                    <div className="container">

                        <Form onFinish={this.submitSearch.bind(this)} className="search-frm">
                            <div className="search-block">
                                <Row className="search-row" gutter={16}>
                                    <Col className="gutter-row" span={6}>
                                        <Select className="filter-dropdown" name="category_id" placeholder="Category" onChange={this.onCategoryChange.bind(this)}>
                                            {/* <option value="">Category</option> */}
                                            <Select.Option value="">Category</Select.Option>
                                            {this.state.categories && this.state.categories &&

                                                this.state.categories.map((category_item) => {

                                                    var key = 'category_item_' + category_item.id;
                                                    return (
                                                        <Select.Option key={key} value={category_item.id}>{category_item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        <input name="search" placeholder="Search for projects by Skills / Tools / Domains" value={this.state.search_keyword} onChange={(t) => {
                                            this.setState({search_keyword: t.target.value});
                                            this.onKeywordChange.bind(this);
                                        }} />

                                        <button type="button" className="search-button" onClick={this.submitSearch.bind(this)}>
                                            <FeatherIcon icon="arrow-right" className="search-btn-icon" />
                                        </button>
                                    </Col>
                                </Row>
                                
                            </div>
                        </Form>
                    </div>

                    <div className="container mt-50">

                        <Row gutter={16}>
                            <Col className="gutter-row" span={17}>
                                <div className="listing-section px-20">
                                    <div className="top-filters d-flex justify-content-between sort-by-filter">
                                        <div className="search-count">{this.state.total} Projects Found</div>

                                        <Select size={this.state.sizeLarge} placeholder="Sort By" style={{ width: 150 }}>
                                            <Select.Option value="">Sort By</Select.Option>
                                            <Select.Option value="Latest">Latest</Select.Option>
                                            <Select.Option value="Budget">Budget</Select.Option>
                                            <Select.Option value="Ending Soon">Ending Soon</Select.Option>
                                        </Select>
                                    </div>

                                    <div className="listing">
                                        
                                        <List
                                            // header={this.header()}
                                            className="projects-list"
                                            itemLayout="horizontal"
                                            size="large"
                                            loading={this.state.loading}
                                            pagination={{
                                                onChange: page => {
                                                    // this.props.onLoad();
                                                    window.setTimeout(() => {
                                                        this.loadProjects(page, false, false);
                                                    }, 200);
                                                },
                                                pageSize: this.state.per_page,
                                                total: this.state.total,
                                                size: "small",
                                                // itemRender: this.pagingItemRender
                                            }}
                                            dataSource={this.state.projects}
                                            renderItem={item => (
                                                <ProjectRow
                                                    project={item}
                                                    user={this.state.user}
                                                />
                                            )}
                                        />
                                    </div>

                                </div>
                            </Col>

                            <Col className="gutter-row" span={7}>
                                <div className="right-section white-bg">

                                    {this.filterContainer()}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                
            </Main>
            
        )
    }

    filterContainer = () => {

        return (

            <div className="filters">

                <div className="header">
                    <div className="main-title">Filter by</div>
                </div>
                <div className="body">

                    <Form ref="filter_form_ref" labelCol={{span: 24}} wrapperCol={{span: 24}} name="projects_filter" onFinish={this.applyFilters.bind(this)}>

                        <div className="filter-heading">Category</div>
                        <div className="filter-list category">
                            <div className="single">
                                {this.state.categories && this.state.categories.length &&

                                    this.state.categories.map((category_item) => {

                                        var key = 'category_item_' + category_item.id;
                                        return (
                                            <Checkbox key={key} value={category_item.id} /*checked={this.state.checkedCategories.get(category_item.id)}*/ onChange={this.onFilterCategoriesChange.bind(this)}>
                                                {category_item.name}
                                                {/* <span className="num">30,789</span> */}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="filter-heading">Domains</div>
                        <div className="filter-list domains">
                            <div className="single">
                                {this.state.industry && this.state.industry.length &&

                                    this.state.industry.map((industry_item) => {

                                        var key = 'industry_item_' + industry_item.id;
                                        return (
                                            <Checkbox key={key} value={industry_item.id} onChange={this.onFilterIndustriesChange.bind(this)}>
                                                {industry_item.label}
                                                {/* <span className="num">30,789</span> */}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="filter-heading">Skills</div>
                        <div className="filter-list skills">
                            <div className="single">
                                {this.state.skills && this.state.skills.length &&

                                    this.state.skills.map((skill_item) => {

                                        var key = 'skill_item_' + skill_item.id;
                                        return (
                                            <Checkbox key={key} value={skill_item.id} onChange={this.onFilterSkillsChange.bind(this)}>
                                                {skill_item.label}
                                                {/* <span className="num">30,789</span> */}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="filter-heading">Tools</div>
                        <div className="filter-list tools">
                            <div className="single">
                                {this.state.tools && this.state.tools.length &&

                                    this.state.tools.map((tool_item) => {

                                        var key = 'tool_item_' + tool_item.id;
                                        return (
                                            <Checkbox key={key} value={tool_item.id} onChange={this.onFilterToolsChange.bind(this)}>
                                                {tool_item.label}
                                                {/* <span className="num">30,789</span> */}
                                            </Checkbox>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="filter-heading">Budget</div>
                        <input type="hidden" name="budget_type" value={this.state.budget_type} />
                        <div className="filter-list budget">
                            <span className="dull">Fixed Budget</span>
                            <Switch /*defaultChecked */size="small" onChange={this.onFilterBudgetTypeChange.bind(this)} />
                            <span className="colored">Hourly</span>
                        </div>

                        <div className="filter-heading">Type</div>
                        <div className="filter-list type">
                            <div className="single">
                                {
                                this.state.project_types && this.state.project_types.length &&
                                    
                                    this.state.project_types.map((type_item) => {

                                        var key = 'type_item_' + type_item.key;
                                        return (
                                            <Checkbox key={key} value={type_item.key} onChange={this.onFilterTypesChange.bind(this)}>
                                                {type_item.value}
                                            </Checkbox>
                                        )
                                    })
                                }

                            </div>
                        </div>

                        <Divider>Or set a range:</Divider>
                        <div className="filter-row slider">
                            <Form.Item label="" name="price_range" rules={[{required:false}]}>
                                <Slider
                                    range
                                    defaultValue={[0, 1000]}
                                    tooltipVisible={true}
                                    tooltipPlacement="top"
                                    min={1}
                                    max={1000}
                                    marks={{
                                        1: '1',
                                        1000: '1000+'
                                    }}
                                    // onChange={this.onPriceRangeChange.bind(this)}
                                />
                            </Form.Item>
                        </div>

                        <div className="filter-row mt-1">
                            <div className="d-flex justify-content-between">
                                <Form.Item>
                                    <Button className="btn purple solid btn-sm" type="submit" htmlType="submit">Filter</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button className="btn outlined purple btn-sm" type="button" htmlType="button" onClick={() => {
                                        this.refs.filter_form_ref.resetFields();
                                    }}>Reset</Button>
                                </Form.Item>
                            </div>
                        </div>
                        {/* <div className="button">
                            <Button type="primary" className="dark-blue-btn">Set a Range</Button>
                        </div> */}
                    </Form>
                </div>
            </div>
        )
    }

    onCategoryChange(value) {
    
        this.setState({
            search_category: value
        });
    }

    onFilterCategoriesChange(e){
        let newArray = [...this.state.checkedCategories, e.target.value];
        if (this.state.checkedCategories.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedCategories: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterIndustriesChange(e){
        let newArray = [...this.state.checkedIndustries, e.target.value];
        if (this.state.checkedIndustries.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedIndustries: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterSkillsChange(e){
        let newArray = [...this.state.checkedSkills, e.target.value];
        if (this.state.checkedSkills.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedSkills: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterToolsChange(e){
        let newArray = [...this.state.checkedTools, e.target.value];
        if (this.state.checkedTools.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedTools: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onFilterBudgetTypeChange(e){
        var isChecked = e;
        if(isChecked){
            this.setState({budgetType: 'hourly'});
        }else{
            this.setState({budgetType: 'fixed'});
        }
        this.setState({budgetTypeChange: true});

        this.refs.filter_form_ref.submit();
    }

    onFilterTypesChange(e){
        let newArray = [...this.state.checkedTypes, e.target.value];
        if (this.state.checkedTypes.includes(e.target.value)) {
            newArray = newArray.filter(_category => _category !== e.target.value);
        } 
        this.setState({
            checkedTypes: newArray
        });

        this.refs.filter_form_ref.submit();
    }

    onKeywordChange(value) {
    
        this.setState({
            search_keyword: value
        });
    }
}

export default ProjectsSearchPage;