import React, { Component } from 'react';

import { Carousel, Empty, Typography, Button } from 'antd';
import { Tabs } from 'antd';
import { Link } from "react-router-dom";

import FreelancerRow from '../../freelancer/FreelancerRow';
import SingleProduct from '../../products/FreelancerProductBlock';

import Price from 'components/Price';

const { TabPane } = Tabs;

class SPProjectsTabs extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    renderSpBlocks = () => {

        const spBlocksList = [];

        for(let i = 0; i < this.props.sp_tab_blocks.length; i++) {
        
            let key = 'spt_'+i;
        
            spBlocksList.push(

                <>
                    <FreelancerRow key={key} freelancer={this.props.sp_tab_blocks[i]} />
                </>
            );
        }
        return spBlocksList;
    }

    renderProjectBlocks = () => {

        const projectBlocksList = [];

        for(let i = 0; i < this.props.project_tab_blocks.length; i++) {

            let key = 'pt_'+i;

            projectBlocksList.push(
                
                <div className="single-project" key={key}>
                    <div className="top-block">
                        <div className="title">{this.props.project_tab_blocks[i].title}</div>
                        <div className="added-on">{this.props.project_tab_blocks[i].posted}</div>
                        {(this.props.project_tab_blocks[i].featured == 1) && this.props.project_tab_blocks[i].featured_text
                            ?
                                <div className="tags">
                                    <span className="tag">{this.props.project_tab_blocks[i].featured_text}</span>
                                </div>
                            :
                                null
                        }
                        
                        {/* <div className="text">{this.props.project_tab_blocks[i].text}</div> */}

                        <Typography.Paragraph
                            ellipsis={{rows: 3}}
                        >
                            {this.props.project_tab_blocks[i].text}
                        </Typography.Paragraph>

                        <div className="links">
                            <span><i className="ico heart"></i><span className="num">{this.props.project_tab_blocks[i].likes_count}</span></span>
                            <span><i className="ico email"></i><span className="num">{this.props.project_tab_blocks[i].proposals_count} proposal</span></span>
                        </div>
                    </div>
                    <div className="bottom-block">
                        <div className="price-block">
                            <Price>{this.props.project_tab_blocks[i].price}</Price>
                        </div>

                        <Link to={this.props.project_tab_blocks[i].button_link} className="btn btn-default">{this.props.project_tab_blocks[i].button_text}</Link>
                    </div>
                </div>
            );
        }
        return projectBlocksList;
    }

    renderProductBlocks = () => {

        const productBlocksList = [];

        for(let i = 0; i < this.props.products_list.length; i++) {
        
            let key = 'prt_'+i;

            productBlocksList.push(

                <SingleProduct key={'active_' + key} product={this.props.products_list[i]} />
            );
        }
        return productBlocksList;
    }


    render () {
        const props = {
            arrows: true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };

        const product_props = {
            arrows: true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]

        };

        return (

            <div className="sp-projects">
                <div className="container">
                    <div className="sp-projects-block">
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane tab="Service Providers" key="1">
                                {this.props.sp_tab_blocks && this.props.sp_tab_blocks.length > 0
                                    ?
                                        
                                        <div className="projects-listing">
                                            <Carousel ref={node => (this.carousel = node)} {...props}>
                                                {this.renderSpBlocks()}
                                            </Carousel>

                                            <div className="justify-center mt-25" style={{width:'100%'}}>
                                                <Link className="btn btn-fill" to="/freelancers-search">
                                                    <Button type="primary">View All</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    :
                                        <Empty />
                                }
                            </TabPane>

                            <TabPane tab="Projects" key="2">
                                {this.props.project_tab_blocks && this.props.project_tab_blocks.length > 0
                                    ?
                                        <div className="projects-listing">
                                            <Carousel ref={node => (this.carousel = node)} {...props}>
                                                {this.renderProjectBlocks()}
                                            </Carousel>

                                            <div className="justify-center mt-25" style={{width:'100%'}}>
                                                <Link className="btn btn-fill" to="/projects-search">
                                                    <Button type="primary">View All</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    :
                                        <Empty />
                                }
                            </TabPane>

                            <TabPane tab="Products/Offers" key="3">
                                {this.props.products_list && this.props.products_list.length > 0
                                    ?
                                        <div className="products-listing products_offers">
                                            <Carousel ref={node => (this.carousel = node)} {...product_props}>
                                                {this.renderProductBlocks()}
                                            </Carousel>

                                            <div className="justify-center mt-25" style={{width:'100%'}}>
                                                <Link className="btn btn-fill" to="/products-search">
                                                    <Button type="primary">View All</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    :
                                        <Empty />
                                }
                            </TabPane>
                        </Tabs>
                        
                    </div>

                </div>
            </div>
        )
    }
}

export default SPProjectsTabs;