import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class Logoutpage extends Component { 
    constructor(props) {
        super();
        this.state = {
            login: true,
        }
    }

    componentDidMount = () => {
        localStorage.removeItem('account_token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_id');

        window.setTimeout(() => {

            this.setState({login: false});
        }, 500);
        
    }

    render () {
        if(this.state.login !== true){
            return <Redirect to='/'/>;
        }

        return (

            <>
                
            </>
        )
    }
}

export default Logoutpage;