import React, { Component } from 'react';

import { Carousel  } from 'antd';
import { Link } from "react-router-dom";

import Api from '../../api/Api';

import Main from '../../components/Main';

class OurStoryPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            main_heading: '',
            main_content: '',
            slides_data: [],
            heading_2: '',
        }

        this.page = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderSlides = () => {

        const slidesList = [];
        for(let i = 0; i < this.state.slides_data.length; i++) {
            let key = 'team_slide_'+i;
            slidesList.push(
                <div className="card d-flex d-flex-column align-center single-member" key={key}>
                    <div className="img-block relative mt--125">
                        <img className="user-pic" src={this.state.slides_data[i].profile_pic_url} alt={this.state.slides_data[i].name} />
                        {(this.state.slides_data[i].social_img != '' && this.state.slides_data[i].social_link != '')
                            ?
                                <Link to={{ pathname: this.state.slides_data[i].social_link }} target="_blank" className="absolute-bottom-right round">
                                    <img className="social-img" src={this.state.slides_data[i].social_img} alt={this.state.slides_data[i].social_link_type} />
                                </Link>
                            :
                                null
                        }
                    </div>
                    <div className="text-center text-block">
                        <div className="c-b fs-22 fw-600 name">{this.state.slides_data[i].name}</div>
                        <div className="fs-15 mb-20 relative line-after designation">{this.state.slides_data[i].designation}</div>
                        <div className="text ov-hidden c-dg2 fs-16">
                            <div dangerouslySetInnerHTML={{ __html: this.state.slides_data[i].text }} />
                        </div>

                    </div>
                </div>
            );
        }
        return slidesList;
    }




    render () {

        const props = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
        
        };

        return (

            <Main full_content={true}>
                <div className="inner-page white-bg">
                    <div className="container">
                        <div className="heading text-center mb-20">{this.state.main_heading}</div>
                        <div className="text-center fs-18" dangerouslySetInnerHTML={{ __html: this.state.main_content }} />

                        <div className="heading text-center mt-75 mb-20">{this.state.heading_2}</div>
                        <Carousel className="blk-slider ptb our-team about" ref={node => (this.carousel = node)} {...props}>
                            {this.renderSlides()}
                        </Carousel>
                    </div>
                </div>
            </Main>
        )
    }


    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/our_story', formData, function(data){
            
            that.setState({
                main_heading: data.main_heading,
                main_content: data.main_content,
                heading_2: data.heading_2,
            });
        });

        Api.post('cms/about', formData, function(data){
            
            that.setState({
                slides_data: data.slides_data
            });
        });
       
    }
}

export default OurStoryPage;