import React, { Component } from 'react';

import { List, Button} from 'antd';

import Api from '../../api/Api';
import Main from '../../components/Main';

import PageSubHeader from '../../components/PageSubHeader';

class PricesPage extends Component { 
    constructor(props) {
        super();
        this.state = {
            main_banner: '',
            main_heading: '',
            main_heading_text: '',

            prices_heading: '',
            prices_content: '',
            prices_data: [],

            packages_heading: '',
            packages_content: '',
            packages_data: []
        }

        this.page = React.createRef();
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }


    renderPrices = () => {

        const pricesList = [];
        for(let i = 0; i < this.state.prices_data.length; i++) {
            let key = 'price_'+i;

            var _class = "block w-23";
            if(i == 0){
                _class = _class+' mt-100';
            }else if(i == 1){
                _class = _class+' mt-50';
            }
            pricesList.push(
                <div className={_class} key={key}>
                    <div className="bg-w br-10 d-flex-column align-center justify-center mb-20 img-block">
                        <img className="img-fluid" src={this.state.prices_data[i].image_url} alt={this.state.prices_data[i].image_alt} />
                    </div>
                    <div className="c-b fs-24 fw-600 mb-10">{this.state.prices_data[i].title}</div>
                    <div className="c-dg2 fs-16" dangerouslySetInnerHTML={{ __html: this.state.prices_data[i].text }} />
                </div>
            );
        }
        return pricesList;
    }


    renderPackages = () => {

        const packagesList = [];
        for(let i = 0; i < this.state.packages_data.length; i++) {
            let key = 'package_'+i;

            var main_classes = 'single-package w-23 ';
            var top_classes = 'top-block bordered bc-ccc br-5 h-205p px-20 py-40 relative text-center ';
            var type_classes = 'package-type fs-24 fw-500 txt-uppercase ';
            var price_classes = 'package-price fs-30 fw-600 ';
            var pay_classes = 'pay-per-month fs-16 ';

            if(this.state.packages_data[i].active == '1'){

                main_classes += 'active';
                top_classes += 'bg-brown';
                type_classes += 'c-w';
                price_classes += 'c-w ';
                pay_classes += 'c-w ';
            }else{

                top_classes += 'bg-w';
                type_classes += 'c-brown';
                price_classes += 'c-b ';
                pay_classes += 'c-lb ';
            }

            packagesList.push(
                
                <div className={main_classes} key={key}>
                    <div className={top_classes}>
                        {this.state.packages_data[i].highlight_text
                            ?
                                <div className="highlight-text bg-o c-w fs-16 absolute px-10 py-5 br-5">{this.state.packages_data[i].highlight_text}</div>
                            :
                                null
                        }
                        <div className={type_classes}>{this.state.packages_data[i].type}</div>
                        <div className={price_classes}>{this.state.packages_data[i].price}</div>
                        {this.state.packages_data[i].per_month
                            ?
                                <div className={pay_classes}>{this.state.packages_data[i].per_month}</div>
                            :
                                null
                        }
                    </div>
                    <div className="bottom-block bg-w bordered bc-ccc br-5 h-320p relative mt-20 p-30">
                        <List itemLayout="horizontal" dataSource={this.state.packages_data[i].points}
                            renderItem={item => (
                                <List.Item className="align-start mb-5 pb-0 pt-0">
                                    <List.Item.Meta className="mr-5" avatar={<i className="mat-ico">done</i>} />
                                    <div className="c-lb fw-400">{item}</div>
                                </List.Item>
                            )}
                        />

                        <div className="text-center">
                            <Button type="primary" className="btn bg-b d-inline fs-16 fw-500 px-25 py-10 txt-capitalize w-auto" block>{this.state.packages_data[i].button_title}</Button>
                        </div>
                    </div>
                </div>
            );
        }
        return packagesList;
    }

    render () {

        return (

            <Main page_title="Prices" hide_page_title="true">

                <PageSubHeader title={this.state.main_heading} sub_title={this.state.main_heading_text} custom_classes="mb-0" />

                <div className="prices-top-section bg-w pb-50 pt-50 h-auto relative w-100v">
                    <div className="container relative index-100">
                        <div className="c-b fs-30 fw-600 mb-15">{this.state.prices_heading}</div>
                        <div className="c-dg2 fs-18" dangerouslySetInnerHTML={{ __html: this.state.prices_content }} />

                        <div className="blocks d-flex justify-content-between mt-50">
                            {this.renderPrices()}
                        </div>
                    </div>
                </div>
                
                <div className="prices-bottom-section bg-lg pt-50 pb-80">
                    <div className="container">
                        <div className="c-b fs-40 fw-b mb-10">{this.state.packages_heading}</div>
                        <div className="text c-dg2 fs-20" dangerouslySetInnerHTML={{ __html: this.state.packages_content }} />

                        <div className="packages d-flex justify-content-between mt-50">
                            
                            {this.renderPackages()}

                        </div>
                    </div>
                </div>
                
            </Main>
        )
    }

    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('cms/prices', formData, function(data){
            
            that.setState({
                main_banner: data.main_banner,
                main_heading: data.main_heading,
                main_heading_text: data.main_heading_text,

                prices_heading: data.prices_heading,
                prices_content: data.prices_content,
                prices_data: data.prices_data,
                
                packages_heading: data.packages_heading,
                packages_content: data.packages_content,
                packages_data: data.packages_data
            });
        });
    }
}

export default PricesPage;