
import React from 'react';

export default function PageSubHeader( props ){

    var main_classes = 'banner-block orange-bg-img';
    
    if(props.custom_classes && props.custom_classes != ''){
        
        main_classes = main_classes + ' ' + props.custom_classes;
    }else{
        main_classes = main_classes + ' mb-50';
    }

    return (

        <div className={main_classes}>
            <div className="container">
                <div className="c-w fs-35 fw-b mb-0">{props.title}</div>

                {props.sub_title &&
                
                    <div className="fs-20 mt-5" dangerouslySetInnerHTML={{ __html: props.sub_title }} />
                }
            </div>
        </div>
    )
}