import React, { Component } from 'react';

import { Modal } from 'antd';

import { FilePdfFilled } from '@ant-design/icons';

class ProjctItemsGallery extends Component { 
    constructor(props) {
        super();
        this.state = {

            gallery: [],

            show_gallery: false,
            file: ''
        }
    }

    componentDidMount = () => {

        this.setState({gallery: []});
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.gallery && this.props.gallery !== this.state.gallery){

            this.setState({gallery: this.props.gallery})
        }
    }

    render () {

        return (

            <div className="projects-details inner-page pt-2">

                <ul>
                    {this.renderGallery()}
                </ul>

                <Modal
                    visible={this.state.show_gallery}
                    width={'70%'}
                    onCancel={() => {

                        this.setState({show_gallery: ''})
                    }}
                    footer={null}
                >
                    <div className="align-center">
                        
                        {this.state.file !== false
                            ?
                                <>
                                    {(this.state.file.ext === 'png' || this.state.file.ext === 'jpg' || this.state.file.ext  === 'jpeg' || this.state.file.ext  === 'webp')
                                        ?
                                            <img className="preview-image" src={this.state.file.thumb} />
                                        :
                                            <a className='align-center vertical' href={this.state.file.url} target="_blank">
                                                
                                                <FilePdfFilled className="fs-40 c-b" />

                                                <strong className='fs-10 mt-5 txt-uppercase'>{this.state.file.ext}</strong>
                                            </a>
                                    }
                                </>
                            :
                                null
                        }
                    </div>
                </Modal>
            </div>
        )
    }

    renderGallery = () => {

        if(this.state.gallery.length > 0){
        
            return this.state.gallery.map((_attachment, index) => {

                return (

                    <li key={"attachment_" + index}>

                        {(_attachment.ext === 'png' || _attachment.ext === 'jpg' || _attachment.ext  === 'jpeg' || _attachment.ext  === 'webp')
                            ?
                        
                                <div onClick={() => {

                                    this.setState({file: _attachment}, () => {
            
                                        this.setState({show_gallery: true})
                                    })
                                }}>
                                    
                                    <img className="preview-image" src={_attachment.thumb} />
                                </div>
                            :
                                <a className='align-center vertical' href={_attachment.url} target="_blank">
                                    
                                    <FilePdfFilled className="fs-40 c-b" />

                                    <strong className='fs-10 mt-5 txt-uppercase'>{_attachment.ext}</strong>
                                </a>
                        }
                    </li>
                )
            })
        }
    }
}

export default ProjctItemsGallery;