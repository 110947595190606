import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, Input, Button, Checkbox, Alert } from 'antd';

import FeatherIcon from 'feather-icons-react';

import { connect } from 'react-redux';
import {User} from '../../actions/user';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

// import ValidatorHelper from '../../helpers/ValidatorHelper';
import LayoutHelper from '../../helpers/LayoutHelper';

import Main from '../../components/Main';

class ForgotPassword extends Component { 
    constructor(props) {
        super();
        this.state = {

            dashboard: false,
            login: false,

            loading: false,

            error_message: '',
            success_message: '',

            message: '',

            email: '',
            
            email_error: '',
            
            status_error: '',
            status_success: '',

            user: false,
            user_token: false,

            redirect: false,
            logout: false
        }
    }

    componentDidMount = () => {

        var user_token = localStorage.getItem('user_token');

        if(user_token){

            this.setState({redirect: true})
        }
    }

    render () {

        if(this.state.redirect){
            return <Redirect to={'/pending-verification/' + this.state.user.unq_index_id} />;
        }

        if(this.state.logout){
            return <Redirect to="/logout" />
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
            }
        };

        const onFinish = values => {
            var formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]))
            
            var that = this;

            Api.post('auth/forgot_password', formData, function(data){
                
                if(data.status == true){
                
                    that.setState({loading: false});
                    that.setState({message: data.message});
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({error_message: false});
                }, 10000);
            });
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        return (

            <Main page="forgot_password" className="login-main-page">
                <div className="container">
                    <div className="login-page d-flex items-end">
                        <div className="right half-container self-right auth-block" style={{position:'relative'}}>

                            <div className="section-heading">Forgot Password</div>

                            {/* using if statement */}
                            {this.state.message &&
                                <Alert message="Success" description={this.state.message} type="success" closable />
                            }

                            {this.state.error_message &&
                                <Alert message="Error" description={this.state.error_message} type="error" closable />
                            }
                            
                            <Form {...layout} name="login_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                
                                <Form.Item label="Email" name="email" rules={[{required:true, message: 'Please enter your email'}, {type: 'email', message: 'Please enter valid email address'}]}>
                                    <Input placeholder="Email address" />
                                </Form.Item>
                                
                                <Form.Item {...tailLayout} className="text-center">
                                    <Button type="primary" size="large" htmlType="submit" className="btn-orange btn-wide">Submit</Button>
                                </Form.Item>
                            </Form>

                            <div className="text-center signup-link">
                                <Link to="/login" className="pink-txt">Back to login</Link>
                            </div>

                            {/* using if statement */}
                            {this.state.loading &&
                                <Loader />
                            }
                        </div>
                    </div>
                </div>
            </Main>
        )
    }

    loginSubmit = (values) => {

        const formData = new FormData();

        Object.keys(values).forEach(key => formData.append(key, values[key]))

        var that = this;
        that.setState({loading: true});
    
        Api.post('forgot_password', formData, function(data){
        
            that.setState({loading: false, email: ''});

            if(data.status == true){
                
                LayoutHelper.addSuccessMessage(that, data.message);
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(ForgotPassword);
