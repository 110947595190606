import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Layout } from 'antd';

import Api from '../api/Api';

class AppFooter extends Component { 
    constructor(props) {
        super();
        this.state = {
            first_section_heading: '',
            first_sec_categories: [],

            second_section_heading: '',
            second_sec_categories: [],

            third_section_heading: '',
            third_sec_categories: [],

            fourth_section_heading: '',
            fourth_sec_categories: [],

            social_links: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

        this.loadData();
    }

    renderCategoryLinks = (list, slug) => {

        const linksList = [];
        for(let i = 0; i < list.length; i++) {
            let key = 'c_'+i;
            let link = slug+list[i].link;

            linksList.push(
                <li key={key}><a href={link}>{list[i].text}</a></li>
                // <li key={key}><Link to={link}>{list[i].text}</Link></li>
            );
        }
        return linksList;
    }
    
    loadData = () => {

        const formData = new FormData();
        var that = this;

        Api.post('common/footer', formData, function(data){
            
            that.props.updateCategories(data.all_categories);

            that.setState({
                first_section_heading: data.first_section_heading,
                first_sec_categories: data.first_sec_categories,

                second_section_heading: data.second_section_heading,
                second_sec_categories: data.second_sec_categories,

                third_section_heading: data.third_section_heading,
                third_sec_categories: data.third_sec_categories,

                fourth_section_heading: data.fourth_section_heading,
                fourth_sec_categories: data.fourth_sec_categories,

                social_links: data.social_links
            });
        });
    }

    render () {

        return (

            <Layout.Footer>
                <div className="top-block">
                    <div className="container">
                        <div className="footer-block">
                            <div className="block">
                                <div className="heading">Solutions</div>
                                <ul className="list-inline">
                                    <li>
                                        <Link to="/">Managed Gig Hiring</Link>
                                    </li>
                                    <li>
                                        <Link to="/marketplace">Service Marketplace</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">Managed Gig Hiring</div>
                                <ul className="list-inline">

                                    <li>
                                        <Link to="/">Client</Link>
                                    </li>
                                    <li>
                                        <Link to="/service-partners">Service Partner</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">Service Marketplace</div>
                                <ul className="list-inline">

                                    <li>
                                        <a href='/category/customer-analytics'>Customer Analytics</a>
                                    </li>
                                    <li>
                                        <a href='/category/operations-analytics'>Operations Analytics</a>
                                    </li>
                                    <li>
                                        <a href='/category/marketing-analytics'>Marketing Analytics</a>
                                    </li>
                                    <li>
                                        <a href='/category/risk-fraud-analytics'>Risk &amp; Fraud Analytics</a>
                                    </li>
                                    <li>
                                        <a href='/category/retail-analytics'>Retail Analytics</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">About</div>
                                <ul className="list-inline">
                                     <li>
                                        <Link to="/about">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/blog">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-of-service">Terms Of Service</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/trust-safety">Trust &amp; Safety</Link>
                                    </li>
                                    <li>
                                        <Link to="/nda">NDA</Link>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="copyright">
                            <div className="block d-flex">
                                <div className="left">
                                    <ul className="list-inline d-flex">

                                        <li>
                                            <a href="https://www.facebook.com/cogniticx/" target="_blank"><i className="ico facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/cogniticx" target="_blank"><i className="ico twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company-beta/13291829/" target="_blank"><i className="ico linkedin"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCF7WzsUKd0MGiEzLd9bcCsg/" target="_blank"><i className="ico youtube"></i></a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="right">
                                    <div className="text">&copy; Cogniticx. ALL RIGHTS RESERVED. 2020</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout.Footer>
        )
    }
}

export default AppFooter;