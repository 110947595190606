import React, { Component } from 'react';

import { Popconfirm, Button } from 'antd';

import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, PaymentElement} from '@stripe/react-stripe-js';

class CardForm extends Component {

    constructor(props) {
        super();
        this.state = {

            error: '',

            loading: false,

            cancel: false
        }
    }

    handleSubmit = async(event) => {

        event.preventDefault();

        const {stripe, elements} = this.props;

        var that = this;

        this.setState({loading: true})

        this.props.paymentProcessing(true);
        try{
        
            const result = await stripe.confirmCardPayment(this.props.secret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            });

            if (result.error) {

                that.setState({loading: false})

                that.props.onError(result.error.message);
                that.props.paymentProcessing(false);
            } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    
                    that.props.onSuccess(result);
                }
                that.props.paymentProcessing(false);
            }

        } catch (e) {

            that.props.onError("There was an error while processing your request! Please refresh the page and try again.");
        }
    }
    
    render() {
    
        const {stripe} = this.props;
        return (
          
            <form onSubmit={this.handleSubmit}>
                
                {this.props.secret &&

                    <div className="cc-form-container">
                        <div className="cc-form">
                            <CardElement />
                        </div>
                        <div className="cc-form-actions">

                            <Popconfirm
                                title="Do you really want to cancel this payment?"
                                onConfirm={() => {

                                    this.props.cancel()
                                }}
                                onCancel={() => {

                                    this.setState({cancel: false})
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="link" onClick={(e) =>  {

                                    this.setState({cancel: true})
                                }} style={{marginRight:20}}>Cancel</Button>
                            </Popconfirm>

                            <Button type="primary" htmlType="submit" disabled={!stripe || this.state.loading}>Pay</Button>
                        </div>
                    </div>
                }
            </form>
        );
    }
}

export default CardForm;