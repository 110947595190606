import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

// add form components
import { Form, Input, Button, Checkbox, Alert } from 'antd';

import LoginBlock from './LoginBlock';

import FeatherIcon from 'feather-icons-react';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import Main from '../../components/Main';

class Loginpage extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            login: false,
            message: false,
            error_message: false,

            pending_verification: false
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){
            this.setState({login: true});
        }
    }

    render () {

        if(this.state.pending_verification){

            return <Redirect to={`/pending-verification/${this.state.pending_verification}`} />;
        }

        if(this.state.login){
        
            return <Redirect to='/account/dashboard'/>;
        }

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
              email: '${label} is not valid!',
              number: '${label} is not a valid number!',
            },
            number: {
              range: '${label} must be between ${min} and ${max}',
            },
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        return (

            <>
                <Main page="login" className="login-main-page">
                    <div className="container">
                        <div className="login-page d-flex items-end">
                            <div className="right half-container self-right auth-block" style={{position:'relative'}}>

                                <div className="section-heading">Login and Explore</div>

                                {/* using if statement */}
                                {this.state.message &&
                                    <Alert message="Success" description={this.state.message} type="success" closable />
                                }

                                {this.state.error_message &&
                                    <Alert message="Error" description={this.state.error_message} type="error" closable />
                                }

                                <LoginBlock
                                    onSuccess={(message) => {

                                        this.setState({login: true})
                                    }}
                                    onError={(message, code, user_code) => {

                                        this.setState({error_message: message})
                                    }}
                                />
                                
                                {/* <Form {...layout} name="login_form" initialValues={{ remember: true }} validateMessages={validateMessages} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                    
                                    <Form.Item label="Email" name="email" rules={[{required:true, message: 'Please enter your email'}, {type: 'email', message: 'Please enter valid email address'}]}>
                                        <Input placeholder="Email address" />
                                    </Form.Item>
                                    
                                    <Form.Item name="password" rules={[{required:true, message:'Please input your password!'}]}>
                                        <Input.Password placeholder="Password" />
                                    </Form.Item>
                                    
                                    <div className="remember-forgot d-flex justify-content-between">
                                        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                                            <Checkbox>Remember me</Checkbox>
                                        </Form.Item>
                                        <Link to="/forgot-password">Forgot Password</Link>
                                    </div>
                                    
                                    <Form.Item {...tailLayout} className="text-center">
                                        <Button type="primary" size="large" htmlType="submit" className="btn-orange btn-wide">Login</Button>
                                    </Form.Item>
                                </Form> */}
                            </div>
                        </div>
                    </div>
                </Main>
            </>
        )
    }
}

export default Loginpage;