import React, { Component } from 'react';

// add form components
import { Form, Input, InputNumber, Button, Select, Row, Col, DatePicker } from 'antd';

import { PlusCircleFilled, CalendarFilled } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';

import Api from '../../api/Api';

import moment from 'moment';

import Loader from '../../components/Loader';

const { Option } = Select;

class PersonalInfo extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: false,
            redirect: false,
            
            loading: false,
            image_uploading: false,
            
            profile_pic: '',
            profile_pic_small: '',
            profile_pic_medium: '',
            profile_preview_url: '',

            profile_banner: '',

            job_looking_for: false,
            current_job_status: false,

            total_selected_languages: 0,
            selected_languages: [],

            language_error: false,

            continue: false,
            
            file: '',

            user: false,

            user_info: {}
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        this.populateForm(_user);
        
        if(account_token){
            
            this.setState({account_token: account_token});
        }else{
            this.setState({redirect: true});
        }

        this.loadUser(account_token);
    }

    loadUser = (account_token) => {
        
        var formData = new FormData();
        formData.append('account_token', account_token);
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/get_details', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateUser(data.user);
                that.setState({user: data.user});
                localStorage.setItem('user', JSON.stringify(data.user));
                that.populateForm(data.user);
            }
        });
    }

    _handleImageChange = async (e, type) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        this.setState({image_uploading: true});
    
        reader.onloadend = () => {
            this.setState({
                updated: false,
                file: file,
                profile_preview_url: reader.result
            });
        }
    
        reader.readAsDataURL(file);

        var _sizes = {};
        _sizes['profile_pic_small'] = [100, 100];
        _sizes['profile_pic_medium'] = [300, 300];

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,webp');
        formData.append('upload_dir', 'freelancer/profile_pic/');

        if(type != 'profile_banner'){
            formData.append('image_sizes', JSON.stringify(_sizes));
        }

        this.setState({image_uploading: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({image_uploading: false});

            if(data.status == 'success'){

                that.updatePhoto(data.random_dir + data.main_file_name, type);
            }else{

                that.props.onError(data.message)
            }
        });
    }

    handleImageClick = (e) => {
        this.profilepicinput.click();
    }

    populateForm = (details) => {

        if(details.languages == ''){
            delete details.languages;
        }
        if(details.price == 0){
            delete details.price;
        }
        if(details.job_looking == 0){
            delete details.job_looking;
        }else{
            details.job_looking = parseInt(details.job_looking);
        }

        if(details.current_job_status == 0){
            delete details.current_job_status;
        }else{
            details.current_job_status = parseInt(details.current_job_status);
        }

        if(details.hourly_rate == 0){
            delete details.hourly_rate;
        }
        if(details.current_ctc == 0){
            delete details.current_ctc;
        }

        if(details.dob != '0000-00-00'){
            
            details.dob = moment(details.emp_from);
        }else{
            delete details.dob;
        }

        if(details.languages_formatted && details.languages_formatted.length > 0){

            this.setState({selected_languages: details.languages_formatted});
        }

        this.setState({current_job_status: details.current_job_status});

        details.country = details.country != '0' ? parseInt(details.country) : '';
        this.refs.update_profile_form.setFieldsValue(details);
    }

    onFinish = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.state.account_token);

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });

        var selected_languages = this.state.selected_languages;

        var has_language_error = false;

        if(selected_languages.length > 0){

            // for(var i in selected_languages){

            //     if(selected_languages[i]['language'] == '' || selected_languages[i]['proficiency'] == ''){

            //         has_language_error = true;
            //     }
            // }
        }else{

            has_language_error = true;
        }

        this.setState({language_error: has_language_error});
        if(has_language_error){
        
            return false;
        }

        formData.append('languages', JSON.stringify(selected_languages));
        
        var that = this;

        this.setState({loading: true});
        Api.post('freelancer/profile_update_personal_info', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateUser(data.user);
                that.props.onSuccess(data.message, that.state.continue);
                that.populateForm(data.user);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not valid!',
                number: '${label} is not a valid number!',
            },
            number: {
                range: '${label} must be between ${min} and ${max}',
            },
        };

        return (

            <div className="update-profile-page d-flex">
                    
                <div className="profile-wrapper">
                    <Row>
                        <Col span={24}>
                            
                            <div className="profile-main-wrapper">

                                <div className="page-heading">Personal Information</div>

                                <Form {...layout} scrollToFirstError={true} name="update_profile_form" ref="update_profile_form" validateMessages={validateMessages} onFinish={this.onFinish.bind(this)}>

                                    <div className="d-flex form-item-block profile-pic-box" style={{position:'relative'}}>
                                        
                                        <div className="w-50 picture">
                                            <label htmlFor="profile_pic">Profile Picture</label>
                                            <div className="profile-pic-bg" style={{cursor:'pointer'}} onClick={this.handleImageClick}>

                                                {this.state.user && this.state.user.profile_pic_full && this.state.user.profile_pic_full != ''
                                                    ?
                                                        <span className="profile-pic-sm" style={{backgroundImage:'url('+(this.state.user.profile_pic_full)+')'}}></span>
                                                    :
                                                        <div style={{position:'relative'}}>
                                                            <FeatherIcon icon="user" size={35} style={{margin:0, width:35, color:'rgba(0,0,0,.4'}} />
                                                            <PlusCircleFilled style={{color:'#ee7d22', position:'absolute', fontSize:18, bottom:-16}} />
                                                        </div>                                                            
                                                }
                                                <input type="file" className="hidden" id="profile_pic" value={this.state.profile_pic} onChange={(e) => {this._handleImageChange(e, 'photo')}} ref={(input)=> this.profilepicinput = input}/>
                                                <input type="hidden" className="hidden" id="profile_pic_small" value={this.state.profile_pic_small} />
                                                <input type="hidden" className="hidden" id="profile_pic_medium" value={this.state.profile_pic_medium} />
                                            </div>
                                        </div>
                                        <div className="w-50 picture">
                                            <label htmlFor="profile_banner">Profile Banner</label>
                                            <div className="profile-pic-banner-bg" style={{cursor:'pointer'}} onClick={() => {
                                                this.profile_banner.click();
                                            }}>

                                                {this.state.user && this.state.user.profile_banner_full && this.state.user.profile_banner_full != ''
                                                    ?
                                                        <span className="profile-banner-preview" style={{backgroundImage:'url('+(this.state.user.profile_banner_full)+')'}}></span>
                                                    :
                                                        <FeatherIcon icon="image" />
                                                }
                                                <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e, 'profile_banner')}} ref={(input)=> this.profile_banner = input}/>
                                            </div>
                                        </div>

                                        {this.state.image_uploading &&
                                            <Loader />
                                        }
                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Name" name="name" rules={[{required:true}]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item className="w-50" label="Company Name" name="company_name" rules={[{required:(this.state.user.type == 'individual' ? false : true)}]}>
                                            <Input disabled={this.state.user.type == 'individual' ? true : false} />
                                        </Form.Item>
                                    </div>

                                    <div className="d-flex form-item-block">
                                        
                                        <div className="ant-row ant-form-item w-50 ant-form-item-has-success">
                                            <div className="ant-col ant-col-24 ant-form-item-label">
                                                <label className="ant-form-item-required" title="Mobile">Mobile</label>
                                            </div>
                                            <div className="ant-col ant-col-24 ant-form-item-control">
                                                <div className="ant-form-item-control-input">
                                                    <div className="ant-form-item-control-input-content">
                                                        {this.state.user ? this.state.user.contact_no : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ant-row ant-form-item w-50 ant-form-item-has-success">
                                            <div className="ant-col ant-col-24 ant-form-item-label">
                                                <label className="ant-form-item-required" title="Email">Email</label>
                                            </div>
                                            <div className="ant-col ant-col-24 ant-form-item-control">
                                                <div className="ant-form-item-control-input">
                                                    <div className="ant-form-item-control-input-content">
                                                        {this.state.user ? this.state.user.email : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Date of birth" name={"dob"} rules={[{required:true}]}>
                                            <DatePicker suffixIcon={<CalendarFilled style={{color:'#6E939C'}} />} disabledDate={(current) => {
                                                return !current || current > moment().endOf('day');
                                            }} format='DD-MM-YYYY' />
                                        </Form.Item>

                                        <Form.Item className="w-50" label="Country" name="country" rules={[{required:true, message:'Please select country!'}]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a country"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >

                                                {this.countriesList()}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-100" label="Address" name="address" rules={[{required:true}]}>
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    <div className="d-flex form-item-block">

                                        <Form.Item className="w-50" label="Zip Code / Post Code" name="postcode" rules={[{required:true}]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item className="w-50" label="City" name="city" rules={[{required:true, message:'Please select city!'}]}>
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    {this.state.user.type == 'individual' &&
                                        <div className="d-flex form-item-block">
                                            <Form.Item className="w-50" label="Availability" name="availability" rules={[{required:true, message:'Please select availability!'}]}>
                                                <Select>
                                                    <Option value="na">Not Available</Option>
                                                    <Option value="full_time">Full Time</Option>
                                                    <Option value="part_time">Part Time</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item className="w-50" label="Hourly Rate (INR)" name="price" rules={[{required:true, message:'Please enter amount!'}]}>
                                                <InputNumber />
                                            </Form.Item>
                                        </div>
                                    }

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-100" label="What's your story in one line (Upto 100 words)" name="story" placeholder="e.g., A passionate Data Scientist with 15yrs experiences" rules={[{required:true}, {max: 100}]}>
                                            <Input.TextArea showCount maxLength={100} rows={4} />
                                        </Form.Item>
                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-100" label="Profile Description (upto 500 words)" name="description" rules={[{required:true}]}>
                                            <Input.TextArea rows={4} />
                                        </Form.Item>
                                    </div>

                                    {/* <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Job you are looking for?" name="job_looking" rules={[{required:true, message:'Please choose option!'}]}>
                                            <Select placeholder="Select one option" allowClear onChange={(value) => {

                                                this.setState({job_looking_for: value})
                                            }}>

                                                {(this.props.job_options && this.props.job_options.length > 0) &&

                                                    this.props.job_options.map((_option) => {

                                                        return (

                                                            <Option key={"job_option_" + _option.id} value={_option.id}>{_option.label}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item className="w-50" label="Your current job status" name="current_job_status" rules={[{required:true, message:'Please choose option!'}]}>
                                            <Select placeholder="Select one option" allowClear onChange={(value) => {
                                                this.setState({current_job_status: value})
                                            }}>

                                                {(this.props.job_status_options && this.props.job_status_options.length > 0) &&

                                                    this.props.job_status_options.map((_option) => {

                                                        return (

                                                            <Option key={"job_status_" + _option.id} value={_option.id}>{_option.label}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="d-flex form-item-block">
                                        <Form.Item className="w-50" label="Hours of work in week" name="weekly_work_hours" rules={[{required:true, message:'Please choose option!'}]}>
                                            <Select placeholder="Select one option" allowClear>

                                                {(this.props.work_hour_options && this.props.work_hour_options.length > 0) &&

                                                    this.props.work_hour_options.map((_option) => {

                                                        return (

                                                            <Option key={"job_option_" + _option.id} value={_option.id}>{_option.label}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                        {this.state.current_job_status
                                            ?
                                                (this.state.current_job_status == 1 || this.state.current_job_status == 2)
                                                    ?
                                                        <Form.Item className="w-50" label="Hourly Rate" name="hourly_rate" rules={[{required:true}]}>
                                                            <Input />
                                                        </Form.Item>
                                                    :
                                                        <Form.Item className="w-50" label="Current CTC" name="current_ctc" rules={[{required:true}]}>
                                                            <Input />
                                                        </Form.Item>
                                            :
                                                null
                                        }
                                    </div> */}

                                    {/* <div className="d-flex form-item-block">
                                        {(this.state.job_looking_for && (this.state.job_looking_for == 1 || this.state.job_looking_for == 3)) &&
                                        
                                            <Form.Item className="w-50" label="GST (If Applicable)" name="gst_no" rules={[{required:false}]}>
                                                <Input />
                                            </Form.Item>
                                        }
                                        <Form.Item className="w-50" label="Vat (For European Countries)" name="vat_no" rules={[{required:false}]}>
                                            <Input />
                                        </Form.Item>
                                    </div> */}

                                    <div className="form-item-block">

                                        <Form.Item className="w-50" style={{width:'50%'}} label="Languages" name="languages_option" rules={[{required:false, message:'Please choose option!'}]}>
                                            <Select
                                                showSearch
                                                placeholder="Select one option"
                                                // allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(language) => {

                                                    if(language != ''){

                                                        var label = '';
                                                        var languages = this.props.languages;
                                                        for(var i in languages){

                                                            if(languages[i]['id'] == language){

                                                                label = languages[i]['label'];
                                                            }
                                                        }

                                                        var selected_languages = this.state.selected_languages;
                                                        selected_languages.push({'language': language, 'label': label, 'proficiency': ''});
                                                        this.setState({selected_languages: selected_languages});
                                                    }
                                                }
                                            }>

                                                {this.props.languages && this.props.languages.length > 0
                                                    ?
                                                        this.props.languages.map((language) => {

                                                            var key = 'language_' + language.id;
                                                            if(!this.isUsedLanguage(language.id)){
                                                            
                                                                return <Option key={key} value={language.id}>{language.label}</Option>
                                                            }else{

                                                                return <Option disabled key={key} value={language.id}>{language.label}</Option>
                                                            }
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </Form.Item>

                                        {this.language_options()}

                                        {/* <button type="button" style={{marginTop:20}} className="btn btn-xs" onClick={() => {

                                            var selected_languages = this.state.selected_languages;
                                            selected_languages.push({'language': '', 'proficiency': ''});
                                            this.setState({selected_languages: selected_languages});

                                        }}>+</button> */}

                                        {this.state.language_error &&
                                        
                                            <div class="ant-form-item-explain" style={{color:'#ff4d4f'}}><div>Please choose minimum one language</div></div>
                                        }
                                    </div>

                                    <div className="d-flex form-item-block justify-content-end">

                                        <Form.Item {...tailLayout}>
                                            <Button className="btn" onClick={() => {
                                                this.setState({continue: false})
                                            }} type="secondary" htmlType="submit" loading={this.state.loading}>Save</Button>
                                            <Button className="btn ml-10" onClick={() => {
                                                this.setState({continue: true})
                                            }} type="primary" htmlType="submit" loading={this.state.loading}>Save & Continue</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    validateMobile = (rule, value) => {
        if(isNaN(value)){

            return Promise.reject('The two passwords that you entered do not match!');
        }else{

            return Promise.resolve();
        }
    }

    language_options = () => {

        var list = [];

        console.log(this.state.selected_languages);
        if(this.state.selected_languages.length > 0){

            var selected_languages = this.state.selected_languages;

            for(var i in selected_languages){

                list.push(this.language_row(selected_languages[i], i));
            }
        }

        return (

            <table width="100%" cellPadding="10">
                <tbody>
                    {list}
                </tbody>
            </table>
        )
    }

    addProficiency = (proficiency, _num) => {

        if(this.state.selected_languages.length > 0){

            var selected_languages = this.state.selected_languages;
            selected_languages[_num]['proficiency'] = proficiency;

            this.setState({selected_languages: selected_languages});
        }
    }

    language_row = (_language, _num) => {

        return (

            <tr key={'tr_' + _num}>

                <td width="30%" style={{borderBottom:'1px solid #ccc'}}>

                    <strong>{_language.label}</strong>
                </td>
                <td width="5%"></td>
                {/* <td width="15%" style={{borderBottom:'1px solid #ccc'}}>

                    <Select placeholder="Proficiency" value={_language.proficiency} style={{width: '100%'}} onChange={(value) => {
                        this.addProficiency(value, _num)
                    }}>
                        <Option value="low">Low</Option>
                        <Option value="intermediate">Intermediate</Option>
                        <Option value="high">High</Option>
                    </Select>
                </td> */}
                <td width="10%" valign="center" align="center">

                    <a style={{cursor:'pointer', display:'block'}} onClick={() => {

                        var selected_languages = this.state.selected_languages;
                        selected_languages.splice(selected_languages.indexOf(_num), 1);
                        this.setState({selected_languages: selected_languages})
                    }}>
                        <FeatherIcon icon="x" style={{color:'#ff4d4f'}} />
                    </a>
                </td>
                <td width="45%"></td>
            </tr>
        )
    }

    isUsedLanguage = (_lang) => {

        var selected_languages = this.state.selected_languages;
        if(selected_languages.length > 0){

            for(var n in selected_languages){

                if(selected_languages[n]['language'] == _lang){

                    return true;
                }
            }
        }
        return false;
    }

    countriesList = () => {

        if((this.props.countries_list && this.props.countries_list.length > 0)){
                                                
            return this.props.countries_list.map((_country) => {

                return (

                    <Option key={"country_code_" + _country.id} value={parseInt(_country.id)}>{_country.name}</Option>
                )
            })
        }
    }

    updatePhoto = (profile_pic, type) => {

        var formData = new FormData();
        formData.append('account_token', this.state.account_token);

        if(type == 'profile_banner'){

            formData.append('profile_banner', profile_pic);
        }else{

            formData.append('profile_pic', profile_pic);
        }
        
        var that = this;

        this.setState({image_uploading: true});
        Api.post('freelancer/profile_update_photo', formData, function(data){
            
            that.setState({image_uploading: false});
            
            if(data.status == true){
            
                that.props.updateUser(data.user);
                that.props.onSuccess(data.message);

                that.setState({user: data.user});
            
            }else{
                that.props.onError(data.message);
            }
        });
    }
}

export default PersonalInfo;