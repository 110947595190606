import React, { Component } from 'react';

import { Link, Redirect } from "react-router-dom";

import Main from '../../components/Main';

import Api from '../../api/Api';
import LayoutHelper from '../../helpers/LayoutHelper';

import ProductInfo from './blocks/ProductInfo';

class ProductDetailsPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',
            
            user: false,
            account_token: false,

            product: false,

            gallery: [],
            reviews: [],
            buyer_tips: [],
            how_it_works: [],

            buy_count: 1,
            selected_addons: [],

            buy_clicked: false,
            sub_total_per_product: 0,
            total: 0,

            loading: false,
            redirectTo: false,
        }

        // this.proposal_submission = React.createRef();
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        user = JSON.parse(user);

        if(user){

            this.setState({user: user});
        }

        var account_token = localStorage.getItem('account_token');
        if(account_token){

            this.setState({account_token: account_token});
        }

        const product_id = this.props.product_id;

        if(product_id && product_id != ''){
            this.loadData(product_id, account_token);
        }else{
            this.setState({redirectTo: '/products-search/'});
        }
    }

    render () {

        if (this.state.redirectTo) {

            return <Redirect to={this.state.redirectTo} />;
        }

        return (

            <Main full_content={true} error_message={this.state.error_message} success_message={this.state.success_message}>
                
                <ProductInfo
                    gallery={this.state.gallery}
                    product={this.state.product}
                    messages={this.state.messages}
                    updateMessage={(row) => {

                        let messages = this.state.messages;

                        messages.push(row);

                        this.setState({messages: messages})
                    }}
                    buyer_tips={this.state.buyer_tips}
                    how_it_works={this.state.how_it_works}

                    onError={(message) => {
                        LayoutHelper.addErrorMessage(this, message)
                    }}
                    onSuccess={(message) => {
                        LayoutHelper.addSuccessMessage(this, message)
                    }}
                    updateProject={(project, status) => {
                        this.setState({project: project, proposal_submitted: status});
                    }}
                    proposal_submitted={this.state.proposal_submitted}
                    updateProposalSubmitted={(status) => {
                        this.setState({proposal_submitted: status})
                    }}
                />

            </Main>
            
        )
    }

    loadData = (product_id, account_token) => {

        var formData = new FormData();
        
        if(account_token){
            formData.append('account_token', account_token);
        }
        formData.append('product_id', product_id);

        var that = this;
        this.setState({loading: true});

        Api.post('products/single', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){

                that.setState({product: data.product});
                that.setState({gallery: data.product.gallery});
                that.setState({reviews: data.product.reviews});

                that.setState({buyer_tips: data.buyer_tips});
                that.setState({how_it_works: data.how_it_works});
            }else{
                that.setState({back: true});
            }
        });
    }
}

export default ProductDetailsPage;