import React, { Component } from 'react';

import { Link } from "react-router-dom";

import Api from '../../api/Api';

import Main from '../../components/Main';

import '../../assets/styles/gig_hiring_style.css';

class ServicePartnerPage extends Component { 
   

    render () {

        return (

            <Main page_title="NDA Page" hide_page_title="true">
                <div className="bg-white tacs_wrapper">
                 <section className="tacs-banner service-partner">
                    <div className="gig_container">
                        <div className="row">
                        <div className="w-50 col-xs-6">
                        </div>
                        <div className="w-50 ml-10">
                            <h5>Service Partners</h5>
                            <h1 className='c-w'>Forging Ties</h1>
                            <h4 className='c-w'>for mutual success</h4>
                            <div className="flex hero-buttons">
                            <a href="http://localhost:3000/gig-hiring" className="df-bg-rust">Client</a> <a href="http://localhost:3000/service-partners" className="df-bg-blue">Service Partners</a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>

                    <section>
                    <div className="gig_container w_1k mb-row">
                        <div className="row">
                        <div className="w-100">
                            <h6 className="tacs-subheading text-center">VALUE PROGRAM</h6>
                            <h2 className="tacs-mainheading text-center width_90">Key Value For Service Partners</h2>
                        </div>
                        </div>
                        <div className="row margin_top_5">
                        <div className="w-50 col-xs-6">
                            <img src="https://cogniticx.digitalflic.com/app/media/uploads/tacs_images/incremental_sales.jpg" alt="" />
                            <h2 className="tacs-sectionheading ml-5 mb-0 mt-10">Incremental</h2>
                            <h6 className="tacs-headingdesc ml-5">Sales opportunities</h6>
                        </div>
                        <div className="w-50 col-xs-6 ml-20">
                            <img src="https://cogniticx.digitalflic.com/app/media/uploads/tacs_images/reduced_cost.jpg" alt="" />
                            <h2 className="tacs-sectionheading ml-5 mb-0 mt-10">Reduced Cost</h2>
                            <h6 className="tacs-headingdesc ml-5">of operations</h6>
                        </div>
                        </div>
                    </div>
                    </section>

                    <section className="bg-wify mb-row">
                    <div className="gig_container w_1k pt-5">
                        <div className="row margin_top_5">
                        <div className="w-50">
                            <h2 className="tacs-sectionheading ml-5 mb-0 mt-10">W.I.F.Y. </h2>
                            <h6 className="tacs-headingdesc ml-5">for select partners we invest our sales efforts for you. We are committed to you!</h6>
                        </div>
                        <div className="w-50 ml-30">
                            <h2 className="tacs-sectionheading mb-0 mt-10">Aquire Skills</h2>
                            <h6 className="tacs-headingdesc">Acquire critical skills, on demand. Scale up and down on a fly!</h6>
                        </div>
                        </div>
                    </div>
                    </section>

                    <div className="gig_container">
                    <div className="row">
                        <div className="w-100">
                        <div className="cta_button_wrapper"><a href="tel:+91 9891926677" class="cta_button">Reach Us At<br /><span>+91 98919 26677</span></a></div>
                        </div>
                    </div>
                    </div>

                    <section className="bg-branding ">
                    <div className="gig_container w_1k mb-row">
                        <div className="row margin_top_5">
                        <div className="w-50">
                            <h2 className="tacs-sectionheading ml-5 mb-0 mt-10">Monetize excess bench </h2>
                            <h6 className="tacs-headingdesc ml-5">Out resourcing – let your talent investments work for you </h6>
                        </div>
                        <div className="w-50 ml-30">
                            <h2 className="tacs-sectionheading mb-0 mt-10">Branding and visibility</h2>
                            <h6 className="tacs-headingdesc">at cogniticx events</h6>
                        </div>
                        </div>
                    </div>
                    </section>


                    <section className="bg-zero_cost pb-25">
                    <div className="gig_container w_1k">
                        <div className="row">
                         <div className="w-100 text-center">
                            <h2 className="mb-0">ZERO COST TO YOU!</h2>
                            <h3 className="mb-0">Our services are free for qualified partners</h3>
                            <p>We charge only if and when we make a difference to your business! </p>
                            <div className="cta_button_wrapper"><a href="tel:+91 9891926677" class="cta_button">Reach Us At<br /><span>+91 98919 26677</span></a></div>
                         </div>
                        </div>
                    </div>
                    </section>

                    </div>
            </Main>
        )
    }


  
}

export default ServicePartnerPage;