import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import Main from 'components/Main';

import Api from 'api/Api';

import { Table, Tabs, Empty, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import SingleProduct from 'components/products/FreelancerProductBlock';

import LayoutHelper from 'helpers/LayoutHelper';

class ProductsBuyerList extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            active_tab: 'active',

            account_token: false,
            user: false,

            active: [],
            delivered: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        var _user = JSON.parse(user);
        this.setState({user: _user});

        if(account_token){
            
            this.setState({account_token: account_token});

            this.loadProducts(account_token, 'active');
        }else{
            // window.location = Api.url + '/logout';
        }
    }
    
    list = (products, type) => {

        return (

            <div className="products_offers">
                {products && products.length > 0
                    ?
                        products.map((product, index) => {

                            return <SingleProduct key={type + index} can_edit={true} product={product} user={this.state.user} />
                        })
                    :
                        null
                }
            </div>
        )
    }

    render () {

        return (

            <>
                <Main page_title="Manage Products" hide_page_title="true" className="products-list account-bg" page="products" error_message={this.state.error_message} success_message={this.state.success_message}>
                    
                    <div className="container-lg page-main-content">
                        
                        <div className="page-heading flex justify-between">
                            <span>Bought Products</span>
                        </div>

                        <Tabs className="manage-products-list" defaultActiveKey="active" onTabClick={(key, event) => {
                    
                            this.setState({active_tab: key});

                            if(key == 'active'){

                                this.loadProducts(this.state.account_token, 'active');
                            }

                            if(key == 'delivered'){

                                this.loadProducts(this.state.account_token, 'delivered');
                            }
                        }}>
                            
                            <Tabs.TabPane tab={
                                <a>
                                    <span>In Progress</span>
                                </a>
                            } key="active">

                                <Table columns={this.getColumns()} dataSource={this.state.active}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={
                                <a>
                                    <span>Completed</span>
                                </a>
                            } key="delivered">

                                <Table columns={this.getColumns()} dataSource={this.state.delivered}>
                                    <Empty />
                                </Table>
                            </Tabs.TabPane>

                        </Tabs>
                    </div>
                </Main>
            </>
        )
    }

    getColumns = () => {

        const columns = [

            {title: 'Service Provider', dataIndex: 'seller_name', key: 'seller_name', render: (name, row) => {
                return (
                    <Link to={`/project-flow/${row.project_unq_id}/${row.buyer_id}`}>
                        
                        {row.seller_photo !== ''
                            ?
                                <Avatar size={45} src={row.seller_photo} />
                            :
                                <Avatar size={45} icon={<UserOutlined />} />
                        }

                        <span className="ml-5 fw-b c-b">{name}</span>
                    </Link>
                )
            }},
            {title: 'Product', dataIndex: 'title', key: 'title',},
            {title: 'Start Date', dataIndex: 'awarded_on_date_formatted', key: 'awarded_on_date_formatted',},
            {title: 'Budget', dataIndex: 'budget', key: 'budget',},
            {title: 'Action', key: 'action', render: (name, row) => {

                return (

                    <Link className="btn" key="details" to={`/project-flow/${row.project_unq_id}/${row.buyer_id}`}>Details</Link>
                )
            }},
        ];

        return columns;
    }

    loadProducts = (account_token, type) => {
        
        var formData = new FormData();
        
        formData.append('account_token', account_token);
        formData.append('type', type);

        console.log(type);
        
        var that = this;

        this.setState({loading: true});
        Api.post('products/bought', formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status){
            
                if(type == 'active'){

                    that.setState({active: data.products});

                }else if(type == 'delivered'){
                    
                    that.setState({delivered: data.products});

                }else{
                    
                    that.setState({all_products: data.products});
                }
            }else{

                // if(data.code == 'no_account'){
                //     window.location = Api.url + '/logout';
                // }
                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default ProductsBuyerList;