import React, { Component } from 'react';

import ProfileCard from '../../components/ProfileCard';

import Price from 'components/Price';

class ProjectFlowSidebar extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

        }
    }
    
    render () {

        var user = this.props.project.project_view == 1 ? this.props.buyer : this.props.seller;

        if(user){

            user.freelancer_name = user.name;

            return (

                <>
                    <ProfileCard
                        user={user}
                        className="bordered mb-10"
                    >
                        <div className='justify-start vertical w-100 p-20'>

                            <div className="justify-between w-100 mb-10">
                                <span className="c-b fw-b">{this.props.project.project_view == 1 ? 'Earnings' : 'Spendings'}</span>
                                <span className="c-b">
                                    <Price>{this.props.project.project_view == 1 ? this.props.buyer.earnings : this.props.seller.spendings}</Price>
                                </span>
                            </div>
                        </div>
                    </ProfileCard>
                </>
            )
        }else{

            return null
        }
    }
}

export default ProjectFlowSidebar;