import React, { Component } from 'react';

// add form components
import { Button, Modal, Tabs, Form, Input, InputNumber, Checkbox, Rate, Row, Col, Popconfirm } from 'antd';

import FeatherIcon from 'feather-icons-react';

import { CrownFilled, StarFilled, CloseOutlined, FileDoneOutlined, PaperClipOutlined, FilePdfFilled } from '@ant-design/icons';

import Api from 'api/Api';

import Price from 'components/Price';

import NoData from 'components/NoData';

class Footer extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,

            button_disabled: false,

            message: '',

            invoice_items: [{
                description: '',
                fixed_cost: 0,
                amount: 0
            }],

            invoice_tax: 0,
            invoice_total: 0,

            invoice_terms_checked: false,
            submitting_invoice: false,

            nda_button: false,
            nda_terms: true,

            rating_popup: false,

            delivery_note: '',

            gallery: [],
            attachment_uploading: false
        }

        this.send_message_form = React.createRef();
        this.invoice_form = React.createRef();
    }

    tabExtraActions = () => {

        if(this.props.project.status == 3 && this.props.project.project_view == 0){

            if(this.props.project.buyer_feedback == 0){

                return (
                    <Button type="link" onClick={() => {
                        this.setState({rating_popup: true})
                    }}>
                        <strong className='c-b'><StarFilled /> Share Feedback</strong>
                    </Button>
                )
            }else{

                return (
                    <>
                        <StarFilled /> <strong>Feedback submitted</strong>
                    </>
                )
            }
        }

        if(this.props.project.status == 3 && this.props.project.project_view == 1){

            if(this.props.project.seller_feedback == 0){

                return (
                    <Button type="link" onClick={() => {
                        this.setState({rating_popup: true})
                    }}>
                        <StarFilled /> Share Feedback
                    </Button>
                )
            }else{

                return (
                    <div className='c-green'>
                        <StarFilled /> <strong>Feedback submitted</strong>
                    </div>
                )
            }
        }
    }

    updateProjectStatus = (status) => {
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        var that = this;

        this.setState({loading: true});

        if(status == 'delivered'){
        
            var url = 'projects/delivered';
        }else{
        
            var url = 'proposals/request_nda';
        }

        Api.post(url, formData, function(data){
            
            that.setState({loading: false});
            
            if(data.status == true){
            
                that.props.updateProposals(data.row);
                that.props.onSuccess(data.message);
                that.props.updateProject(data.project);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }
    
    render () {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        // if(this.props.project.project_type === 'q'){

        //     if(this.props.project.status == '3'){

        //         return false;   
        //     }
        // }

        return (

            <>
                <div className="send-message-block mt-20">

                    {this.props.project.project_view == 1 && this.props.project.status == 2 && this.props.project.awarded_freelancer !== this.props.user.unq_index_id
                        ?
                            <NoData className="mt-20" message="This project has been awarded to another service provider" hide_image={true} icon={<CrownFilled />} />
                        :
                            null
                    }

                    <Tabs defaultActiveKey="1" animated={false} tabBarExtraContent={this.tabExtraActions()}>
                        
                        {
                            (
                                (
                                    (this.props.project.project_view == 1 && this.props.project.status == 1)
                                    ||
                                    (this.props.project.project_view == 1 && this.props.project.status == 2 && this.props.project.awarded_freelancer === this.props.user.unq_index_id)
                                    ||
                                    this.props.project.project_view == 0
                                )
                                &&
                                (this.props.project.project_type === 'p' || this.props.project.project_type === '')
                            )
                            ||
                            (
                                this.props.project.project_type === 'q' && this.props.project.status != 3
                            )
                            ?
                        
                                this.messageTab()
                            :
                                null
                        }

                        {
                            (
                                this.props.project.project_view == 1
                                &&
                                (
                                    this.props.project.status != 1
                                    &&
                                    this.props.project.status != 5
                                    &&
                                    this.props.project.status != 6
                                    &&
                                    this.props.project.status != 7
                                )
                                &&
                                (
                                    this.props.project.awarded_freelancer === this.props.user.unq_index_id
                                )
                                &&
                                (
                                    this.props.project.project_type === 'p' || this.props.project.project_type === ''
                                )
                            ) &&

                            <>

                                {this.props.project.project_type !== 'q' &&

                                    <Tabs.TabPane tab={
                                        <div className=''>
                                            <FeatherIcon icon="file-text" /><span>Raise Invoice</span>
                                        </div>} key="2">

                                        {/* {this.props.project.status == '3'
                                            ?
                                                <NoData className="mt-20" message="Project has been closed" hide_image={true} icon={<CrownFilled />} />
                                            :
                                         */}
                                                <div className="invoice-form-block">
                                                    <div className="block-heading">
                                                        <div className="">New Invoice</div>
                                                    </div>
                                                    <Form name="invoice_form" id="invoice_form" ref={this.invoice_form} onFinish={this.generateInvoice.bind(this)} initialValues={{ invoice_tnc: true }}>

                                                        <Row gutter={16}>
                                        
                                                            <Col span={14}>
                                                                <label>Description</label>
                                                            </Col>
                                                            <Col span={8}>
                                                                <label>Cost</label>
                                                            </Col>
                                                        </Row>

                                                        {this.invoiceItemRow()}

                                                        <div>
                                                            <Button onClick={() => {
                                                                this.addInvoiceRow()
                                                            }} type="primary">+</Button>
                                                        </div>

                                                        {/* <div className="justify-end mb-10">

                                                            <div className="d-flex justify-content-between w-40">
                                                                <label className="w-50">Includes Tax</label>
                                                                
                                                                <Form.Item className="w-50" name="tax">
                                                                    <InputNumber onChange={(tax) => {
                                                                        this.setState({invoice_tax: tax});
                                                                        window.setTimeout(() => {
                                                                            this.calculateTotal()
                                                                        }, 200)
                                                                    }} />
                                                                </Form.Item>
                                                            </div>
                                                        </div> */}

                                                        <div className="d-flex justify-content-end form-item-block">
                                                            <div className="d-flex justify-content-between w-40">
                                                                <label className="w-50">Total</label>
                                                                
                                                                <Form.Item className="w-50" name="total">
                                                                    <p style={{textAlign:'right'}}>
                                                                        <Price>{this.state.invoice_total}</Price>
                                                                    </p>
                                                                </Form.Item>
                                                            </div>
                                                        </div>

                                                        <div className="justify-between">
                                                            <Form.Item name="invoice_tnc" valuePropName="checked">
                                                                <Checkbox onChange={(e) => {

                                                                    this.setState({invoice_terms_checked: !e.target.checked})
                                                                }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet massa interdum.</Checkbox>
                                                            </Form.Item>

                                                            <Form.Item>
                                                                <Button loading={this.state.submitting_invoice} disabled={this.state.invoice_terms_checked} type="primary" htmlType="submit">Send</Button>
                                                            </Form.Item>
                                                        </div>
                                                    </Form>
                                                </div>
                                        {/* } */}
                                    </Tabs.TabPane>
                                }

                                <Tabs.TabPane tab={
                                
                                    <div className=''>
                                        <FileDoneOutlined /><span>Deliver Project</span>
                                    </div>} key="3">

                                    {this.props.project.status == '3'
                                        ?
                                            <NoData className="mt-20" message="Project has been closed" hide_image={true} icon={<CrownFilled />} />
                                        :
                                            <div className="invoice-form-block">
                                                <div className="block-heading">
                                                    <div className="">Deliver Project</div>
                                                </div>
                                                <div className='ant-form ant-form-vertical'>

                                                    <Row gutter={4}>

                                                        <Col span={24}>

                                                            <Form.Item label="Notes">
                                                                <Input.TextArea
                                                                    rows={4}
                                                                    onChange={(e) => {

                                                                        this.setState({delivery_note: e.target.value})
                                                                    }}
                                                                    value={this.state.delivery_note}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <div className="justify-end">

                                                        <Form.Item>
                                                            <Popconfirm
                                                                title={this.state.delivery_note === '' ? 'You sure you want to submit without notes?' : 'You really want to submit this project?'}
                                                                onConfirm={() => {

                                                                    this.deliverProject()
                                                                }}
                                                                onCancel={() => {

                                                                    this.setState({delivery_note: ''})
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button type="primary" loading={this.state.loading}>Submit</Button>
                                                            </Popconfirm>
                                                            
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </Tabs.TabPane>
                            </>
                        }

                        {
                            (
                                this.props.project.project_view == 0
                                &&
                                (
                                    this.props.project.status != 1
                                    &&
                                    this.props.project.status != 5
                                    &&
                                    this.props.project.status != 6
                                    &&
                                    this.props.project.status != 7
                                )
                                &&
                                (
                                    this.props.project.awarded_freelancer !== this.props.user.unq_index_id
                                )
                                &&
                                (
                                    this.props.project.project_type === 'q'
                                )
                            ) &&

                                <>
                                    <Tabs.TabPane tab={
                                
                                        <div className=''>
                                            <FileDoneOutlined /><span>Deliver Project</span>
                                        </div>} key="3">

                                        {this.props.project.status == '3'
                                            ?
                                                <NoData className="mt-20" message="Project has been closed" hide_image={true} icon={<CrownFilled />} />
                                            :
                                                <div className="invoice-form-block">
                                                    <div className="block-heading">
                                                        <div className="">Deliver Project</div>
                                                    </div>
                                                    <div className='ant-form ant-form-vertical'>

                                                        <Row gutter={4}>

                                                            <Col span={24}>

                                                                <Form.Item label="Notes">
                                                                    <Input.TextArea
                                                                        rows={4}
                                                                        onChange={(e) => {

                                                                            this.setState({delivery_note: e.target.value})
                                                                        }}
                                                                        value={this.state.delivery_note}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <div className="justify-end">

                                                            <Form.Item>
                                                                <Popconfirm
                                                                    title={this.state.delivery_note === '' ? 'You sure you want to submit without notes?' : 'You really want to submit this project?'}
                                                                    onConfirm={() => {

                                                                        this.deliverProject()
                                                                    }}
                                                                    onCancel={() => {

                                                                        this.setState({delivery_note: ''})
                                                                    }}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button type="primary" loading={this.state.loading}>Submit</Button>
                                                                </Popconfirm>
                                                                
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </Tabs.TabPane>
                                </>
                        }
                    </Tabs>

                    <Modal
                        title="Submit your feedback"
                        visible={this.state.rating_popup}
                        // onOk={this.handleOk}
                        closable={true}
                        onCancel={() => {
                            this.setState({rating_popup: false})
                        }}
                        width={700}
                        footer={null}
                    >
                        <div className="new-proposal-form">

                            <Form {...layout} scrollToFirstError={true} name="rating_form" initialValues={{ remember: true }} onFinish={this.submitRating.bind(this)}>

                                <div>
                                    <div className='justify-start'>
                                        <Form.Item name="rating" label={this.feedbackLabel()} rules={[{required: true}]}>

                                            <Rate tooltips={['terrible', 'bad', 'normal', 'good', 'wonderful']} allowClear={true} />
                                        </Form.Item>
                                    </div>

                                    <div>
                                        
                                        <Form.Item name="review" label="Write about your experience" rules={[{required: true}]}>
                                            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        </Form.Item>
                                    </div>
                                    
                                    <div className="terms">
                                        <Row justify="space-between" align="bottom">
                                            <Col span={24}>
                                                <div className='justify-end'>
                                                
                                                    <Button className='self-right' loading={this.state.loading} htmlType="submit" type="primary">Submit</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </>
        )
    }

    feedbackLabel = () => {

        if(this.props.project.project_view == 1){

            if(this.props.project.project_type === 'q'){

                return 'Rate the Service provider';
            }else{

                return 'Rate the client'
            }
        }else{

            if(this.props.project.project_type === 'q'){

                return 'Rate the client';
            }else{

                return 'Rate the Service provider'
            }
        }
    }

    messageTab = () => {

        return (
            <Tabs.TabPane tab={
                <div className=''>
                    <FeatherIcon icon="mail" /><span>Send Message</span>
                </div>} key="1">

                <Form name="send_message_form" id="send_message_form" ref={this.send_message_form} onFinish={this.sendMessage.bind(this)} initialValues={{ tnc: true }}>
                    <div className="d-flex form-item-block">
                        <Form.Item className="w-100" name="message" rules={[{required:true}]}>
                            <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                onChange={(e) => {
                                    this.setState({message: e.target.value})
                                }}
                                value={this.state.message}
                            />
                        </Form.Item>
                    </div>

                    <div className="d-flex form-item-block justify-end vertical">

                        {this.renderGallery()}
                        
                        <label className="flex" style={{cursor: 'pointer'}}>
                            <input type="file" className="hidden" onChange={(e) => {this._handleImageChange(e)}} ref={(input)=> this.myinput = input}/>
                            <PaperClipOutlined className="fs-22 c-b" />
                            <b className='c-b ml-3 fs-14'>Add Attachments</b>
                        </label>
                    </div>

                    <div className="justify-between mt-10">
                        <Form.Item name="tnc" valuePropName="checked">
                            <Checkbox onChange={(e) => {
                                this.setState({button_disabled: !e.target.checked})
                            }}>You are agree with our terms & conditions</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button disabled={this.state.button_disabled} type="primary" htmlType="submit">Send</Button>
                        </Form.Item>
                    </div>
                </Form>
            </Tabs.TabPane>
        )
    }

    deliverProject = () => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        if(this.state.delivery_note !== ''){
        
            formData.append('delivery_note', this.state.delivery_note);
        }
        
        var that = this;

        this.setState({loading: true});

        Api.post('proposals/deliver_request', formData, function(data){
            
            that.setState({loading: false, delivery_note: ''});

            if(data.status == true){

                that.props.onSuccess(data.message);
                that.props.updateProposals(data.row);

            }else{
                
                that.props.onError(data.message);
            }
        });
    }

    submitRating = (values) => {

        var formData = new FormData();
        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key]);
            }
        });

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);
        
        var that = this;

        this.setState({loading: true});

        Api.post('proposals/feedback', formData, function(data){
            
            that.setState({loading: false, rating_popup: false});

            if(data.status == true){

                that.props.onSuccess(data.message);
                that.props.updateProposals(data.row);
                that.props.updateProject(data.project);

            }else{
                
                that.props.onError(data.message);
            }
        });
    }

    generateInvoice = (values) => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });

        var that = this;

        this.setState({submitting_invoice: true});

        Api.post('invoices/generate', formData, function(data){
            
            that.setState({
                submitting_invoice: false,
                invoice_total: 0,
                invoice_items: [{
                    description: '',
                    fixed_cost: 0,
                    amount: 0
                }]
            });
            
            if(data.status){
            
                that.props.updateProposals(data.row);
                that.props.onSuccess(data.message);

                that.invoice_form.current.resetFields();
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    addInvoiceRow = () => {

        var invoice_items = this.state.invoice_items;
        invoice_items.push({
            description: '',
            qty: 1,
            fixed_cost: 0,
            time_period: '',
            amount: 0
        })
        this.setState({invoice_items: invoice_items});
    }

    calculateTotal = () => {

        var invoice_items = this.state.invoice_items;
        var invoice_tax = this.state.invoice_tax;

        var total = 0;

        for(var i in invoice_items){

            var _fixed_cost = invoice_items[i]['fixed_cost'];

            total = parseInt(total) + parseInt(_fixed_cost);
        }

        if(parseInt(invoice_tax) > 0){

            total = total + parseInt(invoice_tax);
        }

        var data = {invoice_total: total};

        this.setState(data);
        // this.refs.invoice_form.setFieldsValue(data);
    }

    invoiceItemRow = () => {

        var invoice_items = this.state.invoice_items;
        if(invoice_items.length > 0){

            var _invoice_items = [];

            for(var n = 0; n < invoice_items.length; n++){

                var i = n;
                var key = 'invoice_item_' + i;

                _invoice_items.push(

                    <Row key={key} gutter={16}>
                        
                        <Col span={14}>
                            <Form.Item name={"invoice_description["+n+"]"} rules={[{required:true, message: 'Required'}]}>
                                <Input value={invoice_items[i]['description']} onChange={(e) => {
                                    invoice_items[i]['description'] = e.target.value;
                                    this.setState({invoice_items: invoice_items});
                                    window.setTimeout(() => {
                                        this.calculateTotal();
                                    }, 200);
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"invoice_fixed_cost["+n+"]"} rules={[{required:true, message: 'Required'}]}>
                                <InputNumber value={invoice_items[i]['fixed_cost']} onChange={(fixed_cost) => {
                                    invoice_items[i]['fixed_cost'] = fixed_cost;

                                    var qty = invoice_items[i]['qty'];

                                    var amount = 0;
                                    if(fixed_cost > 0){

                                        amount = parseInt(fixed_cost) * qty;
                                    }
                                    invoice_items[i]['amount'] = amount;

                                    this.setState({invoice_items: invoice_items});
                                    window.setTimeout(() => {
                                        this.calculateTotal();
                                    }, 200);
                                }} />
                            </Form.Item>
                        </Col>

                        {i > 0 &&
                        
                            <Col span={2}>
                                <Button
                                    onClick={() => {
                                        this.removeRow(i);
                                    }}
                                    icon={<CloseOutlined />}
                                    shape="circle"
                                    danger
                                    size="small"
                                />
                            </Col>
                        }
                    </Row>
                );
            }
        }

        return _invoice_items;
    }

    removeRow = (i) => {

        var invoice_items = this.state.invoice_items;

        var _invoice_items = [];

        for(var n in invoice_items){

            if(n != i){

                _invoice_items.push(invoice_items[n])
            }
        }
        this.setState({invoice_items: _invoice_items});
    }
    
    sendMessage = (values) => {
        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('project', this.props.project.project_unq_id);

        if(this.props.user.unq_index_id === this.props.seller_id){

            formData.append('receiver', this.props.buyer.unq_index_id);
        }else{

            if(this.props.seller_id){

                formData.append('seller', this.props.seller_id);
            }
        }

        let gallery = this.state.gallery;

        if(gallery.length > 0){

            let _gallery = [];
            
            gallery.map((_item) => {

                _gallery.push(_item.file)
            })

            formData.append('gallery', JSON.stringify(_gallery));
        }

        Object.keys(values).forEach((key) => {

            if(values[key]){
                formData.append(key, values[key])
            }
        });
        
        var that = this;

        this.setState({loading: true});
        Api.post('messages/send', formData, function(data){
            
            that.setState({loading: false, message: '', gallery: []});

            that.send_message_form.current.setFieldsValue({message: ''});
            
            if(data.status == true){
            
                that.props.updateProposals(data.row);
                that.props.onSuccess(data.message);
            
            }else{
                that.props.onError(data.message);
            }
        });
    }

    _handleImageChange = async (e) => {

        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        const _file = file;
        
        this.setState({attachment_uploading: true});
    
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('tmp_file_name', file);
        formData.append('allowed_types', 'jpg,jpeg,png,gif,svg,webp,pdf,doc,docx,ppt,pptx,txt');
        formData.append('upload_dir', 'messages/');
        formData.append('project_id', this.props.project.project_unq_id);
        
        this.setState({attachment_uploading: true});
        var that = this;

        Api.post('imageuploader', formData, function(data){

            that.setState({image_uploading: false});

            if(data.status == 'success'){

                var url = data.random_dir + data.main_file_name;
                that.setState({media_url: data.media_url + 'messages/'});

                var gallery = that.state.gallery;
                
                gallery.push({extension: data.extension, file: url, url: data.media_url + 'messages/' + url});

                that.setState({gallery: gallery});
            }
        });
    }

    renderGallery = () => {

        let gallery = this.state.gallery;

        if(gallery.length > 0){

            const message_gallery = gallery.map((_gallery_item, index) => {

                return <li key={`message_gallery_${index}`} style={{position: 'relative'}}>

                            <button type="button" className='close' onClick={() => {

                                let gallery = this.state.gallery;
                                gallery.splice(index, 1);

                                this.setState({gallery: gallery})
                            }}>
                                <FeatherIcon icon="x" size={12} />
                            </button>
                    
                            {(_gallery_item.extension === 'png' || _gallery_item.extension === 'jpg' || _gallery_item.extension  === 'jpeg')
                                ?
                            
                                    <img className="preview-image" src={_gallery_item.url} />
                                :

                                    <a className='align-center vertical' href={_gallery_item.url} target="_blank">
                                        
                                        <FilePdfFilled className="fs-40 c-b" />

                                        <strong className='fs-10 mt-5 txt-uppercase'>{_gallery_item.extension}</strong>
                                    </a>
                            }
                        </li>
            })

            return <div className='mt-10 ml-10 form-gallery xs'><ul>{message_gallery}</ul></div>
        }
    }
}

export default Footer;