import React, { Component } from 'react';

import { Tag } from 'antd';

class Tags extends Component { 

    render () {

        var tags = this.props.tags;

        if(tags && tags.length > 0){

            return (

                <div className={"tags " + (this.props.size ? this.props.size : '')}>
                    
                    {this.renderTags()}

                    {(this.props.limit && (this.props.limit < (tags.length))) &&
                    
                        <span className="total-other">+{tags.length - this.props.limit} {this.props.limit_suffex}</span>
                    }
                </div>
            )
        }else{

            return null;
        }
    }

    renderTags = () => {

        var tags = this.props.tags;

        if(tags && tags.length > 0){

            var limit = tags.length;

            if(this.props.limit){

                if(this.props.limit <= limit){

                    limit = this.props.limit;
                }
            }

            var tags_html = [];

            for(var i = 0; i < limit; i++){

                tags_html.push(<Tag key={"tag_" + i}>{tags[i]}</Tag>);
            }

            return tags_html;
        }
    }
}

export default Tags;