import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import Main from '../../components/Main';

class Registerselect extends Component { 
    constructor(props) {
        super();
        this.state = {

            login: false
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){
            this.setState({login: true});
        }
    }

    render () {

        if(this.state.login){
            return <Redirect to='/account/profile'/>;
        }

        return (

            <Main page="get_started" full_content={true}>
                <div className="register-select-section inner-page white-bg text-center">
                    <div className="container">
                        <div className="heading">Let's Get Started!</div>
                        <div className="small-heading">We'd Like To Learn More About You.</div>
                        
                        <div className="blocks">
                            <Link to="/register/provider" className="block orng-bg">
                                <div className="slim-text">I Want To</div>
                                <div className="bold-text">Hire</div>
                            </Link>
                            <Link to="/register/seeker" className="block">
                                <div className="slim-text">I Want To</div>
                                <div className="bold-text">Work</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Main>
            
        )
    }
}

export default Registerselect;